import React from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const NewLabelForm = ({ localLabels, setLocalLabels }) => {

    const handleNewLabel = () => {
        const newLabelId = `new_${Date.now()}`;
        setLocalLabels({
            ...localLabels,
            [newLabelId]: {
                authorId: 'currentUser',
                name: '',
                description: '',
                createdAt: { seconds: Date.now() / 1000, nanoseconds: 0 },
                updatedAt: { seconds: Date.now() / 1000, nanoseconds: 0 }
            }
        });
    };

    const handleLabelChange = (id, field, value) => {
        setLocalLabels({
            ...localLabels,
            [id]: {
                ...localLabels[id],
                [field]: value,
                updatedAt: { seconds: Date.now() / 1000, nanoseconds: 0 }
            }
        });
    };

    return (
        <div>
            <Typography variant="h6">Új címke hozzáadása</Typography>
            <Button variant="outlined" onClick={handleNewLabel} startIcon={<AddIcon />}>
                Új címke
            </Button>
            <Grid container spacing={2}>
                {Object.keys(localLabels).filter(labelId => labelId.startsWith('new_')).map(labelId => {
                    const label = localLabels[labelId];
                    return (
                        <Grid item xs={12} key={labelId}>
                            <TextField
                                fullWidth
                                label="Név"
                                value={label.name}
                                onChange={e => handleLabelChange(labelId, 'name', e.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Leírás"
                                value={label.description}
                                onChange={e => handleLabelChange(labelId, 'description', e.target.value)}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default NewLabelForm;
