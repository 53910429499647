import { useContext, useEffect } from 'react';
import { onIdTokenChanged } from 'firebase/auth';
import { auth, functions, httpsCallable } from '../../firebase.config';
import { jwtDecode } from "jwt-decode";
import { AppContext } from '../appContext';
// import { deviceDetect, mobileModel } from 'react-device-detect';

const RefreshToken = () => {
    const { deviceId } = useContext(AppContext);
    const userLoginFunction = httpsCallable(functions, 'userLogin');

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(auth, async (user) => {
            if (user) {
                // A token frissült vagy megváltozott
                try {
                    // console.log('deviceId', deviceId);

                    // console.log(mobileModel);
                    // console.log(deviceDetect);
                    // Böngésző neve
                    const browserName = window.navigator.appName;

                    // Böngésző verziója
                    const browserVersion = window.navigator.appVersion;

                    // Operációs rendszer neve
                    const osName = window.navigator.platform;

                    // Felbontás
                    const screenWidth = window.screen.width;
                    const screenHeight = window.screen.height;

                    // console.log('Böngésző neve:', browserName);
                    // console.log('Böngésző verziója:', browserVersion);
                    // console.log('Operációs rendszer:', osName);
                    // console.log('Felbontás:', screenWidth, 'x', screenHeight);

                    const token = await user.getIdToken();
                    // console.log('Frissített token:', token);
                    // console.log('user', user?.uid);
                    const decodedToken = jwtDecode(token);

                    // Lejárati idő kinyerése
                    const expirationTime = decodedToken.exp;

                    // Az időt az alábbi formában tudod megjeleníteni (epoch másodpercekben)
                    // console.log('Lejárati idő:', new Date(expirationTime * 1000));
                    const data = {
                        deviceId: deviceId,
                        expiration: expirationTime,
                        browserVersion: browserVersion,
                        screenWidth: screenWidth,
                        screenHeight: screenHeight,
                        osName: osName,
                        userId: user?.uid
                    };
                    console.log('userLogin data', data);
                    // Hívjuk meg a Cloud Function-t
                    const result = await userLoginFunction(data);
                    // A Cloud Function válasza
                    // console.log(result.data);
                } catch (error) {
                    // Hiba a token lekérdezése vagy a Cloud Function hívás során
                    console.error('Hiba:', error);
                }
            } else {
                // A felhasználó kijelentkezett
                console.log('Felhasználó kijelentkezett');
            }
        });

        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    return null;
};

export default RefreshToken;
