import { TextField } from '@mui/material';
import React from 'react';

const FSPFieldDate = ({
    field,
    handleChange,
    value
}) => {
    const onChange = ((value) => {
        handleChange(field.name, value);
        // console.log(value);
        // console.log(field.name);
    });

    return (
        <div>
            <div>
                {field.label}
            </div>
            <TextField
                type='date'
                required={field.required ?? false}
                id={field.name}
                name={field.name}
                label={field.label}
                multiline={field.multiline ?? false}
                rows={field.rows ?? 1}
                onChange={handleChange}
                fullWidth
                value={value}
            />
            {/* <Calendar
                onChange={onChange}
                value={value}
                locale='hu-HU'
                formatLongDate={'yy-mm-dd'}
            /> */}
        </div>
    );
};

export default FSPFieldDate;