import React from 'react';
import { Typography, Badge, Tab, Tabs } from '@mui/material';
import { QrCode2 } from '@mui/icons-material';
import QRPage from './QRPage';
import QRCode from 'react-qr-code';

const Loading = () => {
    return (<>
        <Tabs
            // variant="scrollable"
            allowScrollButtonsMobile
            // scrollButtons="auto"
            // value={groupTab}
            variant="fullWidth"
            // onChange={handleChange}
            // showlabels={false}
            aria-label="icon tabs example"
        >
            <Tab value='qr' icon={<QrCode2 />} label="QR kód" />
        </Tabs>
        <div className="qrcodes">
            <div style={{ height: "auto", paddingTop: 30, margin: "0 auto", maxWidth: 256, width: "60%" }}>
                <QRCode
                    size={250}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={'https://kerdoiv.b612.hu/'}
                    viewBox={`0 0 250 250`}
                />
            </div>
        </div>
    </>
    );
};

export default Loading;