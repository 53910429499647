import { BarChart, Description } from '@mui/icons-material';
import React, { useContext } from 'react';
import { AppContext } from '../../services/appContext';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Badge, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
const EvaluationAlert = ({
    value,
    link = '/evaluations',
    title = 'Új értékelések',
    description = 'új értékelés érkezett'
}) => {
    const { newEvaluation } = useContext(AppContext);
    const newValue = value != null ? value : newEvaluation;
    return (<>
        {newValue > 0 && (
            <Accordion>
                <Link to={link}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>
                            <ListItem>
                                <ListItemAvatar>
                                    <Badge badgeContent={newValue} color="info">
                                        <BarChart />
                                    </Badge>
                                </ListItemAvatar>
                                <ListItemText primary={title} secondary={`${newEvaluation} ${description}`} />
                            </ListItem>
                        </Typography>
                    </AccordionSummary>
                </Link>
            </Accordion>
        )}
    </>
    );
};

export default EvaluationAlert;