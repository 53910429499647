import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem, FormControl, InputLabel, NativeSelect, OutlinedInput, Grid } from '@mui/material';
import ElementsEditor from './ElementsEditor';
import { Timestamp } from 'firebase/firestore';
import UpdateSchedule from '../../../services/actions/UpdateSchedule';
import CreateSchedule from '../../../services/actions/CreateSchedule';
import HeaderSimple from '../../myGroup/components/groupStats/components/components/components/HeaderSimple';

const daysOfWeek = [
    { value: 'monday', label: 'Hétfő' },
    { value: 'tuesday', label: 'Kedd' },
    { value: 'wednesday', label: 'Szerda' },
    { value: 'thursday', label: 'Csütörtök' },
    { value: 'friday', label: 'Péntek' },
    { value: 'saturday', label: 'Szombat' },
    { value: 'sunday', label: 'Vasárnap' },
];
const statuses = [
    { value: 'active', label: 'Aktív' },
    { value: 'end', label: 'Befejezve' },
    { value: 'error', label: 'Hiba' },
    { value: 'stop', label: 'Leállítva' },
];

const timesOfDay = [
    '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'
];

const SchedulesEditorForm = ({ open, setOpen, handleClose, formState,
    handleChange, groups, templates,
    elements, setElements, edited, setEdited
}) => {
    const { subscribe: subscribeUpdate } = UpdateSchedule();
    const { subscribe: subscribeCreate } = CreateSchedule();

    const generateElements = (from, to, day, time) => {
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const timeParts = time.split(':');
        const hour = parseInt(timeParts[0], 10);
        const minute = parseInt(timeParts[1], 10);

        let current = new Date(from);
        let elements = [];

        while (current <= new Date(to)) {
            if (daysOfWeek[current.getDay()] === day) {
                let elementDate = new Date(current);
                elementDate.setHours(hour);
                elementDate.setMinutes(minute);
                elements.push(elementDate);
            }
            current.setDate(current.getDate() + 1);
        }

        return elements;
    };

    useEffect(() => {
        if (edited || elements.length < 0) {
            const newElements = generateElements(formState.from, formState.to, formState.day, formState.time);
            setElements(newElements);
        }
    }, [formState.day, formState.time, formState.from, formState.to]);


    const handleSave = async () => {
        if (!formState.date || !formState.status || !formState.group || !formState.qtemplate) return;

        const selectedTemplate = templates.find(t => t.id === formState.qtemplate);

        // Find the next element that is after the current date
        const nextElement = elements.find(el => el > new Date());

        const newRecord = {
            date: nextElement ? Timestamp.fromDate(nextElement) : (formState.to ? Timestamp.fromDate(new Date(formState.to)) : Timestamp.fromDate(new Date(formState.date))),
            status: nextElement ? formState.status : 'end',
            group: formState.group,
            from: formState.from ? Timestamp.fromDate(new Date(formState.from)) : Timestamp.fromDate(new Date(formState.date)),
            to: formState.to ? Timestamp.fromDate(new Date(formState.to)) : Timestamp.fromDate(new Date(new Date(formState.date).getTime() + 24 * 60 * 60 * 1000)),
            scope: selectedTemplate.data.scope,
            template: selectedTemplate.id,
            qtemplate: selectedTemplate.data,
            evaluate: selectedTemplate.data.evaluate,
            day: formState.day,
            time: formState.time,
            elements: elements,
            all: elements.length,
            index: nextElement && elements ? elements.indexOf(nextElement) : (elements ? elements.length : 0)
        };

        if (formState.id) {
            await subscribeUpdate({ id: formState.id, ...newRecord });
            setElements([]);
        } else {
            await subscribeCreate(newRecord);
            setElements([]);
        }

        setOpen(false);
        setEdited(false);
    };

    useEffect(() => {
        setEdited(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.id]);

    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <DialogTitle>{formState.id ? 'Időzítés szerkesztése' : 'Új időzítés'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name="from"
                            label="Kezdés"
                            type="datetime-local"
                            value={formState.from}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="to"
                            label="Befejezés"
                            type="datetime-local"
                            value={formState.to}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel htmlFor="day-select">Nap</InputLabel>
                            <NativeSelect
                                id="day-select"
                                name="day"
                                value={formState.day}
                                onChange={handleChange}
                                input={<OutlinedInput label="Nap" />}
                            >
                                {daysOfWeek.map(day => (
                                    <option key={day.value} value={day.value}>{day.label}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel htmlFor="time-select">Időpont</InputLabel>
                            <NativeSelect
                                id="time-select"
                                name="time"
                                value={formState.time}
                                onChange={handleChange}
                                input={<OutlinedInput label="Időpont" />}
                            >
                                {timesOfDay.map(time => (
                                    <option key={time} value={time}>{time}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel htmlFor="status-select">Státusz</InputLabel>
                            <NativeSelect
                                id="status-select"
                                name="status"
                                value={formState.status}
                                onChange={handleChange}
                                input={<OutlinedInput label="Státusz" />}
                            >
                                {statuses.map(day => (
                                    <option key={day.value} value={day.value}>{day.label}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required style={{ display: 'none' }}>
                            <InputLabel htmlFor="group-select">Csoport</InputLabel>
                            <NativeSelect
                                id="group-select"
                                name="group"
                                value={formState.group}
                                onChange={handleChange}
                                input={<OutlinedInput label="Csoport" />}
                            >
                                {groups.map(g => (
                                    <option key={g.id} value={g.id}>{g.data.name}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel htmlFor="qtemplate-select">Sablon</InputLabel>
                            <NativeSelect
                                id="qtemplate-select"
                                name="qtemplate"
                                value={formState.qtemplate}
                                onChange={handleChange}
                                input={<OutlinedInput label="Sablon" />}
                            >
                                {templates.map(t => (
                                    <option key={t.id} value={t.id}>{t.data.name}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <ElementsEditor elements={elements} setElements={setElements} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Mégsem</Button>
                <Button variant="contained" onClick={handleSave}>Mentés</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SchedulesEditorForm;
