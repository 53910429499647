import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const BSAccordion = ({ children, title, label = 'Beállítások', show = true }) => {
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    return (
        <> {show &&
            <Card style={{
                padding: '0px',
                backgroundColor: showAdvancedOptions ? "rgba(128, 128, 128, 0)" : '#eee', // szürke 50% átlátszósággal
                boxShadow: 'none',
            }}>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        color="primary"
                        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                        endIcon={showAdvancedOptions ? <ExpandLess /> : <ExpandMore />}
                    >
                        {`${label} `} {showAdvancedOptions ? 'elrejtése' : 'megjelenítése'}
                    </Button>
                </div>
                {showAdvancedOptions &&
                    <CardContent style={{}} >
                        {title && <h2>{title}</h2>}
                        {children}

                    </CardContent>
                }
            </Card >
        }
        </>
    );
};

export default BSAccordion;
