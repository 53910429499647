import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Camera } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const CapturePhoto = ({ open, onClose, onCapturePhoto }) => {
    const [photoDataUrl, setPhotoDataUrl] = useState(null);
    const [selectedCamera, setSelectedCamera] = useState();
    const [cameraKey, setCameraKey] = useState(Date.now()); // A key prop az újraépítéshez

    const handleTakePhoto = (dataUri) => {
        setPhotoDataUrl(dataUri);
        onCapturePhoto(dataUri);
        onClose();
        resetCamera();
    };

    const handleCameraChange = (event) => {
        setSelectedCamera(event.target.value);
    };

    useEffect(() => {
        resetCamera();
    }, [])

    const resetCamera = () => {
        setPhotoDataUrl(null);
        setCameraKey(Date.now());
    };

    return (
        <Dialog fullScreen open={open} onClose={onClose}>

            <DialogTitle><h2>Fotó készítése</h2></DialogTitle>
            <DialogContent>
                <div className='centerContent'>
                    {photoDataUrl ? (
                        <img src={photoDataUrl} alt="Captured Photo" style={{ maxWidth: '100%' }} />
                    ) : (
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="camera-select-label">Kamera választása</InputLabel>
                                <Select
                                    labelId="camera-select-label"
                                    id="camera-select"
                                    value={selectedCamera}
                                    onChange={handleCameraChange}
                                    fullWidth
                                >
                                    <MenuItem value={null}>Alapértelmezett</MenuItem>
                                    <MenuItem value={1}>Kamera 1</MenuItem>
                                    <MenuItem value={2}>Kamera 2</MenuItem>
                                    {/* Add more cameras as needed */}
                                </Select>
                            </FormControl>

                            <Camera
                                onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                                idealFacingMode={selectedCamera ? `user` : `environment`}
                                key={cameraKey} // Újraépítés a key prop segítségével
                            />
                        </div>
                    )}
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={resetCamera} color="primary">
                    Alaphelyzet
                </Button>
                <Button onClick={onClose} color="primary">
                    Mégsem
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CapturePhoto;
