import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import StorageAvatar from '../firebase/StorageAvatar';

const MemberItem = ({ id, icon, label, values, onClick = () => { }, showCheckbox = false }) => {
    return (
        <ListItem
            sx={{
                textAlign: "center",
                alignContent: "center",
                // color: values.indexOf(id) > -1 ? "primary" : "secondary",
                // selected: values.indexOf(id) > -1
            }}
            onClick={() => { onClick(id) }}
            selected={values ? values.indexOf(id) > -1 : null}
            variant={values &&  values.indexOf(id) > -1 ? "contained" : "outlined"}
            color={values && values.indexOf(id) > -1 ? "primary" : "secondary"}
            secondaryAction={showCheckbox ?
                <Checkbox
                    edge="end"
                    // onChange={() => { setValue(id) }}
                    checked={values.indexOf(id) !== -1}
                    inputProps={{ 'aria-labelledby': id }}
                /> : ''}

            disablePadding
        >
            <ListItemButton >
                <ListItemAvatar>
                    <StorageAvatar
                        value={id}
                        bucket={'profile_images'}
                        size='large'
                    // className="categoryListingImg"
                    />
                </ListItemAvatar>
                <ListItemText id={id} primary={label} />
            </ListItemButton>
        </ListItem >
    );
};

export default MemberItem;