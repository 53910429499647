import React from "react";
import { House, Person, Quiz, ThumbsUpDown } from "@mui/icons-material";
import { Badge, BottomNavigation, BottomNavigationAction, Paper, Stack } from "@mui/material";
import { styled } from '@mui/system';
import EvaluationBadge from "../../badges/EvaluationBadge";

const StyledBottomNavigation = styled(BottomNavigation)({
    width: '100%',
    position: 'fixed',
    bottom: 0,
    "& .MuiBottomNavigationAction-root": {
        "@media (max-width: 375px)": {
            minWidth: "auto",
            maxWidth: "75px",
            padding: "3px 0"
        }
    }
});

const Standard = ({
    user,
    profile,
    handleSubmit,
    location,
    newQuestionnaires
}) => {
    return (<>
        {user ?
            <Paper sx={{ zIndex: '1', position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <StyledBottomNavigation
                    showLabels
                    value={location.pathname}
                    onChange={(event, newValue) => {
                        handleSubmit(newValue);
                    }}>
                    <BottomNavigationAction value="/" label="Kezdőoldal" icon={<House />} />
                    {
                        profile?.[0]?.data?.isAdmin &&
                        <BottomNavigationAction value="/manage" label="Kérdőívek" icon={<Quiz />} />
                    }
                    <BottomNavigationAction value="/questionnaires" label="Kitöltés" icon={
                        <Stack spacing={2} direction="row">
                            <Badge badgeContent={newQuestionnaires} color="error">
                                <ThumbsUpDown />
                            </Badge>
                        </Stack>
                    } />
                    <BottomNavigationAction value="/evaluations" label="Értékelések" icon={
                        <Stack spacing={2} direction="row">
                            <EvaluationBadge />
                        </Stack>
                    } />
                    <BottomNavigationAction value="/profile" label="Profil" icon={<Person />} />
                </StyledBottomNavigation>
            </Paper>
            : <p></p>}
    </>
    );
};

export default Standard;