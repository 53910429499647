import { initializeApp } from "firebase/app";
import {
  connectFirestoreEmulator,
  getFirestore,
  // connectAuthEmulator,
  // connectFirestoreEmulator,
  // connectStorageEmulator
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  connectAuthEmulator,
} from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getProp } from "./commons/Utility";
import { apps, firebaseConfig } from "./data/apps";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import registerServiceWorker from "./serviceWorkerRegistration2";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// TESZT
// const firebaseConfig = {
//   apiKey: "AIzaSyBx1HtBwWxJnNTve4f54cvklQ4ogIC7sOA",
//   authDomain: "teszt-454d8.firebaseapp.com",
//   projectId: "teszt-454d8",
//   storageBucket: "teszt-454d8.appspot.com",
//   messagingSenderId: "604669013464",
//   appId: "1:604669013464:web:21635022a5181e66a056ad",
// };

// edunetprvet-eea4
let app = {};
let auth = {};
let db = {};
let storage = {};
let messaging = {};
let functions = () => { };

const getApps = () => {
  const oldfirebaseApps = JSON.parse(localStorage.getItem('firebaseAppsV2'));
  if (oldfirebaseApps !== null && typeof oldfirebaseApps === 'object') {
    return oldfirebaseApps
  } else {
    localStorage.setItem('firebaseAppsV2', JSON.stringify(apps));
    return apps;
  }
}
let lsApps = getApps();
console.log('FIREBASE initializeApp LS', lsApps);
let allApps = lsApps || apps;
console.log('FIREBASE initializeApp 1', allApps);
let selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
console.log('FIREBASE initializeApp 2', selectedAppName);
let selectedApp = allApps[selectedAppName];
console.log('FIREBASE initializeApp 3', selectedApp)
let selectedConfig = getProp(selectedApp, 'config') ?? firebaseConfig;
console.log('FIREBASE initializeApp 4', selectedConfig)

export const initializeFirebaseApp = () => {
  const lsApps = getApps();
  console.log('FIREBASE initializeApp LS', lsApps);
  const allApps = lsApps || apps;
  console.log('FIREBASE initializeApp 1', allApps);
  const selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
  console.log('FIREBASE initializeApp 2', selectedAppName);
  selectedApp = allApps[selectedAppName];
  console.log('FIREBASE initializeApp 3', selectedApp)
  selectedConfig = getProp(selectedApp, 'config') ?? firebaseConfig;
  console.log('FIREBASE initializeApp 4', selectedConfig)
  app = initializeApp(selectedConfig, selectedAppName === 'firstApp' ? '[DEFAULT]' : selectedApp);
  // const app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
  storage = getStorage(app);
  functions = getFunctions(app);
  messaging = getMessaging(app);
}
// const publicKey = selectedApp?.vapidKey;// "BFBn84qAP35iy208a2lXopjwhduQz5YXGUPYAs5DzhZS00arOaiLogrAOT4cP2ZpER2x1SsmRU3FqXL6elA3tjM";
const fetchToken = (setTokenFound) => {
  const publicKey = selectedApp?.vapidKey;
  console.log('selectedApp', selectedApp);
  console.log('teszttoken2', publicKey);

  return getToken(messaging, { vapidKey: publicKey }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      registerServiceWorker(selectedApp);
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// messaging.onBackgroundMessage(function (payload) {
//   console.log("Received background message ", payload);
//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.notification.body,
//     icon: "/logo192.png",
//   };

//   // show browser push notifications
//   window.registration.showNotification(
//     notificationTitle,
//     notificationOptions
//   );

// });

// Firebase EMULATOR
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");
// const db = getFirestore(app);
// connectFirestoreEmulator(db, '127.0.0.1', 8080);
// const storage = getStorage(app);
// connectStorageEmulator(storage, "127.0.0.1", 9199);

// Initialize Firebase
app = initializeApp(selectedConfig, selectedAppName === 'firstApp' ? '[DEFAULT]' : selectedApp);
// const app = initializeApp(firebaseConfig);
auth = getAuth(app);
db = getFirestore(app);
storage = getStorage(app);
functions = getFunctions(app);
messaging = getMessaging(app);

export {
  app,
  auth,
  db,
  storage,
  functions,
  httpsCallable,
  onMessageListener,
  fetchToken,
  deleteToken,
  messaging,
  selectedApp,
  selectedAppName,
  selectedConfig
};