import React from 'react';
import { Box } from '@mui/material';
import CButtonSelect from '../../../../../commons/CButtonSelect';
import Instruction from './components/Instruction';
import Question from './components/Question';

const FSPFieldSelectScale = ({
    field,
    options,
    handleChange,
    value,
    page
}) => {
    return (
        <div className='questionPanel'>
            {/* <Box
                sx={{
                    minHeight: 100,
                }}>
                <h3 className='question'>
                    {field.name}
                    <br /><Instruction text={page?.instruction} />
                </h3>
            </Box> */}
            <Question field={field} />
            <div className='centerContent2'>
                <CButtonSelect
                    field={field.name}
                    handleChange={handleChange}
                    value={value}
                    options={options && Object.keys(options).map((option) => (
                        { value: options[option].value, label: options[option].name }
                    ))} />
            </div>
        </div>
    );
};

export default FSPFieldSelectScale;