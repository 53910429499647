import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const UpdateSchedule = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, date, status, group, from, to, scope,
        template, qtemplate, evaluate, elements, day, all, time, index }) => {
        const newRecord = {
            date: date,
            status: status,
            group: group,
            from: from,
            to: to,
            scope: scope,
            template: template,
            qtemplate: qtemplate,
            evaluate: evaluate,
            elements: elements,
            day: day,
            time: time,
            all: all,
            index: index
        }

        const result = await updateDocument({
            id: id,
            table: 'schedules',
            record: newRecord,
            fields: [
                'date', 'status', 'group', 'from',
                'to', 'scope', 'template', 'qtemplate', 'evaluate',
                'elements', 'all', 'time', 'index', 'day'
            ],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateSchedule;