import React from 'react';
import { ListItemText } from '@mui/material';
import StorageAvatar from '../firebase/StorageAvatar';

const MemberItemBig = ({ name, icon, bucket = 'profile_images', onClick = () => { console.log('xxx') } }) => {
    return (
        <div className='question' onClick={onClick}>
            <div className='avatar-container'>
                <StorageAvatar
                    value={icon}
                    bucket={bucket}
                    className="categoryListingImg"
                    presence={"online"}
                />
            </div>
            <ListItemText id={name} primary={name} />
        </div>

    )
};

export default MemberItemBig;