import React from 'react';
import { Button, FormControl, Stack } from '@mui/material';
import { v4 as uuidv4 } from "uuid";

const ValueSelect = ({
    selected, setSelected, options
}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '5px' }}>
            <FormControl variant="standard" sx={{ marginRight: 1, minWidth: 100, overflowY: 'auto', maxHeight: 200 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                    {Object.keys(options).map((option, index) => (
                        <Button
                            key={uuidv4()}
                            variant={selected == option ? "contained" : "outlined"}
                            onClick={() => setSelected(option)}
                            size="small"
                            sx={{
                                // minHeight: '50px',
                                // minWidth: '100px'
                            }}
                        >
                            {options[option].label}
                        </Button>
                    ))}
                </div>
            </FormControl>
        </div >
    );
};

export default ValueSelect;
