import { functions, httpsCallable } from '../../firebase.config';

const FindUser = async () => {
    const getFunction = httpsCallable(functions, 'findUser');

    const getData = async (email) => {
        try {
            const result = await getFunction({ email: email });
            console.log('Firebase Function Response:', result?.data);
            return result?.data;
        } catch (error) {
            console.error('Error calling Firebase Function:', error);
            throw error;
        }
    };
    return { getData };
};

export default FindUser;