import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../services/appContext';
import GroupList from '../groups/GroupList';

const UserGroups = ({ profile }) => {
    // const [profile, setProfile] = useState(null);
    const [groups, setGroups] = useState([]);
    const { snapshots } = useContext(AppContext);
    const [members, setMembers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [managers, setManagers] = useState([]);
    const [subscribes, setSubscribes] = useState([]);
    // useEffect(() => {
    //     if (snapshots && snapshots['profile'] && snapshots['profile'][0]) {
    //         setProfile(snapshots['profile'][0].data);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [snapshots['profile']]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setGroups(snapshots['allgroups']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    useEffect(() => {
        const members = groups?.filter((g) => g?.data?.Members?.indexOf(profile?.id) > -1);
        const admins = groups?.filter((g) => g?.data?.admins?.indexOf(profile?.id) > -1);
        const managers = groups?.filter((g) => g?.data?.managers?.indexOf(profile?.id) > -1);
        const subscribes = groups?.filter((g) => g?.data?.subscribed?.indexOf(profile?.id) > -1);
        setMembers(members);
        setAdmins(admins);
        setManagers(managers);
        setSubscribes(subscribes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, profile]);

    // console.log('groups', groups);
    // console.log('profile', profile);
    return (
        <div style={{ textAlign: 'left' }}>
            <GroupList
                title={'Csoportjai'}
                groups={members}
                status={'member'}
            />
            <GroupList
                title={'Feliratkozásai'}
                groups={subscribes}
                status={'subscribed'}
            />
            {profile?.isAdmin &&
                <GroupList
                    title={'Csoportjai (oktatóként)'}
                    groups={admins}
                    status={profile?.isAdmin ? 'member' : 'admin'}
                />
            }
            {(profile?.isAdmin || profile?.isManager) &&
                <GroupList
                    title={'Csoportjai (szerkesztőként)'}
                    groups={managers}
                    status={profile?.isManager ? 'member' : 'admin'}
                />
            }
        </div>
    );
};

export default UserGroups;