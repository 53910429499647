// Mapping function to get axis label from the fields
const getAxisLabel = (field, property = 'category') => {
    const axisLabels = {
        direction: {
            category: 'Önértékelés/értékelés',
            series: 'Önértékelés/értékelés',
            filter: 'Önértékelés/értékelés',
        },
        evaluatedUser: {
            category: 'Csoport perspektíva',
            series: 'Csoport perspektíva',
            filter: 'Csoport perspektíva',
        },
        groupAuthor: {
            category: 'Értékelő perspektíva',
            series: 'Értékelő perspektíva',
            filter: 'Értékelő perspektíva',
        },
        evaluated_value_normalized: {
            category: 'Normalizált értékelés',
            series: 'Normalizált értékelés',
            filter: 'a normalizált értékelés',
        },
        evaluated_value: {
            category: 'értékelés',
            series: 'értékelés',
            filter: 'értékelés',
            value: 'az értékelések',
        },
        filledQuestionnaire_count: {
            category: 'Kitöltések száma',
            series: 'Kitöltések száma',
            filter: 'Kitöltések száma',
            value: 'a kitöltések',
        },
        evaluated_count: {
            category: 'Kitöltés',
            series: 'Kitöltés',
            filter: 'Kitöltés',
            value: 'a kitöltések',
        },
        emotional: {
            category: 'Érzelmi áll.',
            series: 'Érzelmi áll.',
            filter: 'Érzelmi áll.',
            value: 'az érzelmi állapotának',
        },
        updatedAt: {
            category: 'Dátum',
            series: 'Dátum',
            filter: 'Dátum',
        },
        evaluated: {
            category: 'Értékeltek',
            series: 'Értékeltek',
            filter: 'Értékelt',
        },
        label: {
            category: 'Címke',
            series: 'Címke',
            filter: 'Címke',
        },
        labels: {
            category: 'Címke',
            series: 'Címke',
            filter: 'Címke',
        },
        template: {
            category: 'Kérdőív',
            series: 'Kérdőív',
            filter: 'Kérdőív',
        },
        templates: {
            category: 'Kérdőív',
            series: 'Kérdőív',
            filter: 'Kérdőív',
        },
        questionnaire: {
            category: 'Kérdőív',
            series: 'Kérdőív',
            filter: 'Kérdőív',
        },
        questionnaires: {
            category: 'Kérdőív',
            series: 'Kérdőív',
            filter: 'Kérdőív',
        },
        filledQuestionnaire: {
            category: 'Kitöltés',
            series: 'Kitöltés',
            filter: 'Kitöltés',
            value: 'a kitöltések',
        },
        filledQuestionnaires: {
            category: 'Kitöltés',
            series: 'Kitöltés',
            filter: 'Kitöltés',
            value: 'a kitöltések',
        },
        question: {
            category: 'Kérdések',
            series: 'Kérdések',
            filter: 'Kérdések',
        },
        count: {
            category: 'számát',
            series: 'számát',
            filter: 'számát',
        },
        min: {
            category: 'minimumát',
            series: 'minimumát',
            filter: 'minimumát',
        },
        max: {
            category: 'maximumát',
            series: 'maximumát',
            filter: 'maximumát',
        },
        average: {
            category: 'átlagát',
            series: 'átlagát',
            filter: 'átlagát',
        },
        median: {
            category: 'mediánját',
            series: 'mediánját',
            filter: 'mediánját',
        },
        variance: {
            category: 'szórását',
            series: 'szórását',
            filter: 'szórását',
        },
        label_name: {
            category: 'címke',
            series: 'címke',
            filter: 'címke',
        },
        normalized: {
            category: 'normalizált értékét',
            series: 'normalizált értékét',
            filter: 'normalizált értékét',
        }
        // Add other fields as needed
    };
    return axisLabels[field] && axisLabels[field][property] ? axisLabels[field][property] : field;
};


const splitFieldString = (fieldString) => {
    // Find the last occurrence of "_"
    const lastUnderscoreIndex = fieldString.lastIndexOf('_');

    // If there is no underscore, return the original string as field and an empty string as statFunction
    if (lastUnderscoreIndex === -1) {
        return { field: fieldString, statFunction: '' };
    }

    // Split the string into two parts
    const valuesField = fieldString.substring(0, lastUnderscoreIndex);
    const statFunction = fieldString.substring(lastUnderscoreIndex + 1);

    return { valuesField, statFunction };
};

// Function to get filter description
const getFilterDescription = (filters, filterFields) => {
    return filters.map(filter => filterFields[filter.field]?.label || filter.field).join(', ');
};

const getFilterFieldsString = (filters) => {
    if (!filters || filters.length === 0) {
        return 'csoport';
    }

    const fields = filters.map((filter, index) =>
        getAxisLabel(filter.field, 'filter').toLowerCase());
    // index === 0
    //     ? getAxisLabel(filter.field, 'filter')
    //     : getAxisLabel(filter.field, 'filter').toLowerCase());


    if (fields.length === 1) {
        return fields[0];
    }

    const lastField = fields.pop();
    return `${fields.join(', ')} és ${lastField}`;
};

// Function to dynamically generate interpretation based on data
const generateInterpretation = (chartData) => {
    const { defaultCategoryField, defaultSeriesField } = chartData;

    let interpretation = `Ez a diagram az értékelések számát és normalizált értékeit mutatja be a(z) ${getAxisLabel(defaultCategoryField)} szerint`;

    if (defaultSeriesField) {
        interpretation += `, különböző sorozatokkal (${getAxisLabel(defaultSeriesField)}).`;
    } else {
        interpretation += '.';
    }

    interpretation += ` Az adatokból láthatjuk az értékelések eloszlását és időbeli trendjeit, amely segíthet az önértékelések és mások által adott értékelések közötti különbségek feltárásában.`;

    return interpretation;
};

// Function to dynamically generate the title of the chart
const generateTitle = (chartData) => {
    const { defaultCategoryField, defaultSeriesField } = chartData;
    const categoryLabel = getAxisLabel(defaultCategoryField);
    const seriesLabel = defaultSeriesField ? getAxisLabel(defaultSeriesField) : '';
    return `${categoryLabel} ${seriesLabel ? 'és ' + seriesLabel : ''} csoportosítás`;
};

// Function to dynamically generate the description of the chart
const generateDescription = (chartData) => {
    const { defaultCategoryField, defaultSeriesField } = chartData;
    const categoryLabel = getAxisLabel(defaultCategoryField);
    const seriesLabel = defaultSeriesField ? getAxisLabel(defaultSeriesField) : '';
    return `Ez a diagram az egyes felhasználók által végzett és róluk készült értékelések számát mutatja a különböző ${categoryLabel} ${seriesLabel ? 'és ' + seriesLabel : ''} szerint.`;
};

const addArticle = (text) => {
    if (!text || typeof text !== 'string') {
        return text;
    }

    const vowels = 'aeiouáéíóöőúüűAEIOUÁÉÍÓÖŐÚÜŰ';
    const firstChar = text.charAt(0);

    if (vowels.includes(firstChar)) {
        return `az ${text}`;
    } else {
        return `a ${text}`;
    }
};

const generateDescription2 = (chartData) => {
    const {
        defaultCategoryField,
        defaultSeriesField,
        defaultValuesField,
        filterFields: chartFilters,
    } = chartData;
    const filters = chartFilters?.map(x => x.field);
    const { valuesField, statFunction } = splitFieldString(chartData?.defaultValuesField);;
    // console.log('valuesField', valuesField);
    // console.log('statFunction', statFunction);
    const categoryLabel = getAxisLabel(defaultCategoryField).toLowerCase();
    // console.log('chartFilters', chartFilters);
    // console.log('defaultSeriesField', defaultSeriesField);
    const defaultSeriesField2 = defaultSeriesField && chartFilters
        ? (filters.indexOf(defaultSeriesField) > -1
            ? null
            : defaultSeriesField)
        : defaultSeriesField;
    // console.log('defaultSeriesField2', defaultSeriesField2);
    // const defaultCategoryField2 = defaultCategoryField && chartFilters
    // ? (filters.indexOf(defaultCategoryField) > -1
    //     ? null
    //     : defaultCategoryField)
    // : defaultCategoryField;
    // console.log('defaultCategoryField2', defaultCategoryField2);

    const seriesLabel = defaultSeriesField2 ? getAxisLabel(defaultSeriesField2).toLowerCase() : null;
    // const valuesLabel = getAxisLabel(defaultValuesField);
    const valuesLabel2 = getAxisLabel(valuesField, 'value');
    const statFunctionLabel = getAxisLabel(statFunction);
    // console.log('valuesLabel2', valuesLabel2);
    // console.log('statFunctionLabel', statFunctionLabel);
    const filtersDescription2 = getFilterFieldsString(chartData?.filterFields);
    // console.log('filtersDescription2', filtersDescription2);
    const description = `Ez a diagram ${valuesLabel2} ${statFunctionLabel} mutatja a kiválasztott ${filtersDescription2} esetében a különböző ${categoryLabel} ${seriesLabel ? ' és ' + seriesLabel : ''} kategóriái szerint csoportosítva.`;
    return description;
};

// Function to dynamically generate the description of the chart in JSON format
const generateChartDescriptionJSON = (label, chartData, filterFields) => {
    // console.log('chartData', chartData);
    const { valuesField, statFunction } = splitFieldString(chartData?.defaultValuesField);;
    const {
        defaultCategoryField,
        defaultSeriesField,
        defaultValuesField,
        filterFields: chartFilters,
    } = chartData;

    const categoryLabel = getAxisLabel(defaultCategoryField);

    const valuesLabel = getAxisLabel(defaultValuesField);
    const statFunctionLabel = getAxisLabel(statFunction);
    const description2 = generateDescription2(chartData);
    // console.log('LEIRÁS', description2);
    const filtersDescription = chartFilters && chartFilters.length > 0
        ? getFilterDescription(chartFilters, filterFields)
        : 'Nincs szűrő';

    //seriesLabel
    const filters = chartFilters?.map(x => x.field);
    const defaultSeriesField2 = defaultSeriesField && chartFilters
        ? (filters.indexOf(defaultSeriesField) > -1
            ? null
            : defaultSeriesField)
        : defaultSeriesField;
    const seriesLabel = defaultSeriesField2 ? getAxisLabel(defaultSeriesField2) : null;

    //title
    const title = defaultCategoryField === 'updatedAt'
        ? `${label}. ${seriesLabel ? seriesLabel + ' szerinti csoportosítás időbeli trendje' : 'Értékelések (' + getFilterFieldsString(chartData?.filterFields) + ') időbeli trendje'}`
        : `${label}. ${categoryLabel} ${seriesLabel ? 'és ' + seriesLabel?.toLowerCase() : ''} szerinti csoportosítás`;

    const description = defaultCategoryField === 'updatedAt'
        ? `Ez a diagram az értékelések számát és normalizált értékeit mutatja be időbeli trendek szerint.`
        : generateDescription(chartData);

    const interpretation = defaultCategoryField === 'updatedAt'
        ? `Ez a diagram az értékelések számát és normalizált értékeit mutatja be időben. Az adatokból láthatjuk az értékelések eloszlását és időbeli trendjeit, amely segíthet az önértékelések és mások által adott értékelések közötti különbségek feltárásában.`
        : generateInterpretation(chartData);

    const question = defaultCategoryField === 'updatedAt'
        ? `Hogyan alakultak az értékelések időben a különböző ${seriesLabel?.toLowerCase() ? `${seriesLabel?.toLowerCase()} sorozatok` : 'sorozatok'} szerint?`
        : `Hogyan alakultak az értékelések a ${categoryLabel?.toLowerCase()} kategóriái ${seriesLabel?.toLowerCase() ? `és ${addArticle(seriesLabel?.toLowerCase())} sorozatok` : ''} szerint?`;

    return {
        title,
        description: description2,
        chartDescription: {
            horizontalAxis: categoryLabel,
            verticalAxis: valuesLabel,
            series: seriesLabel,
            filters: filtersDescription,
        },
        analysisAndInterpretation: {
            question,
            interpretation,
        },
    };
};

export default generateChartDescriptionJSON;
