import React from 'react';
import Document2 from '../../hooks/document2';

const GetUser = ({ setter, user, refresh = false }) => {

    const table = 'users';
    const tableKey = 'user-' + user;

    const model = Document2({
        setState: setter,
        key: tableKey,
        table: table,
        refresh: refresh
    });

    const subscribe = async () => {
        let unsubscribe2;
        unsubscribe2 = await model.subscribe({ id: user });
        if (unsubscribe2) {
            // console.log('SIKERES  LEKÉRDEZÉS', unsubscribe2)
        }
        else {
            console.log('HIBA')
        }
        return unsubscribe2;
    }
    return { subscribe };
};

export default GetUser;