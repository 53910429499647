import React, { useEffect, useState } from 'react';

const Image2 = ({ url, action, next, show, hide, name, actual, canvasWidth, canvasHeight }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        
        if (actual) {
            if (action === 'show') {
                show(name);
                setVisible(true);
            } else if (action === 'hide') {
                hide(name);
                setVisible(false);
            }
            next();
        }
    }, [action, actual, hide, name, next, show]);

    return visible ? (
        <img
            src={url}
            alt=""
            style={{
                position: 'absolute',
                marginTop:'-2px',
                marginLeft:'1px',
                top: '50%', // Középre igazítás
                left: '50%', // Középre igazítás
                transform: 'translate(-50%, -50%)', // Középre igazítás
                maxWidth: '100%', // Max szélesség
                maxHeight: '100%', // Max magasság
                zIndex: 10, // Magasabb z-index érték
                width: canvasWidth
            }}
        />
    ) : null;
};

export default Image2;
