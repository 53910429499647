import React, { useState } from 'react';
import GroupList from '../../components/groups/GroupList';
import GoBackButton from '../../commons/GoBackButton';
import { Add } from '@mui/icons-material';
import CreateGroupForm from './components/CreateGroupForm';
import { Fab } from '@mui/material';

const View = ({ profile, groups, isSuperAdmin, isManager, }) => {
    const [open, setOpen] = useState(false);
    const members = groups?.filter((g) => profile?.groups?.indexOf(g.id) > -1);
    const admins = groups?.filter((g) => profile?.admins?.indexOf(g.id) > -1);
    const managers = groups?.filter((g) => profile?.managers?.indexOf(g.id) > -1);
    const subscribes = groups?.filter((g) => profile?.subscribed?.indexOf(g.id) > -1);
    return (
        <div className="profileList">
            <GoBackButton />
            <p className="pageHeader">Csoportok</p>
            <GroupList
                title={'Csoportjaim'}
                groups={members}
            />
            <GroupList
                title={'Feliratkozások'}
                groups={subscribes}
            />
            {profile?.isAdmin &&
                <GroupList
                    title={'Csoportjaim (oktatóként)'}
                    groups={admins}
                    status={'admins'}
                />
            }
            {(profile?.isAdmin || profile?.isManager) &&
                <GroupList
                    title={'Csoportjaim (ügyintézőként)'}
                    groups={managers}
                    status={'managers'}
                />
            }
            {
                (isManager || isSuperAdmin) &&
                <Fab
                    sx={{
                        position: 'fixed',
                        bottom: '5.0rem',
                        right: '1rem',
                    }}
                    color="primary"
                    onClick={() => { setOpen(true) }}
                >
                    <Add />
                </Fab>
            }
            {open &&
                <CreateGroupForm
                    open={open}
                    setOpen={setOpen}
                />
            }
        </div>
    );
};

export default View;