import { useContext, useState } from 'react';
import { AppContext } from '../services/appContext';

const Document4 = ({ table }) => {
    const { queryDoc } = useContext(AppContext);
    const [id2, setId] = useState(null);

    const subscribe = async ({ filter, setter, key, id, refresh = false, notFromCache = true }) => {
        setter([{
            id: id2,
            data: null
        }]);
        setId(id)

        const result = await queryDoc({
            key: key,
            table: table,
            id: id,
            refresh: refresh,
            notFromCache: notFromCache
        })
        setter([{
            id: id,
            data: result
        }]);
        return result
    }

    return {
        subscribe
    };
};

export default Document4;