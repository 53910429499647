import { TextField } from '@mui/material';
import React from 'react';
import Instruction from './components/Instruction';
import Question from './components/Question';

const FSPFieldText = ({
    field,
    submit,
    handleChange,
    value,
    page
}) => {
    let input;

    const handleChange1 = (event) => {
        event.preventDefault();
        handleChange(field.name, input.value);
    };

    return (
        <div>
            {/* <h3 className='question'>
                {field.name}
                <br /><Instruction text={page?.instruction} />
            </h3> */}
            <Question field={field} />
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleChange(field.name, input.value);
                    submit();
                }}>
                <TextField
                    onChange={(a, b) => handleChange1(a, b, false)}
                    required={field.required ?? false}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    multiline={field.multiline ?? true}
                    rows={field.rows ?? 5}

                    fullWidth
                    inputRef={node => {
                        input = node;
                    }}
                    defaultValue={value} />
            </form>
        </div>
    );
};

export default FSPFieldText;