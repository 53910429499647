import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FieldPageSlider from './components/FieldPageSlider';
import SubmitPage from './components/SubmitPage';
import StartPage from './components/StartPage';
import FieldPage from './components/FieldPage';
import ReStart from './components/ReStart';
import Thanks from './components/Thanks';
import QPage from './components/ui/QPage';
import QuestionsUtitity from './QuestionsUtitity';
import { convertArrayById } from '../../commons/Utility';
import { AppContext } from '../../services/appContext';

const StepperSampling = ({
    profile,
    questionnaire,
    filledQuestionnaire_id,
    admins,
    members,
    submitCallback,
    navigateAfterSubmit,
    success,
    myGroups,
    defaultValues,
    started,
    group
}) => {
    const navigate = useNavigate();
    const { sliderView, setSliderView } = useContext(AppContext);
    const { generateQuestions, generateStepper,
        findFirstUnFilledQuestion, saveToLocalstorage,
        loadFromLocalstorage } = QuestionsUtitity()

    // emotional, persons
    const [emotional, setEmotional] = useState();
    const [persons, setPersons] = useState([]);

    //steps = convertID(category) ???
    // TODO: összevonni ha lehet
    const [steps, setSteps] = useState([]);
    const [categories, setCategories] = useState([{ id: 1, data: { name: 'project', icon: 'sdfds' } }]);

    //pages, values
    const [values, setValues] = useState(defaultValues ?? 0);
    const [pages, setPages] = useState();

    // actual step, category
    const [actual, setActual] = useState(-1);
    const [actualCategory, setActualCategory] = useState(-1);

    const [load, setLoad] = useState(false);

    const exit = () => {
        navigate(`/questionnaires`);
    }

    useEffect(() => {
        if (values && actual > 0) {
            saveToLocalstorage({
                questionnaire: questionnaire,
                values: values,
                emotional: emotional,
                persons: persons,
                pages: pages,
                actual: actual,
                categories: categories,
                filledQuestionnaire_id: filledQuestionnaire_id
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
        if (profile && values && questionnaire) {
            if (Object.keys(values).length < 1) {
                loadFromLocalstorage({
                    setEmotional: setEmotional,
                    setPersons: setPersons,
                    setPages: setPages,
                    setValues: setValues,
                    setCategories: setCategories,
                    setLoad: setLoad,
                    filledQuestionnaire_id: filledQuestionnaire_id
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaire]);

    useEffect(() => {
        const f = generateQuestions({ questionnaire: questionnaire, categories: categories, person: sliderView });
        setPages(f);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaire, categories]);

    // NAVIGATION (PREV, NEXT, END)
    const previous = () => {
        if (actual > -1) {
            setActual(actual - 1);
        }
    }

    const next = () => {
        if (actual < pages.length + 1) {
            setActual(actual + 1);
        }
    }

    const next2 = () => {
        setup();
        if (actual < pages.length + 1) {
            setActual(actual + 1);
        }
    }

    // SETUP
    const setup = () => {

        // set PERSONS
        const m = members ?? []
        const allPersons = m.concat(admins);
        const pId = persons?.members ?? []

        let selectedPersons = [];
        let filteredPersons = [];
        switch (questionnaire.evaluate) {
            case "project":
            case "self":
                setPersons({ "members": [profile?.id] });
                selectedPersons = [profile?.id];
                filteredPersons = [{ "id": profile?.id, "data": profile }];
                break;
            case "members":
            case "admins":
                selectedPersons = persons?.admins ? pId.concat(persons?.admins) : pId;
                filteredPersons = allPersons.filter((a) => selectedPersons.indexOf(a.id) > -1);
                break
            default:
                break;
        }

        // SET categories
        setCategories(convertArrayById(filteredPersons));

        // SET pages
        const pages = generateQuestions({ questionnaire, categories: filteredPersons, person: sliderView })
        setPages(pages);

        // SET steps
        const steps = generateStepper({
            slideItems: convertArrayById(filteredPersons),
        })
        setSteps(steps);

        // SET last
        const last = findFirstUnFilledQuestion({
            pages: pages,
            values: values
        })
        setActual(last);

        // SET ActualCategory
        const fieldsLength = pages.length;
        if (last >= fieldsLength) {
            setActualCategory(pages[fieldsLength - 1].category_id)
        }
        else {
            if (last > 0)
                setActualCategory(pages[last].category_id)
        }
    }

    const submit = async () => {
        await submitCallback({ values: values, emotional: emotional });
    }

    const handleChange = (name, value) => {
        if (Array.isArray(value)) {
            setPersons({ ...persons, [name]: value });
        } else {
            setPersons({ ...persons, [name]: value });
        }
    }
    const valid2 = () => {
        const page = pages[actual];
        const categoryId = page?.category_id;
        const questionId = page?.question;
        const value = values?.[categoryId]?.[questionId];
        return value || page.type === 'person'
    }
    const valid = () => {
        const page = pages[actual];
        const categoryId = page?.category_id;
        const category = categories && categoryId ? categories[categoryId].data : null;
        const actualCategory = category ? values[category.id] : null;
        const questionId = page?.question;
        const actualValue = actualCategory ? actualCategory[questionId] : null;
        if (page?.questionType === 'ertekeles') {
            return typeof actualValue === 'string' && actualValue.length > 0;
        }
        if (page?.questionType === 'tobbvalasztos') {
            const min = page?.min ? page?.min : 1;
            return (Array.isArray(actualValue) && actualValue.length >= min) || page.type === 'person';
        } else {
            // return actualValue > -1 || page.type === 'person';
            const value = values?.[categoryId]?.[questionId];
            return value || page.type === 'person'
        }
    }

    // console.log('questionnaire', questionnaire);
    // console.log('activeStep', activeStep);
    // console.log('actualCategory', actualCategory);
    return (
        <QPage exit={exit} sliderView={sliderView} setSliderView={setSliderView}>
            {actual < 0 &&
                <StartPage
                    next={next2}
                    admins={admins}
                    members={members}
                    handleChange={handleChange}
                    values={persons}
                    questionnaire={questionnaire}
                    emotional={emotional}
                    setEmotional={setEmotional} />
            }
            {actual > -1 && actual < pages.length &&
                (sliderView ?
                    <FieldPageSlider
                        valid={valid}
                        pages={pages}
                        actual={actual}
                        setActual={setActual}
                        previous={previous}
                        next={next}
                        values={values}
                        setValues={setValues}
                        questionnaire={questionnaire}
                        categories={categories}
                        steps={steps}
                        group={group}
                    />
                    :
                    <FieldPage
                        valid={valid}
                        pages={pages}
                        actual={actual}
                        setActual={setActual}
                        previous={previous}
                        next={next}
                        values={values}
                        setValues={setValues}
                        questionnaire={questionnaire}
                        categories={categories}
                        steps={steps}
                        actualCategory={actualCategory}
                        setActualCategory={setActualCategory}
                        group={group}
                    />
                )
            }
            {actual >= pages?.length && (
                success ?
                    <Thanks
                        navigateAfterSubmit={navigateAfterSubmit}
                        success={success}
                        myGroups={myGroups}
                    />
                    :
                    <SubmitPage
                        questionnaire={questionnaire}
                        values={values}
                        next={next}
                        previous={previous}
                        submitCallback={submit}
                        success={success}
                        started={started}
                        myGroups={myGroups}
                        categories={categories}
                        steps={steps}
                        group={group}
                    />
            )}
            <ReStart
                load={load}
                setLoad={setLoad}
                setActualCategory={setActualCategory}
                pages={pages}
                setup={setup}
                setEmotional={setEmotional}
            />
        </QPage>
    );
};

export default StepperSampling;