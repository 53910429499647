import React from 'react';
import SubscribedUser from './SubscribedUser';
import DeleteManagerAction from '../../services/actions/DeleteManagerAction';

const ManagerUsers = ({ users, groupId = null, isAdmin = false, group, navigateToUser = false }) => {
    const { deleteAdminAction } = DeleteManagerAction();
    return (
        <div>
            {users && Object.keys(users).map((g, index) => {
                return (
                    <SubscribedUser
                        level='manager'
                        group={group}
                        key={`manager-${users[g]}`}
                        user={users[g]}
                        groupId={groupId}
                        deleteAction={isAdmin ? deleteAdminAction : undefined}
                        navigateToUser={navigateToUser}
                    />)
            })}
        </div>
    );
};

export default ManagerUsers;