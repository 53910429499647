import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import SelectGroup from './components/SelectGroup';
import MultiSelectQuestions from './components/MultiSelectQuestions';

const RadarChart = ({ groups, questions, data }) => {
    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [availableQuestions, setAvailableQuestions] = useState([]);
    const [radarChartData, setRadarChartData] = useState({
        options: {
            scale: {
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 10,
                },
            },
        },
        series: [],
    });

    const handleGroupChange = (event) => {
        const selectedGroupName = event.target.value;
        setSelectedGroupName(selectedGroupName);

        const selectedGroup = groups.find((group) => group.data.name === selectedGroupName);
        setSelectedGroup(selectedGroup);
    };

    const handleQuestionChange = (event) => {
        setSelectedQuestions(event.target.value);
    };

    const getRadarChartData = () => {
        const seriesData = {
            options: {
                scale: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 10,
                    },
                },
                labels: selectedQuestions,
            },
            series: [],
        };

        // Iterate over groupAuthors and create series for each
        const groupAuthors = ['self', 'evaluator', 'evaluated', 'other'];
        const names = {
            self: 'Önértékelés',
            evaluator: 'Értékelő',
            evaluated: 'Értékelt',
            other: 'Egyéb'
        }
        groupAuthors.forEach((groupAuthor) => {
            const series = {
                name: names[groupAuthor],
                data: selectedQuestions.map((selectedQuestion) => {
                    const filteredData = data.filter(
                        (item) =>
                            item.groupId === selectedGroup.id &&
                            item.question === selectedQuestion &&
                            item.groupAuthor === groupAuthor
                    );
                    const averageValue =
                        filteredData.reduce((sum, item) => sum + parseFloat(item.average), 0) /
                        filteredData.length;

                    // Kerekítés két tizedesre
                    return parseFloat(averageValue.toFixed(2));
                }),
            };

            seriesData.series.push(series);
        });

        // Update categories (labels) to use question texts
        seriesData.options.scale.pointLabels = {
            fontSize: 14,
            color: '#000000',
            formatter: function (value, { seriesIndex, dataPointIndex }) {
                return selectedQuestions[dataPointIndex];
            },
        };

        return seriesData;
    };

    useEffect(() => {
        setAvailableQuestions(Object.values(questions));
    }, [questions]);

    useEffect(() => {
        setRadarChartData(getRadarChartData());
    }, [selectedQuestions, selectedGroup, data]);

    return (
        <div>
            <SelectGroup
                selectedGroupName={selectedGroupName}
                handleGroupChange={handleGroupChange}
                groups={groups}
            />
            <MultiSelectQuestions
                label={'Címkék'}
                selectedQuestions={selectedQuestions}
                handleQuestionChange={handleQuestionChange}
                availableQuestions={availableQuestions}
            />
            <ApexCharts
                type="radar"
                width="99%"
                height={600}
                options={radarChartData.options}
                series={radarChartData.series}
            />
        </div>
    );
};

export default RadarChart;
