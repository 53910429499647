import React, { useContext } from 'react';
import { AppContext } from '../appContext';

const CreateGroupAction = () => {
    const { createDocument } = useContext(AppContext);

    const subscribe = async ({ name, active }) => {
        const newRecord = {
            name: name,
            active: active
        }

        const result = await createDocument({
            table: 'groups',
            record: newRecord,
            fields: ['name', 'active'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default CreateGroupAction;