import { useContext } from 'react';
import { AppContext } from '../appContext';

const UpdateUserFieldAction = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, field, value }) => {
        const newRecord = {
            [field]: value,
        }
        console.log('newRecord', newRecord);
        const result = await updateDocument({
            id: id,
            table: 'users',
            record: newRecord,
            fields: [field],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateUserFieldAction;