import { Box } from '@mui/material';
import React, { useState } from 'react';
import QPage from '../../../components/stepperForm/components/ui/QPage';
import QQuestion from '../../../components/stepperForm/components/ui/QQuestion';
import UploadAvatar from '../../../components/firebase/UploadAvatar';

const ProfileAvatar = ({ auth, exit }) => {
    return (
        <QPage exit={() => exit(false)}>
            <QQuestion >
                <div className=''>
                    <div className='centerContent2'>
                        <UploadAvatar userId={auth.currentUser.uid} exit={() => exit(false)} />
                    </div>
                </div>
            </QQuestion>
        </QPage>
    );
};

export default ProfileAvatar;