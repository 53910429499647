import React, { useContext, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Badge, IconButton, Grid } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import Tada from 'react-reveal/Tada';
import { apps } from '../../data/apps';
import { AppContext } from '../../services/appContext';
import { initializeFirebaseApp } from '../../firebase.config';
import AppIcon from './AppIcon';
import ConfirmDialog from '../../commons/ConfirmDialog';

const AppEditor = ({ readonly = false }) => {
    const [open, setOpen] = useState(false);
    const [deleteAppName, setDeleteAppName] = useState(false);
    const { selectedApp, setSelectedApp, firebaseApps, setFirebaseApps } = useContext(AppContext);
    const storedApps = firebaseApps ?? apps;

    const setApp = (app) => {
        setSelectedApp(app);
        localStorage.setItem('selectedApp', app);
        initializeFirebaseApp();
    }

    const askDeleteApp = (appName) => {
        setDeleteAppName(appName);
        setOpen(true);
    }
    const deleteApp = () => {
        const { [deleteAppName]: deleted, ...rest } = firebaseApps;
        setFirebaseApps(rest);
        localStorage.setItem('firebaseAppsV2', JSON.stringify(rest));
        setSelectedApp('firstApp');
        localStorage.setItem('selectedApp', 'firstApp');
    }

    return (
        <div>
            {storedApps &&
                <Grid container spacing={1} justifyContent="center">
                    {Object.keys(storedApps).map((appName, index) => {
                        const defaultApp = storedApps[appName].defaultApp;
                        return (
                            <Grid item key={index} style={{ maxWidth: 100 }}>
                                <BottomNavigation
                                    key={`BottomNavigation${index}`}
                                    style={{ background: 'transparent', marginBottom: '1em' }}
                                    showLabels
                                    value={selectedApp}
                                    onChange={(event, newValue) => {
                                        readonly ? console.log('') : setApp(newValue)
                                    }}>
                                    <BottomNavigationAction
                                        key={`BottomNavigationAction${index}`}
                                        label={storedApps[appName].label}
                                        value={appName}
                                        style={{ color: selectedApp === appName ? "#00cc66" : '' }}
                                        sx={{
                                            bgcolor: '#daaaa',
                                        }}
                                        icon={
                                            defaultApp || readonly ? (
                                                <AppIcon
                                                    key={`AppIcon${index}`}
                                                    icon={storedApps[appName].logo}
                                                    active={selectedApp === appName}
                                                />
                                            ) : (
                                                <Badge
                                                    key={`Badge${index}`}
                                                    badgeContent={
                                                        <IconButton
                                                            key={`IconButton${index}`}
                                                            color="error"
                                                            onClick={() => askDeleteApp(appName)}
                                                            size="small"
                                                        >
                                                            <Tada key={`Tada${index}`}>
                                                                <HighlightOff key={`HighlightOff${index}`} />
                                                            </Tada>
                                                        </IconButton>
                                                    }
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <AppIcon key={`AppIcon${index}`} icon={storedApps[appName].logo} active={selectedApp === appName} />
                                                </Badge>
                                            )
                                        }
                                    />
                                </BottomNavigation>
                            </Grid>
                        );
                    })}
                </Grid>
            }
            <ConfirmDialog
                open={open}
                question={"Biztos végrehajtja a műveletet?"}
                handleConfirm={() => { setOpen(false); deleteApp() }}
                handleCancel={() => setOpen(false)}
            />
        </div>
    );
};

export default AppEditor;
