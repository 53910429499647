import { useContext } from 'react';
import { AppContext } from '../appContext';

const UpdateIsAdminOfUserAction = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, isAdmin }) => {
        const newRecord = {
            isAdmin: isAdmin,
        }
        console.log('id', id);
        console.log('isAdmin', isAdmin);
        const result = await updateDocument({
            id: id,
            table: 'users',
            record: newRecord,
            fields: ['isAdmin'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateIsAdminOfUserAction;