import React, { useEffect, useState } from 'react';
import FSPFieldSelectTemplate from '../FSPFieldSelectTemplate';
import FSPFieldDate from '../FSPFieldDate';
import FSPFieldModelMultiSelectGroup from '../FSPFieldModelMultiSelectGroup';

const FSPFormField = ({
    field,
    handleChangeArray,
    newRecord,
    meta
}) => {
    const metaModelName = field.model ? field.model : field.question;
    const model = meta[metaModelName] ?? [];

    const [hide, setHide] = useState(0);
    const {
        name, q2, label, valuesLabel, valuesKey, autoHide, addId
    } = field;
    const onSelect = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    };
    const onRemove = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    }

    useEffect(() => {
        if (autoHide &&
            Array.isArray(autoHide) &&
            autoHide.indexOf(newRecord.type) > -1
        ) {
            setHide(1);
        } else {
            setHide(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newRecord]);

    // console.log('meta', meta);
    // console.log('metaT', metaModelName);
    return <>
        {!hide &&
            <div>
                {
                    field.type === 'date' &&
                    <FSPFieldDate
                        field={field}
                        value={newRecord[field.name] ?? ''}
                        handleChange={handleChangeArray}
                    />
                }
                {field.type === 'document_select_template' &&
                    <FSPFieldSelectTemplate
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={model}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChangeArray}
                        meta={meta}
                        newRecord={newRecord}
                    />
                }
                {field.type === 'document_multiselect_group' &&
                    <FSPFieldModelMultiSelectGroup
                        id={name}
                        label={label}
                        placeholder={q2}
                        meta={model}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        selected={newRecord[name] ?? []}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        handleChange={handleChangeArray}
                    />
                }
            </div>
        }
    </>
};

export default FSPFormField;