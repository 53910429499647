import React from "react";
import { ListItemAvatar, ListItemText } from "@mui/material";
import StorageAvatar from "../firebase/StorageAvatar";

function GroupItemMini({ group }) {
    return (
        <li className="categoryListing">
            <ListItemAvatar>
                <StorageAvatar
                    value={group?.Profilkép}
                    bucket={'group_images'}
                    size='large'
                />
            </ListItemAvatar>
            <ListItemText primary={group?.name} />
        </li>
    );
}

export default GroupItemMini;
