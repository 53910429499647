import React from 'react';
import { Accordion, AccordionSummary, Box, Rating, Chip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import NextButton from '../../buttons/NextButton';
import MemberItem from '../../members/MemberItem';
import QQuestion from './ui/QQuestion';
import QFooter from './ui/QFooter';
import QStepper from './stepper/QStepper';
import { styles } from '../styles'
import { convertArrayById } from '../../../commons/Utility';
import GroupItemMini from '../../groups/GroupItemMini';

const SubmitPage = ({
    questionnaire,
    values,
    next,
    previous,
    submitCallback,
    success,
    started,
    myGroups,
    categories,
    steps,
    group
}) => {
    const form = questionnaire?.qtemplate;
    const allMeta = form?.meta;
    const meta = allMeta?.questions;
    const scales = allMeta?.scales;

    const [expanded, setExpanded] = React.useState();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (<>
        <QQuestion>
            <div>
                <h1>Összefoglalás</h1>
                <p>Az eredmények megtekintéséhez kattintson a kategória nevére.</p>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        margin: 0,
                        padding: 0,
                        // minHeight: 400,
                        maxHeight: `calc(100vh - 300px)`,
                        '& ul': { padding: 0 },
                    }}>
                    {values && Object.keys(values).map((id, index) => (
                        <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                {questionnaire?.evaluate === 'project' ?
                                    <GroupItemMini
                                        group={group?.data}
                                    />
                                    : <MemberItem
                                        key={index}
                                        id={categories[id]?.data?.id}
                                        icon={categories[id]?.data?.icon}
                                        label={categories[id]?.data?.name}
                                        values={null}
                                        onClick={() => { }}
                                        showCheckbox={false} />
                                }
                            </AccordionSummary>
                            {values[id] && Object.keys(values[id]).map((id2, index2) => {
                                const z = convertArrayById(scales[meta[id2]['Skála']].elemek, 'value');
                                return (
                                    <p><h4>{meta[id2].name}</h4>
                                        {scales[meta[id2]['Skála']].type === 'szam' &&
                                            (<Rating readOnly
                                                onChange={(event, newValue) => {
                                                    console.log(newValue);
                                                }}
                                                name="customized-10"
                                                defaultValue={values[id][id2]}
                                                max={10} />)
                                        }
                                        {scales[meta[id2]['Skála']].type === 'ertekeles' &&
                                            <span>{values[id][id2]}</span>
                                        }
                                        {scales[meta[id2]['Skála']].type === 'betu' &&
                                            <div><Chip label={z[values[id][id2]].name} /></div>

                                        }
                                        {scales[meta[id2]['Skála']].type === 'tobbvalasztos' &&
                                            values[id][id2].map((id3, index3) => (

                                                <div><Chip label={z[id3].name} /></div>
                                            ))
                                        }
                                    </p>
                                )
                            })
                            }
                        </Accordion>
                    ))
                    }
                    {Object.keys(success).map((s, index) =>
                        <p>{myGroups[s].name}</p>
                    )}
                </Box>
                <NextButton
                    name={'Indítás'}
                    variant={'contained'}
                    onClick={() => { submitCallback({ values: values }) }}
                    disabled={started || success}
                />
            </div>
        </QQuestion>
        <QFooter show={true} styles={styles}>
            <QStepper
                mini={true}
                activeStep={steps.length}
                lastSlideIsVisible={true}
                styles={styles}
                steps={steps}
                previous={previous}
                next={next}
            />
        </QFooter>
    </>
    );
};

export default SubmitPage;