import { Typography } from "@mui/material"
import { images } from "./images"

export const groupStatAnimation = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Diagram használata',
    description: `A diagramok használatát és a különböző nézetek kezelését az alábbi animáció mutatja be. Az animációban részletesen látható, hogyan válthatunk az egyszerű, interaktív és haladó nézetek között, és hogyan alkalmazhatjuk a különböző beállítási lehetőségeket a diagramokon.
`,
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.stat2',
            content: '',
            description:``
            ,
            animation: images.groupstatsAnim,
            help: null,
        },
    ]
}
