import { AddAPhoto } from '@mui/icons-material';
import React, { useState } from 'react';
import { Accordion, AccordionSummary, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import ProfileAvatar from '../pages/profile/components/ProfileAvatar';
import { auth } from '../firebase.config';

const UploadAvatarWarning = ({ profile }) => {
    const [show, setShow] = useState();
    return (<>
        {profile && !profile?.updatedAvatar &&
            <Accordion>
                <div onClick={() => { setShow(true) }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>
                            <ListItem>
                                <ListItemAvatar>
                                    <AddAPhoto />
                                </ListItemAvatar>
                                <ListItemText primary={'Profilkép feltöltése'} secondary={`Válassz ki egy fényképet a telefonodról, vagy készíts egy új fényképet a telefonod kamerájával`} />
                            </ListItem>
                        </Typography>
                    </AccordionSummary>
                </div>
                {show && <ProfileAvatar auth={auth} exit={() => (setShow(false))} />}
            </Accordion>
        }
    </>);
};

export default UploadAvatarWarning;