import React from "react";
import { useState, useEffect, useContext } from "react";
import { QrCode2, AddAPhoto, Groups, AdminPanelSettings } from '@mui/icons-material';
import { Box, BottomNavigationAction } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { auth, deleteToken, messaging } from "../../firebase.config";
import { AppContext } from "../../services/appContext";
import StorageAvatar from "../../components/firebase/StorageAvatar";
import ProfileAvatar from "./components/ProfileAvatar";
import Install from "./components/Install";
import Name from "./components/Name";
import DeleteToken from "../../services/actions/DeleteToken";
import { Tab, Tabs } from '@mui/material';
import MyQRCode from "./components/MyQRCode";
import MyGroups from "./components/MyGroups";
import Setup from "./components/Setup";
import Badge from '@mui/material/Badge';
import Debug from "./components/Debug";
import { IconButton } from '@mui/material';

const Profile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  // const [profileTab, setProfileTab] = React.useState(0);
  const handleChangeValue = (event, newValue) => { setProfileTab(newValue); };
  const {
    version,
    snapshots,
    isTokenFound,
    deviceId,
    counter, setCounter,
    debug, setDebug,
    profileTab, setProfileTab
  } = useContext(AppContext);
  const [show, setShow] = useState();
  const { deleteAction } = DeleteToken();

  // Log out from Firebase
  const onLogout = () => {
    if (isTokenFound) {
      console.log('id', deviceId);
      deleteToken(messaging);
      console.log('isTokenFound', isTokenFound);
      deleteAction({
        id: deviceId,
        // token: isTokenFound
      })
    }
    auth.signOut();
    console.log('location', 'location');
    navigate("/sign-in");
  };

  useEffect(() => {
    if (snapshots && snapshots['profile'] && snapshots['profile'][0] && snapshots['profile'][0].data) {
      setProfile(snapshots['profile'][0].data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshots['profile']]);


  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: 0,

      }}>
      <div className="profileList">
        {!show && <>
          <header className="profileHeader">
            <p className="pageHeader">Profil</p>
            <button type="button" className="logOut" onClick={onLogout}>
              Kijelentkezés
            </button>
          </header>
          <div className='centerContent2'>
            <div className="avatar-container">
              <div onClick={() => { setShow('profile') }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={

                    <BottomNavigationAction
                      showLabel
                      onClick={() => { setShow('profile') }}
                      icon={
                        <IconButton
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.7)'
                          }}>
                          <AddAPhoto />
                        </IconButton>
                      }
                    />

                  }
                >
                  <StorageAvatar
                    value={auth.currentUser.uid}
                    bucket={'profile_images'}
                    size='xlarge'
                  />
                </Badge>
              </div>
            </div>
            <h4 className="profileButton">{profile?.name}
              <br></br>
              {profile?.email}</h4>

            <Tabs
              // variant="scrollable"
              allowScrollButtonsMobile
              // scrollButtons="auto"
              value={profileTab}
              variant="fullWidth"
              onChange={handleChangeValue}
              // showlabels={false}
              aria-label="icon tabs example"
            >
              <Tab icon={<QrCode2 />} label="QR kód" />
              {/* <Tab icon={<AdminPanelSettings />} label="Beállítások" /> */}
              <Tab icon={<Groups />} label="Csoportok" />
              <Tab icon={<AdminPanelSettings />} label="Beállítások" />
            </Tabs>
            {profileTab === 0 &&
              <MyQRCode
                auth={auth}
                uid={auth.currentUser.uid}
              />
            }
            {profileTab === 1 &&
              <MyGroups />
            }
            {profileTab === 2 &&
              <Setup auth={auth} />
            }
            <Debug
              version={version}
              counter={counter}
              setCounter={setCounter}
              debug={debug}
              setDebug={setDebug}
              deviceId={deviceId}
              isTokenFound={isTokenFound}
            />
          </div>
        </>
        }
        {show === 'profile' && <ProfileAvatar auth={auth} exit={() => (setShow())} />}
        {show === 'name' && <Name auth={auth} exit={() => (setShow())} />}
        {show === 'install' && <Install auth={auth} exit={() => (setShow())} />}
      </div>
    </Box>
  );
};

export default Profile;