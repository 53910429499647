import Snapshot2 from '../../hooks/snapshot2';

const MyQuestionnaires = ({ setter }) => {

    const model = Snapshot2({
        setState: setter,
        key: 'questionnaires',
        table: 'questionnaires',
        refresh: true
    });

    const subscribe = ({ allGroups, date }) => {
        let unsubscribe2;
        // console.log('allGroups', allGroups);
        if (allGroups.length > 0) {
            unsubscribe2 = model.subscribe({
                filter: [{
                    field: "group",
                    operation: 'in',
                    data: allGroups,
                },
                    // {
                    //     field: "createdAt",
                    //     operation: '>=',
                    //     data: date,
                    // },
                ],
                orderBy: {
                    field: "createdAt",
                    direction: "desc"
                },
                limit: 5,
                startAfter: 0
            })
        }
        else {
            console.log('még nincs csoport', allGroups)
            unsubscribe2 = () => { console.log('még nincs csoport') }
        }
        return unsubscribe2;
    }
    return { subscribe };
};

export default MyQuestionnaires;