import { sum, mean, median } from "stats-lite";

export const chartColors = [
    '#82ca9d',
    '#4285f4',
    '#ea4335',
    '#fbbc04',
    '#34a853',
    '#ff6d01',
    '#46bdc6',
    '#7baaf7',
    '#f07b72',
    '#fcd04f',
    '#71c287',
    '#ff994d',
    '#7ed1d7',
    '#6038de',
    '#b1b5dc',
];

// create base data format:
// [{emotional: 5, question: 'wdPIaK1p4x96Thd6gE3g', value: 5},
// ...]
export const createBaseData = (model, meta) => {
    let lines = [];
    const chartData = [];
    model.map((field, index) => {
        let emotional = field?.emotional;
        let values = field?.values
        Object.keys(values).map((value, index) => {
            lines.push({
                emotional: emotional,
                question: value,
                value: values[value]
            })
        })
    })
    return lines;
}

export const createDataToChart = ({ data, meta, categories, groupByFields, category, field }) => {
    const groupByGroups = groupAndAverage(
        data,
        groupByFields, category, field
    );
    // console.log('groupByGroups', groupByGroups);
    const withInfo = addMetaInfo(
        {
            model: groupByGroups,
            meta: meta,
            info: {
                question: { meta: 'questions', keyField: 'question', keyLabel: 'name' },
                groupId: { meta: 'groups', keyField: 'groupId', keyLabel: 'name' },
                labels: { meta: 'questions', keyField: 'question', keyLabel: 'labels' },
            }
        }
    );
    // console.log('withInfo', withInfo);

    const changedKeyToName = changeKeyToName({
        model: withInfo,
        meta: categories.model,
        field: categories.field,
    });
    // console.log('addGroupName', addGroupName);
    const addLabel = addMetaInfo(
        {
            model: changedKeyToName,
            meta: meta,
            info: {
                name: {
                    transform: (row) => (
                        row
                            ? groupByFields.map(field => row[field]).join('-')
                            : null)
                }
            }
        }
    );
    return addLabel;
}

export const addMetaInfo = ({ model, meta, info }) => {
    // console.log('xxxx', model);
    // console.log('meta', meta);
    // // console.log('groups', gro    ups);
    let lines = [];
    model && model.map((row, index) => {
        let newValues = {};
        let newValue;
        Object.keys(info).map((id) => {
            // console.log('id', id);
            // console.log('info[id]', info[id]);
            if (info[id]?.transform) {
                newValue = info[id]?.transform(row);
            } else {
                const meta1 = meta[info[id].meta];
                // console.log('meta1', meta1);
                const keyField = info[id].keyField;
                // console.log('keyField', keyField);
                const keyLabel = info[id].keyLabel;
                // console.log('keyLabel', keyLabel);
                const value = keyField && row ? row[keyField] : null;
                // console.log('value', value);
                const metaValues = meta1 && value ? meta1[value] : null;
                // console.log('metaValues', metaValues);
                newValue = metaValues && keyLabel ? metaValues[keyLabel] : null
                // console.log('newValue', newValue);
            }
            newValues = { ...newValues, [id]: newValue }
        })

        lines.push({
            ...row,
            ...newValues
        })

    })
    return lines;
}
export const changeKeyToName = ({ model, meta, field }) => {
    let newModel = [];
    model && model.map((row, index) => {
        let m = { ...row };
        meta && Object.keys(meta).map((metaKey, index) => {
            m = { ...m, [meta[metaKey][field]]: m[metaKey] }
        });
        newModel.push(m);
    });
    return newModel;
};

export const addMetaInfoName = (model, field1, meta) => {
    // console.log('xxxx', model);
    // console.log('meta', meta);
    // console.log('groups', groups);
    let lines = [];

    let date;
    model.map((field, index) => {
        if (meta && field && field1 && field[field1] && meta[field[field1]])
            lines.push({
                ...field,
                name: meta[field[field1]],
            })

    })
    return lines;
}

export const groupingData = (data, groupByField, groupingFields, subGroup = null) => {
    
    console.log('data', data);
    console.log('groupByField', groupByField);
    console.log('groupingFields', groupingFields);
    console.log('subGroup', subGroup);
    let number = 0;
    let result = [];
    let fieldsSum = [];
    if (data && Array.isArray(data)) {
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                let groupByFieldValue = subGroup == null ? row[groupByField] : subGroup[row[groupByField]];
                if (!result[groupByFieldValue]) {
                    result[groupByFieldValue] = [];
                }
                if (!result[groupByFieldValue][groupingField]) {
                    result[groupByFieldValue][groupingField] = [];
                }
                result[groupByFieldValue][groupingField].push(row[groupingField])
            });
            number += 1;
        });

        let data_sum = [];
        let data_mean = [];
        let data_median = [];
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                let groupByFieldValue = subGroup == null ? row[groupByField] : subGroup[row[groupByField]];

                if (!data_sum[groupByFieldValue]) {
                    data_sum[groupByFieldValue] = [];
                    data_mean[groupByFieldValue] = [];
                    data_median[groupByFieldValue] = [];
                }
                data_sum[groupByFieldValue][groupingField] = sum(result[groupByFieldValue][groupingField]);
                data_mean[groupByFieldValue][groupingField] = mean(result[groupByFieldValue][groupingField]);
                data_median[groupByFieldValue][groupingField] = median(result[groupByFieldValue][groupingField]);

                data_sum[groupByFieldValue] = {
                    ...data_sum[groupByFieldValue],
                    name: groupByFieldValue,
                    db: result[groupByFieldValue][groupingField].length
                };
                data_mean[groupByFieldValue] = {
                    ...data_mean[groupByFieldValue],
                    name: groupByFieldValue,
                    db: result[groupByFieldValue][groupingField].length
                };
                if (!fieldsSum[groupingField]) {
                    fieldsSum[groupingField] = 0;
                }

                // console.log('aaaa', data_sum[groupByFieldValue][groupingField])
                // console.log('aaaa1', groupByFieldValue)
                // console.log('aaaa2', groupingField)
                fieldsSum[groupingField] = Math.max(fieldsSum[groupingField], data_sum[groupByFieldValue][groupingField]);
            });
        });
        // console.log('fieldsSum', fieldsSum);

        return {
            number: number,
            result: result,
            sum: data_sum,
            fieldsSum: fieldsSum,
            mean: data_mean,
            median: data_median,
        }
    } else return null

}
export const groupingData2D = (data, groupByField2, groupingFields, subGroup = null) => {
    console.log('data', data);
    console.log('groupByField', groupByField2);
    console.log('groupingFields', groupingFields);
    console.log('subGroup', subGroup);
    let number = 0;
    let result = [];
    let fieldsSum = [];
    let groupByFieldValueA = [];
    if (data && Array.isArray(data)) {
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                groupByFieldValueA[0] = subGroup == null ? row[groupByField2[0]] : subGroup[row[groupByField2[0]]];
                groupByFieldValueA[1] = subGroup == null ? row[groupByField2[1]] : subGroup[row[groupByField2[1]]];
                // console.log('groupByFieldValueA[0]', groupByFieldValueA[0]);
                // console.log('groupByFieldValueA[1]', groupByFieldValueA[1]);

                let groupByFieldValue = groupByFieldValueA[0] + '-' + groupByFieldValueA[1];
                if (!result[groupByFieldValueA[0]]) {
                    result[groupByFieldValueA[0]] = [];
                }
                if (!result[groupByFieldValueA[0]][groupByFieldValueA[1]]) {
                    result[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                }
                if (!result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]) {
                    result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = [];
                }
                result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField].push(row[groupingField])
            });
            number += 1;
        });
        // console.log('result', result);
        let data_sum = [];
        let data_mean = [];
        let data_median = [];
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                // let groupByFieldValue = subGroup == null ? row[groupByField2] : subGroup[row[groupByField2]];
                groupByFieldValueA[0] = subGroup == null ? row[groupByField2[0]] : subGroup[row[groupByField2[0]]];
                groupByFieldValueA[1] = subGroup == null ? row[groupByField2[1]] : subGroup[row[groupByField2[1]]];
                let groupByFieldValue = groupByFieldValueA[0] + '-' + groupByFieldValueA[1];

                if (!data_sum[groupByFieldValueA[0]]) {
                    data_sum[groupByFieldValueA[0]] = [];
                    data_mean[groupByFieldValueA[0]] = [];
                    data_median[groupByFieldValueA[0]] = [];
                }
                if (!data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]]) {
                    data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                    data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                    data_median[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                }
                data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = sum(result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);
                data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = mean(result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);
                data_median[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = median(result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);

                data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]] = {
                    ...data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]],
                    name: groupByFieldValue,
                    db: result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField].length
                };
                data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]] = {
                    ...data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]],
                    name: groupByFieldValue,
                    db: result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField].length
                };
                if (!fieldsSum[groupByFieldValueA[0]]) {
                    fieldsSum[groupByFieldValueA[0]] = [];
                }
                if (!fieldsSum[groupByFieldValueA[0]][groupingField]) {
                    fieldsSum[groupByFieldValueA[0]][groupingField] = 0;
                }

                // console.log('aaaa', data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField])
                // console.log('aaaa1', groupByFieldValue)
                // console.log('aaaa2', groupingField)
                fieldsSum[groupByFieldValueA[0]][groupingField] = Math.max(fieldsSum[groupByFieldValueA[0]][groupingField], data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);
            });
        });
         console.log('fieldsSum', fieldsSum);
         console.log('fieldsSum2', {
            number: number,
            result: result,
            sum: data_sum,
            fieldsSum: fieldsSum,
            mean: data_mean,
            median: data_median,
        });
        return {
            number: number,
            result: result,
            sum: data_sum,
            fieldsSum: fieldsSum,
            mean: data_mean,
            median: data_median,
        }
    } else return null
}

export const createSubGroup = ({ group1, group1Key, group2, group2Label }) => {
    console.log('group1', group1);
    console.log('group1Key', group1Key);
    console.log('group2', group2);
    console.log('group2Label', group2Label);
    let category = {};
    Object.keys(group1).map((key1, index) => {
        // console.log(key1);
        const ids = group1[key1][group1Key];
        // console.log(ids);
        ids && ids.map((id, index2) => {
            // console.log(id);
            const labels2 = group2[id];
            // console.log(labels2);
            const labels3 = labels2 ? labels2[group2Label] : null;
            // console.log(labels3);
            if (labels3) {
                if (!category[key1]) category[key1] = [];
                category[key1].push(labels3);
            }
        })
    })
    return category;
}

export const groupingData3D = ({ data, groupByField2, groupingFields, subGroupField, subGroup }) => {
    console.log('data', data);
    console.log('groupByField', groupByField2);
    console.log('groupByField', groupByField2);
    console.log('subGroupField', subGroupField);
    console.log('subGroup', subGroup);
    let number = 0;
    let result = [];
    let fieldsSum = [];
    let groupByFieldValueA = [];
    // console.log('subGroup', subGroup);
    if (data && Array.isArray(data)) {
        data.map((row, index) => {
            // console.log('row', row);
            // console.log('subGroupField', subGroupField);
            const labelValues = row[subGroupField];
            // console.log('labelValues', labelValues);
            const labelValues2 = subGroup[labelValues];
            // console.log('labelValues2', labelValues2);
            groupingFields.map((groupingField) => {
                // console.log('groupByField2', groupByField2);
                // console.log('groupingField1111', groupingField);
                // console.log('row', row);
                // console.log('subGroup', subGroup);
                groupByFieldValueA[0] = subGroup == null ? row[groupByField2[0]] : subGroup[row[groupByField2[0]]];
                groupByFieldValueA[1] = subGroup == null ? row[groupByField2[1]] : subGroup[row[groupByField2[1]]];
                // console.log('groupByFieldValueA[0]', groupByFieldValueA[0]);
                // console.log('groupByFieldValueA[1]', groupByFieldValueA[1]);

                let groupByFieldValue = groupByFieldValueA[0] + '-' + groupByFieldValueA[1];
                if (!result[groupByFieldValueA[0]]) {
                    result[groupByFieldValueA[0]] = [];
                }
                if (!result[groupByFieldValueA[0]][groupByFieldValueA[1]]) {
                    result[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                }
                if (!result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]) {
                    result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = [];
                }
                result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField].push(row[groupingField])
            });
            number += 1;
        });
        // console.log('result', result);
        let data_sum = [];
        let data_mean = [];
        let data_median = [];
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                // let groupByFieldValue = subGroup == null ? row[groupByField2] : subGroup[row[groupByField2]];
                groupByFieldValueA[0] = subGroup == null ? row[groupByField2[0]] : subGroup[row[groupByField2[0]]];
                groupByFieldValueA[1] = subGroup == null ? row[groupByField2[1]] : subGroup[row[groupByField2[1]]];
                let groupByFieldValue = groupByFieldValueA[0] + '-' + groupByFieldValueA[1];

                if (!data_sum[groupByFieldValueA[0]]) {
                    data_sum[groupByFieldValueA[0]] = [];
                    data_mean[groupByFieldValueA[0]] = [];
                    data_median[groupByFieldValueA[0]] = [];
                }
                if (!data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]]) {
                    data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                    data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                    data_median[groupByFieldValueA[0]][groupByFieldValueA[1]] = [];
                }
                data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = sum(result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);
                data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = mean(result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);
                data_median[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField] = median(result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);

                data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]] = {
                    ...data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]],
                    name: groupByFieldValue,
                    db: result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField].length
                };
                data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]] = {
                    ...data_mean[groupByFieldValueA[0]][groupByFieldValueA[1]],
                    name: groupByFieldValue,
                    db: result[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField].length
                };
                if (!fieldsSum[groupByFieldValueA[0]]) {
                    fieldsSum[groupByFieldValueA[0]] = [];
                }
                if (!fieldsSum[groupByFieldValueA[0]][groupingField]) {
                    fieldsSum[groupByFieldValueA[0]][groupingField] = 0;
                }

                // console.log('aaaa', data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField])
                // console.log('aaaa1', groupByFieldValue)
                // console.log('aaaa2', groupingField)
                fieldsSum[groupByFieldValueA[0]][groupingField] = Math.max(fieldsSum[groupByFieldValueA[0]][groupingField], data_sum[groupByFieldValueA[0]][groupByFieldValueA[1]][groupingField]);
            });
        });
        // console.log('fieldsSum', fieldsSum);

        return {
            number: number,
            result: result,
            sum: data_sum,
            fieldsSum: fieldsSum,
            mean: data_mean,
            median: data_median,
        }
    } else return null
}

export function groupAndAverage(data, groupByFields, groupingField, valueField) {
    const groupedData = {};
    let isValue = {};
    data.forEach(item => {
        const groupKey = groupByFields.map(field => item[field]).join('-');
        // console.log('groupByFields', groupByFields)
        // console.log('groupKey', groupKey)
        if (!groupedData[groupKey]) {
            groupedData[groupKey] = {
                count: 1,
            };
            groupByFields.forEach(field => {
                groupedData[groupKey][field] = item[field];
            });
        } else {
            groupedData[groupKey].count += 1;
        }

        if (!groupedData[groupKey][groupingField]) {
            groupedData[groupKey][groupingField] = {};
        }

        const fieldValue = item[valueField] || 0;
        if (fieldValue > 0) {
            isValue[groupingField] = 1;
            if (!groupedData[groupKey][groupingField][item[groupingField]]) {
                groupedData[groupKey][groupingField][item[groupingField]] = {
                    sum: Number(fieldValue),
                    count: 1,
                };

            } else {
                // console.log('x',  groupKey, groupedData[groupKey][groupingField],item[groupingField] );
                groupedData[groupKey][groupingField][item[groupingField]].sum += Number(fieldValue);
                groupedData[groupKey][groupingField][item[groupingField]].count += 1;
            }
        }
    });
    // console.log('x', groupedData);
    const result = Object.values(groupedData).map(group => {
        const groupResult = {
            count: group.count,
        };

        groupByFields.forEach(field => {
            groupResult[field] = group[field];
        });
        // console.log(group)
        // console.log(group[groupingField])
        let i = 0;
        // const a= group[groupingField].filter(x=>isValue(x));
        Object.entries(group[groupingField]).forEach(([key, value]) => {
            if (value?.sum && value?.sum > 0) {
                const averagingFunction = value => value.sum / value.count;
                groupResult[key] = averagingFunction(value);
                i++;
            }

        });
        // console.log('groupResult',groupResult)
        if (i > 0)
            return groupResult;
        else
            return
    });

    return result;
}