import React, { useCallback, useEffect, useState } from 'react';

const Loadings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadings, setLoadings] = useState({})
    const setLoading = ({ key, status }) => {
        // console.log('LOADINGS key', key);
        // console.log('LOADINGS status', status);

        if (status) {
            setIsLoading(true);
            setLoadings((prevState) => ({
                ...prevState,
                [key]: status,
            }));
            console.log('LOADINGS', loadings);
            console.log('isLoading', isLoading);
        } else {
            // console.log('TTTTTT', loadings);
            // console.log('TTTTTT', loadings.length);
            if (loadings.length === 1) {
                setIsLoading(false);
            }
            setLoadings((prevState) => ({
                ...prevState,
                [key]: status,
            }));
            // setLoadings((prevState) => {
            //     console.log('prevState', prevState);
            //     const { [key]: deleted, ...rest } = prevState;
            //     console.log(rest);
            //     return rest;
            // });
        }
    };

    useEffect(() => {
        console.log('isLoading', isLoading)
    }, [isLoading]);

    return { isLoading, loadings, setLoading };
};

export default Loadings;