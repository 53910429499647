import React from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell,Paper } from '@mui/material';
import LogRow from './LogRow';

const LogTable = ({ logData }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Dátum</TableCell>
          <TableCell>Érték</TableCell>
          <TableCell>Érzelmi áll.</TableCell>
          <TableCell>Sablon</TableCell>
          <TableCell>Értékelt</TableCell>
          <TableCell>Kérdés</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logData.map((log, index) => (
          <LogRow key={index} log={log} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default LogTable;
