import React from 'react';
import SubscribedUser from './SubscribedUser';
import DeleteMemberAction from '../../services/actions/DeleteMemberAction';

const MemberUsers = ({ users, groupId = null, isAdmin = false, group, navigateToUser = false }) => {
    const { deleteMemberAction } = DeleteMemberAction();
    return (
        <div>
            {users && Object.keys(users).map((g, index) => {
                return (
                    <SubscribedUser
                        key={`member-${users[g]}`}
                        level='member'
                        group={group}
                        user={users[g]}
                        groupId={groupId}
                        deleteAction={isAdmin ? deleteMemberAction : undefined}
                        navigateToUser={navigateToUser}
                    />)
            })}
        </div>
    );
};

export default MemberUsers;