import React, { useContext, useEffect, useState } from 'react';
import View from './View';
import { auth } from '../../firebase.config';
import { AppContext } from '../../services/appContext';

const MyProfile = () => {
    const [profile, setProfile] = useState(null);
    const { snapshots, sliderView, setSliderView } = useContext(AppContext);
    
    useEffect(() => {
        if (snapshots && snapshots['profile'] && snapshots['profile'][0] && snapshots['profile'][0].data) {
            setProfile(snapshots['profile'][0].data);
        }
    }, [snapshots['profile']]);
    console.log(auth);
    return (
        <div>
            <View
                profile={profile}
                uid={auth?.currentUser?.uid}
                auth={auth}
            />
        </div>
    );
};

export default MyProfile;