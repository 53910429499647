import React from 'react';
import GetQTemplate from '../../../../services/documents/GetQTemplate';
import GetUser2 from '../../../../services/documents/GetUser2';
import { sum, mean, median, variance, numbers } from "stats-lite";

const StatUtility = () => {
    const templateData = GetQTemplate()
    const userData = GetUser2()

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // A timestamp értéke másodpercben van, ezért megszorozzuk 1000-rel, hogy milliszekundumban legyen
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // A hónapokat 0-tól 11-ig számolják, ezért hozzáadunk 1-et, és két számjegyre formázzuk
        const day = ('0' + date.getDate()).slice(-2); // A napokat két számjegyre formázzuk

        return `${year}-${month}-${day}`;
    };
    const formatDateWeek = (date) => {
        const currentDate = new Date(date * 1000); // Timestamp érték milliszekundumba
        currentDate.setUTCHours(0, 0, 0, 0);
        const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
        const dayNum = currentDate.getUTCDay() || 7;
        currentDate.setUTCDate(currentDate.getUTCDate() + 4 - dayNum);
        const yearStartISO = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
        const weekNumber = Math.ceil((((currentDate - yearStartISO) / 86400000) + 1) / 7);

        return `${currentDate.getUTCFullYear()}-${('0' + weekNumber).slice(-2)}`;
    };

    const addDate = async (logData) => {
        const data = [];
        await Promise.all(
            logData?.map(async (line) => {
                const updatedAt = formatDate(line?.updatedAt);
                const updatedAtWeek = formatDateWeek(line?.updatedAt);
                const date_from = formatDate(line?.date_from);
                const date_fromWeek = formatDateWeek(line?.date_from);
                data.push({
                    ...line,
                    updatedAt: updatedAt,
                    updatedAtMonth: updatedAt.slice(0, 7),
                    updatedAtWeek: updatedAtWeek,
                    date_from: date_from,
                    date_fromMonth: date_from.slice(0, 7),
                    date_fromWeek: date_fromWeek,
                    evaluatedMonth: updatedAt.slice(0, 7).slice(-2),
                    evaluatedYear: updatedAt.slice(0, 7).slice(0, 4),
                });
            })
        );
        return data
    };

    const getTemplatesAndEvaluatedUsersList = (data) => {
        const templateList = [];
        const evaluatedUsersList = [];

        data.forEach(item => {
            if (!templateList.includes(item.template)) {
                templateList.push(item.template);
            }
            if (!evaluatedUsersList.includes(item.evaluated)) {
                evaluatedUsersList.push(item.evaluated);
            }
        });

        return { templateList, evaluatedUsersList };
    };

    const getLabelsStat4 = async (data, templates) => {
        // console.log('templates', templates);
        // Inicializáljuk a csoportosított statisztikát
        const labelsStat = [];

        // Iterálunk az adatokon
        if (data && templates) {
            data.forEach((item, index) => {
                // Ellenőrizzük, hogy az elemnek van-e címke
                if (item?.template && templates[item?.template]) {
                    const template = templates[item?.template];
                    const meta = template?.meta ? template?.meta : null;
                    const questions = meta?.questions ? meta?.questions : null;
                    const question = questions[item.question];
                    // console.log('question', question);

                    const labels = question && question?.labels ? question?.labels : [];
                    // console.log('labels', labels);
                    // Iterálunk az elem címkéin
                    labels.forEach((label) => {
                        labelsStat.push({
                            ...item,
                            label: label,
                            label_name: template?.meta?.labels[label].name
                        });
                    });
                }
            });
        }

        return labelsStat;
    };

    const getLabelsStat5 = async (data, meta) => {
        const templates = meta?.templates;
        const questions = meta?.questions;
        const labelsAll = meta?.labels;
        // console.log('meta', meta);
        // Inicializáljuk a csoportosított statisztikát
        const labelsStat = [];

        // Iterálunk az adatokon
        if (data && templates) {
            data.forEach((item, index) => {
                // Ellenőrizzük, hogy az elemnek van-e címke
                if (item?.template && templates[item?.template]) {
                    // const template = templates[item?.template];
                    // const meta = template?.meta ? template?.meta : null;
                    // const questions = meta?.questions ? meta?.questions : null;
                    const question = questions[item.question];
                    // console.log('question', question);

                    const labels = question && question?.labels ? question?.labels : [];
                    // console.log('labels', labels);
                    // Iterálunk az elem címkéin
                    labels?.forEach((label) => {
                        const a = labelsAll && labelsAll[label] ? labelsAll[label]?.name : 'x';
                        // console.log('aaaa', a);
                        // console.log('labelsAll', labelsAll);
                        // console.log('label', label);
                        labelsStat.push({
                            ...item,
                            label: label,
                            label_name: a
                        });
                    });
                }
            });
        }

        return labelsStat;
    };

    const getLabelsStat3 = (data, groupId = 'id') => {
        // Inicializáljuk a csoportosított statisztikát
        const labelsStat = {};

        // Iterálunk az adatokon
        data.forEach((item) => {
            // Ellenőrizzük, hogy az elemnek van-e címke
            if (item.labels && item.labels.length > 0) {
                // Iterálunk az elem címkéin
                item.labels.forEach((label, index) => {
                    // Ha a címke még nem szerepelt a statisztikában, létrehozzuk az objektumot
                    const d = item.evaluatedMonth ?? item.evaluatedWeek;
                    const g = item.groupId ?? groupId
                    const labelName = item.groupAuthor + '-' + item.evaluatedYear + '-' + d + '-' + g + '-' + '-' + label;

                    if (!labelsStat[labelName]) {
                        labelsStat[labelName] = {
                            count: 1,
                            sum: parseFloat(item['evaluatedMonth']),
                            average: parseFloat(item['evaluated_value']),
                            question: label,
                            questionId: item['labelsId'][index],
                            groupAuthor: item["groupAuthor"],
                            evaluatedYear: item["evaluatedYear"],
                            evaluatedMonth: item["evaluatedMonth"] ?? item["evaluatedWeek"],
                            groupId: g,
                            // template: item["template"],
                            timeselect: item["timeselect"],
                            updatedAt: item["updatedAt"]
                        }
                    } else {
                        // Ha már szerepel a címke, frissítjük a statisztikát az aktuális elem adataival
                        labelsStat[labelName].count += item['count(evaluated_value)'];
                        labelsStat[labelName].sum += parseFloat(item['sum_evaluated_value']);
                        labelsStat[labelName].average = labelsStat[labelName].sum / labelsStat[labelName].count;
                    }
                });
            }
        });
        return labelsStat;
    };

    const getTemplate = async ({ id }) => {
        const a = await templateData.subscribe({
            id: id,
            setter: (model) => { },
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
        return a;
    }

    const getUser = async ({ id }) => {
        const a = await userData.subscribe({
            id: id,
            setter: (model) => { },
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
        return a;
    }

    const getAllData = async (logData) => {
        // console.log('logData111', logData);
        const data = [];
        let allLabels = [];
        let allQuestions = [];
        const templates = [];
        const evaluated = [];
        // setLoading(true);
        await Promise.all(
            logData?.map(async (line) => {
                const b = await getTemplate({ id: line?.template });
                templates[line?.template] = b;
                const labels = templates[line?.template]?.meta?.questions[line?.question]?.labels || [];
                const labelsFull = labels.map(l => templates[line?.template]?.meta?.labels[l].name);
                labels.forEach(element => {
                    allLabels[element] = templates[line?.template]?.meta?.labels[element].name;
                });
                allQuestions[line?.question] = templates[line?.template]?.meta?.questions[line?.question]?.name || '';
                const d = line?.evaluatedMonth ?? line?.evaluatedWeek;
                const a = formatDate(line?.updatedAt);
                data.push({
                    ...line,
                    updatedAt: a,
                    timeselect: a.slice(0, 7),
                    evaluatedMonth: a.slice(0, 7).slice(-2),
                    evaluatedYear: a.slice(0, 7).slice(0, 4),
                    template: templates[line?.template]?.description || '',
                    templateId: line?.template,
                    evaluated: line?.evaluated,
                    labelsId: labels,
                    question: templates[line?.template]?.meta?.questions[line?.question]?.name || '',
                    questionId: line?.question,
                    labels: labelsFull,
                });
            })
        );
        // console.log('allLabels', allLabels);
        // setLabels(allLabels);
        // setQuestions(allQuestions);

        // console.log('allQuestions', allQuestions);
        // console.log('dataa', data);
        const labelsStat = getLabelsStat3(data);
        // console.log('labelsStat', labelsStat);
        return {
            labels: allLabels,
            questions: allQuestions,
            data: labelsStat
        };
    };

    const addMetaToData = async (logData, meta, keyField, labelField) => {
        const data = [];
        await Promise.all(
            logData?.map(async (line) => {
                const value = line ? line[keyField] : null;
                const record = meta ? meta[value] : null;
                const label = record ? record[labelField] : null;
                const fieldName = keyField + '_name';
                data.push({
                    ...line,
                    [fieldName]: label
                });
            })
        );
        return data;
    };
    const addMetaToData2 = async (logData, field, labelField) => {
        const data = [];
        await Promise.all(
            logData?.map(async (line) => {
                const value = line ? line[field.field] : null;
                const record = field.meta ? field.meta[value] : null;
                const label = record ? record[labelField] : null;
                const fieldName = field.field + '_name';
                data.push({
                    ...line,
                    [fieldName]: label
                });
            })
        );
        return data;
    };

    const getMetaOfTemplate = async (templatesId) => {
        let allLabels = [];
        let allQuestions = [];
        let templates = [];
        let allScales = [];
        await Promise.all(
            templatesId?.map(async (line) => {
                const template = await getTemplate({ id: line });
                const labels = template?.meta?.labels || [];
                allLabels = { ...allLabels, ...labels };
                const questions = template?.meta?.questions || [];
                allQuestions = { ...allQuestions, ...questions };
                templates = { ...templates, [line]: template };
                const scales = template?.meta?.scales || [];
                allScales = { ...allScales, ...scales };
            })
        );
        return {
            labels: allLabels,
            questions: allQuestions,
            templates: templates,
            scales: allScales
        };
    };
    const getUsers = async (usersId) => {
        let users = [];
        await Promise.all(
            usersId?.map(async (line) => {
                try {
                    const user = await getUser({ id: line });
                    users = { ...users, [line]: user };
                } catch (error) {
                    console.log('hiányzó user', line)
                    users = { ...users, [line]: null };
                }

            })
        );
        return users;
    };


    const calculateStatistics = ({ data, groupByFields, valuesFields }) => {
        // Group data by the specified fields
        const groupedData = data.reduce((acc, item) => {
            const groupKey = groupByFields.map(field => item[field]).join('|');

            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }

            acc[groupKey].push(item);

            return acc;
        }, {});

        // Calculate statistics for each group
        const result = Object.keys(groupedData).map(groupKey => {
            const groupItems = groupedData[groupKey];
            const stats = {};

            valuesFields.forEach(field => {
                const { min, max, count, sum1, average1, median1, variance1 } = calculateStats(groupItems, field);
                stats[`${field}_min`] = min;
                stats[`${field}_max`] = max;
                stats[`${field}_count`] = count;
                stats[`${field}_sum`] = sum1;
                stats[`${field}_average`] = average1;
                stats[`${field.value}_median`] = median1;
                stats[`${field.value}_variance`] = variance1;
            });

            const groupInfo = groupKey.split('|').reduce((acc, value, index) => {
                acc[groupByFields[index]] = value;
                return acc;
            }, {});

            return { ...groupInfo, ...stats };
        });

        return result;
    };

    const calculateStatistics2 = ({ data, groupByFields, valuesFields }) => {
        // Group data by the specified fields
        const groupedData = data.reduce((acc, item) => {
            const groupKey = groupByFields.map(field => item[field.value]).join('|');

            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }

            acc[groupKey].push(item);

            return acc;
        }, {});

        // Calculate statistics for each group
        const result = Object.keys(groupedData).map(groupKey => {
            const groupItems = groupedData[groupKey];
            const stats = {};

            valuesFields.forEach(field => {
                const { min, max, count, sum1, average1, median1, variance1 } = calculateStats(groupItems, field);
                stats[`${field}_min`] = min;
                stats[`${field}_max`] = max;
                stats[`${field}_count`] = count;
                stats[`${field}_sum`] = sum1;
                stats[`${field}_average`] = average1;
                stats[`${field.value}_median`] = median1;
                stats[`${field.value}_variance`] = variance1;
            });

            const groupInfo = groupKey.split('|').reduce((acc, value, index) => {
                acc[groupByFields[index].value] = value;
                return acc;
            }, {});

            return { ...groupInfo, ...stats };
        });

        return result;
    };
    const calculateStats = (arr, field) => {
        // console.log('arrarr',  arr );
        const values1 = arr.map(item => parseFloat(item[field]));
        const values = numbers(values1);
        // console.log('values',  values );
        if (values.length > 0) {
            const min = Math.min(...values);
            const max = Math.max(...values);
            const count = values1.length;
            const average1 = mean(values);
            const median1 = median(values);
            const variance1 = Math.sqrt(variance(values));
            const sum1 = sum(values);
            return {
                min,
                max,
                count,
                sum1,
                average1,
                median1,
                variance1
            };
        } else {
            const min = 0;
            const max = 0;
            const count = 0;
            const average1 = 0;
            const median1 = 0;
            const variance1 = 0;
            const sum1 = 0;
            return {
                min,
                max,
                count,
                sum1,
                average1,
                median1,
                variance1
            };
        }
    };

    const calculateStatistics3 = ({ data, groupByFields, valuesFields }) => {
        // Group data by the specified fields
        const groupedData = data.reduce((acc, item) => {
            const groupKey = groupByFields.map(field => item[field?.value]).join('|');

            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }

            acc[groupKey].push(item);

            return acc;
        }, {});

        // Calculate statistics for each group
        const result = Object.keys(groupedData).map(groupKey => {
            const groupItems = groupedData[groupKey];
            const stats = {};

            valuesFields.forEach(field => {
                const { min, max, count, sum1, average1, median1, variance1 } = calculateStats(groupItems, field.value);
                // console.log('groupItems', { groupItems })
                // console.log('statistics', { min, max, count, sum1, average1, median1, variance1 })
                // console.log('field', field)
                stats[`${field.value}_min`] = min;
                stats[`${field.value}_max`] = max;
                stats[`${field.value}_count`] = count;
                stats[`${field.value}_sum`] = sum1;
                stats[`${field.value}_average`] = average1;
                stats[`${field.value}_median`] = median1;
                stats[`${field.value}_variance`] = variance1;
                stats[`category`] = 'Értékelés';
                stats[`series`] = 'Értékelés';
            });

            const groupInfo = groupKey.split('|').reduce((acc, value, index) => {
                acc[groupByFields[index]?.value] = value;
                return acc;
            }, {});

            return { ...groupInfo, ...stats };
        });

        return result;
    };

    const normalizeValue = ({ data, questions, scales }) => {
        // console.log('dataxxx', data);
        return data.map(item => {
            // Keresse meg a kérdést az questions tömbből a kérdés azonosítója alapján
            const question = questions[item.question];

            if (question) {
                // Keresse meg a skálát az scales tömbből a kérdéshez tartozó skála azonosítója alapján
                const scale = scales[question.Skála];

                if (scale) {
                    // Konvertálja a min és max értékeket számokká
                    const min = Number(scale.min);
                    const max = Number(scale.max);
                    const evaluatedValue = Number(item.evaluated_value);

                    // Számolja ki a normalizált értéket és kerekítse egészre
                    const evaluated_value_normalized = Math.round(
                        100 * (evaluatedValue - min) / (max - min)
                    );

                    // Adja hozzá az új mezőt az adathoz
                    return {
                        ...item,
                        evaluated_value_normalized: evaluated_value_normalized ?? item.evaluated_value
                    };
                }
            }

            // Ha nem található a kérdés vagy a skála, térjen vissza az eredeti adattal
            return item;
        });
    }

    const calculateDaysPassed = (date) => {
        const date1 = new Date(date);
        const currentDate = new Date();
        const timeDifference = currentDate - date1;
        const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysPassed;
    };

    return {
        formatDate,
        addDate,
        getTemplatesAndEvaluatedUsersList,
        getAllData,
        getMetaOfTemplate,
        calculateStatistics,
        calculateStatistics2,
        calculateStatistics3,
        getLabelsStat3,
        getLabelsStat4,
        getLabelsStat5,
        addMetaToData,
        addMetaToData2,
        getUsers,
        normalizeValue,
        calculateDaysPassed
    };
};
// export const normalizeValue = ({ data, questions, labels }) = {

// }
export const groupingData4 = (data, groupByField, groupingFields, subGroup = null) => {

    // console.log('data', data);
    // console.log('groupByField', groupByField);
    // console.log('groupingFields', groupingFields);
    // console.log('subGroup', subGroup);
    let number = 0;
    let result = [];
    let fieldsSum = [];
    if (data && Array.isArray(data)) {
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                let groupByFieldValue = subGroup == null ? row[groupByField] : subGroup[row[groupByField]];
                if (!result[groupByFieldValue]) {
                    result[groupByFieldValue] = [];
                }
                if (!result[groupByFieldValue][groupingField]) {
                    result[groupByFieldValue][groupingField] = [];
                }
                result[groupByFieldValue][groupingField].push(row[groupingField])
            });
            number += 1;
        });

        let data_sum = [];
        let data_mean = [];
        let data_median = [];
        data.map((row, index) => {
            groupingFields.map((groupingField) => {
                let groupByFieldValue = subGroup == null ? row[groupByField] : subGroup[row[groupByField]];

                if (!data_sum[groupByFieldValue]) {
                    data_sum[groupByFieldValue] = [];
                    data_mean[groupByFieldValue] = [];
                    data_median[groupByFieldValue] = [];
                }
                data_sum[groupByFieldValue][groupingField] = sum(result[groupByFieldValue][groupingField]);
                data_mean[groupByFieldValue][groupingField] = mean(result[groupByFieldValue][groupingField]);
                data_median[groupByFieldValue][groupingField] = median(result[groupByFieldValue][groupingField]);

                data_sum[groupByFieldValue] = {
                    ...data_sum[groupByFieldValue],
                    name: groupByFieldValue,
                    db: result[groupByFieldValue][groupingField].length
                };
                data_mean[groupByFieldValue] = {
                    ...data_mean[groupByFieldValue],
                    name: groupByFieldValue,
                    db: result[groupByFieldValue][groupingField].length
                };
                if (!fieldsSum[groupingField]) {
                    fieldsSum[groupingField] = 0;
                }

                // console.log('aaaa', data_sum[groupByFieldValue][groupingField])
                // console.log('aaaa1', groupByFieldValue)
                // console.log('aaaa2', groupingField)
                fieldsSum[groupingField] = Math.max(fieldsSum[groupingField], data_sum[groupByFieldValue][groupingField]);
            });
        });
        // console.log('fieldsSum', fieldsSum);

        return {
            number: number,
            result: result,
            sum: data_sum,
            fieldsSum: fieldsSum,
            mean: data_mean,
            median: data_median,
        }
    } else return null

}

export default StatUtility;