import React from 'react';
import { Box, Dialog, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import CIconButton from '../../../buttons/CIconButton';

const QPage = ({ children, exit }) => {
    return (
        <Dialog fullScreen open={true}>
            <CIconButton
                icon={<Close />}
                color={'primary'}
                confirm={false}
                onClick={() => { exit() }}
                style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                }}
            />

            <DialogContent
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    height: '100%',
                    overflowY: 'auto',
                    paddingRight:'0px',
                    paddingBottom:'0px',
                    paddingLeft:'0px',
                    paddingTop: '20px',
                    '& > div': {
                        maxWidth: '600px',
                        width: '100%',
                    },
                }}
            >
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {children}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default QPage;