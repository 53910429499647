import { useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from "../../firebase.config";

const ImagesBucket = ({ queryDoc }) => {
    const [images, setImages] = useState([]);

    const fetchImageAsBlob = (imageUrl) => {
        return new Promise((resolve, reject) => {
            fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => resolve(blob))
                .catch((error) => reject(error));
        });
    };

    const setImage = async ({ bucket, value, notFromCache = false, notFromLocalStorage = false }) => {
        const id = bucket + '/' + value;
        if (bucket && value) {
            const localStorageKey = bucket + value;
            const imageDataUrl = localStorage.getItem(localStorageKey);
            if (!images[id] || notFromCache) {
                if (imageDataUrl && !notFromLocalStorage) {
                    setImages((prevState) => ({
                        ...prevState,
                        [id]: imageDataUrl
                    }));
                    console.log('LOCAL STORAGE! The image is already savedto the local storage.');
                } else {
                    console.log('FIREBASE STORE! The image downloading from firebase');
                    const imageRef = ref(storage, id);
                    try {
                        const url = await getDownloadURL(imageRef);
                        const response = await fetch(url);
                        const blob = await response.blob();
                        const reader = new FileReader();

                        reader.onload = function () {
                            const imageDataUrl = reader.result;
                            localStorage.setItem(localStorageKey, imageDataUrl);
                            setImages((prevState) => ({
                                ...prevState,
                                [id]: imageDataUrl
                            }));
                        };

                        reader.readAsDataURL(blob);
                    } catch (error) {
                        console.error('Kép letöltése sikertelen: ' + error);
                    }
                }
            } else {
                console.log('CACHED! The image has already been downloaded from the store.');
            }
        }
    }

    const checkGroupAvatar = (group) => {
        const name = 'avatarUpdateAt' + group?.id;
        const avatarUpdatedAt = group?.avatarUpdatedAt;
        const image = group ? group['Profilkép'] : null;
        const avatarUpdatedAtOld = localStorage.getItem(name);
        // console.log('2', name);
        // console.log('3', avatarUpdatedAt);
        // console.log('4', avatarUpdatedAtOld);
        if (avatarUpdatedAt != avatarUpdatedAtOld) {
            // console.log('5', image);
            if (image) {
                localStorage.setItem(name, avatarUpdatedAt);
                setImage({
                    bucket: 'group_images',
                    value: image,
                    notFromCache: true,
                    notFromLocalStorage: true
                });
            }
        }

    }
    const groupsAvatarUpdater = ({ groups }) => {
        let update = 0;
        let usersProfiles;
        let newUsersProfiles = {};
        // console.log('1', groups);
        const getUsers = (groups) => {
            let users = {}
            groups?.forEach(groupData => {
                const group = groupData?.data;
                checkGroupAvatar(group);
                const admins = group?.admins ?? [];
                const Members = group?.Members ?? [];
                const allUser = admins.concat(Members);
                // console.log('allUser', allUser);

                const profileUpdate = group?.profileUpdate;
                // console.log('profileUpdate', profileUpdate);
                if (profileUpdate) {
                    Object.keys(profileUpdate).forEach(id => {
                        // console.log('idd', id);
                        // console.log('allUser[id]', allUser.indexOf(id));
                        // console.log('allUser[id]', allUser.indexOf(id));
                        if (allUser.indexOf(id) > -1) {
                            users = { ...users, [id]: profileUpdate[id] };
                        }
                    })
                }
                // console.log('x', users);
            })
            return users;
        };
        const checkUser = (user, usersProfiles) => {
            // console.log('user', user);
            // console.log('usersProfiles', usersProfiles);
            const id = user?.id;
            const newDate = user.data;
            const oldDate = usersProfiles && usersProfiles[id] ? usersProfiles[id].data : null;
            // console.log('newDate', newDate);
            // console.log('oldDate', oldDate);
            newUsersProfiles[id] = {
                id: id,
                data: newDate
            }
            if (newDate?.seconds != oldDate?.seconds) {
                usersProfiles[id] = {
                    id: id,
                    data: newDate
                }

                // console.log('Töröljük a user LS adatait', user);
                // console.log('Új usersProfiles', usersProfiles);
                // localStorage.removeItem('profile_images' + id);
                localStorage.removeItem('user-' + id);
                localStorage.removeItem('user-' + id + '-' + id);
                queryDoc({
                    key: 'user-' + id,
                    table: 'users',
                    id: id,
                    refresh: true,
                    notFromCache: true
                })
                setImage({
                    bucket: 'profile_images',
                    value: id,
                    notFromCache: true,
                    notFromLocalStorage: true
                });
                // const a = getAvatar(id);
                return 1
            } else {
                return 0
            }
        };

        // console.log('!!!!!!!!!');
        // console.log(groups);

        // új  users adatok
        let users = getUsers(groups);
        // console.log('users', users);
        // LS letöltés
        usersProfiles = JSON.parse(localStorage.getItem('usersProfiles')) ?? {}
        // console.log(usersProfiles);

        Object.keys(users).forEach(id => {
            const user = { id: id, data: users[id] };
            update += checkUser(user, usersProfiles);
            // console.log('usersProfiles', usersProfiles);
        })
        // console.log('4', usersProfiles);
        // if (update > 0) {
        //     console.log('FRISSÍTÜNK', usersProfiles);
        //     localStorage.setItem('usersProfiles', JSON.stringify(usersProfiles));
        // } else {
        //     console.log('NEM FRISSÍTÜNK');
        // }
        // console.log('newUsersProfiles', newUsersProfiles);
        if (Object.keys(newUsersProfiles).length > 0) {
            localStorage.setItem('usersProfiles', JSON.stringify(newUsersProfiles));
        }
    }
    return {
        images,
        setImage,
        groupsAvatarUpdater
    };
};

export default ImagesBucket;