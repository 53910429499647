import { useContext } from 'react';
import { AppContext } from '../appContext';

const UpdateEmailVerificationCounterAction = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, emailVerifiedCounter }) => {
        const newRecord = {
            emailVerifiedCounter: emailVerifiedCounter,
        }
        const result = await updateDocument({
            id: id,
            table: 'users',
            record: newRecord,
            fields: ['emailVerifiedCounter'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateEmailVerificationCounterAction;