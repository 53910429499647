import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import HeaderSimple from './HeaderSimple';
import { helpMetaEditor } from '../../anonimousData/Helps';
import LabelsView from './components/LabelsView';
import QuestionsView from './components/QuestionsView';
import NewLabelForm from './components/NewLabelForm';

const MetaEditor2 = ({
    questions,
    labels,
    setQuestions,
    setLabels,
    onClose
}) => {
    const [localQuestions, setLocalQuestions] = useState({ ...questions });
    const [localLabels, setLocalLabels] = useState({ ...labels });
    const [newLabelQuestionId, setNewLabelQuestionId] = useState(null);
    const [newLabelId, setNewLabelId] = useState('');
    const [newQuestionLabelId, setNewQuestionLabelId] = useState(null);
    const [newQuestionId, setNewQuestionId] = useState('');
    const [view, setView] = useState('labels');

    const handleSave = () => {
        setQuestions(localQuestions);
        setLabels(localLabels);
        onClose();
    };

    const load = () => {
        const { localQuestions, localLabels } = JSON.parse(localStorage.getItem('editedLabels'));
        setLocalQuestions(localQuestions);
        setLocalLabels(localLabels);
    };
    const save = () => {
        localStorage.setItem('editedLabels', JSON.stringify({ localQuestions, localLabels }));
        setLabels(localLabels);
    };
    return (
        <Dialog open={true} fullScreen onClose={onClose} fullWidth maxWidth="md">
            <HeaderSimple
                title={'Címkék szerkesztése'}
                description={'A címke szerkesztő egy felületet biztosít a kérdések és címkék kezeléséhez egy párbeszédablakban. Ezen a felületen szerkesztheted a kérdésekhez tartozó címkéket, valamint új címkéket is hozzáadhatsz, amelyek megjelennek a statisztikákban.'}
                help={helpMetaEditor}
            />
            <DialogContent>
                <Button variant="outlined" onClick={() => setView(view === 'labels' ? 'questions' : 'labels')}>
                    {view === 'labels' ? 'Kérdések nézet' : 'Címkék nézet'}
                </Button>
                {view === 'labels' ? (
                    <LabelsView
                        localLabels={localLabels}
                        localQuestions={localQuestions}
                        setLocalQuestions={setLocalQuestions}
                        newQuestionLabelId={newQuestionLabelId}
                        setNewQuestionLabelId={setNewQuestionLabelId}
                        newQuestionId={newQuestionId}
                        setNewQuestionId={setNewQuestionId}
                    />
                ) : (
                    <QuestionsView
                        localLabels={localLabels}
                        localQuestions={localQuestions}
                        setLocalQuestions={setLocalQuestions}
                        newLabelQuestionId={newLabelQuestionId}
                        setNewLabelQuestionId={setNewLabelQuestionId}
                        newLabelId={newLabelId}
                        setNewLabelId={setNewLabelId}
                    />
                )}
                <NewLabelForm
                    localLabels={localLabels}
                    setLocalLabels={setLocalLabels}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={save} color="primary">
                    Mentés
                </Button>
                <Button onClick={load} color="primary">
                    Beolvasás
                </Button>
            </DialogActions>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Mégsem
                </Button>
                <Button variant="contained" onClick={handleSave} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MetaEditor2;
