import { Done, HourglassBottom } from '@mui/icons-material';
import React from 'react';
import Tada from 'react-reveal/Tada';
// import * as Icons from '@mui/icons-material';
import { Box } from '@mui/material';
import QPage from '../components/stepperSampling/components/ui/QPage';
import NextButton from '../components/buttons/NextButton';
import { useNavigate } from 'react-router-dom';
import Icons from './MIIcons';

const InfoMessage = ({
    icon = 'Done',
    className = 'primaryButton',
    color = "success",
    label = 'Jelenleg nincs kitöltendő kérdőív',
    description = 'Elképzelhető, hogy hálózati probléma lépett fel, vagy időközben lejárt a határidő, vagy egy másik eszközön kitöltötted a kérdőívet... ',
    options = null
}) => {
    const IconComponent = Icons[icon];
    const navigate = useNavigate();
    return (
        <QPage>
            <Box
                // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    padding: 0,
                }}
            >
                <div className='centerContent'>
                    <h2>{label}</h2>
                    <Tada>
                        <IconComponent sx={{ fontSize: 170 }} color={color} />
                    </Tada>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                    {options &&
                        <div>
                            {options.map((option, index) => (
                                <div key={`div${index}`}>
                                    <NextButton
                                        key={`button${index}`}
                                        name={option.name}
                                        className={option.className ?? className}
                                        onClick={() => { typeof option.onClick === 'function' ? option.onClick() : navigate(option.link) }}
                                    // disabled={!values[field.name] || (Array.isArray(values[field.name]) && values[field.name].length < 1)}
                                    />
                                </div>
                            ))}
                        </div>
                    }


                </div>
            </Box>
        </QPage >
    );
};

export default InfoMessage;