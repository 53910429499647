import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../services/appContext';
import { snapshotSetState } from '../commons/Utility';

const Query3 = ({ setState, key, table, refresh = false }) => {
    const {
        queries,
        queryDocs,
    } = useContext(AppContext);

    const subscribe = async ({ filter }) => {
        // console.log('key', key);
        // console.log('table', table);
        // console.log('filter', filter);
        // console.log('refresh', refresh);
        const result = await queryDocs({
            key: key,
            table: table,
            filter: filter,
            refresh: refresh
        })
        .then((snap) => {
            // console.log('snapsnap', snap);
            return snap;
        })
            .catch((err) => {
                
                console.error('error', err);
                return null
            });
        // console.log('result1', filter);
        // console.log('result', result);
        return result
    }

    useEffect(() => {
        // console.log('queries[key]', queries[key]);
        snapshotSetState({
            snapshot: queries[key],
            callback: setState
        })
    }, [queries[key]]);

    return {
        subscribe
    };
};

export default Query3;