import Snapshot2 from '../../hooks/snapshot2';

const MyEvaluated = ({ setter }) => {

    const model = Snapshot2({
        setState: setter,
        key: 'myevaluations',
        table: 'evaluated',
        refresh: true
    });

    const subscribe = ({ user }) => {
        const unsubscribe2 = model.subscribe({
            filter: [{
                field: "evaluatedId",
                operation: '==',
                data: user.uid,
            },
                // {
                //     field: "to",
                //     operation: '>',
                //     data: currentDate
                // }
            ],
            orderBy: {
                field: "createdAt",
                direction: "desc"
            },
            limit: 5,
            // orderBy: {
            //     field: "from",
            //     direction: "asc"
            // },
        });
        return unsubscribe2;
    }
    return { subscribe };
};

export default MyEvaluated;