import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Collapse, MobileStepper } from '@mui/material';
import React from 'react';

const MiniStepper = ({
    lastSlideIsVisible,
    steps,
    activeStep = 0,
    previous,
    next,
    valid
}) => {
    return (
        <Collapse in={lastSlideIsVisible}
            style={{ width: '100%', padding: 0, flexGrow: 1 }}>
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                sx={{ background: '#f1f1f1', width: '100%', padding: 0, flexGrow: 1 }}
                nextButton={
                    <Button size="small" onClick={next} disabled={!valid}>
                        Következő
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={previous}>
                        <KeyboardArrowLeft />
                        Előző
                    </Button>
                }
            />
        </Collapse >
    );
};

export default MiniStepper;