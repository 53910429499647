import React from 'react';
import ReactApexChart from 'react-apexcharts';

const EvaluationsMonthChart = ({ data }) => {
  // Adatok előkészítése a diagramhoz
  const chartData = data.reduce((acc, item) => {
    const month = item.updatedAt.slice(0, 7);
    if (!acc[month]) {
      acc[month] = 0;
    }
    acc[month] += 1;
    return acc;
  }, {});

  const categories = Object.keys(chartData).sort();
  const seriesData = categories.map(category => chartData[category]);

  // Az ApexCharts konfigurációja
  const chartOptions = {
    chart: {
      id: 'line-chart',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories,
      title: {
        text: 'Hónap'
      }
    },
    yaxis: {
      title: {
        text: 'Kitöltés'
      }
    },
    dataLabels: {
      enabled: false
    }
  };

  // console.log('chartData', chartData);

  return (
    <ReactApexChart
      options={chartOptions}
      series={[{ name: 'Értékek', data: seriesData }]}
      type="bar"
      height={400}
    />
  );
};

export default EvaluationsMonthChart;
