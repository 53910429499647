import Snapshot2 from '../../hooks/snapshot2';

const MyUserData = ({ setter }) => {

    const model = Snapshot2({
        setState: setter,
        key: 'myUserData',
        table: 'userData',
    });

    const subscribe = ({ user }) => {
        const unsubscribe2 = model.subscribe({
            filter: {
                field: "uid",
                operation: '==',
                data: user.uid
            }
        });
        return unsubscribe2;
    }
    return { subscribe };
};

export default MyUserData;