import { images } from "./images"

export const version01 = {
  type: 'help',
  isAdmin: false,
  icon: 'PendingActions',
  content: 'Újdonságok 2023.10.10',
  description: 'Az új verzióban számos fejlesztés történt. A legfontosabbak a következők: ',
  steps: [
    {
      type: 'slide', // slide, link
      selector: '.selectApplication',
      content: 'Rendszer kiválasztása',
      description: 'A bejelentkező/regsztrációs oldalon ki lehet választani, hogy a "demó" vagy az "éles" rendszeren szeretnénk dolgozni.',
      image: images.signin,
      help: null,
      mark: 'rect',
      markRadius: 30,
      markXPercent: 33,
      markYPercent: 26,
      markRectangleWidth: 71,
      markRectangleHeight: 34
    },
    {
      type: 'slide', // slide, link
      selector: '.registrationGoogle',
      content: 'Regisztráció Google azonosítóval',
      description: 'Lehet regisztrálni Google azonosítóval is (ebben az esetben nem kell jelszót beállítani)',
      image: images.signup,
      help: null,
      mark: 'rect',
      markRadius: 30,
      markXPercent: 13,
      markYPercent: 73,
      markRectangleWidth: 87,
      markRectangleHeight: 83
    },
    {
      type: 'slide', // slide, link
      selector: '.dashboardQRCode',
      content: 'Csoport Qr kód a kezdőoldalon',
      description: 'A nyitó oldalon közvetlen elérhetőek a csoportok QR kódjai, valamint a "jelentkezés csoportba" gomb.',
      image: images.dashboard,
      help: null,
      mark: 'rect',
      markRadius: 11,
      markXPercent: 7,
      markYPercent: 14,
      markRectangleWidth: 92,
      markRectangleHeight: 37
    },
    {
      type: 'title', // slide, link
      selector: '.title',
      content: 'Dokumentáció',
      description: 'További segédanyagok a rendszer használatához'
    },
    {
      type: 'link', // slide, link
      selector: '.authentication',
      content: 'Bejelentkezés, regisztráció, elfelejtett jelszó',
      description: 'Bejelentkezés, regisztráció, elfelejtett jelszó',
      link: 'authentication',
    },

  ]
}
