import Test from './store/test';
import ImagesBucket from './store/imagesBucket';
import Loadings from './store/loadings';
import Queries from './store/queries';
import Documents from './store/documents';
import Snapshots from './store/snapshots';
import Subscriptions from './store/subscriptions';
import Counters from './store/counters';
import Sampling from './store/sampling';

const useAppStore = () => {
    const {
        success, setSuccess,
        start, setStart,
        submited, setSubmited,
        error, setError
    } = Sampling();
    const { counter, setCounter } = Test();
    const {
        newEvaluation, setNewEvaluation,
        newEvaluationMonth, setNewEvaluationMonth,
        newQuestionnaires, setNewQuestionnaires,
        isAdmin, setIsAdmin,
        isManager, setIsManager,
        isSuperAdmin, setIsSuperAdmin,
        selectedApp, setSelectedApp,
        sliderView, setSliderView,
        theme, setTheme,
        messageQuestionnaires, setMessageQuestionnaires,
        messageEvaluations, setMessageEvaluations,
        emailQuestionnaires, setEmailQuestionnaires,
        emailEvaluations, setEmailEvaluations,
        debug, setDebug,
        showErrorIcon, setShowErrorIcon,
        allEvaluationsData, setAllEvaluationsData,
        oldEvaluations, setOldEvaluations,
        evaluations, setEvaluations,
        profile, setProfile,
        evaluationsMonthsNumber, setEvaluationsMonthsNumber,
        firebaseApps, setFirebaseApps,
        deviceId, setDeviceId,
        isTokenFound, setTokenFound,
        profileTab, setProfileTab,
        profileUserTab, setProfileUserTab,
        groupTab, setGroupTab,
        facingMode, setFacingMode,
        viewStatusOfGroups, setViewStatusOfGroups,
        version
    } = Counters();
    const { isLoading, loadings, setLoading } = Loadings();

    const {
        subscriptions,
        setSubscription,
        deleteSubscription } = Subscriptions({ setLoading: setLoading });

    const {
        documents,
        setDocument,
        queryDoc,
        createDocument,
        updateDocument,
        deleteDocument,
        deleteDocuments,
        queryDocByFilter
    } = Documents({ setLoading: setLoading });
    const { images, setImage, groupsAvatarUpdater } = ImagesBucket({ queryDoc: queryDoc });
    const { queries, queryDocs } = Queries({ setLoading: setLoading });
    const {
        subscribeToSnapshot,
        unsubscribeFromSnapshot,
        snapshots,
        setSnapshot
    } = Snapshots({
        setLoading: setLoading,
        subscriptions: subscriptions,
        setSubscription: setSubscription,
        deleteSubscription: deleteSubscription
    });
    return {
        counter, setCounter,
        newEvaluation, setNewEvaluation,
        newEvaluationMonth, setNewEvaluationMonth,
        newQuestionnaires, setNewQuestionnaires,
        isAdmin, setIsAdmin,
        isManager, setIsManager,
        isSuperAdmin, setIsSuperAdmin,
        images, setImage, groupsAvatarUpdater,
        isLoading, loadings, setLoading,
        documents, setDocument, queryDoc,
        createDocument, updateDocument,
        deleteDocument, deleteDocuments,
        queryDocByFilter,
        queries, queryDocs,
        subscribeToSnapshot, unsubscribeFromSnapshot,
        subscriptions, snapshots,
        selectedApp, setSelectedApp,
        sliderView, setSliderView,
        theme, setTheme,
        messageQuestionnaires, setMessageQuestionnaires,
        messageEvaluations, setMessageEvaluations,
        emailQuestionnaires, setEmailQuestionnaires,
        emailEvaluations, setEmailEvaluations,
        debug, setDebug,
        allEvaluationsData, setAllEvaluationsData,
        oldEvaluations, setOldEvaluations,
        evaluations, setEvaluations,
        profile, setProfile,
        evaluationsMonthsNumber, setEvaluationsMonthsNumber,
        firebaseApps, setFirebaseApps,
        deviceId, setDeviceId,
        isTokenFound, setTokenFound,
        profileTab, setProfileTab,
        profileUserTab, setProfileUserTab,
        groupTab, setGroupTab,
        setSnapshot,
        success, setSuccess,
        start, setStart,
        submited, setSubmited,
        error, setError,
        facingMode, setFacingMode,
        showErrorIcon, setShowErrorIcon,
        viewStatusOfGroups, setViewStatusOfGroups,
        version
    };
};

export default useAppStore;