import React, { useState } from 'react';
import StorageAvatar from '../../components/firebase/StorageAvatar';
import QRCode from 'react-qr-code';
import GoBackButton from '../../commons/GoBackButton';
import { Alert } from '@mui/material';

const View = ({ uid, profile, auth }) => {
    const code = `${window.location.origin}/addMember/${auth?.config?.apiKey}/${uid}`;
    const [shareLinkCopied, setShareLinkCopied] = useState(false);
    return (
        <div className="profileList">
            <GoBackButton />
            <header className="profileHeader">
                <p className="pageHeader">Profil</p>
            </header>
            <div className='centerContent2'>
                <div className="avatar-container">
                    <div>
                        <StorageAvatar
                            value={uid}
                            bucket={'profile_images'}
                            size='xlarge'
                        />
                    </div>
                </div>

                <h4 className="profileButton">{profile?.name}
                    <br></br>
                    {profile?.email}

                </h4>
                <div

                    onClick={() => {
                        navigator.clipboard.writeText(code);
                        setShareLinkCopied(true);
                        setTimeout(() => {
                            setShareLinkCopied(false);
                        }, 2000);
                    }}
                >

                    <QRCode
                        size={512}
                        style={{ height: "auto", maxWidth: "100%", width: "80%" }}
                        value={code}
                        viewBox={`0 0 512 512`}
                    />

                </div>
                <Alert severity="info">
                    A csoportok adminjai a QR kódod beolvasásával fel tudnak venni a csoportjukba
                    {shareLinkCopied && <p className="linkCopied">A QR kódod kimásoltuk a vágólapra</p>}
                </Alert>
            </div>
        </div>
    );
};

export default View;