import React from 'react';
import { addMetaInfo, createSubGroup } from '../../../components/charts/CChartConstats';

const Test = ({ templateData2, meta, groups, questions, labels }) => {
    const base3 = addMetaInfo(
        {
            model: templateData2,
            meta: { questions: meta.questions, groups: groups },
            info: {
                name: { meta: 'questions', keyField: 'question', keyLabel: 'name' },
                labels: { meta: 'questions', keyField: 'question', keyLabel: 'labels' },
                group: { meta: 'groups', keyField: 'groupId', keyLabel: 'name' },
                date: { transform: (field) => (field.evaluatedYear + '-' + field.evaluatedMonth) }
            }
        }
    );
    const base4 = addMetaInfo(
        {
            model: base3,
            meta: { questions: meta.questions, groups: groups },
            info: {
                label: { transform: (field) => (field.labels ? field.labels[0] : null) }
            }
        }
    );

    // dataForLeaderColumns3Chart = [];
    // const titles = questions;
    // const groupByLeader = allData3;
    // if (questionnaire && groupByLeader.sum) {
    //     [
    //         'subscale1_percent',
    //         'subscale2_percent',
    //         'subscale3_percent',
    //         'subscale4_percent',
    //         'subscale5_percent',
    //         'subscale6_percent',
    //         'subscale7_percent',
    //         'subscale8_percent',
    //         'subscale9_percent'
    //     ]
    //         .forEach(value => {
    //             let results = [];
    //             let tvalue = titles[value];
    //             ['vezető', 'nem_vezető']
    //                 .forEach(field => {
    //                     let tfield = titles[field];
    //                     results[titles[field]] = groupByLeader['mean'] && groupByLeader['mean'][tfield] && groupByLeader['mean'][tfield][value] ? groupByLeader['mean'][tfield][value] : 0
    //                 })
    //             results['name'] = tvalue;
    //             dataForLeaderColumns3Chart.push(results);
    //         }
    //         );
    // };

    // const allData4 = groupingData3D({
    //     data: templateData,
    //     groupByField2: ["groupId", "question"],
    //     groupingFields: ["count(evaluated_value)",
    //         "average_evaluated_value",
    //     ],
    //     subGroupField: "question",
    //     subGroup: labelsOfQuestions,
    // });

    const labelsOfQuestions = createSubGroup({
        group1: questions,
        group1Key: 'labels',
        group2: labels,
        group2Label: 'name'
    });

    let titles_labels = [];
    Object.keys(labels).forEach((x) => { titles_labels[x] = labels[x].name });
    const titlesOLd = Object.keys(questions).map((x) => ({ [x]: questions[x].name }));

    let titles = [];
    Object.keys(questions).forEach((x) => { titles[x] = questions[x].name });

    let shortName = [];
    Object.keys(questions).forEach((x) => {
        if (questions[x].labels &&
            questions[x].labels[0] &&
            titles_labels[questions[x].labels[0]]) {
            shortName[x] = titles_labels[questions[x].labels[0]];
        }

        else { shortName[x] = questions[x].name; }
    });
    return (
        <div>

        </div>
    );
};

export default Test;