import { AddAPhoto } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storage } from '../../firebase.config';
import { ref, uploadBytes } from 'firebase/storage';
import UpdateGroupAvatarUpdateAt from '../../services/actions/UpdateGroupAvatarUpdatedAt';
import Resizer from 'react-image-file-resizer';

const UploadGroupImage = ({ groupId, group }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const cropRef = useRef(null);
    const { subscribe } = UpdateGroupAvatarUpdateAt();
    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file && isImageFile(file) && isFileSizeValid(file)) {
            await uploadImage(file);
        } else {
            toast.error('Hiba: Érvénytelen fájl formátum vagy méret!');
        }
    };

    const isImageFile = (file) => {
        return file.type.startsWith('image/');
    };

    const isFileSizeValid = (file) => {
        return file.size <= 500 * 2000 * 1024;
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(file, 600, 600, "JPEG", 90, 0, (uri) => { console.log(uri); resolve(uri); }, "blob",);;
        });

    const uploadImage = async (file) => {
        try {
            // console.log('xxxxx',file);
            const storageRef = ref(storage, 'group_images/' + group?.Profilkép);
            //   const storageRef = storage.ref(`group_images/${groupId}`);
            const bytes = await getArrayBuffer(file);
            // const dataUrl = cropRef.current.getImage().toDataURL();

            // const result = await fetch(dataUrl);

            // const blob1 = await result.blob();
            const blob = await resizeFile(file);
            // console.log('xxxxx',blob);
            await uploadBytes(storageRef, blob);

            toast.success('A kép sikeresen feltöltve!');
            const a = await subscribe({ id: groupId })
                .then((snap) => {
                    console.log('snapsnap', snap);
                })
                .catch((err) => {
                    console.error('error', err);
                });
            // console.log('a', a);


        } catch (error) {
            toast.error('Hiba a képfeltöltés során!');
            console.error(error);
        }
    };

    const getArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    return (
        <>
            <input
                accept="image/*"
                id="image-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <label htmlFor="image-upload">
                <div className='' >
                    <AddAPhoto style={{ verticalAlign: "-6px" }} />

                </div >
            </label>
        </>
    );
};

export default UploadGroupImage;
