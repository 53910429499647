import React, { useEffect, useState } from 'react';
import { CircularProgress, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import StorageAvatar from '../firebase/StorageAvatar';
import GetUser from '../../services/documents/GetUser';

const UserListItem2 = ({ user, showAvatar = true }) => {
    const [profile, setProfile] = useState(null);
    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }
    const profileData = GetUser({
        setter: (model) => { setProfile(getData(model)) },
        user: user
    })

    const getProfile = async () => {
        const a = await profileData.subscribe();
        if (a) {
            // console.log('success', a)
        }
        else {
            console.log('HIBA111')
        }
    }

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <ListItem>
            <ListItemButton>
                {showAvatar &&
                    <ListItemAvatar>
                        <StorageAvatar
                            value={user}
                            bucket={'profile_images'}
                            size='large'
                        />
                    </ListItemAvatar>
                }
                <ListItemText id={user} primary={profile?.name} secondary={profile?.email} />
            </ListItemButton>
        </ListItem >
    );
};

export default UserListItem2;