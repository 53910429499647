import React, { useState, useCallback } from "react";
import InfiniteScroll from "./InfiniteScroll";
import BChart2 from "./BChart2";

const InfiniteScrollList = ({ label, data, filterFields, meta, items }) => {
    const [showItems, setShowItems] = useState(items.slice(0, 1));
    const [loading, setLoading] = useState(false);

    const fetchMoreData = (newItems) => {
        if (newItems.length >= items.length) {
            return [];
        }
        return [items[newItems.length]];
    };

    const loadMore = useCallback(() => {
        if (!loading && showItems.length < items.length) {
            setLoading(true);
            const newData = fetchMoreData(showItems);
            if (newData.length > 0) {
                setShowItems((prevItems) => [...prevItems, ...newData]);
            }
            setLoading(false);
        }
    }, [loading, showItems]);

    return (
        <div>
            {showItems.map((item, index) => (
                <MemoizedListItem label={`${label}/${index + 1}`} key={item.id} item={item} data={data} filterFields={filterFields} meta={meta} />
            ))}
            {showItems.length < items.length && <InfiniteScroll loadMore={loadMore} />}
        </div>
    );
};

const ListItem = ({ label, item, data, filterFields, meta }) => (
    <div className="list-item">
        <BChart2
            label={label}
            key={item.id}
            data={data}
            options={item.options}
            allFields={filterFields}
            meta={meta}
        />
    </div>
);

const MemoizedListItem = React.memo(ListItem);

export default InfiniteScrollList;
