import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ReactComponent as ArrowRightIcon } from "../../assets/svg/keyboardArrowRightIcon.svg";
import visibilityIcon from "../../assets/svg/visibilityIcon.svg";
import React from "react";
import OAuth from "../../components/OAuth";
import AppSelector from "../../components/apps/AppSelector";
import { auth, initializeFirebaseApp } from "../../firebase.config";
import NextButton from "../../components/buttons/NextButton";
import { apps } from "../../data/apps";
import { AppContext } from "../../services/appContext";
import { Grid } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { selectedApp, setSelectedApp } = useContext(AppContext);
  const storedApps = apps;

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // const auth = getAuth();
      initializeFirebaseApp();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email was sent");
    } catch (error) {
      toast.error("Cound not send reset email");
    }
  };

  return (
    <>
      <div className="pageContainer">
        <div className="loginLayout">
          <header className="logoHeader">
            <img
              // alt={translate('education_2030')}
              // className={classes.logo}
              src={storedApps[selectedApp].logo || '/logo.png'}
              width={100}
            />
            <p className="pageHeader">{storedApps[selectedApp].label}</p>
            <AppSelector />
          </header>
          <div className="profileList">
            <form onSubmit={onSubmit}>
              <h2 className="registerLink">Elfelejtett jelszó</h2>
              <input
                type="email"
                className="emailInput"
                placeholder="Email"
                id="email"
                value={email}
                onChange={onChange}
              />
              <NextButton
                name={'Új jelszó beállítása'}
              />
              <p>Adja meg e-mail címét, kattintson az „Új jelszó beállítása“ gombra.
                Ezután egy e-mailt fog kapni a megadott e-mail címre.
                A kapott e-mailben kattintson az „Új jelszó” hivatkozásra,
                ami át fogja átirányítja egy oldalra, amely lehetővé teszi új jelszó megadását.</p>
            </form>
            <div style={{ paddingTop: 30 }}>
              <Grid container>
                <Grid item xs>
                  <Link to="/sign-in" className="registerLink2">
                    Bejelentkezés
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/sign-up" className="registerLink2">
                    Regisztráció
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
