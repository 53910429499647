import React, { useState } from 'react';

const Sampling = () => {
    const [success, setSuccess] = useState(false);
    const [start, setStart] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [error, setError] = useState(false);
    return {
        success, setSuccess,
        start, setStart,
        submited, setSubmited,
        error, setError
    };
};

export default Sampling;