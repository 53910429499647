import React from "react";
import { Link } from "react-router-dom";
import { Badge, ListItemAvatar, ListItemText } from "@mui/material";
import { GroupAdd } from "@mui/icons-material";
import StorageAvatar from "../firebase/StorageAvatar";
import UserItem from "../subscribed/UserItem";

function GroupItemMini4({ group, status = 'member', level = 'member' }) {
    let bcolor = "info";
    let bcontent = 0;
    let bvariant = 'standard';
    if (status === 'admin') {
        bcolor = "error";
        bcontent = <GroupAdd />;
        bvariant = 'dot';
    }
    if (status === 'subscribed') {
        bcolor = "error";
        bcontent = <GroupAdd />;
        bvariant = 'dot';
    }
    return (
        <li className="categoryListing">
            <Link
                to={`/mygroup/${group?.id}`}
                className="categoryListingLink"
            >
                <ListItemAvatar>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={bcontent}
                        color={bcolor}
                        variant={bvariant}
                    // overlap="circular"
                    >
                        <StorageAvatar
                            value={group?.Profilkép}
                            bucket={'group_images'}
                            size='large'
                        />
                    </Badge>
                </ListItemAvatar>
                <ListItemText primary={group?.name} secondary={<UserItem
                    user={group['Oktató']}
                    showAvatar={false}
                />
                } />
            </Link>
        </li>
    );
}

export default GroupItemMini4;
