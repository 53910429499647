import React from 'react';
import closeIcon from "../../assets/svg/back.png";
import { useNavigate } from 'react-router-dom';
import QPage from '../../components/stepperSampling/components/ui/QPage';
import InfoCards from '../../commons/help/widgets/InfoCards';
import { helps } from '../../commons/help/data/helps';
import Help from '../../commons/help/Help';
import { ArrowBack, Close } from '@mui/icons-material';

const View = ({ id, view = 'card', goBack }) => {
    const navigate = useNavigate();
    const help = helps[id];

    return (
        <div className="profile">
            <div
                className="backIconDiv"
                onClick={() => goBack(id)}
            >
                <ArrowBack />
            </div>

            <div className='help'>
                <h1>{help.content}</h1>
                <p className='helpDescription' dangerouslySetInnerHTML={{ __html: help.description }} />
                {view === 'card' &&
                    <InfoCards steps={help.steps} />
                }
                {view === 'slide' &&
                    <Help
                        height={window.innerHeight - 150}
                        steps={help.steps} />
                }
            </div>
        </div>
    );
};

export default View;