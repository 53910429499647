import React from 'react';
import NextButton from '../../buttons/NextButton';
import QFooter from './ui/QFooter';
import QStepper from './stepper/QStepper';
import { styles } from '../styles'
import QQuestion from './ui/QQuestion';
import FSPQuestion from './FSPQuestion';
import { Box } from '@mui/material';

const FieldPageSlider = ({
    valid,
    pages,
    actual,
    setActual,
    previous,
    next,
    values,
    setValues,
    questionnaire,
    categories,
    steps,
    group
}) => {
    const form = questionnaire?.qtemplate;
    const meta = form?.meta;
    const page = pages[actual];
    return (<>
        <QQuestion>
            <div className='questionPanel' style={{ marginTop: '40px', width: "100%" }}>
                <Box
                    sx={{
                        maxHeight: `calc(100vh - 150px)`,
                    }}>
                    <FSPQuestion
                        pages={pages}
                        actual={actual}
                        setActual={setActual}
                        next={next}
                        meta={meta}
                        values={values}
                        setValues={setValues}
                        valid={valid}
                        categories={categories}
                        questionnaire={questionnaire}
                        group={group}
                    />
                </Box>
                {(page.questionType === 'tobbvalasztos' || page.questionType === 'ertekeles') &&
                    <NextButton
                        name={'Tovább'}
                        variant={'contained'}
                        onClick={() => { next() }}
                        disabled={!valid()}
                    />
                }
            </div>
        </QQuestion>
        <QFooter show={true} styles={styles}>
            <QStepper
                mini={questionnaire?.evaluate === 'project' || questionnaire?.evaluate === 'self'}
                activeStep={page?.id_category}
                lastSlideIsVisible={true}
                styles={styles}
                steps={steps}
                previous={previous}
                next={next}
                valid={valid()} />
        </QFooter>
    </>)
}

export default FieldPageSlider;