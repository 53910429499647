import React, { useEffect, useState, useContext } from 'react';
import View from './View';
import { AppContext } from '../../services/appContext';
const MyGroups = ({ user }) => {
    const [profile, setProfile] = useState(null);
    const [myGroups, setMyGroups] = useState([]);
    const { queries, snapshots } = useContext(AppContext);
    const { isSuperAdmin, isManager } = useContext(AppContext);

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(snapshots['profile']);
        }
    }, [snapshots['profile']]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
        }
    }, [snapshots['allgroups']]);

    return (
        <View profile={profile ? profile[0]?.data : null}
            groups={myGroups}
            isSuperAdmin={isSuperAdmin}
            isManager={isManager}
        >
        </View>
    );
};

export default MyGroups;