import React, { useEffect, useState } from 'react';
import ConfirmDialog from '../../../../../commons/ConfirmDialog';

const TemplateChecker = () => {
    const [open, setOpen] = useState(false);

    const handleLSClear = () => {
        const templateversion = localStorage.getItem('templateversion');
        if (!templateversion || templateversion < 20240611) {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);

                if (key.indexOf('qtemplates-') === 0) {
                    console.log(key);
                    localStorage.removeItem(key);
                }
                caches.keys().then((keys) => {
                    keys.forEach((request, index, array) => {
                        caches.delete(request);
                        console.log('index', request)
                    });
                });
                localStorage.setItem('templateversion', 20240611);
                window.location.reload(true);
            }
        }

    };
    useEffect(() => {
        const templateversion = localStorage.getItem('templateversion');
        if (!templateversion || templateversion < 20240611) {
            setOpen(true);
        }
    }, [])

    return (
        <>
            <ConfirmDialog
                open={open}
                question={"A sablonadatok elavultak. Szeretné frissíteni?"}
                handleConfirm={() => { setOpen(false); handleLSClear() }}
                handleCancel={() => setOpen(false)}
            />
        </>


    );
};

export default TemplateChecker;