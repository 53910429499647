import {
    getFirestore, collection, setDoc, getDocs, onSnapshot,
    deleteDoc, addDoc, doc, getDoc, query, where, documentId, orderBy, limit, startAfter
} from "firebase/firestore";
import { db, auth, storage } from "../../firebase.config";
import { useState } from "react";

const Queries = ({ setLoading, id }) => {
    const [queries, setQueries] = useState([])

    const getData = (values) => {
        let listings = [];
        if (values) {
            values.forEach((doc) => {
                return listings.push({
                    id: doc.id,
                    data: { ...doc.data(), id: doc.id },
                });
            });
        }
        return listings;
    }

    const setQuery = ({ key, value }) => {
        const v = getData(value);
        setQueries((prevState) => ({
            ...prevState,
            [key]: v,
        }));
    };

    const getDocuments = ({ key, table, filter }) => {
        // console.log('key', key);
        // console.log('table', table);
        // console.log('filter', filter);
        const q = filter ? query(collection(db, table), where(
            filter.field === 'uid' ? documentId() : filter.field,
            filter.operation,
            filter.data
        )) : query(collection(db, table));
        getDocs(q).then((snap) => {
            setLoading({ key: key, status: false })
            setQuery({ key: key, value: snap.docs });

        })
            .catch((err) => {
                setLoading({ key: key, status: false })
                console.error('error', err);
                throw err;
            });
    }
    const isEmpty = (a) => {
        const isArray = Array.isArray(a);
        return isArray && a.length < 1
    }
    const queryDocs = async ({ key, table, filter, refresh = false, qOrderBy = null, qLimit = null, qStartAfter = null }) => {
        try {
            let q = ''
            // console.log('qqqqq LIMIT', qLimit != null);
            // console.log('qqqqq LIMIT', typeof qLimit);
            // console.log('qqqqq qOrderBy', qOrderBy);

            const start = startAfter != null ? startAfter(qStartAfter) : startAfter(0);

            if (key && table) {
                const queryConstraints = [];
                if (!queries[key] || refresh || isEmpty(queries[key])) {
                    setLoading({ key: key, status: true })
                    if (filter) {
                        // Ha a filters paraméter egy objektum, akkor konvertáljuk egy tömbbé
                        let filters
                        if (!Array.isArray(filter)) {
                            filters = [filter];
                        } else {
                            filters = filter;
                        }
                        filters.forEach((f) => {
                            queryConstraints.push(where(
                                f.field === 'uid' ? documentId() : f.field,
                                f.operation,
                                f.data
                            ))
                        });
                    }
                    if (qOrderBy != null) {
                        const o = orderBy(qOrderBy.field, qOrderBy.direction);
                        queryConstraints.push(o);
                    }
                    if (qLimit != null) {
                        queryConstraints.push(limit(qLimit));
                    }
                    if (qStartAfter) {
                        queryConstraints.push(startAfter(qStartAfter));
                    }
                    q = query(collection(db, table), ...queryConstraints);

                    const result = await getDocs(q).then((snap) => {
                        setLoading({ key: key, status: false })
                        setQuery({ key: key, value: snap.docs });
                        return snap;
                    })
                        .catch((err) => {
                            setLoading({ key: key, status: false })
                            console.error('error', err);
                            return null
                        });
                    return result;
                } else {
                    setLoading({ key: key, status: false })
                    console.log('CACHED! The QUERY DATA has already been downloaded from the server.', queries[key]);
                    return queries[key];
                }
            }
        } catch (error) {
            console.error("Error adding document: ", error);
            return null;
        }
    }

    return { queries, queryDocs };
};

export default Queries;