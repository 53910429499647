import React from "react";
import { Link } from "react-router-dom";
import { getStringDate } from "../../commons/Utility";
import { evaluatedCount } from "../../commons/StatUtility";
import StampImage from "../firebase/StampImage";

const EvaluationsItemList = ({ evaluation, group, selectItem, profile }) => {
    const id = evaluation.id;
    const count = evaluatedCount(evaluation?.data?.evaluations) || 0;
    const read = evaluation?.data?.read || 0;

    return (
        <>
            {evaluation && group && (
                <li className="categoryListing">
                    <Link
                        to={`/${selectItem}/${id}`}
                        className="categoryListingLink">
                        {group.Profilkép && (
                            <div >
                                <StampImage
                                    value={group.Profilkép}
                                    bucket={"group_images"}
                                    className="categoryListingImg"
                                    size="xlarge"
                                    variant="rounded"
                                    countStatus={count - read}
                                />
                            </div>
                        )}

                        <div className="ecolumn2">
                            <div className="categoryListingLocation">
                                <div>
                                    {getStringDate(evaluation?.data?.from).substring(5, 10)}
                                </div>
                                <div style={{ color: "#999999" }}>
                                    {getStringDate(evaluation?.data?.from).substring(0, 4)}
                                </div>
                            </div>
                        </div>

                        <div className="ecolumn3">

                            <div className="categoryListingName">{group?.name}</div>
                            <div className="categoryListingLocation">
                                {evaluation?.data?.description}
                            </div>

                        </div>
                    </Link>
                </li>
            )}
        </>
    );
};

export default EvaluationsItemList;
