import { useContext } from 'react';
import { AppContext } from '../appContext';

const DeleteSchedule = () => {
    const { deleteDocument } = useContext(AppContext);

    const deleteAction = async ({ id, token }) => {
        const result = await deleteDocument({
            table: 'schedules',
            id: id
        })
        return result;
    }

    return {
        deleteAction
    }
};

export default DeleteSchedule;