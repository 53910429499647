export const evaluatedDirectionName =
{
    'other': { name: 'Értékelés' },
    'self': { name: 'Önértékelés' },
}

export const evaluatedTypesName =
{
    'other': { name: 'Egyéb' },
    'self_other': { name: 'Önértékelés' },
    'evaluator': { name: 'Értékelő' },
    'evaluated': { name: 'Értékelt' },
    'myself': { name: 'Önértékelésem' }
};

export const evaluatedTypesUser =
{
    'myself_notuser': { name: 'Önértékelésem' },	            // 8 ÉN értékelem MAGAM
    'self_other_user': { name: 'Felhasználó önértékelése' },	            //1  MÁS (nem én, nem a user) értékeli 	magát
    'self_other_notuser': { name: 'Mások önértékelése' },	// 2 USER önértékelés  
    
    'other_user': { name: 'Mások értékelései' },		            //4 MÁS értékeli a USERT
    'other_notuser': { name: 'Egyéb' },	            //5 MÁS értékel MÁS-t
    'evaluator_notuser': { name: 'Értékelésem másokra' },	        //6 ÉN értékelek MÁS-t
    'evaluator_user': { name: 'Értékelésem' },		            //3 ÉN értékelem a USER-t
    'evaluated_notuser': { name: 'Értékelt' },		            //7 ENgem értékelnek

    'evaluated_user': { name: 'Értékelt' },		                //ha sajét magát elemzi valaki
    'myself_user': { name: 'Önértékelésem' }		            //ha sajét magát elemzi valaki
};;

export const dateFilters = {
    day: {
        value: 'day',
        label: 'napi',
        controller: null,
        action: null,
        icon: 'Today'
    },
    week:
    {
        value: 'week',
        label: 'heti',
        controller: null,
        action: null,
        icon: 'DateRange'
    },
    month: {
        value: 'month',
        label: 'havi',
        controller: null,
        action: null,
        icon: 'CalendarToday'
    },
};

export const dateFilters2 = {
    updatedAt: {
        value: 'updatedAt',
        label: 'napi',
        controller: null,
        action: null,
        icon: 'Today'
    },
    updatedAtWeek:
    {
        value: 'updatedAtWeek',
        label: 'heti',
        controller: null,
        action: null,
        icon: 'DateRange'
    },
    updatedAtMonth: {
        value: 'updatedAtMonth',
        label: 'havi',
        controller: null,
        action: null,
        icon: 'CalendarToday'
    },
    
};

export const dateFilters3 = {
    date_from: {
        value: 'date_from',
        label: 'napi',
        controller: null,
        action: null,
        icon: 'Today'
    },
    date_fromWeek:
    {
        value: 'date_fromWeek',
        label: 'heti',
        controller: null,
        action: null,
        icon: 'DateRange'
    },
    date_fromMonth: {
        value: 'date_fromMonth',
        label: 'havi',
        controller: null,
        action: null,
        icon: 'CalendarToday'
    },
};
export const chartTypeFilters2 = {
    bar: {
        value: 'bar',
        field: 'bar',
        label: 'Oszlop',
        icon: 'BarChart',
        horizontal: false,
        dataLabels: true
    },
    stacked: {
        value: 'stacked',
        field: 'bar',
        label: 'Halmozott',
        icon: 'StackedBarChart',
        // horizontal: true,
        dataLabels: true,
        stacked: true,
    },
    bar2: {
        value: 'bar2',
        field: 'bar',
        label: 'Horizontális',
        icon: 'BarChart',
        horizontal: true,
        dataLabels: true,
        rotated: true
    },
    stackedhorizontal: {
        value: 'stackedhorizontal',
        field: 'bar',
        label: 'Halmozott',
        icon: 'StackedBarChart',
        horizontal: true,
        dataLabels: true,
        stacked: true,
        rotated: true
    },
    line: {
        value: 'line',
        field: 'line',
        label: 'Vonal',
        icon: 'ShowChart',
        horizontal: false,
        dataLabels: true
    },
    radar: {
        value: 'radar',
        field: 'radar',
        label: 'Radar',
        icon: 'Radar',
        horizontal: false,
        dataLabels: false
    }
};
export const valueOrCountFilter = {
    evaluated_value_average: {
        value: 'evaluated_value_average',
        label: 'Értékelések átlaga',
        icon: 'BarChart'
    },
    evaluated_value_count:
    {
        value: 'evaluated_value_count',
        label: 'Kitöltések száma',
        icon: 'Article'
    }
};

export const statFunctions = {
    count: {
        field: 'count',
        value: 'count',
        label: 'darab',
        icon: 'Today',
        decimal: 0
    },
    min: {
        field: 'min',
        value: 'min',
        label: 'min',
        icon: 'Today',
        decimal: 0
    },
    max: {
        field: 'max',
        value: 'max',
        label: 'max',
        icon: 'Today',
        decimal: 0
    },
    average: {
        field: 'average',
        value: 'average',
        label: 'átlag',
        icon: 'Today',
        decimal: 1
    },
    median: {
        field: 'median',
        value: 'median',
        label: 'medián',
        icon: 'Today',
        decimal: 1
    },
    variance: {
        field: 'variance',
        value: 'variance',
        label: 'szórás',
        icon: 'Today',
        decimal: 1
    },
}

export const valuesFields = {
    filledQuestionnaire_count: {
        field: 'evaluated_value',
        value: 'evaluated_value',
        label: 'Kérdőív',
        groupByFields: true,
        stat: ['count'],
        defaultValue: 'count',
        decimal: 0
    },
    evaluated_count: {
        field: 'evaluated_value',
        value: 'evaluated_value',
        label: 'Kitöltés',
        groupByFields: true,
        stat: ['count'],
        defaultValue: 'count',
        decimal: 0
    },
    evaluated_value: {
        field: 'evaluated_value',
        value: 'evaluated_value',
        label: 'Értékelés',
        stat: ['count', 'min', 'max', 'average', 'median', 'variance']
    },
    evaluated_value_normalized: {
        field: 'evaluated_value_normalized',
        value: 'evaluated_value_normalized',
        label: 'Normalizált',
        stat: ['count', 'min', 'max', 'average', 'median', 'variance'],
        defaultValue: 'average',
    },
    emotional: {
        field: 'emotional',
        value: 'emotional',
        label: 'Érzelmi áll.',
        stat: ['count', 'min', 'max', 'average', 'median', 'variance']
    },
    filledQuestionnaire:
    {
        field: 'filledQuestionnaire',
        value: 'filledQuestionnaire',
        label: 'Kitöltés'
    },
    evaluated: {
        field: 'evaluated',
        value: 'evaluated',
        label: 'Értékelt'
    },
    questionnaire: {
        field: 'questionnaire',
        value: 'questionnaire',
        label: 'Kérdőív'
    },
    label_name: {
        field: 'label_name',
        value: 'label_name',
        label: 'Címke'
    },
    template: {
        field: 'template',
        value: 'template',
        label: 'Kérdőív'
    },
    date: {
        value: 'updatedAt',
        field: 'updatedAt',
        label: 'Dátum',
        isDate: true
    },
}