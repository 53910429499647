import React from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressFilling = ({ title, value, maxValue }) => {
    // console.log('value', value);
    // console.log('maxValue', maxValue);
    const percent = Math.round(100 * value / (maxValue > 0 ? maxValue : 100));
    return (

        <div className={'centerContent2'} style={{ maxWidth:'200px', width: '40%' }}>
            <CircularProgressbarWithChildren value={percent}
            // styles={buildStyles({
            //     // Rotation of path and trail, in number of turns (0-1)
            //     rotation: 0.25,

            //     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            //     strokeLinecap: 'butt',

            //     // Text size
            //     textSize: '16px',

            //     // How long animation takes to go from one percentage to another, in seconds
            //     pathTransitionDuration: 0.5,

            //     // Can specify path transition in more detail, or remove it entirely
            //     // pathTransition: 'none',

            //     // Colors
            //     pathColor: `rgba(62, 152, 199, ${value / maxValue})`,
            //     textColor: '#f88',
            //     trailColor: '#d6d6d6',
            //     backgroundColor: '#3e98c7',
            // })}
            >
                <div style={{ color: '#f88', fontSize: 22, marginTop: -5 }}>
                    {value && maxValue &&
                        `${value}/${maxValue}`}
                </div>
                <div style={{ color: '#3e98c7', fontSize: 42, marginTop: -5 }}>
                    {value && maxValue && percent && `${percent}%`}
                </div>
            </CircularProgressbarWithChildren>
            <p className="">{title}</p>
        </div>
    );
};

export default ProgressFilling;