// ColourFull.js

import React from 'react';
import './style2.css';

const Circles = () => {
  return (
    <div className="demo-3">
        <div className="wrapper">
    <section className="main">
				
				<ul className="bokeh">
					<li></li>
					<li></li>
					<li></li>
					<li></li>
                    <li></li>
                    <li></li>
				</ul>
			</section>
            </div>
        </div>
  );
}

export default Circles;
