import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../services/appContext';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import { Badge } from "@mui/material";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    border: '2px solid white',
    '@media (max-width: 375px)': {
        maxWidth: 70,
        maxHeight: 70
    },
}));

const StorageAvatar = ({ value, bucket, size = "xlarge", variant = "circular",
    className = "categoryListingImg", presence = null,
    notFromCache = false, notFromLocalStorage = false, status = 'member' }) => {
    const { images, setImage } = useContext(AppContext);

    useEffect(() => {
        setImage({
            bucket: bucket,
            value: value,
            notFromCache: notFromCache,
            notFromLocalStorage: notFromLocalStorage
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    let height = 40;
    switch (size) {
        case 'xxxlarge':
            height = 150;
            break;

        case 'xlarge':
            height = 100;
            break;

        default:
            break;
    }
    let bcolor = "info";
    let bcontent = 0;
    let bvariant = 'standard';
    // if (isAdmin) {
    //     bcolor = "info";
    //     bcontent = group?.data?.Members?.length;
    //     bvariant = 'standard';
    // }
    if (status === 'admin') {
        bcolor = "success";
        bcontent = 'Oktató';
        // bvariant = 'dot';
    }
    if (status === 'subscribed') {
        bcolor = "error";
        bcontent = 'Feliratkozva';
        // bvariant = 'dot';
    }
    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={bcontent}
            color={bcolor}
            size={height}
            variant={bvariant}
        // overlap="circular"
        >
            <StyledAvatar
                appearance="circle"
                src={images[bucket + '/' + value]}
                // src={"https://pbs.twimg.com/profile_images/803832195970433027/aaoG6PJI_400x400.jpg"}
                // size={size}
                sx={{ width: height, height: height, border: '2px solid white' }}
                // borderColor="#0000"
                name="Csoport"
                label="Név"
                // status={status}
                presence={presence}
                variant={variant}
            />
        </Badge>
    );
};

export default StorageAvatar;
