import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../services/appContext';
const StorageImage = ({ status, statusText, value, bucket, className = "categoryListingImg", height, width }) => {
    const { images, setImage } = useContext(AppContext);

    useEffect(() => {
        setImage({ bucket: bucket, value: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            {status === 'progress' && (
                <div className={status !== 'progress' ? 'questionnaireItemProgress' : 'questionnaireItemSuccess'} >
                    {statusText}
                </div>
            )}
            <img
                src={images[bucket + '/' + value] || '/logo.png'}
                // style={{ maxWidth: "150px", maxHeight: "150px" }}
                alt="Profilkép"
                className={className}
                height={height}
                width={width}
            />
        </>
    );
};

export default StorageImage;