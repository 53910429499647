import React from 'react';
import GroupItemMini4 from './GroupItemMini4';

const GroupList = ({ title, groups, status = 'member' }) => {
    // console.log('groups', groups);
    return (
        <>
            {groups && Array.isArray(groups) && groups.length > 0 &&
                <>
                    <h3>{title}</h3>
                    <div className="listingsList">
                        {groups && groups.map(group => (
                            <GroupItemMini4
                                key={group.id}
                                group={group.data}
                                id={group.id}
                                onDelete={() => { }}
                                onEdit={() => { }}
                                status={status}
                            />
                        ))}
                    </div>
                </>
            }
        </>
    );
};

export default GroupList;