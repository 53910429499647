import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { auth } from '../../firebase.config';

const UserQRCode = ({ uid }) => {
    const code = `${window.location.origin}/addMember/${auth?.config?.apiKey}/${uid}`;
    const [shareLinkCopied, setShareLinkCopied] = useState(false);

    return (
        <>
            <h3>QR kód</h3>
            <div style={{ height: "auto", paddingTop: 30, margin: "0 auto", maxWidth: 256, width: "100%" }}>
                <QRCode
                    size={512}
                    style={{ height: "auto", maxWidth: "100%", width: "80%" }}
                    value={code}
                    viewBox={`0 0 512 512`}
                />
                <div
                    onClick={() => {
                        navigator.clipboard.writeText(code);
                        setShareLinkCopied(true);
                        setTimeout(() => {
                            setShareLinkCopied(false);
                        }, 2000);
                    }}>
                    {/* <img src={shareIcon} alt="Share" /> */}
                    Kód másolása vágólapra
                </div>
                {shareLinkCopied && <p className="linkCopied">A csoport kódját kimásoltuk a vágólapra</p>}
            </div>
        </>
    );
};

export default UserQRCode;