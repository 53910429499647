import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, IconButton, LinearProgress, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton } from '@mui/material';
import StorageAvatar from '../firebase/StorageAvatar';
import { Check, Close } from '@mui/icons-material';
import CIconButton from '../buttons/CIconButton';
import GetUser from '../../services/documents/GetUser';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useNavigate } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#d32f2f',
        color: '#d32f2f',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SubscribedUser = ({
    user, groupId = null, acceptAction, deleteAction, group,
    navigateToUser = false, level = 'member'
}) => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [loadings, setLoadings] = useState([]);
    const loadingKey = 'subscribeAccepting-' + user + '-' + groupId;
    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }
    const profileData = GetUser({
        setter: (model) => { setProfile(getData(model)) },
        user: user
    })

    const getProfile = async () => {
        const a = await profileData.subscribe();
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS111', a)
        }
        else {
            console.log('HIBA111')
        }
    }

    useEffect(() => {
        getProfile();
    }, []);

    const handleAccept = async () => {
        setLoadings((prevState) => ({
            ...prevState,
            [loadingKey]: true,
        }));
        const a = await acceptAction({ userId: user, groupId: groupId });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('HIBA')
        }
        // setLoadings((prevState) => ({
        //     ...prevState,
        //     [loadingKey]: false,
        // }));
    }

    const handleReject = async () => {
        setLoadings((prevState) => ({
            ...prevState,
            [loadingKey]: true,
        }));
        const a = await deleteAction({ userId: user, groupId: groupId });
        if (a) {

            console.log('SIKERES LEKÉRDEZÉS', a)
        }
        else {
            console.log('HIBA')
        }
        // setLoadings((prevState) => ({
        //     ...prevState,
        //     [loadingKey]: false,
        // }));
    }
    // const now = new Date().getTime();
    // const expiration = group && group?.expirations && group?.expirations[user] ? group?.expirations[user] : null;
    // console.log('profile', profile);
    return (
        <ListItem
            key={`memberlist-${user}`}
            // onClick={() => navigateToUser ? navigate(`/user/${user}`) : {}}
            sx={{
                textAlign: "center",
                alignContent: "center",
            }}
            secondaryAction={<>
                {loadings[loadingKey] &&
                    <CircularProgress />
                }
                {!loadings[loadingKey] && acceptAction &&
                    <CIconButton
                        onClick={handleAccept}
                        disabled={loadings[loadingKey]}
                        icon={<Check />}
                        confirm={true}
                        color="primary" />
                }
                {!loadings[loadingKey] && deleteAction &&
                    <CIconButton
                        onClick={handleReject}
                        disabled={loadings[loadingKey]}
                        confirm={true}
                        icon={<Close />}
                        color="secondary" />
                }</>
            }
            disablePadding>
            <ListItemButton >
                <ListItemAvatar>
                    {/* {(expiration && expiration * 1000 >= now) &&
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                        >
                            <StorageAvatar
                                value={user}
                                bucket={'profile_images'}
                                size='large'
                            // className="categoryListingImg"
                            />
                        </StyledBadge>
                    }
                    {(!expiration || expiration * 1000 < now) &&
                        <StorageAvatar
                            value={user}
                            bucket={'profile_images'}
                            size='large'
                        // className="categoryListingImg"
                        />
                    } */}
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant={!profile || level === 'member'
                            || (level === 'admin' && profile?.isAdmin)
                            || (level === 'manager' && profile?.isManager)
                            ? "standard" : "dot"}
                    >
                        <StorageAvatar
                            value={user}
                            bucket={'profile_images'}
                            size='large'
                        // className="categoryListingImg"
                        />
                    </StyledBadge>
                </ListItemAvatar>
                <ListItemText
                    key={`memberlisttext-${user}`}
                    id={user}
                    primary={profile ? profile?.name : <Skeleton animation="wave" width={100} height={20} style={{ marginBottom: 6 }} />}
                    secondary={profile ? profile?.email : <Skeleton animation="wave" width={200} height={10} style={{ marginBottom: 6 }} />}
                    onClick={() => navigateToUser ? navigate(`/user/${user}`) : {}}
                />
            </ListItemButton>
        </ListItem >
    );
};

export default SubscribedUser;