import React from 'react';

const ScopeItem = ({ scope }) => {
    const labels = {
        admins: 'Oktatók',
        members: 'Hallgatók',
        all: 'Mindenki'
    }
    return (
        <span className="listingType">
            {labels[scope]}
        </span>
    );
};

export default ScopeItem;