import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const SubscribeAction = () => {
    const { createDocument } = useContext(AppContext);

    const subscribe = async ({ groupId }) => {
        const user = auth.currentUser ? auth.currentUser : { uid: null }
        const userId = user.uid;
        const newRecord = {
            groupId: groupId,
            userId: userId
        }
        const result = await createDocument({ table: 'groupsubscribes', record: newRecord })
        return result;
    }

    return {
        subscribe
    }
};

export default SubscribeAction;