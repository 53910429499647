import React from 'react';
import Icons from './MIIcons';
// import * as Icons from '@mui/icons-material';

const CIcon = ({ icon, active = false }) => {
    if (icon) {
        const IconComponent = Icons[icon];
        return (
            <IconComponent
                style={{ color: active ? "#00cc66" : '' }}
                color={active ? "#00cc66" : ''} 
                />
        );
    } else {
        return null;
    }

};

export default CIcon;