export const form = {
    name: 'Kérdőív készítés',
    description: 'Az értékelő kérdőív készítés modul lehetővé teszi az oktatók számára, hogy értékeléseket indítsanak el. Az oktató értékelő lap és kurzus kiválasztásával elindíthatja az értékelési folyamatot.',
    "section": [
        {
            "name": 'Kérdőív adatok',
            "fields": [
                {
                    "type": "document_multiselect_group",
                    "required": "true",
                    "indexFields": [],
                    "updatefields": [],
                    "fields": {},
                    "name": "group",
                    "helper": "Kérem válassza ki a kitöltő csoportokat!",
                    "label": "Csoport",
                    "model": "groups",
                    "valuesKey": "id",
                    "valuesLabel": "name",
                    "_id": "item_1693864846699"
                },
                {
                    "label": "Sablon",
                    "required": "true",
                    "fields": {},
                    "_id": "item_1693774737428",
                    "helper": "Kérem válassza ki a megfelelő kérdés sablont!",
                    "indexFields": [],
                    "name": "template",
                    "type": "document_select_template",
                    "updatefields": [],
                    "model": "qtemplates",
                    "valuesKey": "id",
                    "valuesLabel": "name"
                },
                // {
                //     "type": "value_select_static",
                //     "required": "true",
                //     "indexFields": [],
                //     "updatefields": [],
                //     "fields": {},
                //     "model": "constants",
                //     "document": "kerdoiv_scope",
                //     "valuesKey": "value",
                //     "valuesField": "values",
                //     "valuesLabel": "label",
                //     "name": "scope",
                //     "helper": "Kérem válassza ki a kitöltők körét!",
                //     "label": "Kitöltők",
                //     "_id": "item_1693775413753"
                // },
                {
                    "type": "date",
                    "required": "true",
                    "indexFields": [],
                    "updatefields": [],
                    "fields": {},
                    "name": "from",
                    "helper": "Kérem válassza ki a kitöltési időszak kezdetét!",
                    "label": "Dátumtól",
                    "_id": "item_1693775469235"
                },
                {
                    "type": "date",
                    "required": "true",
                    "indexFields": [],
                    "updatefields": [],
                    "fields": {},
                    "name": "to",
                    "helper": "Kérem válassza ki a kitöltési időszak végét!",
                    "label": "Kitöltési határidő",
                    "_id": "item_1693775485523"
                }
            ]
        }
    ]
}