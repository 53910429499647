import React from 'react';
import { Grid } from '@mui/material';
import { CSVLink } from "react-csv";
import DashboardButton from '../../commons/buttons/DashboardButton';
import StatApexChart from './components/StatApexChart';
import LabelsApexChart from './components/LabelsApexChart';
import RadarChart from './components/RadarChart';
import RadarChart2 from './components/RadarChart2';

const View = ({ getData, questions, labelsData, labels, groups, data = [] }) => {
    return (
        <div className="profileList">
            <header className="profileHeader">
                <p className="pageHeader"> Értékelés statisztika</p>
            </header>
            <main>
                <Grid container spacing={0}>
                    <DashboardButton
                        label={'Frissítés'}
                        icon={'Refresh'}
                        onClick={() => getData()}
                    />
                    <CSVLink separator={";"} data={data} filename={"prvet_stat.csv"}>
                        <DashboardButton
                            label={'CSV letöltés'}
                            icon={'Download'}
                        />
                    </CSVLink>
                </Grid>
                <h3>Kérdés értékelése csoportban</h3>
                <StatApexChart
                    groups={groups}
                    questions={questions}
                    data={data}
                />
                {labelsData && groups && labels &&
                    <>
                        <h3>Címke értékelése csoportban</h3>
                        <LabelsApexChart
                            groups={groups}
                            labels={labels}
                            data={Object.values(labelsData)}
                        />
                    </>

                }
                {labelsData && groups && labels &&
                    <>
                        <h3>Címkék értékelése csoportban</h3>
                        <RadarChart
                            groups={groups}
                            questions={labels}
                            data={Object.values(labelsData)}
                        />
                    </>

                }
                {labelsData && groups && labels &&
                    <>
                        <h3>Címkék értékelési szempontonként</h3>
                        <RadarChart2
                            groups={groups}
                            questions={labels}
                            data={Object.values(labelsData)}
                        />
                    </>

                }
            </main>
        </div>
    );
};

export default View;