import React from 'react';
import QButtons from './QButtons';
import { Collapse, Step, StepLabel, Stepper } from '@mui/material';
import StorageAvatar from '../../../../firebase/StorageAvatar';

const NormalStepper = ({
    lastSlideIsVisible,
    steps,
    activeStep = 0,
    previous,
    next,
    valid,
    styles
}) => {
    return (
        <>
            <Collapse in={lastSlideIsVisible}>
                <Stepper
                    alternativeLabel={'Kérdőív kitöltés'}
                    activeStep={activeStep}
                    style={{ width: '100%' }}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel sx={styles.stepLabel}>
                                <div className='avatar-container'>
                                    <StorageAvatar
                                        value={label.id}
                                        bucket={'profile_images'}
                                        size='large'
                                    // className="categoryListingImg"
                                    />
                                </div>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Collapse >
            <QButtons
                lastSlideIsVisible={true}
                enabledNext={valid}
                useStepper={true}
                previous={previous}
                next={next}
                styles={styles}
            />
        </>
    );
};

export default NormalStepper;