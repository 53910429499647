import React from 'react';
import EvaluationsItemList from '../../components/evaluations/EvaluationsListItem';
import DoneMessage from '../../commons/DoneMessage';
import ScrollToBottomIcon from '../../components/buttons/RBButton';
import { BarChart, DataSaverOff, SsidChart, Timeline } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const View = ({
    profile,
    evaluations,
    groups,
    selectItem,
}) => {
    const navigate = useNavigate();

    return (
        <div className="profileList">
            <header className="profileHeader">
                <p className="pageHeader">Értékelések</p>
            </header>

            <main>
                {typeof evaluations == 'object' && Array.isArray(evaluations) && evaluations.length < 1 &&
                    <DoneMessage icon='BarChart' label='Jelenleg nincs elérhető értékelés' />
                }
                <div className="listingsList">
                    {groups && evaluations &&
                        evaluations.map((element, index) => {
                            return (
                                <EvaluationsItemList
                                    key={index}
                                    profile={profile}
                                    evaluation={element}
                                    group={groups[element.data.group]}
                                    selectItem={selectItem}
                                />
                            )
                        }
                        )
                    }
                </div>
                <ScrollToBottomIcon
                    name={'Kitöltéseim'}
                    icon={<Timeline />}
                    onClick={() => { navigate('/statistics'); }}
                />
            </main>

        </div>
    );
};

export default View;