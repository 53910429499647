import React from 'react';
import Snapshot2 from '../../hooks/snapshot2';

const MyFilledQuestionnaires = () => {

    const status = (data) => {
        console.log('SNAPSHOT SUBSCRIBED', data)
    }

    const model = Snapshot2({
        setState: status,
        key: 'filledquestionnaires',
        table: 'filledquestionnaires',
        refresh: true
    });

    const subscribe = ({ currentDate, user }) => {
        const unsubscribe2 = model.subscribe({
            filter: [{
                field: "authorId",
                operation: '==',
                data: user.uid,
            },
            {
                field: "status",
                operation: '==',
                data: 'new'
            },
            {
                field: "to",
                operation: '>=',
                data: currentDate
            }
            ],
            orderBy: {
                field: "to",
                direction: "desc"
            },
            limit: 5,
        });
        return unsubscribe2;
    }
    return { subscribe };
};

export default MyFilledQuestionnaires;