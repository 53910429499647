import React, { useEffect, useState } from 'react';
import GetUser from '../../services/documents/GetUser';

const UserItem = ({ user, groupId = null, acceptAction, deleteAction, showAvatar = true }) => {
    const [profile, setProfile] = useState(null);
    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }
    const profileData = GetUser({
        setter: (model) => { setProfile(getData(model)) },
        user: user
    })

    const getProfile = async () => {
        const a = await profileData.subscribe();
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
    }

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>{profile?.name}
            {/* <ListItem>
                <ListItemText id={user} primary={profile?.name} secondary={profile?.email} />
            </ListItem> */}
        </>
    );
};

export default UserItem;