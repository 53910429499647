import { images } from "./images"

export const admins = {
    "type": "help",
    "icon": "Users",
    "content": "Csoport oktatóinak és ügyintézőinek kezelése",
    "image": images.group,
    "description": `<p>Ez a dokumentáció részletes útmutatót nyújt a csoportok felhasználóinak hatékony kezeléséhez, beleértve a hallgatók, oktatók, ügyintézők és jelentkezők kezelését.</p>
    <ul>
                    <li>Nyissa meg a csoport oldalát.</li>
                    <li>Menjen a beállítások menüpontra.</li>
                </ul>
    `,
    "steps": [
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `
            `,
            "image": images.group,
            "help": null,
            "mark": 'circle',
            "markRadius": 20,
            "markXPercent": 83,
            "markYPercent": 85,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Csoporttagok törlése",
            "description": `
                <p>A csoporttagok törlése lehetővé teszi a felhasználók eltávolítását a csoportból. Ehhez a művelethez csoport ügyintézői jogosultság szükséges.</p>
                <ul>
                    <li>Válassza a "Felhasználók" fület.</li>
                    <li>Keresse meg a törölni kívánt felhasználót, majd távolítsa el őt a csoportból.</li>
                </ul>
            `,
            "image": images.userCRUD,
            "help": null,
            "mark": 'circle',
            "markRadius": 20,
            "markXPercent": 81,
            "markYPercent": 50,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Új oktatók és ügyintézők felvétele",
            "description": `
                <p>Új oktatók és ügyintézők felvétele lehetővé teszi új oktató vagy ügyintéző hozzáadását a csoporthoz. Ezt a műveletet csoport ügyintézői jogosultsággal lehet végrehajtani,
                valamint szükséges, hogy a felhasználó oktatói/ügyintézői jogosultsággal rendelkezzen.</p>
                <ul>
                    <li>Nyissa meg a csoport oldalát.</li>
                    <li>Menjen a beállítások menüpontra.</li>
                    <li>Válassza az "Felhasználók" fület.</li>
                    <li>Kattintson a megfelelő (oktató/ügyintéző) hozzáadás gombra</li>
                </ul>
            `,
            "image": images.userCRUD,
            "help": null,
            "mark": 'circle',
            "markRadius": 20,
            "markXPercent": 85,
            "markYPercent": 57,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `
                <ul>
                    <li>Adja meg a hozzáadni kívánt felhasználók e-mail címét</li>
                </ul>
            `,
            "image": images.admin2,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `
             <ul>
                    <li>Adja hozzá őket a csoporthoz mint oktató vagy ügyintéző</li>
                </ul>
            `,
            "image": images.admin3,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `<ul>
                    <li>Üzenet jelzi a művelet sikerességét</li>
                </ul>`,
            "image": images.admin4,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        }
    ]
}