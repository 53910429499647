import React, { useContext, useEffect, useState } from 'react';
import { apps } from '../../data/apps';
import { AppContext } from '../../services/appContext';
import AppQRCode from '../../components/apps/AppQRCode';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getAppData } from '../../services/restApi/getAppData';
import { auth } from '../../firebase.config';
import NextButton from '../../components/buttons/NextButton';
import AppEditor from '../../components/apps/AppEditor';
import BackToSignInButton from '../../components/apps/BackToSignInButton';

const AppData = () => {

    const params = useParams();
    const { id } = params;
    const { selectedApp, firebaseApps, setFirebaseApps } = useContext(AppContext);
    const storedApps = firebaseApps ?? apps;
    const [server, setServer] = useState(storedApps[selectedApp]);
    const [error, setError] = useState();
    const [logged, setLogged] = useState();

    const getData = async (server) => {
        const data = await getAppData(`https://kerdoiv.b612.hu/apps/${server}.json`);
        // console.log(data);
        // console.log('data111', data);
        if (data === null) {
            setServer(null);
            setError('Az alkalmazás nem létezik');
            return;
        }
        if (storedApps[data.id]) {
            setServer(storedApps[data.id]);
            setError('Az alkalmazás már létezik');
            return;
        }
        // Ellenőrizzük az ID-t és a config mezőt
        if (typeof data.id === 'string' && data.config && typeof data.config === 'object') {
            const requiredFields = [
                'apiKey',
                // 'authDomain',
                // 'databaseURL',
                // 'projectId',
                // 'storageBucket',
                // 'messagingSenderId',
                // 'appId'
                // 'measurementId',
            ];
            // Ellenőrizzük a config mezőt
            const configIsValid = requiredFields.every(field => typeof data.config[field] === 'string');
            if (!configIsValid) {
                setServer(null);
                setError('Hiányzó vagy érvénytelen config mezők');
                return;
            }
            setServer(data);
            setError();
            // Ha minden rendben, hozzáadjuk az alkalmazást
        }
    }

    const createApp = (app) => {
        const apps = { ...firebaseApps, [app?.id]: app }
        setFirebaseApps(apps);
        localStorage.setItem('firebaseAppsV2', JSON.stringify(apps));
        if (!logged) {
            localStorage.setItem('selectedApp', app?.id);
        }
    };

    useEffect(() => {
        setLogged(auth?.currentUser?.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    // useEffect(() => {
    //     setServer(selectedApp);
    // }, [selectedApp]);

    useEffect(() => {
        // console.log('qr1', id);
        // console.log('storedApps', storedApps);
        if (id && storedApps) {
            if (storedApps[id]) {
                setServer(storedApps[id]);
            } else {
                console.log('nincs, megpróbáljuk felvenni...')
                getData(id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, storedApps]);
    // console.log('firebaseAppsV2', firebaseApps);
    // console.log('server', server);

    const serverId = server?.id;
    // console.log('udd', udd);
    // console.log('firebaseApps[server?.id]', firebaseApps[udd]);
    return (
        <div className="profileList">
            <main>
                <BackToSignInButton />
                <header className="logoHeader">
                    <img
                        alt={'PR-VET'}
                        // className={classes.logo}
                        src={server?.logo || '/logo.png'}
                        width={100}
                    />

                    <p className="pageHeader">{server?.label}</p>
                    {<AppQRCode app={server} />}
                </header>
                <AppEditor readonly={logged} />
                {server && firebaseApps[serverId] && serverId &&
                    <Alert severity="info">Az alkalmazás már telepítve</Alert>
                }
                {error &&
                    <Alert severity="error">{error}</Alert>
                }
                {server?.id && !firebaseApps[serverId] &&
                    <NextButton
                        name={'Alkalmazás telepítése'}
                        variant={'contained'}
                        onClick={() => createApp(server)}
                    // disabled={!valid()}
                    />
                }
                {/* {logged ? 'bejelentkezve' : 'nincs bejelentkezve'} */}
            </main >
        </div >
    );
};

export default AppData;