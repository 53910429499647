import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';

const MultiSelectQuestions = ({ label, selectedQuestions, handleQuestionChange, availableQuestions }) => {
    return (
        <FormControl>
            <InputLabel id="question-label">{label ?? 'Kérdések'}</InputLabel>
            <Select
                multiple
                value={selectedQuestions}
                onChange={handleQuestionChange}
                renderValue={(selected) => selected.join(', ')}
                style={{ minWidth: '300px', maxWidth: '90vw' }}
            >
                {availableQuestions.map((question) => (
                    <MenuItem key={question} value={question}>
                        <Checkbox checked={selectedQuestions.indexOf(question) > -1} />
                        <ListItemText primary={question} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectQuestions;