import { useEffect, useState, useRef } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase.config";

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true); // check to see if logged in;
  const isMounted = useRef(true);

  useEffect(() => {
    console.log('loggedIn', loggedIn);
    console.log('isMounted', isMounted);
    
    // if (isMounted) {
      // const auth = getAuth();
      
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setLoggedIn(true);
        }
        setCheckingStatus(false);
      });
    // }
    return () => {
      isMounted.current = false; // prevent memory leak
    };
    // added dependency array to prevent memory leak
  }, [isMounted]);

  return { loggedIn, checkingStatus };
};

// Protected routes in v6
// http://stackoverflow.com/questions/65505665/protected-route-with-firebase

// Fix memory leak warning
// https://stackoverflow.com/questions/59780268/cleanup-memory-leaks-on-an-unmounted-component-in-react-hooks
