import React, { useContext, useEffect, useState } from 'react';
import View from './View';
import { AppContext } from '../../services/appContext';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { convertArrayById3 } from '../../commons/Utility';
import InfoMessage from '../../commons/InfoMessage';
import { getAuth, updateProfile } from "firebase/auth";
import Document2 from '../../hooks/document2';
import { evaluatedBaseData, evaluatedStat } from '../../commons/StatUtility';
import UpdateEvaluated from '../../services/actions/UpdateEvaluated';
import { auth } from '../../firebase.config';

const Evaluation = () => {
    // const auth = getAuth();
    const params = useParams();
    const { id } = params;
    const { queries, snapshots, isLoading, loadings, setLoading } = useContext(AppContext);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [evaluations, setEvaluations] = useState(null);
    const [evaluation, setEvaluation] = useState(null);
    const [profile, setProfile] = useState(null);
    const [groupList, setGroupList] = useState(null);
    const [myGroups, setMyGroups] = useState([]);
    const [error, setError] = useState(false);
    const [stat, setStat] = useState();
    const [myUserData, setMyUserData] = useState();

    const { subscribe } = UpdateEvaluated();

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    const setQuestionnaireData = (model) => {
        if (model && model[0] && model[0].data)
            setQuestionnaire(getData(model));
    }

    const myQuestionnaire = Document2({
        setState: (model) => { setQuestionnaireData(model) },
        key: 'questionnaire-' + id,
        table: 'questionnaires',
        refresh: false,
        notFromCache: false
    });

    const getEvaluationsValues = ({ questionnaire }) => {
        const evaluationValues = [];

        if (questionnaire && typeof questionnaire === 'object' && Object.keys(questionnaire).length > 0) {
            Object.keys(questionnaire).forEach(key => {
                if (key.startsWith('evaluation-')) {
                    evaluationValues.push(questionnaire[key]);
                }
            });
        }
        return evaluationValues;
    }
    const getQuestionnaire = async (id2) => {
        const questionnaire = await myQuestionnaire.subscribe({ id: id2 });
        if (questionnaire) {
            if (
                // questionnaire?.statistics?.values &&
                questionnaire?.qtemplate?.meta &&
                questionnaire?.qtemplate &&
                evaluation?.evaluations) {

                const evaluationValues = getEvaluationsValues({ questionnaire: evaluation });
                const aa = await evaluatedBaseData(
                    evaluationValues
                    // questionnaire?.statistics?.values
                );
                const b = await evaluatedStat(
                    aa,
                    questionnaire?.qtemplate?.meta.questions,
                    questionnaire?.qtemplate?.meta.scales
                );
                setStat(b);
            }
        }
        else {
            console.log('Error')
        }
    }

    useEffect(() => {
        // ADMINS
        if (profile && profile.admins) {
            setGroupList(profile.admins)
        }
    }, [profile]);

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
    }, [snapshots['profile']]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(convertArrayById3(snapshots['allgroups']));
        }
    }, [snapshots['allgroups']]);

    useEffect(() => {
        setEvaluations(convertArrayById3(snapshots['myevaluations']));
    }, [snapshots['myevaluations']]);

    useEffect(() => {
        setMyUserData(snapshots['myUserData']);
    }, [snapshots['myUserData']]);

    useEffect(() => {
        if (evaluations && typeof evaluations === 'object' && Object.keys(evaluations).length > 0 && id) {
            const e = evaluations[id];
            if (e) {
                setEvaluation(e)
                setError(false);
            }
            else {
                console.log('Error');
                setError(true);
            }
        }
    }, [id, evaluations]);

    useEffect(() => {
        if (evaluation && evaluation?.questionnaire)
            getQuestionnaire(evaluation?.questionnaire);
    }, [evaluation]);

    const updateRead = async (read) => {
        const a = await subscribe(
            {
                id: id,
                read: read
            })
            // .then((snap) => {
            //     console.log('snap', snap);

            // })
            .catch((err) => {
                console.error('error', err);
            });;
    }
    useEffect(() => {
        return () => {
            if (evaluation && stat) {
                if (evaluation?.read != stat.num) {
                    updateRead(stat.num);
                }
            }

        }
    }, [evaluation, stat]);
    // if (evaluation?.evaluations) {
    //     evaluatedBaseData(evaluation?.evaluations, null);
    // }
    // console.log('stat111', stat);
    return (
        <>  {error ?
            <InfoMessage
                color='error'
                icon='Error'
                label='Valami probléma történt'
                description={`Elképzelhető, hogy hálózati probléma lépett fel, 
                vagy időközben lejárt egy kitöltési határidő, 
                vagy egy másik eszközön kitöltötted a kérdőívet, de lehet, hogy mi hibáztunk... \n
                <br><h4>A következő lehetőségek állnak rendelkezésre:</h4>`}
                options={[
                    { name: 'Kezdőlap', link: '/' },
                    { name: 'Bejelentkezés', onClick: () => auth.signOut(), className: 'secondaryButton' }
                ]}
            />
            : (
                profile && questionnaire && evaluation && id && myGroups ? (
                    <View
                        stat={stat}
                        profile={profile}
                        questionnaire={questionnaire}
                        evaluation={evaluation}
                        myUserData={myUserData}
                        id={id}
                        group={myGroups[evaluation?.group]}
                        groups={myGroups}
                    />
                ) : (
                    <Spinner />
                )
            )
        }
        </>
    );
};

export default Evaluation;