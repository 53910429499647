import React, { useContext, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { AppContext } from '../../services/appContext';
import styles from './Qrscan.css';
import CIcon from '../../commons/CIcon';
import { GoBack } from '../../commons/GoBack';
import GoBackButton from '../../commons/GoBackButton';
import { Link, useNavigate, useParams } from "react-router-dom";
import NextButton from '../../components/buttons/NextButton';
import QPage from '../../components/stepperSampling/components/ui/QPage';

const QrSetup = ({ onResult = (x) => { console.log(x) } }) => {
    const navigate = useNavigate();
    const { facingMode, setFacingMode } = useContext(AppContext);
    const [data, setData] = useState('No result');
    const [facingMode2, setFacingMode2] = useState(facingMode !== 'default' ? { facingMode: facingMode } : {}); // Default value

    const handleFacingModeChange = (event) => {
        if (event.target.value === 'default') {
            setFacingMode2({});
            setFacingMode('default')
            localStorage.setItem('facingMode', 'default');
        } else {
            setFacingMode2({ facingMode: event.target.value });
            setFacingMode(event.target.value);
            localStorage.setItem('facingMode', event.target.value);
        }

    };
    const handleFacingModeChange2 = (mode) => {
        if (mode === 'default') {
            setFacingMode2({});
            setFacingMode('default');
            localStorage.setItem('facingMode', 'default');
        } else {
            setFacingMode2({ facingMode: mode });
            setFacingMode(mode);
            localStorage.setItem('facingMode', mode);
        }

    };
    return (
        <QPage>
            <GoBackButton />
        <Box
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                padding: 0,
            }}
        >
            <div className='centerContent'>
                <h1>Kamera kiválasztása</h1>
                <p>Kérem, válassza ki a rendelkezésre álló kameraopciók közül azt, amelyet használni kívánja. </p>
                <BottomNavigation
                    showLabels
                    value={facingMode}
                    onChange={(event, newValue) => {
                        handleFacingModeChange2(newValue);
                        navigate(-1);
                    }}
                >

                    <BottomNavigationAction value="default" label="Alapértelmezett" icon={<CIcon icon={'Camera'} />} />
                    <BottomNavigationAction value="environment" label="Hátlapi" icon={<CIcon icon={'CameraAlt'} />} />
                    {/* <BottomNavigationAction value="left" label="Bal" icon={<CIcon icon={'ArrowLeft'} />} />
                <BottomNavigationAction value="right" label="Jobb" icon={<CIcon icon={'ArrowRight'} />} /> */}
                    <BottomNavigationAction value="user" label="Előlapi" icon={<CIcon icon={'CameraFront'} />} />
                </BottomNavigation>
                {/* <GoBackButton /> */}
                <NextButton
                    name={'Vissza'}
                    variant={'contained'}
                    onClick={() => { navigate(-1); }}
                // disabled={!valid()}
                />
            </div>
        </Box>
        </QPage>
    );
};

export default QrSetup;
