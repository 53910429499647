import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { Avatar, Grid, BottomNavigationAction } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { AddAPhoto } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import ProfileAvatar from './ProfileAvatar';
import DashboardButton from '../../../commons/buttons/DashboardButton';

const MyQRCode = ({ auth, uid }) => {
    const code = `${window.location.origin}/addMember/${auth?.config?.apiKey}/${uid}`;
    const [show, setShow] = useState();
    const navigate = useNavigate();

    return (
        <>
            <div className="qrcodes">
                <Grid container spacing={2}>
                    <Grid className="profileButton" item xs={3}>
                        <BottomNavigationAction showLabel onClick={() => { setShow('profile') }} label="Új profilkép" icon={
                            <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
                                <AddAPhoto />
                            </Avatar>
                        } />
                    </Grid>
                    <Grid className="profileButton" item xs={3}>
                        <DashboardButton
                            label={'Jelentkezés csoportba'}
                            icon={'GroupAdd'}
                            onClick={() => (navigate("/subscribe"))}
                            isAvatar={true}
                            bgcolor={'#9B59B6'}
                        />
                    </Grid>
                </Grid>

            </div >
            <div style={{ height: "auto", paddingTop: 30, margin: "0 auto", maxWidth: 256, width: "90%" }}>
                <QRCode
                    size={512}
                    style={{ height: "auto", maxWidth: "100%", width: "80%" }}
                    value={code}
                    viewBox={`0 0 512 512`}
                />
            </div>
            {show === 'profile' && <ProfileAvatar auth={auth} exit={() => (setShow())} />}
        </>
    );
};

export default MyQRCode;