import React from 'react';
import { Collapse, Step, StepLabel, Stepper } from '@mui/material';

const QStepper = ({ lastSlideIsVisible, steps, styles, activeStep = 0 }) => {
    const screenHeight = window.screen.height;

    return (
        <>
            {screenHeight > 600 &&
                < Collapse in={lastSlideIsVisible}>
                    <Stepper
                        alternativeLabel={'Kérdőív készítés'}
                        activeStep={activeStep}
                        style={{ width: '100%' }}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel sx={styles.stepLabel}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Collapse >
            }
        </>
    )
};

export default QStepper;