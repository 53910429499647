import { authentication } from "./authentication";
import { evaluations } from "./evaluations";
import { dashboard } from "./dashboard";
import { version01 } from "./version01";
import { documentation } from "./documentation";
import { start } from "./start";
import { subcribe } from "./subscribe";
import { group } from "./group";
import { sampling } from "./sampling";
import { avatar } from "./avatar";
import { install } from "./install";
import { updateGroup } from "./updateGroup";
import { createGroup } from "./createGroup";
import { updateUser } from "./updateUser";
import { activeGroup } from "./activeGroup";
import { schedules } from "./schedules";
import { groupStatTypes } from "./groupStatTypes.js";
import { labels } from "./labels.js";
import { dataGrouping } from "./dataGrouping.js";
import { groupStatCharts } from "./groupStatCharts.js.js";
import { groupStatAnimation } from "./groupStatAnimation.js";
import { roles } from "./roles.js";
import { members } from "./members.js";
import { admins } from "./admins.js";

export const helps =
{
  authentication: authentication,
  avatar: avatar,
  documentation: documentation,
  evaluations: evaluations,
  dashboard: dashboard,
  group: group,
  start: start,
  sampling: sampling,
  subscribe: subcribe,
  version01: version01,
  install: install,
  updateGroup: updateGroup,
  createGroup: createGroup,
  updateUser: updateUser,
  activeGroup: activeGroup,
  schedules: schedules,
  groupStatTypes: groupStatTypes,
  labels: labels,
  dataGrouping: dataGrouping,
  groupStatCharts: groupStatCharts,
  groupStatAnimation: groupStatAnimation,
  roles: roles,
  members: members,
  admins: admins
}