import React from 'react';
import GroupItemMini from '../../components/groups/GroupItemMini';
import GroupItemMini3 from '../../components/groups/GroupItemMini3';
import { Alert, Checkbox, List, ListItem, ListItemAvatar } from '@mui/material';
import StorageAvatar from '../../components/firebase/StorageAvatar';
import GoBackButton from '../../commons/GoBackButton';
import MemberCover from '../../components/questionnaire/MemberCover';
import NextButton from '../../components/buttons/NextButton';
import Spinner from '../../components/Spinner';

const View = ({ isMember, isLoading, isUserError, profile,
    app, user, auth, myGroups, group, setGroup, addUser }) => {
    // console.log(group)
    return (
        <div className="profile">
            <GoBackButton />
            <MemberCover
                group={group?.data}
                profile={profile}
                mini={true}
            />
            <div className="listingDetails">
                <p className="listingName">
                    Felhasználó felvétele
                </p>
                {isUserError && !isLoading ?
                    <Alert severity="error">Sajnos ez a felhasználó nem található a rendszerben</Alert>
                    : <>
                        <div className='centerContent2'>
                            <div className="avatar-container">
                                <StorageAvatar
                                    value={user}
                                    bucket={'profile_images'}
                                    size='large'
                                />
                            </div>
                            <h4 className="profileButton">
                                {profile?.name}
                                <br></br>
                                {profile?.email}
                            </h4>
                        </div>

                        <List
                            dense
                            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            sx={{
                                // width: '90%',
                                // maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: `calc(100vh - 280px)`,
                                '& ul': { padding: 0 },
                            }}
                        >
                            {myGroups && myGroups?.map(x => {
                                return (
                                    <ListItem
                                        sx={{
                                            textAlign: "center",
                                            alignContent: "center",
                                        }}
                                        onClick={() => { setGroup(x) }}
                                        selected={group?.id === x.id}
                                        variant={group?.id === x.id ? "contained" : "outlined"}
                                        color={group?.id === x.id ? "primary" : "secondary"}
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                checked={group?.id === x.id}
                                                inputProps={{ 'aria-label': x.id }}
                                            />}
                                        disablePadding
                                    >
                                        <GroupItemMini3 group={x?.data} />
                                    </ListItem>
                                )
                            })}
                        </List>
                        {/* {isMember ? 'Tag' : 'Nem tag'} */}
                        {isMember &&
                            <Alert severity="success">A felhasználó már tagja a csoportnak</Alert>
                        }
                        {myGroups?.length < 1 &&
                            < Alert severity="success">A felhasználó minden csoporthoz hozzá van adva</Alert>
                        }
                        {!isMember && !group && myGroups?.length > 0 &&
                            <Alert severity="info">Válasszon csoportot</Alert>
                        }
                        {isLoading ? <Spinner /> :
                            <NextButton
                                name={'Felhasználó felvétele a csoportba'}
                                variant={'contained'}
                                onClick={() => { addUser({ user: user, groupId: group?.id }) }}
                                disabled={isLoading || isMember || isUserError || !group}
                            />
                        }

                    </>}
            </div >
        </div >
    );
};

export default View;