import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../services/appContext';
import GroupList from '../../../components/groups/GroupList';

const MyGroups = () => {
    const [profile, setProfile] = useState(null);
    const [groups, setGroups] = useState([]);
    const { snapshots } = useContext(AppContext);

    useEffect(() => {
        if (snapshots && snapshots['profile'] && snapshots['profile'][0]) {
            setProfile(snapshots['profile'][0].data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['profile']]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setGroups(snapshots['allgroups']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    const members = groups?.filter((g) => profile?.groups?.indexOf(g.id) > -1);
    const admins = groups?.filter((g) => profile?.admins?.indexOf(g.id) > -1);
    const managers = groups?.filter((g) => profile?.managers?.indexOf(g.id) > -1);
    const subscribes = groups?.filter((g) => profile?.subscribed?.indexOf(g.id) > -1);

    return (
        <div style={{ textAlign: 'left' }}>
            <GroupList
                title={'Csoportjaim'}
                groups={members}
                status={'member'}
            />
            <GroupList
                title={'Feliratkozások'}
                groups={subscribes}
                status={'subscribed'}
            />
            {profile?.isAdmin &&
                <GroupList
                    title={'Csoportjaim (oktatóként)'}
                    groups={admins}
                    status={'admins'}
                />
            }
            {(profile?.isAdmin || profile?.isManager) &&
                <GroupList
                    title={'Csoportjaim (ügyintézőként)'}
                    groups={managers}
                    status={'managers'}
                />
            }
        </div>
    );
};

export default MyGroups;