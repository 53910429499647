import { Badge, Chip, Rating } from '@mui/material';
import React from 'react';
import { convertArrayById } from '../../commons/Utility';

const EvaluationValue = ({ question, value, scales }) => {
    const scale = scales && question ? scales[question['Skála']] : null;
    const scaleValues = scale ? convertArrayById(scale?.elemek, 'value') : [];
    const scaleType = scale?.type;
    const createStat = (values, scaleValues) => {
        let data = []
        Object.keys(values).forEach(e => {
            if (!data[values[e]]) {
                data[values[e]] = 0;
            }
            data[values[e]] = data[values[e]] + 1;
        });
        return data.filter(function (el) {
            return el != null;
        });;
    }

    const createValueList = (values, scaleValues) => {
        const r = createStat(values, scaleValues);
        return (
            Object.keys(r).map((id3, index3) => (
                <span key={index3}>
                    <Badge badgeContent={r[id3]} color="info">
                        <Chip label={scaleValues[value[id3]]?.name} />
                    </Badge>
                </span>
            ))
        )
    }
    return (<>
        {question && value && scales &&
            <p>{question?.name}:
                {scaleType === 'szam' &&
                    <Rating readOnly
                        name="customized-10"
                        defaultValue={value}
                        max={10} />
                }
                {scaleType === 'ertekeles' && (
                    <>
                        {typeof value === 'string' &&
                            <div>{value}</div>
                        }
                        {typeof value === 'object' &&
                            Object.keys(value).map((id3, index3) => (
                                <div key={index3} >
                                    <Chip label={value[id3]} />
                                </div>
                            ))
                        }
                    </>
                )
                }
                {scaleType === 'betu' &&
                    <div>{typeof value === 'object' ?
                        createValueList(value, scaleValues) :
                        <Chip label={scaleValues[value]?.name} />
                    }

                    </div>
                }
                {scaleType === 'tobbvalasztos' &&
                    <div>{typeof value === 'object' ?
                        createValueList(value, scaleValues) :
                        <Chip label={scaleValues[value]?.name} />
                    }

                    </div>
                }
            </p>
        }</>

    );
};

export default EvaluationValue;