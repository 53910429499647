import React from 'react';
import Qrscan from '../../components/qr/Qrscan';
import QRCode from 'react-qr-code';
import GoBackButton from '../../commons/GoBackButton';
import { getKeysFromArray } from '../../commons/Utility';
import { Alert } from '@mui/material';

const View = ({ groupId, subscribeToGroup, isMember, isSubscribed }) => {
    const value = `https://kerdoiv.b612.hu/subscribe/${groupId}`;

    return (
        <div className="profileList">
            <header>
                <p className="pageHeader">Jelentkezés csoportba</p>
            </header>
            {isMember || isSubscribed ? <>
                <Alert severity="warning">Már a csoport tagja vagy, vagy már korábban jelentkeztél a csoportba</Alert>
                <GoBackButton />
            </> :
                <>
                    <h2>Csoport QR kódja</h2>
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
                        <QRCode
                            size={512}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={value}
                            viewBox={`0 0 512 512`}
                        />
                    </div>
                    <Alert severity="info">A csoportba történő jelentkezéshez kattints a gomra! A jelentkezéssel automatikusan elfogadod az Adatkezelési Tájékoztatóban foglaltakat</Alert>
                    <button onClick={subscribeToGroup} type="button" className="primaryButton">
                        Jelentkezem
                    </button>
                    <GoBackButton />
                </>
            }
        </div>
    );
};

export default View;