import React, { useContext, useEffect, useState } from 'react';
import { fetchToken, onMessageListener, selectedConfig } from '../../firebase.config';
import UpdateToken from '../../services/actions/UpdateToken';
import { auth } from "../../firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { AppContext } from '../../services/appContext';
import { toast } from "react-toastify";

const Messenger = () => {
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const { subscribe } = UpdateToken();
    const [user] = useAuthState(auth);
    const { deviceId, isTokenFound, setTokenFound } = useContext(AppContext);
    // console.log('teszttoken1');
    // fetchToken(setTokenFound);

    useEffect(() => {

        console.log('teszttoken1');
        fetchToken(setTokenFound);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const updateToken = (id, token, userId) => {
        subscribe({
            id: id,
            token: token,
            userId: userId
        })
    }

    useEffect(() => {
        if (deviceId && isTokenFound && user?.uid) {
            console.log('teszttoken1');
            updateToken(deviceId, isTokenFound, user?.uid);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTokenFound, deviceId, user]);

    onMessageListener().then(payload => {
        setShow(true);
        console.log(payload);

        setNotification({ title: payload.notification.title, body: payload.notification.body })


        if ('serviceWorker' in navigator && 'PushManager' in window) {
            if (payload?.gcm?.notification?.projecId === selectedConfig?.projecId) {
                toast.success(`Értesítés: ${payload.notification.title}`);
                navigator.serviceWorker.ready.then(registration => {
                    registration.showNotification(payload.notification.title, {
                        body: payload.notification.body,
                        icon: '/logo192.png',
                        click_action: payload?.fcmOptions?.link,
                    });
                });
            }

        } else {
            console.error('A service worker or push notifications are not supported in this browser.');
        }
    }).catch(err => console.log('failed: ', err));

    return null;
};

export default Messenger;