import { images } from "./images"

export const stepsGroupSetup =
    [
        {
            type: 'link', // slide, link
            selector: '.updateGroup',
            content: 'Új csoport létrehozása',
            short: 'Új csoport létrehozása',
            description: '',
            link: 'createGroup',
            isAdmin: true,
            icon: 'Groups',
        },
        {
            type: 'link', // slide, link
            selector: '.updateGroup',
            content: 'Csoport adatainak módosítása',
            short: 'Csoport adatainak módosítása',
            description: '',
            link: 'updateGroup',
            isAdmin: true,
            icon: 'Groups',
        },
        {
            type: 'link', // slide, link
            selector: '.activeGroup',
            content: 'Aktív/inaktív csoportok',
            short: 'Aktív/inaktív csoportok',
            description: '',
            link: 'activeGroup',
            isAdmin: true,
            icon: 'Groups',
        },
    ]

