import React from 'react';
import Spinner from '../../components/Spinner';
import StepperSampling from '../../components/stepperSampling/StepperSampling';
import Thanks from '../../components/stepperSampling/components/Thanks';

const View = ({
    id,
    profile,
    group,
    admins,
    members,
    questionnaire,
    submitCallback,
    navigateAfterSubmit,
    started,
    loading,
    submited,
    success
}) => {
    // console.log('submited', submited);
    // console.log('steps', steps);
    // console.log('categories', categories);
    // console.log('actual', actual);
    // console.log('group', group);
    return (<>
        {submited ?
            <Thanks
                navigateAfterSubmit={navigateAfterSubmit}
                success={success}
                myGroups={[{ groupId: group }]}
            />
            : (
                !loading ? (
                    <div>
                        <StepperSampling
                            profile={profile}
                            questionnaire={questionnaire}
                            filledQuestionnaire_id={id}
                            admins={admins}
                            members={members}
                            submitCallback={submitCallback}
                            navigateAfterSubmit={navigateAfterSubmit}
                            success={success}
                            myGroups={[{ groupId: group }]}
                            defaultValues={{}}
                            started={started}
                            group={group}
                        />
                    </div>
                ) : (<Spinner />)
            )
        }
    </>
    );
};

export default View;