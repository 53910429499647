import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { chartColors, createBaseData, groupingData } from './CChartConstats';

const CPieChart = ({ name, data, meta, questionnaire, field = 'value' }) => {
    // console.log('meta', meta);
    // console.log('questionnaire', questionnaire);

    // console.log('allData', data);
    let subScaleChartData = [
        // { name: 'aaa', value: 11 },
        // { name: 'bbb', value: 22 }
    ];
    if (data && Array.isArray(data) && meta) {
        const questions = meta?.questions;
        const questionsKeys = Object.keys(meta?.questions);
        questionsKeys.forEach((key) => {
            const name = questions[key]['name'];
            const dataKey = data[key];
            // console.log('dataKey', dataKey);
            let value =
                data
                    ? dataKey?.[field]
                    : 0;
            if (value) {
                subScaleChartData.push({ name: name, value: parseInt(value) });
            }
        });
    }
    // console.log('aaaaa',subScaleChartData);
    // console.log('subScaleChartData', subScaleChartData);
    return (<>

        {subScaleChartData && Array.isArray(subScaleChartData) && subScaleChartData.length > 0 &&
            <>
                <h3>
                    {name}
                </h3>
                <ResponsiveContainer width={window.innerWidth - 50} height={window.innerWidth - 50}>
                    <PieChart
                    // width={800}
                    // height={800}
                    >
                        <Pie
                            // isAnimationActive={false}   
                            // width={800}
                            // height={800}
                            data={subScaleChartData}
                            cx="50%"
                            cy="50%"
                            label
                            labelLine={false}
                            // label={renderCustomizedLabel}
                            outerRadius={150}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {subScaleChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                            ))}
                            {/* <LabelList dataValues={a} /> */}
                        </Pie>
                        {/* <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
                    <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
                        {/* <Legend /> */}
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </>
        }

    </>);
};

export default CPieChart;