import React, { useEffect, useState } from 'react';
import FSPFieldText from './components/FSPFieldText';
import FSPFieldSelect from './components/FSPFieldSelect';
import FSPFieldNumber from './components/FSPFieldNumber';
import { convertArrayById } from '../../../../commons/Utility';
import FSPFieldDate from './components/FSPFieldDate';
import FSPFieldModelMultiSelect from './components/FSPFieldModelMultiSelect';
import FSPFieldSelectScale from './components/FSPFieldSelectScale';
import FSPFieldMultipleSelectScale from './components/FSPFieldMultipleSelectScale';
import QuestionsUtitity from '../../QuestionsUtitity';

const FSPField = ({
    page,
    pages,
    values,
    setValues,
    setActual,
    questions,
    parentRecord,
    scales,
    actual,
    valid,
    next
}) => {
    const { findFirstUnFilledQuestion } = QuestionsUtitity()
    const [op, setOp] = useState([]);
    const [nextStep, setNextStep] = useState(false);
    const [autoNext, setAutoNext] = useState(true);
    const [hide, setHide] = useState(0);
    const field = questions[page?.question] ?? [];
    const scaleId = field ? field['Skála'] : null;
    const scale = scales && scaleId ? scales[scaleId] : null;
    const fieldType = scale?.type;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    }
    useEffect(() => {
        if (nextStep) {
            setNextStep(false);
            const x = findFirstUnFilledQuestion({
                pages: pages,
                values: values,
            });
            if (autoNext && valid())
                setActual(Math.min(x, actual + 1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextStep]);

    const handleChangeArray = (name, value, autoNext = true) => {
        setAutoNext(autoNext);
        const _id = page.category_id;
        const c = values[_id] ? { ...values[_id], [page.question]: value } : { [page.question]: value };
        setValues({ ...values, [_id]: c });
        setNextStep(true);
    }
    const handleChangeArrayErtekeles = (name, value, autoNext = true) => {
        setAutoNext(autoNext);
        const _id = page.category_id;
        const c = values[_id] ? { ...values[_id], [page.question]: value } : { [page.question]: value };
        setValues({ ...values, [_id]: c });
    }
    const submit = () => {
        if (valid())
            next();
    }

    const {
        id, name, q2, label,
        model,
        valuesLabel, valuesKey,
        values_key, values_label, valuesField,
        _document, document, autoHide, _valuesField, _model, addId
    } = field;

    const onSelect = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    };

    const onRemove = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    }

    var modelfieldsOptions = [];

    if (fieldType === 'value_select_dynamic') {
        const tables = convertArrayById(questions[model], 'id');
        const table = values ? values[_document] : null;
        const documentum = tables && table ? tables[table] : null;
        modelfieldsOptions =
            documentum && valuesField
                ? documentum[valuesField]
                : [];
    }

    if (fieldType === 'value_select_dynamic2') {
        const doc = values && _document ? values[_document] : null;
        const vField = values && _valuesField ? values[_valuesField] : null;
        const tables = questions && model && questions[model] ? convertArrayById(questions[model], 'id') : null;
        const table = tables && doc ? tables[doc] : null;
        const mainFields = table && table['fields'] ?
            (Array.isArray(table['fields']) ? convertArrayById(table['fields'], 'name') : table['fields'])
            : null;
        const mainField = mainFields && vField ? mainFields[vField] : null;
        const subFields = mainField &&
            mainField['fields'] ?
            mainField['fields'] : null;
        modelfieldsOptions =
            subFields
                ? subFields
                : [];
    }

    if (fieldType === 'value_select_dynamic_parent') {
        const tables = convertArrayById(questions[model], 'id');
        const table = parentRecord.model;
        const valuesField2 = 'fields';
        const documentum = tables && table ? tables[table] : null;
        modelfieldsOptions =
            documentum && valuesField2
                ? documentum[valuesField2]
                : [];
    }

    useEffect(() => {
        if (fieldType === 'value_select_static' && document && valuesField) {
            const tables = convertArrayById(questions, 'id');
            const documentum = tables ? tables[document] : null;
            const modelfieldsOptions = documentum && documentum.data ?
                documentum.data[valuesField] : [];
            setOp(modelfieldsOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldType]);

    useEffect(() => {
        if (autoHide &&
            Array.isArray(autoHide) &&
            autoHide.indexOf(values.type) > -1
        ) {
            setHide(1);
        } else {
            setHide(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const category = page && values ? values[page.category_id] : null;
    const value = category ? category[page.question] : null;
    // console.log('field', field);
    // console.log('fieldType', fieldType);
    // console.log('options', scale.elemek);
    return <>
        {!hide &&
            <div>
                {
                    fieldType === 'ertekeles' &&
                    <FSPFieldText
                        submit={submit}
                        field={field}
                        value={value ?? ''}
                        handleChange={handleChangeArrayErtekeles}
                        page={page}
                    />
                }

                {fieldType === 'szam' &&
                    <FSPFieldNumber
                        field={field}
                        value={value ?? ''}
                        options={scale.elemek ?? []}
                        handleChange={handleChangeArray}
                        page={page}
                    />
                }

                {fieldType === 'betu' &&
                    <FSPFieldSelectScale
                        field={field}
                        value={value ?? ''}
                        options={scale.elemek ?? []}
                        handleChange={handleChangeArray}
                        page={page}
                    />
                }

                {fieldType === 'tobbvalasztos' &&
                    <FSPFieldMultipleSelectScale
                        field={field}
                        values={value ?? []}
                        options={scale.elemek ?? []}
                        handleChange={handleChangeArray}
                        page={page}
                    />
                }

                {
                    fieldType === 'date' &&
                    <FSPFieldDate
                        field={field}
                        value={values[field.name] ?? ''}
                        handleChange={handleChangeArray}
                        page={page}
                    />
                }

                {fieldType === 'select' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={field.values ?? []}
                        valuesKey={values_key}
                        valuesLabel={values_label}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'document_select' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={questions ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'document_select_dynamic' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={questions[values[_model]] ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'value_select_dynamic' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        // options={meta[model] ?? []}
                        options={modelfieldsOptions ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'value_select_dynamic2' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={modelfieldsOptions ?? []}
                        _valuesField={_valuesField}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'value_select_dynamic_parent' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        // options={meta[model] ?? []}
                        options={modelfieldsOptions ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'value_select_static' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        // options={meta[model] ?? []}
                        options={op}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={values[name]}
                        handleChange={handleChange}
                        page={page}
                    />
                }

                {fieldType === 'document_multiselect' &&
                    <FSPFieldModelMultiSelect
                        id={id}
                        label={label}
                        placeholder={q2}
                        model={questions ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        selected={values[name] ?? []}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        page={page}
                    />
                }
            </div>
        }
    </>
};

export default FSPField;