import axios from "axios";

export const getAppData = async (url) => {
    console.log('url', url);
    try {
        // Ellenőrizzük az URL-t
        const urlRegex = /^https:\/\/kerdoiv\.b612\.hu\/apps\/.+/;
        if (!urlRegex.test(url)) {
            console.log('Invalid URL');
            return null;
        }

        // Kérjük le a JSON-t az axios segítségével
        const response = await axios.get(url, {
            withCredentials: false,
        });

        // Ellenőrizzük a visszakapott értéket
        if (response.status === 200 && typeof response.data === 'object' && response.data !== null) {
            console.log('Data retrieved successfully');
            return response.data;
        } else {
            console.log('Invalid response or data');
            return null;
        }
    } catch (error) {
        console.error('Error during request', error);
        return null;
    }
};
