import React from 'react';
import EvaluationValue from './EvaluationValue';

const EvaluationValues = ({ questions, values, scales }) => {

    return (
        <>
            {values && Object.keys(values).map((id2, index2) => {
                const question = questions[id2];
                const value = values[id2];
                return (
                    <EvaluationValue
                        key={index2}
                        question={question}
                        scales={scales}
                        value={value}
                    />
                )
            })
            }
        </>
    );
};

export default EvaluationValues;