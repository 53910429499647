import React, { useState, useEffect } from 'react';
import { TextField, Switch, FormControlLabel, Button, Box, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateGroupNameAndActive from '../../../services/actions/UpdateGroupNameAndActive';
import UserListItem2 from '../../../components/subscribed/UserListItem2';

const GroupForm = ({ group }) => {
    const [name, setName] = useState(group.name || '');
    const [active, setActive] = useState(group.active);
    const [isModified, setIsModified] = useState(false);
    const [selectedInstructor, setSelectedInstructor] = useState(group.Oktató || '');

    const { subscribe } = UpdateGroupNameAndActive();

    useEffect(() => {
        setIsModified(name !== group.name || active !== (group.active === true) || selectedInstructor !== group.Oktató);
    }, [name, active, selectedInstructor, group.name, group.active, group.Oktató]);

    const handleSave = async () => {
        const id = group.id;
        try {
            await subscribe({ id, name, active, oktato: selectedInstructor });
            toast.success('Mentés sikeres!');
        } catch (error) {
            toast.error('Mentés sikertelen!');
        }
    };
    const admins = group?.admins ?? [];
    const managers = group?.managers ?? [];
    const combinedMembers = [...new Set([...admins, ...managers])]; // Combine and remove duplicates

    return (
        <>
            <ToastContainer />
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    label="Csoport neve"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={name.length > 0 && name.length < 6}
                    helperText={name.length > 0 && name.length < 6 ? "A névnek legalább 6 karakter hosszúnak kell lennie" : ""}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                        />
                    }
                    label="Aktív"
                />
                <FormControl>
                    <InputLabel id="instructor-label">Oktató</InputLabel>
                    <Select
                        labelId="instructor-label"
                        value={selectedInstructor}
                        onChange={(e) => setSelectedInstructor(e.target.value)}
                        label="Oktató"
                    >
                        {combinedMembers.map(id => (
                            <MenuItem key={`user-${id}`} value={id}>
                                <UserListItem2 key={`user2-${id}`} user={id} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={name.length < 6 || !isModified}
                    onClick={handleSave}
                >
                    Mentés
                </Button>
            </Box>
        </>
    );
};

export default GroupForm;
