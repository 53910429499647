import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../services/appContext';
import { snapshotSetState } from '../commons/Utility';

const Query = ({ setState, key, table, filter, refresh = false }) => {

    const {
        queries,
        queryDocs,
    } = useContext(AppContext);

    const subscribe = () => {
        queryDocs({
            key: key,
            table: table,
            filter: filter,
            refresh: refresh
        })
        return () => { }
    }

    useEffect(() => {
        snapshotSetState({
            snapshot: queries[key],
            callback: setState
        })
    }, [queries[key]]);

    return {
        subscribe
    };
};

export default Query;