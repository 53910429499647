import React from 'react';
import CGroupMultiSelect from '../../../../../commons/CGroupMultiSelect';

const FSPFieldModelMultiSelectGroup = ({
    id,
    meta,
    valuesKey,
    valuesLabel,
    selected,
    addId,
    handleChange
}) => {
    const options = (typeof meta === 'object') ?
        Object.keys(meta).map
            ((option, index) => (
                { "id": meta[option][valuesKey], "name": meta[option][valuesLabel], "icon": meta[option]['Profilkép'] }
            )) : [];
    var optionsFiltered = options.filter((el) => typeof el.id == 'string' && typeof el.name == 'string')
    if (addId) {
        optionsFiltered = [...optionsFiltered, { "id": "id", "name": "id" }]
    }
    return (<>
        <CGroupMultiSelect
            field={id}
            meta={meta}
            handleChange={handleChange}
            values={selected}
            options={optionsFiltered}
            valuesKey={valuesKey}
            valuesLabel={valuesLabel}
        />

    </>
    );
};

export default FSPFieldModelMultiSelectGroup;