import React, { useEffect, useState } from 'react';
import { Avatar, BottomNavigationAction, Grid, Tab, Tabs, Typography } from '@mui/material';
import { AccessTime, DataUsage, Group, Label, Person, Refresh } from '@mui/icons-material';
import { styled } from '@mui/system';
import { deepOrange } from '@mui/material/colors';

import GetGroupAnonymousEvaluated from '../../../../services/firebase/GetGroupAnonymousEvaluated';
import StatUtility from '../anonimousData/StatUtility';
import { evaluatedDirectionName, evaluatedTypesName, evaluatedTypesUser, valuesFields } from '../anonimousData/Constansts';
import Spinner from '../../../../components/Spinner';
import TemplateChecker from './components/TemplateChecker';
import MetaEditor2 from './metaeditor/MetaEditor2';
import InfiniteScrollList from './InfiniteScrollList';

import { chart_LabelsSeriesFilterChart1 } from './charts/chart_LabelsSeriesFilterChart1';
import { chart_LabelsSeriesFilterChart4 } from './charts/chart_LabelsSeriesFilterChart4';
import { chart_LabelsSeriesFilterChart5 } from './charts/chart_LabelsSeriesFilterChart5';
import { chart_LabelsSeriesFilterChart6 } from './charts/chart_LabelsSeriesFilterChart6';
import { chart_LabelsSeriesFilterChart7 } from './charts/chart_LabelsSeriesFilterChart7';
import { chart_LabelsSeriesFilterChart8 } from './charts/chart_LabelsSeriesFilterChart8';
import { chart_LabelsSeriesFilterChart9 } from './charts/chart_LabelsSeriesFilterChart9';
import { chart_LabelsSeriesFilterChart0 } from './charts/chart_LabelsSeriesFilterChart0';
import { chart_LabelsSeriesFilterChart_evaluated1 } from './charts/chart_LabelsSeriesFilterChart_evaluated1';
import { chart_LabelsSeriesFilterChart_evaluated2 } from './charts/chart_LabelsSeriesFilterChart_evaluated2';
import { chart_LabelsSeriesFilterChart_evaluated3 } from './charts/chart_LabelsSeriesFilterChart_evaluated3';
import { chart_LabelsSeriesFilterChart_evaluated4 } from './charts/chart_LabelsSeriesFilterChart_evaluated4';
import { chart_LabelsSeriesFilterChart_evaluated11 } from './charts/chart_LabelsSeriesFilterChart_evaluated11';
import { chart_LabelsSeriesFilterChart_evaluated12 } from './charts/chart_LabelsSeriesFilterChart_evaluated12';
import { chart_LabelsSeriesFilterChart_evaluated13 } from './charts/chart_LabelsSeriesFilterChart_evaluated13';
import { chart_LabelsSeriesFilterChart_evaluated14 } from './charts/chart_LabelsSeriesFilterChart_evaluated14';
import { chart_date1 } from './charts/chart_date1';
import { chart_date2 } from './charts/chart_date2';
import { chart_date3 } from './charts/chart_date3';
import { chart_date4 } from './charts/chart_date4';
import { chart_date5 } from './charts/chart_date5';
import { chart_date6 } from './charts/chart_date6';
import { chart_date7 } from './charts/chart_date7';
import { chart_date8 } from './charts/chart_date8';
import { chart_group2 } from './charts/chart_group2';
import { chart_group3 } from './charts/chart_group3';
import { chart_group4 } from './charts/chart_group4';
import { chart_group5 } from './charts/chart_group5';
import { chart_group4a } from './charts/chart_group4a';
import { chart_group5a } from './charts/chart_group5a';
import { chart_group5b } from './charts/chart_group5b';
import { chart_group4b } from './charts/chart_group4b';
import { chart_grouptime2 } from './charts/chart_grouptime2';
import { chart_grouptime3 } from './charts/chart_grouptime3';
import { chart_grouptime4 } from './charts/chart_grouptime4';
import { chart_grouptime5 } from './charts/chart_grouptime5';
import { chart_grouptime8 } from './charts/chart_grouptime8';
import { chart_grouptime1 } from './charts/chart_grouptime';
import Help from '../../../../commons/help/Help';
import { helps } from '../../../../commons/help/data/helps';
import InfoCards from '../../../../commons/help/widgets/InfoCards';
import { images } from '../../../../commons/help/data/images';
import { stepsGroupStats } from '../../../../commons/help/data/stepsGroupStats';
import FiltersBox2 from './components/FiltersBox2';
import BSAccordion from '../groupStats/components/BSAccordion';

const GroupStats2 = ({ groupId }) => {
    const [groupTab, setGroupTab] = React.useState('operations1');
    const [data, setData] = useState(null);
    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [labels, setLabels] = useState();
    const [questions, setQuestions] = useState();
    const [templates, setTemplates] = useState();
    const [users, setUsers] = useState();
    const [isMetaEditorOpen, setIsMetaEditorOpen] = useState(false);
    const help = helps['documentation'];
    const filterFields = {
        direction: {
            field: 'direction',
            value: 'direction',
            label: 'Önért./értékelés',
            meta: evaluatedDirectionName,
            series: true
        },
        groupAuthor: {
            field: 'groupAuthor',
            value: 'groupAuthor',
            label: 'Értékelő perspektíva',
            meta: evaluatedTypesName,
            series: true
        },
        evaluatedUser: {
            field: 'evaluatedUser',
            value: 'evaluatedUser',
            label: 'Csoport perspektíva',
            meta: evaluatedTypesUser,
            series: true,
            notUserFilter: true
        },
        evaluated: {
            field: 'evaluated',
            value: 'evaluated',
            meta: users,
            label: 'Értékeltek',
            series: true
        },
        label: {
            field: 'label',
            value: 'label',
            meta: labels,
            label: 'Címke',
            series: true
        },
        question: {
            field: 'question',
            value: 'question',
            meta: questions,
            label: 'Kérdések',
            series: true
        },
        template: {
            field: 'template',
            value: 'template',
            meta: templates,
            label: 'Kérdőívek',
            series: true
        },
        updatedAt: {
            value: 'updatedAt',
            field: 'updatedAt',
            label: 'Dátum',
            isDate: true
        },
    };

    const StyledTabs = styled(Tab)(({ theme }) => ({
        minWidth: 0,
        margin: "0.5em",
        fontSize: "0.8em",

    }));

    const handleChange = (event, newValue) => { setGroupTab(newValue); };
    const {
        addDate,
        getTemplatesAndEvaluatedUsersList,
        getMetaOfTemplate,
        getUsers,
        normalizeValue,
        calculateDaysPassed
    } = StatUtility();

    const getGroupData = async (refresh = false) => {
        setLoading(true);
        // console.log('refresh', refresh);
        const { getData } = await GetGroupAnonymousEvaluated();

        try {
            setLoading(true);
            const logs1 = await getData(groupId, refresh);

            const logs = logs1?.data;

            setDate(logs1?.date);
            const dataWithDate = await addDate(logs?.response?.result);
            const { templateList, evaluatedUsersList } = getTemplatesAndEvaluatedUsersList(logs?.response?.result);
            // console.log('dataWithDate', dataWithDate)
            // get users
            const groupUsers = await getUsers(evaluatedUsersList);
            setUsers(groupUsers);

            // get labels, questions, templates
            const { labels, questions, templates, scales } = await getMetaOfTemplate(templateList);

            const normalizedData = normalizeValue({ data: dataWithDate, questions, scales });
            setData(normalizedData);
            setLabels(labels);
            setQuestions(questions);
            setTemplates(templates);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getGroupData(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenMetaEditor = () => {
        setIsMetaEditorOpen(true);
    };
    const handleCloseMetaEditor = () => {
        setIsMetaEditorOpen(false);
    };
    const meta = {
        templates: templates,
        questions: questions,
        labels: labels,
        evaluated: users
    };

    return (
        <div>
            <Grid container spacing={0}>
                <Grid className="profileButton" item xs={12}>
                    <BSAccordion label='Súgók'>
                        <InfoCards steps={stepsGroupStats} dialog={true} />
                    </BSAccordion>
                </Grid>

                <Grid className="profileButton" item xs={3}>
                    <BottomNavigationAction
                        showLabel
                        onClick={() => getGroupData(true)}
                        label={<>Frissítés<br />({date ? `${calculateDaysPassed(date)} napja frissítve` : 'Adatok frissítése'})</>}
                        icon={
                            <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
                                <Refresh />
                            </Avatar>
                        } />
                </Grid>
                <Grid className="profileButton" item xs={3}>
                    <BottomNavigationAction
                        showLabel
                        onClick={handleOpenMetaEditor}
                        label={'Címkék szerkesztése'}
                        icon={
                            <Avatar sx={{ bgcolor: '#1ABC9C' }} variant="square">
                                <Label />
                            </Avatar>
                        } />
                </Grid>

                <TemplateChecker />
            </Grid>
            {isMetaEditorOpen && (
                <MetaEditor2
                    questions={questions}
                    labels={labels}
                    setQuestions={setQuestions}
                    setLabels={setLabels}
                    onClose={handleCloseMetaEditor}
                />
            )}

            {/* <Help
                height={window.innerHeight - 150}
                steps={help.steps} /> */}
            {loading && <Spinner />}
            <Tabs
                // variant="scrollable"
                allowScrollButtonsMobile
                // scrollButtons="auto"
                value={groupTab}
                variant="fullWidth"
                onChange={handleChange}
                // showlabels={false}
                aria-label="icon tabs example">
                <StyledTabs
                    value='operations1'
                    icon={<DataUsage />}
                    label="Aktivitás"
                />
                <StyledTabs
                    value='operations2'
                    icon={<Person />}
                    label={<Typography variant="h12">Tagok</Typography>}
                />
                <StyledTabs
                    value='operations3'
                    icon={<AccessTime />}
                    label={<Typography variant="h12">Dátum</Typography>}
                />
                <StyledTabs
                    value='operations4'
                    icon={<Group />}
                    label={<Typography variant="h12">Csoport</Typography>}
                />
                <StyledTabs
                    value='operations6'
                    icon={<Group />}
                    label={<Typography variant="h12">Csoport Dátum</Typography>}
                />
            </Tabs>

            {groupTab === 'operations1' &&
                <InfiniteScrollList
                    label={'I'}
                    data={data}
                    filterFields={filterFields}
                    meta={meta}
                    items={[
                        // { id: 1, options: chart_time_evaluated_value },
                        { id: 11, options: chart_LabelsSeriesFilterChart0, type: 'BChart2' },
                        { id: 2, options: chart_LabelsSeriesFilterChart5, type: 'BChart2' },
                        { id: 3, options: chart_LabelsSeriesFilterChart6, type: 'BChart2' },
                        { id: 4, options: chart_LabelsSeriesFilterChart4, type: 'BChart2' },
                        { id: 5, options: chart_LabelsSeriesFilterChart1, type: 'BChart2' },
                        { id: 6, options: chart_LabelsSeriesFilterChart7, type: 'BChart2' },
                        { id: 7, options: chart_LabelsSeriesFilterChart8, type: 'BChart2' },
                        { id: 8, options: chart_LabelsSeriesFilterChart9, type: 'BChart2' },
                    ]}
                />
            }
            {groupTab === 'operations2' &&
                <InfiniteScrollList
                    label={'II'}
                    key={111}
                    data={data}
                    filterFields={filterFields}
                    meta={meta}
                    items={[
                        { id: 101, options: chart_LabelsSeriesFilterChart_evaluated2, type: 'BChart2' },
                        { id: 102, options: chart_LabelsSeriesFilterChart_evaluated1, type: 'BChart2' },
                        { id: 103, options: chart_LabelsSeriesFilterChart_evaluated3, type: 'BChart2' },
                        { id: 104, options: chart_LabelsSeriesFilterChart_evaluated4, type: 'BChart2' },
                        { id: 105, options: chart_LabelsSeriesFilterChart_evaluated14, type: 'BChart2' },
                        { id: 106, options: chart_LabelsSeriesFilterChart_evaluated11, type: 'BChart2' },
                        { id: 107, options: chart_LabelsSeriesFilterChart_evaluated12, type: 'BChart2' },
                        { id: 108, options: chart_LabelsSeriesFilterChart_evaluated13, type: 'BChart2' },
                    ]}
                />
            }
            {groupTab === 'operations3' &&
                <InfiniteScrollList
                    label={'III'}
                    key={222}
                    data={data}
                    filterFields={filterFields}
                    meta={meta}
                    items={[
                        { id: 201, options: chart_date1, type: 'BChart2' },
                        { id: 202, options: chart_date2, type: 'BChart2' },
                        { id: 203, options: chart_date3, type: 'BChart2' },
                        { id: 204, options: chart_date6, type: 'BChart2' },
                        { id: 205, options: chart_date4, type: 'BChart2' },
                        { id: 206, options: chart_date5, type: 'BChart2' },
                        { id: 208, options: chart_date8, type: 'BChart2' },
                        { id: 207, options: chart_date7, type: 'BChart2' },
                    ]}
                />
            }
            {groupTab === 'operations4' &&
                <InfiniteScrollList
                    label={'IV'}
                    key={444}
                    data={data}
                    filterFields={filterFields}
                    meta={meta}
                    items={[
                        { id: 301, options: chart_group2, type: 'BChart2' },
                        { id: 302, options: chart_group3, type: 'BChart2' },
                        { id: 303, options: chart_group4, type: 'BChart2' },
                        { id: 304, options: chart_group5, type: 'BChart2' },
                        { id: 305, options: chart_group4a, type: 'BChart2' },
                        { id: 306, options: chart_group5a, type: 'BChart2' },
                        { id: 307, options: chart_group4b, type: 'BChart2' },
                        { id: 308, options: chart_group5b, type: 'BChart2' },
                    ]}
                />
            }
            {groupTab === 'operations6' &&
                <InfiniteScrollList
                    label={'V'}
                    key={555}
                    data={data}
                    filterFields={filterFields}
                    meta={meta}
                    items={[
                        { id: 401, options: chart_grouptime1, type: 'BChart2' },
                        { id: 402, options: chart_grouptime2, type: 'BChart2' },
                        { id: 403, options: chart_grouptime3, type: 'BChart2' },
                        { id: 404, options: chart_grouptime4, type: 'BChart2' },
                        { id: 405, options: chart_grouptime5, type: 'BChart2' },
                        { id: 408, options: chart_grouptime8, type: 'BChart2' },
                    ]}
                />
            }
        </div>
    );
};

export default GroupStats2;