import React, { useState, useRef, useEffect } from 'react';
import { auth, functions, httpsCallable } from '../../firebase.config';
import { Dialog, DialogTitle, DialogContent, Button, TextField, Typography } from '@mui/material';
import Spinner from '../../components/Spinner';
import QPage from '../../components/stepperSampling/components/ui/QPage';
import GoBackButton from '../../commons/GoBackButton';
import { Close, GroupAdd } from '@mui/icons-material';
import Tada from 'react-reveal/Tada';
import NextButton from '../../components/buttons/NextButton';
import { toast } from "react-toastify";
import DashboardButton from '../../commons/buttons/DashboardButton';

const CreateFirebaseUser = ({ groupId }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const emailInputRef = useRef(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCreateUser = async () => {
    // Validáció
    if (name.length < 6) {
      toast.error("A névnek legalább 6 karakter hosszúnak kell lennie.");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Érvénytelen email formátum.");
      return;
    }
    try {
      setLoading(true);


      // Firebase Cloud Function hívása
      const createNewUser = httpsCallable(functions, 'createNewUser');

      // Hívás a szükséges adatokkal
      const result = await createNewUser({ name, email, groupId });

      // Az új felhasználó azonosítója
      const userId = result.data.userId;
      console.log('Új felhasználó létrehozva:', userId);

      // Sikeres létrehozás esetén üzenet megjelenítése
      setSuccessMessage('Felhasználó sikeresen létrehozva!');
    } catch (error) {
      console.error('Hiba történt a felhasználó létrehozása során:', error.message);
      toast.error("Hiba történt a felhasználó létrehozása során: "+error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setSuccessMessage('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSuccessMessage('');
  };

  useEffect(() => {
    // Megnyitáskor az email mező fókuszba állítása
    setEmail('');
    setName('');
    emailInputRef.current?.focus();
  }, [openDialog]);

  return (
    <>
      {/* Gomb, amelyre kattintva megjelenik a form */}
      <DashboardButton
        label={'Felhasználó létrehozása'}
        icon={'AlternateEmail'}
        onClick={() => (handleOpenDialog())}
        isAvatar={true}
        bgcolor={'#1ABC9C'}
      />

      {/* <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Felhasználó létrehozása
      </Button> */}

      {/* Dialógus */}
      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} 
      style={{ backgroundColor: '#eee' }}
      PaperProps={{
        style: { backgroundColor: '#eee' },
      }}
      >
        <div onClick={() => handleCloseDialog()} className="helpButton2">
          <Close />
        </div>

        <div className='centerContent2'>

          <h2>Felhasználó létrehozása</h2>
          <Tada>
            <GroupAdd sx={{ fontSize: 170 }} color="success" />
          </Tada>
          {loading ? (
            <Spinner />
          ) : successMessage ? (
            <Typography variant="body1">
              {successMessage}
              <button
                className={"primaryButton"}
                onClick={handleCloseDialog}>
                Ok
              </button>
            </Typography>
          ) : (
            <>
              {/* A felhasználó létrehozásához szükséges űrlap */}
              <div className="pageContainer">
                <div className="loginLayout">
                  <div className="profileList">
                    <input
                      label="Email"
                      type="text"
                      value={email}
                      className="emailInput"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      margin="normal"
                      inputRef={emailInputRef}
                    />
                    <input
                      label="Név"
                      type="text"
                      value={name}
                      placeholder="Név"
                      className="nameInput"
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      margin="normal"
                    />

                    <button
                      variant="contained"
                      color="primary"
                      className={"primaryButton passwordInputDiv"}
                      onClick={handleCreateUser}>
                      Létrehozás
                    </button>
                  </div>
                </div>
              </div>
              {/* <button
                    className={"secondaryButton2 passwordInputDiv"}
                    onClick={handleCloseDialog}>
                    Mégse
                  </button> */}
            </>
          )}
        </div>


      </Dialog>
    </>
  );
};

export default CreateFirebaseUser;
