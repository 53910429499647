import React, { useEffect, useState } from 'react';
import View from './View';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Help = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = params;
    const [previousPath, setPreviousPath] = useState(null);

    useEffect(() => {
        // Frissítsük az előző útvonalat, amikor az útvonal változik
        setPreviousPath(location.pathname);
    }, [location.pathname]);

    const goBack = (id) => {
        // Ellenőrizzük, van-e előző oldal
        const path = '/help/' + id;
        if (previousPath !== null && path !== previousPath) {
            navigate(-1);
        } else {
            if (id === 'documentation') {
                navigate('/');
            } else {
                navigate('/help/documentation');
            }
        }
    };
    return (
        <View
            id={id}
            view={'card'} //card vagy slide
            goBack={goBack}
        />
    );
};

export default Help;