import { Badge, BottomNavigationAction, Button, Card, CardActions, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import GroupQrCode from './GroupQrCode';
import { AdminPanelSettings, GroupAdd, ThumbsUpDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import StorageAvatar from '../firebase/StorageAvatar';

const GroupQrItem = ({ user, group }) => {
    const [show, setShow] = useState(false);
    const groupUrl = `https://kerdoiv.b612.hu/subscribe/${group.id}`;
    const isMember = user && group?.data?.Members && group?.data?.Members.indexOf(user) > -1;
    const isAdmin = user && group?.data?.admins && group?.data?.admins.indexOf(user) > -1;
    const isManager = user && group?.data?.managers && group?.data?.managers.indexOf(user) > -1;
    const isSubscribed = user && group?.data?.subscribed && group?.data?.subscribed.indexOf(user) > -1;
    const navigate = useNavigate();

    let bcolor = "info";
    let bcontent = 0;
    let bvariant = 'standard';
    // if (isAdmin) {
    //     bcolor = "info";
    //     bcontent = group?.data?.Members?.length;
    //     bvariant = 'standard';
    // }
    if ((isAdmin || isManager) && group?.data?.subscribed?.length > 0) {
        bcolor = "error";
        bcontent = group?.data?.subscribed?.length;
        bvariant = 'standard';
    }
    if (isSubscribed) {
        bcolor = "error";
        bcontent = <GroupAdd />;
        bvariant = 'dot';
    }
    return (
        <Grid
            // onClick={() => { setShow(!show) }}
            // onClick={() => navigate(`/groupQR/${group?.id}`)}
            className="profileButton"
            item
            xs={3}>
            <BottomNavigationAction
                showLabel
                // onClick={() => { setShow(!show) }}
                onClick={() => navigate(`/mygroup/${group?.id}`)}
                label={group?.data?.name}
                // style={{ maxWidth: '127px'}}
                classes={{label: 'groupIconText'}}
                icon={<Badge
                    badgeContent={bcontent}
                    color={bcolor}
                    variant={bvariant}
                // overlap="circular"
                >
                    <StorageAvatar
                        // value={group?.data ? group?.data['Oktató'] : null}
                        value={group?.data ? group?.data['Profilkép'] : null}
                        bucket={'group_images'}
                        className="categoryListingImg"
                        size="xlarge"
                        variant="rounded"
                    />
                    {/* <QRCode
                        size={32}
                        fgcolor={bcolor}
                        style={{ fgColor: bcolor, height: "auto", maxWidth: "32px", width: "32px" }}
                        value={groupUrl}
                        viewBox={`0 0 300 300`}
                    /> */}
                </Badge>
                } />
            {show &&
                <GroupQrCode
                    setShow={setShow}
                    group={group}
                    isMember={isMember}
                    isAdmin={isAdmin || isManager}
                    isSubscribed={isSubscribed}
                />
            }
        </Grid>
    )
};

export default GroupQrItem;