import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { AppContext } from "../../services/appContext";
import { auth } from "../../firebase.config";
import AcceptTermsAndConditonalsAction from "../../services/actions/AcceptTermsAndCondionalsAction";

const TermsAndConditions = () => {
    const version = 1;
    const [open, setOpen] = useState(false);
    const [user] = useAuthState(auth);
    const { snapshots } = useContext(AppContext);
    const { subscribe } = AcceptTermsAndConditonalsAction();

    useEffect(() => {
        const profileData = snapshots && snapshots['profile'] && snapshots['profile'][0] ? snapshots['profile'][0]?.data : null;
        if (user?.uid && profileData) {
            if (!profileData?.termsAndConditions || profileData.termsAndConditions < version ) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, snapshots['profile']]);

    const onAccept = () => {
        subscribe({
            id: user?.uid,
            termsAndConditions: version
        })
    }

    return (
        <Dialog fullScreen open={open}>
            <DialogTitle>Felhasználási Feltételek</DialogTitle>
            <DialogContent>
                <div className="profileList">
                    <h1>SZOFTVER JOGI MEGÁLLAPODÁS</h1>
                    <p>Köszöntjük a PR-VET Kérdőív Applikáció tesztelési fázisában részt vevőket!</p>
                    <p>Szeretnénk tájékoztatni mindenkit, hogy jelenleg a PR-VET Kérdőív Applikáció tesztelési fázisában vagyunk, és az alkalmazás továbbfejlesztése és optimalizálása folyamatban van. A tesztelési fázisban előfordulhatnak hibák és nem várt problémák, és ezért szeretnénk hangsúlyozni, hogy ebben a szakaszban semmilyen jogi garancia vagy követelés nem érvényesíthető az alkalmazással kapcsolatban.</p>
                    <p>Azért kérünk minden tesztelőt, hogy aktívan vegyen részt a visszajelzések megosztásában és az esetleges hibák jelentésében. A célunk az, hogy a PR-VET Kérdőív Applikáció a lehető legjobb élményt nyújtsa a felhasználóknak, és a tesztelési fázis segítségével szeretnénk kiküszöbölni minden esetleges problémát.</p>
                    <p>Kérjük, vegyék figyelembe, hogy a jelenlegi fázisban az alkalmazás változhat, és az esetleges hibák vagy hiányosságok javításra kerülnek a fejlesztőcsapatunk által. Az alkalmazás végleges verziójával kapcsolatos jogi kötelezettségek és garanciák csak az alkalmazás hivatalos kiadásával együtt lépnek életbe.</p>
                    <p>Köszönjük a támogatásukat és együttműködésüket a PR-VET Kérdőív Applikáció tesztelési fázisában!</p>
                    <p>Tisztelettel,</p>
                    <p>PR-VET Kérdőív Applikáció Csapat</p>
                    <p>A PR-VET Kérdőív applikáció tesztelési fázisban van. Az alábbi csupán a felület tesztelését szolgáló tesztszöveg!</p>
                    <h2>1. LICENC</h2>
                    <p>1.1. Licenc nyújtása. A szoftver (a továbbiakban: "Szoftver") fejlesztője vagy tulajdonosa (a továbbiakban: "Licencadó") ezen a ponton a felhasználónak (a továbbiakban: "Licencelt Fél") nemkizárólagos, nem átruházható, visszavonható licencet nyújt a Szoftver használatára, a jelen Megállapodás feltételei szerint.</p>
                    <p>1.2. Felhasználási Jogok. A Licencelt Fél jogosult a Szoftvert a Licencadó által meghatározott módon és célra használni.</p>
                    <p>1.3. Átruházás és Másolás. A Licencelt Fél nem jogosult a Szoftvert harmadik félnek átadni, bérbeadni, kölcsönözni, vagy másolni, kivéve, ha a Licencadó kifejezetten engedélyezi.</p>
                    <h2>2. SZELLEMI TULAJDON</h2>
                    <p>2.1. Szoftver Tulajdonsága. A Licencadó fenntart minden szellemi tulajdonjogot a Szoftverrel kapcsolatban.</p>
                    <p>2.2. Módosítások és Fejlesztések. A Licencelt Fél nem jogosult a Szoftvert módosítani, fejleszteni vagy a Licencadó írásos beleegyezése nélkül más szoftverrel kombinálni.</p>
                    <h2>3. GARANCIA ÉS FELELŐSSÉG KORLÁTOZÁSA</h2>
                    <p>3.1. Jogdíjmentesség. A Szoftvert "ahogy van" alapon szállítjuk, és a Licencadó semmilyen kifejezett vagy hallgatólagos garanciát nem vállal a Szoftverrel kapcsolatban, beleértve, de nem kizárólagosan, a piacra dobhatóság vagy az adott célra való alkalmasság garanciáit.</p>
                    <p>3.2. Felelősség Korlátozása. A Licencadó semmilyen felelősséget nem vállal a Szoftver használatából vagy használatának képtelenségéből eredő közvetlen vagy következményes károkért.</p>
                    <h2>4. FELMONDÁS</h2>
                    <p>4.1. Felmondás Jogosultsága. Mindkét fél jogosult felmondani ezt a Megállapodást írásban, ha a másik fél megsérti valamelyik feltételét és a megsértésre irányuló felszólításnak nem tesz eleget 30 napon belül.</p>
                    <h2> 5. EGYÉB FELTÉTELEK</h2>
                    <p>5.1. Teljes Megállapodás. Ez a Megállapodás teljes körű megállapodást képez a felek között a Szoftverrel kapcsolatban, és semmilyen egyéb írásbeli vagy szóbeli megállapodást nem helyettesít.</p>
                    <p>5.2. Joghatóság és Irányadó Jog. A Megállapodásnak a Licencadó székhelye szerinti törvények alá van vetve, és a felek kifejezetten alávetik magukat azoknak.</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => onAccept(version)} color="primary">
                    Elfogadom
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsAndConditions;
