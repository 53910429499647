import React from 'react';
import { SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfied sx={{ fontSize: 35 }} color="error" />,
        iconBig: <SentimentVeryDissatisfied sx={{ fontSize: 150 }} color="error" />,
        label: 'Előfordult már veled, hogy úgy hatott a kritika/vélemény, mintha hideg vízzel öntöttek volna nyakon?',
    },
    2: {
        icon: <SentimentDissatisfied sx={{ fontSize: 35 }} color="error" />,
        iconBig: <SentimentDissatisfied sx={{ fontSize: 150 }} color="error" />,
        label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, hogy ez túlzás, nem reális, túl szép, hogy igaz legyen?',
    },
    3: {
        icon: <SentimentSatisfied sx={{ fontSize: 35 }} color="warning" />,
        iconBig: <SentimentSatisfied sx={{ fontSize: 150 }} color="warning" />,
        label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, hogy ez túlzás, nem reális, túl szép, hogy igaz legyen?',
    },
    4: {
        icon: <SentimentSatisfiedAlt sx={{ fontSize: 35 }} color="success" />,
        iconBig: <SentimentSatisfiedAlt sx={{ fontSize: 150 }} color="success" />,
        label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, mintha egy erős energialöketet kaptál volna?',
    },
    5: {
        icon: <SentimentVerySatisfied sx={{ fontSize: 35 }} color="success" />,
        iconBig: <SentimentVerySatisfied sx={{ fontSize: 150 }} color="success" />,
        label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, hogy azt mondtad magadban, ezen érdemes gondolkodnom?',
    },
};
const EvaluationEmotion = ({ value, big = false }) => {
    return (
        <>
            {big ? customIcons[value]?.iconBig : customIcons[value]?.icon}
        </>
    );
};

export default EvaluationEmotion;