import React from 'react';
import { Box, Chip, Select, MenuItem, IconButton, OutlinedInput } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const LabelSelector = ({
    availableLabels,
    newLabelQuestionId,
    setNewLabelQuestionId,
    newLabelId,
    setNewLabelId,
    questionId,
    handleLabelAdd,
    localLabels
}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', m: 0.5 }}>
        <Select
            value={newLabelQuestionId === questionId ? newLabelId : ''}
            onChange={(e) => {
                setNewLabelQuestionId(questionId);
                setNewLabelId(e.target.value);
            }}
            displayEmpty
            renderValue={(selected) => {
                if (!selected) {
                    return <em>Címke kiválasztása</em>;
                }
                return localLabels[selected]?.name || 'Ismeretlen címke';
            }}
            input={<OutlinedInput />}
        >
            <MenuItem value="" disabled>
                Címke kiválasztása
            </MenuItem>
            {availableLabels.map(labelId => (
                <MenuItem key={labelId} value={labelId}>
                    {localLabels[labelId].name}
                </MenuItem>
            ))}
        </Select>
        <IconButton
            color="primary"
            onClick={handleLabelAdd}
            disabled={!newLabelId || newLabelQuestionId !== questionId}
        >
            <AddIcon />
        </IconButton>
    </Box>
    );
};

export default LabelSelector;
