import React from 'react';

const AppLicense = ({ version }) => {
    return (
        <div className='license' style={{ marginTop: 'auto', position: 'absolute', bottom: 100, width: '100%' }}>
            <div className='centerContent2'>
                <div>Minden jog fenntartva </div>
                <div>Copyright © EDUNET-PR-VET, B-612, 2024 </div>
                <div>PR-VET Kérdőív</div>
                <div>{version}</div>
            </div>
        </div>
    );
};

export default AppLicense;
