import React, { useEffect, useState } from 'react';
import Query from '../../../hooks/query';
import Template from './Template';
import { List } from '@mui/material';
import QuestionsUtitity from '../../../components/stepperForm/QuestionsUtitity';
import CreateQuestionnaireAction from '../../../services/actions/CreateQuestionnaireAction';
import Spinner from '../../../components/Spinner';
import { toast } from "react-toastify";

const Templates = ({ group }) => {
    const [templates, setTemplates] = useState([]);
    const [start, setStart] = useState(false);
    const [success, setSuccess] = useState([]);
    const { subscribe } = CreateQuestionnaireAction();
    const { filterQtemplatesByProjects } = QuestionsUtitity()

    const qtemplates = Query({
        setState: setTemplates,
        key: 'qtemplates',
        table: 'qtemplates',
        filter: null
    });

    useEffect(() => {
        qtemplates.subscribe();
        setSuccess([]);
        return () => {
            // unsubscribe();
            // unsubscribe2();
        };
    }, []);

    const filteredTemplates = filterQtemplatesByProjects(group?.projects, templates);

    const createQuestionnaire = async ({ template }) => {
        const from = new Date();
        const to = new Date(from);
        to.setDate(from.getDate() + 1);
        setStart(true);
        const a = await subscribe(
            {
                group: group?.id,
                from: from,
                to: to,
                scope: template?.scope,
                template: template?.id,
                qtemplate: template,
                evaluate: template?.evaluate
            }
        );
        if (a) {
            // console.log('SIKERES', a?.id);
            toast.success(`Sikeres művelet:`);
            setSuccess([...success, template?.id]);
        }
        else {
            console.log('HIBA')
            // toast.error(`Hiba történt a jelentkezésnél`);
        }
        setStart(false);
    }

    return (
        <div>
            {start && <Spinner />}
            <h3>Kérdőív indítása</h3>
            <List>
                {filteredTemplates.map((template, index) => (
                    <Template
                        disabled={success.indexOf(template?.id) > -1}
                        template={template}
                        createQuestionnaire={createQuestionnaire}
                        group={group}
                    />
                ))}
            </List>
        </div>
    );
};

export default Templates;