import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import QPage from '../../../components/stepperForm/components/ui/QPage';
import QQuestion from '../../../components/stepperForm/components/ui/QQuestion';
import { usePWAInstall } from 'react-use-pwa-install'

const Install = ({ exit }) => {
    const install = usePWAInstall()
    return (
        <QPage exit={() => exit(false)}>
            <QQuestion >
                <div className='centerContent'>
                    <div className="profileCard">
                        <h1>PR-VET telepítés</h1>
                        {!install && <p>
                            Az applikáció már telepítve van a telefonra, vagy telepítés alatt áll. Az alkalmazás ikonja megtalálható a kezdőképernyőn.
                        </p>}
                        <img
                            // alt={translate('education_2030')}
                            // className={classes.logo}
                            src={'/logo.png'}
                            width={100}
                        />
                        <p>PR-VET</p>
                    </div>
                    {install && <Button onClick={install}>PR-VET alkalmazás telepítése</Button>}
                </div>
            </QQuestion>
        </QPage >
    );
};

export default Install;