import React from 'react';
import { Box, Button } from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';

const QButtons = ({ lastSlideIsVisible, enabledNext, useStepper, previous, next, styles }) => {
    return (
        <>
            <Box
                sx={[
                    {
                        justifyContent: 'space-between',
                        display: 'flex',
                    },
                    useStepper && {
                        mt: !lastSlideIsVisible ? '0rem' : '1rem',
                    },
                ]}
            >
                <Button
                    disableElevation
                    disableRipple
                    onClick={() => {
                        // setButtonDisabled(true)
                        previous();
                    }}
                    variant='text'
                    sx={[styles.textButton]}
                //   disabled={buttonDisabled}
                >
                    <ChevronLeftRounded sx={{ mr: '.5rem' }} />
                    Vissza
                </Button>

                <Button
                    disableElevation
                    disabled={!enabledNext}
                    onClick={() => {
                        // setButtonDisabled(true)
                        next();
                    }}
                    disableRipple
                    variant='contained'
                    sx={[styles.filledButton]}
                >
                    {'Next'}
                </Button>
            </Box >
        </>
    )

};

export default QButtons;