import React from 'react';
import QRCode from 'react-qr-code';
import QPage from '../stepperForm/components/ui/QPage';
import StorageAvatar from '../firebase/StorageAvatar';
import StorageImage from '../firebase/StorageImage';
import NextButton from '../buttons/NextButton';
import { useNavigate } from 'react-router-dom';
import { AvatarGroup, Button, Grid, IconButton } from '@mui/material';
import closeIcon from "../../assets/svg/close.png";
import SubscribedUsers from '../subscribed/SubscribedUsers';
const GroupQrCode = ({ group, setShow,
    isMember, isAdmin, isSubscribed }) => {
    const groupUrl = `https://kerdoiv.b612.hu/subscribe/${group?.id}`;
    const navigate = useNavigate();
    return (
        <div className="profile">
            <div className="groupHeaderDiv2">
                <StorageImage
                    value={group?.data?.Profilkép}
                    bucket={'group_images'}
                    className="groupHeaderImg"
                    height="200px"
                    width="100%" />
                <div className='groupHeaderPrice3'>
                    <StorageAvatar
                        value={group?.data?.Oktató}
                        bucket={'profile_images'}
                        className="groupHeaderImg"
                        height="200px"
                        width="100%" />
                </div>
                <div className='groupHeaderText'>
                    <p className="listingType">
                        {isAdmin ? 'Oktató' : isMember ? 'Csoportag' :
                            isSubscribed ? 'Jelentkeztél' : ' Nem vagy tag'}
                    </p>
                </div>
                <div className='groupHeaderTextRight3'>
                    <AvatarGroup max={5}>
                        {group?.data?.Members?.map(x => (
                            <StorageAvatar
                                value={x}
                                bucket={'profile_images'}
                                className="aaa"
                                size={'medium'}
                            />
                        ))
                        }
                        {group?.data?.admins?.map(x => (
                            <StorageAvatar
                                value={x}
                                bucket={'profile_images'}
                                className="aaa"
                                size={'medium'}
                            />
                        ))
                        }
                    </AvatarGroup>
                </div>
            </div>
            <div className="shareIconDiv">
                <div className='centerContent2' onClick={() => setShow(false)}>
                    <img src={closeIcon} alt="Share" />
                </div>
            </div>
            <div className='centerContent2'
            // onClick={() => setShow(false)}
            >
                <h2>{group?.data?.name}</h2>

                <QRCode
                    size={300}
                    style={{ height: "auto", maxWidth: "80%", width: "80%" }}
                    value={groupUrl}
                    viewBox={`0 0 300 300`}
                />
                {isAdmin && group?.data?.subscribed?.length > 0 &&
                    <>
                        <h3>Jelentkezők</h3>
                        < SubscribedUsers
                            users={group?.data?.subscribed}
                            groupId={group.id}
                            isAdmin={isAdmin}
                        />
                    </>
                }
                {/* <div className="qrcodes">
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {isAdmin &&
                                <DashboardButton
                                    label={'Felhasználó felvétele'}
                                    icon={'GroupAdd'}
                                    onClick={() => (navigate("/subscribe"))}
                                    isAvatar={true}
                                    bgcolor={'#9B59B6'}
                                />
                            }
                        </Grid>
                    </Grid>
                </div> */}

                <NextButton
                    name={'Csoportadatok'}
                    variant={'contained'}
                    onClick={() => navigate(`/mygroup/${group?.id}`)}
                // disabled={!valid()}
                />
            </div>
        </div>
    );
};

export default GroupQrCode;