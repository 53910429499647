import { useContext } from 'react';
import { AppContext } from '../appContext';

const AcceptTermsAndConditonalsAction = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, termsAndConditions }) => {
        const newRecord = {
            termsAndConditions: termsAndConditions,
        }
        const result = await updateDocument({
            id: id,
            table: 'users',
            record: newRecord,
            fields: ['termsAndConditions'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default AcceptTermsAndConditonalsAction;