import React from 'react';
import NextButton from '../../buttons/NextButton';
import MemberItemBig from '../../members/MemberItemBig';
import QFooter from './ui/QFooter';
import QStepper from './stepper/QStepper';
import { styles } from '../styles'
import QQuestion from './ui/QQuestion';
import NextPerson from './NextPerson';
import FSPField from './FSPField/FSPField';

const FieldPage = ({
    valid,
    pages,
    actual,
    setActual,
    previous,
    next,
    // end,
    values,
    setValues,
    questionnaire,
    categories,
    steps,
    actualCategory,
    setActualCategory,
}) => {
    const form = questionnaire?.qtemplate;
    const meta = form?.meta;
    const page = pages[actual];
    const categoryId = page?.category_id;
    const category = categories && categoryId ? categories[categoryId].data : null;

    return (<>
        {(actualCategory !== category.id &&
            (questionnaire?.evaluate === 'members' || questionnaire?.evaluate === 'members' || questionnaire?.evaluate === 'all')
        ) ?
            (<>
                <NextPerson
                    id={category.id}
                    name={category.name}
                    icon={category.id}
                    setActualCategory={setActualCategory}
                />
            </>)
            :
            (<>
                <QQuestion overflow='auto'>
                    <div className='questionPanel' style={{ width: "100%" }}>
                        <h1>{page.label}</h1>
                        <p>{page.helper}</p>
                        <MemberItemBig
                            name={category.name}
                            icon={category.id}
                        />

                        <FSPField
                            valid={valid}
                            page={page}
                            pages={pages}
                            questions={meta.questions}
                            scales={meta.scales}
                            newRecord={values}
                            next={next}
                            setValues={setValues}
                            setActual={setActual}
                            actual={actual}
                            values={values}
                        />
                        {(page.questionType === 'tobbvalasztos' || page.questionType === 'ertekeles') &&
                            <NextButton
                                name={'Tovább'}
                                variant={'contained'}
                                onClick={() => { next() }}
                                disabled={!valid()}
                            />
                        }
                    </div>
                </QQuestion>
                <QFooter show={true} styles={styles}>
                    <QStepper
                        mini={questionnaire?.evaluate === 'project' || questionnaire?.evaluate === 'self'}
                        activeStep={page?.id_category}
                        lastSlideIsVisible={true}
                        styles={styles}
                        steps={steps}
                        previous={previous}
                        next={next}
                        valid={valid} />
                </QFooter>
            </>)
        }
    </>)

}

export default FieldPage;