import React, { useEffect, useRef } from 'react';
import cursorImage from '../../assets/cursor.png';

const CursorAnimation2 = ({ x1, y1, x2, y2, x0 = 0, duration = 1, clickDuration = 0.5, delay = 1, repeatDelay = 0.5, canvasRef, next, index, action, show, hide, name, actual }) => {
    const cursorRef = useRef(null);

    useEffect(() => {
        if (actual) {
            if (action === 'show') {
                show(name);
                next();
            }
            if (action === 'hide') {
                hide(name);
                next();
            }
            if (action === 'move') {
                startAnimation();
            }
        }
    }, [action, actual, hide, name, next, show]);

    const startAnimation = () => {
        const cursor = cursorRef.current;
        const canvas = canvasRef.current;
        if (!cursor || !canvas) return;

        const rect = canvas.getBoundingClientRect();
        const startX = ((x1) / 100) * rect.width + x0;
        const startY = (y1 / 100) * rect.height;
        const endX = ((x2) / 100) * rect.width + x0;
        const endY = (y2 / 100) * rect.height;

        cursor.style.left = `${startX}px`;
        cursor.style.top = `${startY}px`;
        cursor.style.opacity = 1;

        setTimeout(() => {
            cursor.style.transition = `left ${duration}s, top ${duration}s`;
            cursor.style.left = `${endX}px`;
            cursor.style.top = `${endY}px`;

            setTimeout(() => {
                cursor.style.transform = 'scale(0.9)';
                setTimeout(() => {
                    cursor.style.transform = 'scale(1)';
                    setTimeout(() => {
                        cursor.style.opacity = 0;
                        cursor.style.transition = 'none';
                        cursor.style.left = `${startX}px`;
                        cursor.style.top = `${startY}px`;
                        next();
                    }, delay * 1000);
                }, clickDuration * 1000);
            }, duration * 1000);
        }, 100);
    };

    useEffect(() => {
        startAnimation();
    }, []);

    return (
        <img
            ref={cursorRef}
            src={cursorImage}
            alt="Cursor"
            style={{
                position: 'absolute',
                width: '30px',
                height: '30px',
                transformOrigin: 'center',
                transition: 'opacity 0.5s, transform 0.2s',
                opacity: 0,
                zIndex: 1
            }}
        />
    );
};

export default CursorAnimation2;
