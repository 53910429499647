import React from 'react';
import './style.css';

const ColourFull = () => {
    return (
        <div className='centerContent2'>
            <div className='loader'>
                <div className='dot' style={{ "--d": ".1s", "--c": "radial-gradient(circle at center, #e7e9c7, #e7e9c7)", "--index": 5 }}></div>
                <div className='dot' style={{ "--d": ".2s", "--c": "radial-gradient(circle at center, #d2d584, #d2d584)", "--index": 3 }}></div>
                <div className='dot' style={{ "--d": ".3s", "--c": "radial-gradient(circle at center, #c0c767, #c0c767)", "--index": 3 }}></div>
                <div className='dot' style={{ "--d": ".4s", "--c": "radial-gradient(circle at center, #aec03f, #aec03f)", "--index": 2 }}></div>
                <div className='dot' style={{ "--d": ".5s", "--c": "radial-gradient(circle at center, #9eb22f, #9eb22f)", "--index": 1 }}></div>
                <div className='dot' style={{ "--d": ".6s", "--c": "radial-gradient(circle at center, #c03855, #989132)", "--index": 0 }}></div>
            </div>
        </div>
    );
}

export default ColourFull;
