import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import './style.css';
// import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import group2 from "../../assets/jpg/group2.jpg";
import ImageWithCircleOverlay from '../images/ImageWithCircleOverlay';

const Help = ({ steps, height = '80%' }) => {
    const url = `https://kerdoiv.b612.hu/help/`;
    // const listing = [
    //     { selector: 1, image: 'authentication/signup.png', content: 'egy', description: 'a bejelentkező/regsztrációs oldalon ki lehet választani, hogy a Demó rendszerrel vagy az "éles" rendszeren szeretnénk dolgozni.' },
    //     { selector: 2, image: 'authentication/signin.png', content: 'ketto', description: 'a bejelentkező/regsztrációs oldalon ki lehet választani, hogy a Demó rendszerrel vagy az "éles" rendszeren szeretnénk dolgozni.' },
    //     { selector: 3, image: 'authentication/reset.png', content: 'sss', description: 'a bejelentkező/regsztrációs oldalon ki lehet választani, hogy a Demó rendszerrel vagy az "éles" rendszeren szeretnénk dolgozni.' },
    // ];

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    return (
        <Swiper
            // className={"swiper"}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            // className="mySwiper"
            style={{ height: height }}
        // slides={[{ id: 1, name: 'egy' }, { id: 2, name: 'ketto' }, { id: 3, name: 'sss' }]}
        >
            {steps.filter(x => x.type === 'slide').map((step, index) => (
                <>
                    <SwiperSlide
                        key={step.selector}
                        // onClick={() => navigate(`/category/${data.type}/${id}`)}
                        className="swiperSlideDiv"
                    >
                        <Card
                            key={`Card${index}`}
                            sx={{ height: height }}
                        >
                            <CardActionArea key={`CardActionArea${index}`}>
                                {/* <CardMedia
                                    sx={{ height: "500px" }}
                                    component="img"
                                    height="500"
                                    // maxHeight="50"
                                    image={url + image.image}
                                    alt={image.name}
                                /> */}
                                {step?.version === 2 &&
                                    <ImageWithCircleOverlay
                                        key={`ImageWithCircleOverlay${index}`}
                                        step={step}
                                        imageHeight={height - 200}
                                    />
                                }
                                {step?.version !== 2 &&
                                    <ImageWithCircleOverlay
                                        key={`ImageWithCircleOverlay${index}`}
                                        step={step}
                                        imageHeight={height - 200}
                                    />
                                }
                                <CardContent key={`CardContent${index}`}>
                                    <Typography key={`Typography${index}`} gutterBottom variant="h5" component="div">
                                        {step.content}
                                    </Typography>
                                    <Typography key={`Typography2${index}`} variant="body2" color="text.secondary">
                                        {step.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </SwiperSlide>
                </>
            ))}
            <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
            </div>
        </Swiper>
    );
};

export default Help;