import React, { useContext, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Button, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { AppContext } from '../../services/appContext';
import styles from './Qrscan.css';
import CIcon from '../../commons/CIcon';
import { useNavigate } from 'react-router-dom';
import { Camera, Cameraswitch, Close, CloseFullscreen } from '@mui/icons-material';

const QrReaderComponent = ({ onResult = (x) => { console.log(x) } }) => {
    const navigate = useNavigate();
    const { facingMode, setFacingMode } = useContext(AppContext);
    const [data, setData] = useState('No result');
    const [facingMode2, setFacingMode2] = useState(facingMode !== 'default' ? { facingMode: facingMode } : {}); // Default value

    const handleFacingModeChange = (event) => {
        if (event.target.value === 'default') {
            setFacingMode2({});
            setFacingMode('default')
            localStorage.setItem('facingMode', 'default');
        } else {
            setFacingMode2({ facingMode: event.target.value });
            setFacingMode(event.target.value);
            localStorage.setItem('facingMode', event.target.value);
        }

    };
    const handleFacingModeChange2 = (mode) => {
        if (mode === 'default') {
            setFacingMode2({});
            setFacingMode('default');
            localStorage.setItem('facingMode', 'default');
        } else {
            setFacingMode2({ facingMode: mode });
            setFacingMode(mode);
            localStorage.setItem('facingMode', mode);
        }

    };
    return (
        <>
            <>
                {!facingMode2?.facingMode ?
                    <QrReader
                        className="qr-reader"
                        onResult={(result, error) => {
                            if (!!result) {
                                setData(result?.text);
                                onResult(result?.text)
                            }

                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        // constraints={facingMode}
                        // constraints={
                        //     { facingMode: facingMode }
                        // }
                        // facingMode={facingMode} // Pass the selected value to QrReader
                        style={{ width: '100%' }}
                    />
                    :
                    <QrReader
                        className="qr-reader"
                        onResult={(result, error) => {
                            if (!!result) {
                                setData(result?.text);
                                onResult(result?.text)
                            }

                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        constraints={facingMode2}
                        // constraints={
                        //     { facingMode: facingMode }
                        // }
                        // facingMode={facingMode} // Pass the selected value to QrReader
                        style={{ width: '100%' }}
                    />
                }
            </>
            {/* <p>{data}</p> */}
            {/* <label> */}
            {/* <ButtonGroup
                    variant="outlined"
                    color="primary"
                    aria-label="facing mode"
                    style={{ marginBottom: '10px' }}
                >
                    <Button onClick={() => handleFacingModeChange2('default')}>Alapértelmezett</Button>
                    <Button onClick={() => handleFacingModeChange2('environment')}>Hátlapi kamera</Button>
                    <Button onClick={() => handleFacingModeChange2('left')}>Bal</Button>
                    <Button onClick={() => handleFacingModeChange2('right')}>Jobb</Button>
                    <Button onClick={() => handleFacingModeChange2('user')}>Előlapi kamera</Button>
                </ButtonGroup> */}
            {/* <select value={facingMode2?.facingMode ?? 'default'} onChange={handleFacingModeChange}>
                    <option value="default">Alapértelmezett</option>
                    <option value="environment">Hátlapi kamera</option>
                    <option value="left">Bal</option>
                    <option value="right">Jobb</option>
                    <option value="user">Előlapi kamera</option>
                </select> */}
            {/* </label> */}

            <Button onClick={() => navigate(`/qrsetup`)} ><Cameraswitch />Kamera kiválasztása</Button>
        </>
    );
};

export default QrReaderComponent;
