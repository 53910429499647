import React from 'react';
import InfoCard from './InfoCard';
import InfoLink from './InfoLink';
import InfoTitle from './InfoTitle';

const InfoCards = ({ steps, dialog = false }) => {
    return (
        <>
            {steps.map((step, index) => (
                <div key={`steps${index}`} >
                    {step.type === 'slide' &&
                        <InfoCard key={`slide${index}`} step={step} />
                    }
                    {step.type === 'link' &&
                        <InfoLink key={`link${index}`} step={step} dialog={dialog} />
                    }
                    {step.type === 'title' &&
                        <InfoTitle key={`title${index}`} step={step} />
                    }
                </div>
            ))}
        </>
    );
};

export default InfoCards;