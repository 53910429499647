import { images } from "./images"

export const group = {
    type: 'help',
    isAdmin: false,
    icon: 'Groups',
    content: 'Csoportok',
    description: 'A csoportok kezelése rendkívül egyszerű és kényelmes az alkalmazásban, melynek során többféle funkcionalitáshoz is hozzáférhetünk.',
    steps: [
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Csoportok listája",
            "description": `
                <p>A felhasználó csoportjainak listája a főoldalon és a felhasználó profilján keresztül is elérhető:</p>
                <ul>
                    <li>
                        <strong>Főoldalon:</strong> A csoport borítóképe és neve, valamint a jelentkezők száma a jobb felső sarokban.
                    </li>
                </ul>
            `,
            image: images.dashboard2b,
            "help": null,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 15,
            "markYPercent": 90,
            "markRectangleWidth": 30,
            "markRectangleHeight": 96
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `
                        <strong>Profil oldalon:</strong> A listában bontásban szerepel:
                        <ul>
                            <li>Csoportjaim</li>
                            <li>Csoportjaim (oktatóként)</li>
                            <li>Csoportjaim (ügyintézőként)</li>
                        </ul>
            `,
            image: images.groupMembers,
            "help": null,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 78,
            "markYPercent": 90,
            "markRectangleWidth": 90,
            "markRectangleHeight": 96
        },
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Csoport adatai',
            description: `Kiválasztva a csoportot a csoport oldalára érkezünk. Az egyes funkciók füleken csoportosítva érhetőek el `,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.groups2',
            content: 'QR Kód',
            "description": `
                <p>A QR Kód fül alatt látható a csoport egyedi QR kódja, amely lehetővé teszi a gyors csatlakozást a csoportba. Egyszerűen beolvasható, és azonnal elérhetővé teszi a csoport főoldalát.</p>
            `,
            image: images.groupQR,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 12,
            "markYPercent": 34,
            "markRectangleWidth": 29,
            "markRectangleHeight": 41
        },
        {
            type: 'slide', // slide, link
            selector: '.groups3',
            content: 'Tagok',
            "description": `
                <p>A Tagok fül alatt teljes körűen megtekinthető a csoport összes tagjának listája, valamint két gomb:</p>
                <ul>
                    <li>Felhasználó felvétele (QR kóddal)</li>
                    <li>Felhasználó felvétele (vagy létrehozása e-mail címmel)</li>
                </ul>
                <p>A listában megtekinthető a csoport összes tagja:</p>
                <ul>
                    <li>Hallgatók</li>
                    <li>Oktatók</li>
                    <li>Ügyintézők</li>
                    <li>Jelentkezők</li>
                </ul>
                <p>Ez lehetőséget ad a csoport adminisztrátorainak a feliratkozások kezelésére.</p>
            `,
            image: images.groupMembers,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 32,
            "markYPercent": 34,
            "markRectangleWidth": 49,
            "markRectangleHeight": 41
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Kérdőív indítása",
            "description": `
                <p>A gyors tesztindítás lehetősége biztosítja, hogy a teszteket egy kattintással el lehessen indítani. Az indításhoz kattintsunk a kérdőív melletti gombra.</p>
            `,
            "image": images.groupQuick,
            "help": null,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 52,
            "markYPercent": 34,
            "markRectangleWidth": 69,
            "markRectangleHeight": 41
        },
        {
            type: 'slide', // slide, link
            selector: '.groups4',
            content: 'Statisztika',
            "description": `
                <p>A csoport statisztikák a csoport oldalán találhatóak, azon belül a STATISZTIKA fülön érhetőek el.</p>
                <ul>
                    <li>Az applikáció első alkalommal kérni fogja a sablonadatok frissítését, hogy a legfrissebb és legpontosabb adatokat használhassuk az elemzésekhez.</li>
                    <li>Az oldalon található két gomb: adatok frissítése és címkék szerkesztése.</li>
                    <li>Az adatok frissítésével naprakészen tarthatjuk a statisztikai adatokat.</li>
                    <li>A címkék szerkesztése gombbal módosíthatjuk a címkéket. A címkék szerkesztése lehetővé teszi, hogy testreszabjuk és finomhangoljuk a kérdések és az ezekre adott értékelések csoportosítását. Ez az eszköz rugalmasan lehetőséget biztosít az adatok elemzéséhez.</li>
                </ul>
                <p>A statisztikák öt fő kategóriába vannak osztva, amelyek lehetővé teszik az adatok részletes és átfogó elemzését:</p>
                <ul>
                    <li>Az első csoportban az aktivitással kapcsolatos adatok láthatók</li>
                    <li>A másodikban az egyes diákok értékelései érhetőek el</li>
                    <li>A harmadikban ennek az időbeli trendje</li>
                    <li>A negyedikben a csoport elemzése végezhető el</li>
                    <li>Az ötödikben találhatóak a csoport időbeli trendjei</li>
                </ul>
            `,
            image: images.groupStat,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 72,
            "markYPercent": 34,
            "markRectangleWidth": 89,
            "markRectangleHeight": 41
        },
    ]
}
