import React, { useState } from 'react';
import DashboardButton from '../../commons/buttons/DashboardButton';
import { Paper, BottomNavigation, BottomNavigationAction, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Badge, IconButton } from '@mui/material';
import { toast } from "react-toastify";
import { ContactPage, FolderDelete, HelpOutline, InstallMobile, SecurityUpdate } from '@mui/icons-material';
import Install from '../../pages/profile/components/Install';
import LicenseAgreement from './LicenseAgreement';
import ConfirmDialog from '../../commons/ConfirmDialog';
import { styled } from '@mui/system';

const StyledBottomNavigation = styled(BottomNavigation)({
    width: '100%',
    position: 'fixed',
    bottom: 0,
    "& .MuiBottomNavigationAction-root": {
        "@media (max-width: 375px)": {
            minWidth: "auto",
            maxWidth: "75px",
            padding: "3px 0"
        }
    }
});

const AppsFunctions = () => {
    const [show, setShow] = useState()
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState();
    const clearLS = () => {
        // const selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
        // const firebaseApps = localStorage.getItem('firebaseAppsV2');
        localStorage.clear();
        // localStorage.setItem('selectedApp', selectedAppName);
        // localStorage.setItem('firebaseAppsV2', firebaseApps);
        window.location.reload(true);
        toast.success(`Töröljük a mentett adatokat`);
    }
    const handleSubmit = (a) => {
        setValue(a);
        // navigate(a);
    };
    const appUpdate = () => {
        console.log('cache', caches)
        caches.keys().then((keys) => {
            keys.forEach((request, index, array) => {
                caches.delete(request);
                console.log('index', request)
            });
        });
        window.location.reload(true);
        toast.success(`Frissítjük az alkalmazást`);
    }

    const navigate = useNavigate();
    return (
        <Paper sx={{ zIndex: '1', position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <StyledBottomNavigation
                showLabels
                //   value={location.pathname}
                onChange={(event, newValue) => {
                    handleSubmit(newValue);
                }}>

                <BottomNavigationAction
                    showLabel
                    onClick={() => setShow('install')}
                    label="Applikáció telepítése" icon={
                        <InstallMobile />
                    } />

                {/* <DashboardButton
                    key={`help/documentation`}
                    label={'Súgó'}
                    icon={'HelpOutline'}
                    onClick={() => { navigate(`/help/documentation`) }}
                /> */}
                <BottomNavigationAction
                    showLabel
                    onClick={() => { navigate(`/help/documentation`) }}
                    label="Applikáció dokumentáció"
                    icon={
                        <HelpOutline />
                    } />

                <BottomNavigationAction
                    showLabel
                    onClick={() => setShow('licenseagreement')}
                    label="Felhasználási feltételek"
                    icon={
                        <ContactPage />
                    } />

                <BottomNavigationAction showLabel onClick={() => { setOpen(true) }} label="Adatok törlése" icon={<FolderDelete />} />

                <BottomNavigationAction showLabel onClick={() => { appUpdate() }} label="Applikáció frissítése" icon={<SecurityUpdate />} />
            </StyledBottomNavigation>

            <ConfirmDialog
                open={open}
                question={"Biztos végrehajtja a műveletet?"}
                handleConfirm={() => { setOpen(false); clearLS() }}
                handleCancel={() => setOpen(false)}
            />


            {show === 'install' && <Install exit={() => (setShow())} />}
            {show === 'licenseagreement' && <LicenseAgreement exit={() => (setShow())} />}

        </Paper >
    );
};

export default AppsFunctions;