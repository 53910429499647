import React, { useContext, useState } from 'react';
import BackToSignInButton from '../../components/apps/BackToSignInButton';
import AppEditor from '../../components/apps/AppEditor';
import AppsFunctions from '../../components/apps/AppsFunctions';
import AppLicense from '../../components/apps/AppLicense';
import AddApp from '../../components/apps/AddApp';
import { AppContext } from '../../services/appContext';
import DashboardButton from '../../commons/buttons/DashboardButton';
import { useNavigate } from "react-router-dom";

const Apps = () => {
    const [open, setOpen] = useState(false);
    const { firebaseApps, setFirebaseApps, version } = useContext(AppContext);
    const navigate = useNavigate();

    const createApp = (app) => {
        const apps = { ...firebaseApps, [app?.id]: app }
        setFirebaseApps(apps);
        localStorage.setItem('firebaseAppsV2', JSON.stringify(apps));
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="centerContent2">
                <p className="pageHeader">Alkalmazások </p>
                <BackToSignInButton />
                <AppEditor />
                <AppLicense version={version} />

                <div className='centerContent'>
                    <DashboardButton
                        key={`help/documentation`}
                        label={'Új alkalmazás'}
                        icon={'AddCircleOutline'}
                        onClick={handleOpen}
                    />
                    {/* <DashboardButton
                    label={'QR olvasó'}
                    icon={'CropFree'}
                    onClick={() => (navigate("/subscribe"))}
                    // isAvatar={true}
                    // bgcolor={'#9B59B6'}
                /> */}
                </div>
                <AddApp
                    storedApps={firebaseApps}
                    createApp={createApp}
                    open={open}
                    handleClose={handleClose}
                />
            </div>
            <AppsFunctions />
        </>

    );
};

export default Apps;
