import React from 'react';
import ApexCharts from 'react-apexcharts';
import { v4 as uuidv4 } from "uuid";

const ChartComponent = ({ chartData, type }) => {
  return (
    chartData && chartData.series && chartData.options &&
    <ApexCharts
      key={uuidv4()}
      type={type}
      height={350}
      options={chartData.options}
      series={chartData.series}
    />
  );
};

export default ChartComponent;
