import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../services/appContext';
import GroupQrItem from './GroupQrItem';
import { Alert, Box, FormControlLabel, Grid, Switch } from '@mui/material';
import { auth } from '../../firebase.config';
import QRCode from 'react-qr-code';

const GroupQrCodes = ({ isAdmin }) => {
    // const [viewStatusOfGroups, setViewStatusOfGroups] = useState(true);
    const { snapshots, viewStatusOfGroups, setViewStatusOfGroups } = useContext(AppContext);
    const [myGroups, setMyGroups] = useState();
    const user = auth?.currentUser?.uid;
    const email = auth?.currentUser?.email;
    const code = `${window.location.origin}/addMember/${auth?.config?.apiKey}/${user}`;
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if (snapshots && Array.isArray(snapshots['allgroups'])) {
            setMyGroups(snapshots['allgroups']);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    const handleChange = (event) => {
        setViewStatusOfGroups(event.target.checked);
    };

    return (
        <>  
            {myGroups && myGroups?.length > 0 &&
                < div className="qrcodes">
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <h2>{viewStatusOfGroups ? 'Csoportok' : 'Inaktív csoportok'}</h2>
                        <FormControlLabel control={<Switch
                            checked={viewStatusOfGroups}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Aktív" />
                    </Box>
                    <Grid container spacing={0}>
                        {myGroups &&
                            myGroups.filter(x => viewStatusOfGroups ? x?.data?.active : !x?.data?.active).map((group, index) => {
                                return (
                                    <Grid key={`grid${index}`} item xs={4}>
                                        <GroupQrItem key={`GroupQrItem${index}`} user={user} group={group} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div >
            }
            {(!loading && myGroups && myGroups?.length < 1) &&
                <>
                    <h2>Jelentkezés csoportba</h2>
                    <p>{`A csoportok adminjai a QR kód beolvasásával, vagy e-mail címeddel(${email})tudnak felvenni a csoportjukba`}</p>

                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "70%" }}>
                        <></>
                        <QRCode
                            size={512}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={code}
                            viewBox={`0 0 512 512`}
                        />
                    </div>
                </>
            }
        </>);
};

export default GroupQrCodes;