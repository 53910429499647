import Document4 from '../../hooks/document4';

const GetUser2 = () => {

    const table = 'users';

    const model = Document4({
        table: table,
    });

    const subscribe = async ({ id, setter, refresh = false, notFromCache = false }) => {
        let unsubscribe2;
        unsubscribe2 = await model.subscribe({
            id: id,
            setter: setter,
            refresh: refresh,
            notFromCache: notFromCache,
            key: 'user-' + id,
            filter: []
        });

        if (unsubscribe2) {
            // console.log('SIKERES  LEKÉRDEZÉS', unsubscribe2)
        }
        else {
            console.log('HIBA')
        }
        return unsubscribe2;
    }
    return { subscribe };
};

export default GetUser2;