import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import StorageAvatar from '../firebase/StorageAvatar';

const GroupItemMini2 = ({ id, option, meta, label, values, onClick = () => { }, showCheckbox = true }) => {
    return (
        <ListItem
            sx={{
                textAlign: "center",
                minWidth:"90%",
                alignContent: "center",
            }}
            onClick={() => { onClick(id) }}
            selected={values ? values.indexOf(id) > -1 : null}
            variant={values && values.indexOf(id) > -1 ? "contained" : "outlined"}
            color={values && values.indexOf(id) > -1 ? "primary" : "secondary"}
            secondaryAction={showCheckbox ?
                <Checkbox
                    edge="end"
                    checked={values.indexOf(id) !== -1}
                    inputProps={{ 'aria-labelledby': id }}
                /> : ''}
            disablePadding
        >
            <ListItemButton >
                <ListItemAvatar>
                    <StorageAvatar
                        value={option.icon}
                        bucket={'group_images'}
                        size='large'
                    />
                </ListItemAvatar>
                <ListItemText id={id} primary={option.name} />
            </ListItemButton>
        </ListItem >
    );
};

export default GroupItemMini2;