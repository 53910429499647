import { help6 } from "../../anonimousData/Helps";

export const chart_group5a = {
    "chartType": "BSChart",
    "defaultCategoryField": "groupAuthor",
    "defaultSeriesField": "question",
    "defaultValuesField": 'evaluated_value_normalized',
    "defaultChartType": "stackedhorizontal",
    "filterFields": [
        // { "field": 'evaluated' },
        { "field": 'question' }
    ],
    "title": "IV/6. Értékelő perspektíva szerinti csoportosítás",
    "description": "Ez a diagram az egyes felhasználók által végzett és róluk készült értékelések számát mutatja a különböző értékelő típusok szerint.",
    "help": help6
};
