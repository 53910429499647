import React from 'react';
import { Box, Typography } from '@mui/material';
import MyToggleButtonGroup from '../MyToggleButtonGroup';

const dateFilters2 = {
    minimal: {
        value: 'minimal',
        label: 'Minimális',
        controller: null,
        action: null,
        icon: 'Today'
    },
    standard:
    {
        value: 'standard',
        label: 'Standard',
        controller: null,
        action: null,
        icon: 'FilterAlt'
    },
    setup: {
        value: 'setup',
        label: 'Teljes',
        controller: null,
        action: null,
        icon: 'Tune'
    },
};
const ChartLabel = ({ label, setViewMode, defaultMode = 'minimal' }) => {
    const [viewMode, setInternalViewMode] = React.useState(dateFilters2[defaultMode]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setInternalViewMode(newValue);
        setViewMode(event);
    };
    const handleChange2 = (event) => {
        // console.log('xyz', event.value)
        setInternalViewMode(event);
        setViewMode(event.value);
    };
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="h6">{label}</Typography>
            <MyToggleButtonGroup
                selectedFilter={viewMode}
                handleFilterChange={handleChange2}
                filters={dateFilters2}
                title=''
            />
            {/* <FormControl variant="standard" size="small">
                <InputLabel id="view-mode-select-label">View Mode</InputLabel>
                <Select
                    labelId="view-mode-select-label"
                    id="view-mode-select"
                    value={viewMode}
                    onChange={handleChange}
                // label="View Mode"
                >
                    <MenuItem value="minimal">Minimál</MenuItem>
                    <MenuItem value="interactive">Interaktív</MenuItem>
                    <MenuItem value="advanced">Haladó</MenuItem>
                </Select>
            </FormControl> */}
        </Box>
    );
};

export default ChartLabel;
