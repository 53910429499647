import React from 'react';
import Typography from '@mui/material/Typography';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SortIcon from '@mui/icons-material/Sort';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';

export const helpIcons = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Ikonok és jelentésük:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            <TodayIcon /> adatok megjelenítése heti felbontásban
        </Typography>
        <Typography variant="body2" gutterBottom>
            <DateRangeIcon />adatok megjelenítése heti felbontásban
        </Typography>
        <Typography variant="body2" gutterBottom>
            <CalendarTodayIcon />adatok megjelenítése heti felbontásban
        </Typography>
        <Typography variant="body2" gutterBottom>
            <SortIcon />adatok rendezése kategória szerint
        </Typography>
        <Typography variant="body2" gutterBottom>
            <CropRotateIcon />kategória és sorozatok felcserélése
        </Typography>
        <Typography variant="body2" gutterBottom>
            <BarChartIcon />váltás oszlopdiagram nézetre
        </Typography>
        <Typography variant="body2" gutterBottom>
            <ShowChartIcon />váltás vonaldiagram nézetre
        </Typography>
    </div>
);

export default helpIcons;
