import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

const GoBackButton = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    return (
        <div
            className="backIconDiv"
            onClick={() => {
                goBack()
            }}
        >
            <ArrowBack />
        </div>
    );
};

export default GoBackButton;