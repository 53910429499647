import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import View from './View';
import SubscribeAction from '../../services/actions/SubscribeAction';
import { db, auth, storage } from "../../firebase.config";
import Query from '../../hooks/query';
import { toast } from "react-toastify";
import { getKeysFromArray } from "../../commons/Utility";
import { AppContext } from '../../services/appContext';
import Spinner from '../../components/Spinner';

const SubscribeToGroupController = () => {
    const { queries, snapshots, isLoading, loadings, setLoading } = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const { groupId } = params;
    const user = auth.currentUser ? auth.currentUser : { uid: null }
    const userId = user.uid;
    const [myGroups, setMyGroups] = useState(null);
    const [mySubscribed, setMySubscribed] = useState(null);
    const [isMember, setIsMember] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(null);
    const { subscribe } = SubscribeAction();
    const [profile, setProfile] = useState(null);


    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
    }, [snapshots['profile']]);

    useEffect(() => {
        console.log('profile', profile);
        setIsMember(profile?.groups?.indexOf(groupId) > -1);
        setIsSubscribed(profile?.subscribed?.indexOf(groupId) > -1);
    }, [profile]);

    const subscribeToGroup = async () => {
        const a = await subscribe({ auth: auth, groupId: groupId });
        if (a) {
            toast.success(`Sikeres jelentkezés`);
            navigate("/profile");
        }
        else {
            toast.error(`Hiba történt a jelentkezésnél`);
        }
    }

    return (
        <>
            {(isMember != null && isSubscribed != null) ?
                <View
                    groupId={groupId}
                    subscribeToGroup={subscribeToGroup}
                    isMember={isMember}
                    isSubscribed={isSubscribed}
                /> : <Spinner />

            }
        </>
    );
};

export default SubscribeToGroupController;