import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import LabelItem from './LabelItem';

const LabelsView = ({ localLabels, localQuestions, setLocalQuestions, newQuestionLabelId, setNewQuestionLabelId, newQuestionId, setNewQuestionId }) => {
    return (
        <>
            {Object.keys(localLabels).map(labelId => {
                const label = localLabels[labelId];
                const availableQuestions = Object.keys(localQuestions).filter(questionId => !localQuestions[questionId]?.labels?.includes(labelId));
                return (
                    <Paper key={labelId} sx={{ p: 2, mb: 2 }}>
                        <Typography variant="h6">{label.name}</Typography>
                        <Box>
                            <LabelItem
                                labelId={labelId}
                                localQuestions={localQuestions}
                                setLocalQuestions={setLocalQuestions}
                                availableQuestions={availableQuestions}
                                newQuestionLabelId={newQuestionLabelId}
                                setNewQuestionLabelId={setNewQuestionLabelId}
                                newQuestionId={newQuestionId}
                                setNewQuestionId={setNewQuestionId}
                            />
                        </Box>
                    </Paper>
                );
            })}
        </>
    );
};

export default LabelsView;
