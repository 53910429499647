import React from 'react';
import coverPhoto from "../../assets/jpg/questionnaire1.jpg";
import GoBackButton from '../../commons/GoBackButton';
import QuestionnaireCover from '../../components/questionnaire/QuestionnaireCover';
import { createBaseData } from '../../components/charts/CChartConstats';
import ResultCards from '../../components/cards/ResultCards';
import BarCharts from './components/BarCharts';
import PieCharts from './components/PieCharts';
import Header from './components/Header';
import Test from './components/Test';
import PieCharts1 from './components/PieCharts1';
import StateCharts from './components/StateCharts';
import EvaluationValues from '../../components/evaluations/EvaluationValues';
import EvaluationsValues from '../../components/evaluations/EvaluationsValues';
import { groupingData, groupingData2D } from '../../components/charts/CChartConstats';
import { Avatar, Badge, BottomNavigationAction, Grid, Tab, Tabs } from '@mui/material';
import { Summarize, TableView, BarChart } from '@mui/icons-material';
const View = ({
    profile, questionnaire, myUserData, evaluation, group, groups, id, stat }) => {

    const groupUrl = `https://kerdoiv.b612.hu/subscribe/${group?.id}`;
    const values = evaluation?.evaluations;
    const template = questionnaire?.qtemplate ?? {};
    const meta = template?.meta;
    const scales = meta?.scales;
    const questions = meta?.questions;
    const labels = meta?.labels;
    const templateId = questionnaire?.template + '-week';
    const templateId2 = questionnaire?.template + '-month';
    const userData = myUserData ? myUserData[0]?.data : null;
    const templateData = userData && userData[templateId] ? userData[templateId]?.result : null;
    const templateData2 = userData && userData[templateId2] ? userData[templateId2]?.result : null;
    const notZero = templateData?.filter(x => Number(x['average_evaluated_value']) != 0);
    const isScale = notZero?.length > 0;
    // const base = createBaseData(evaluation?.evaluations, meta);
    const [tabvalue, setTabValue] = React.useState(0);
    const handleChange = (event, newValue) => { setTabValue(newValue); };
    // console.log('base', base);
    // console.log('userData', userData);
    // console.log('values', values);
    // console.log('templateData', templateData);
    // console.log('templateData2', templateData2);

    return (
        <div className="profile">
            {/* <GoBackButton /> */}
            <QuestionnaireCover
                mini={true}
                coverPhoto={coverPhoto}
                group={group}
                groupUrl={groupUrl}
                name={template?.description}
                questionnaire={questionnaire}
                profile={profile}
            />
            <div className="listingDetails">
                <Header
                    questionnaire={questionnaire}
                    template={template}
                    group={group}
                />
                <Tabs
                    // variant="scrollable"
                    allowScrollButtonsMobile
                    // scrollButtons="auto"
                    value={tabvalue}
                    variant="fullWidth"
                    onChange={handleChange}
                    // showlabels={false}
                    aria-label="icon tabs example"
                >
                    <Tab icon={<Summarize />} label="Összefoglalás" />
                    <Tab icon={<TableView />} label="Eredmények" />
                    <Tab icon={<BarChart />} label="Diagramok" />

                </Tabs>
                {tabvalue === 0 &&
                    <>
                        <ResultCards
                            values={values}
                            templateData={templateData}
                            stat={stat}
                            meta={meta}
                        />
                        <StateCharts
                            stat={stat}
                            questionnaire={questionnaire}
                            group={group}
                        />
                    </>
                }
                {tabvalue === 2 &&
                    <>
                        {/* <PieCharts1
                            stat={stat}
                            base={base}
                            meta={meta}
                            questionnaire={questionnaire}
                        /> */}
                        {isScale &&
                            <>
                                <BarCharts
                                    templateData={templateData}
                                    templateData2={templateData2}
                                    questions={questions}
                                    groups={groups}
                                    meta={meta}
                                />
                                <PieCharts
                                    meta={meta}
                                    group={group}
                                    templateData={templateData}
                                    questionnaire={questionnaire}
                                />
                            </>
                        }
                    </>
                }
                {tabvalue === 1 &&
                    <>
                        {stat && questionnaire && questionnaire?.qtemplate && scales &&
                            <EvaluationValues
                                questions={questions}
                                scales={scales}
                                values={stat.values}
                            />
                        }
                        <EvaluationsValues
                            questions={questions}
                            scales={scales}
                            values={values}
                        />
                        <Test
                            templateData2={templateData2}
                            meta={meta}
                            groups={groups}
                            questions={questions}
                            labels={labels}
                        />
                    </>
                }

            </div>
        </div>
    );
};

export default View;