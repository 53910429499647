import React from 'react';
import { helps } from './data/helps';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardButton from '../buttons/DashboardButton';

const HelpIcons = ({ steps }) => {
    const help = helps[steps];
    const navigate = useNavigate();

    return (
        <div className="qrcodes">
            <h2>Súgók</h2>
            <Grid container spacing={0}>
                {help?.steps?.filter((x) => x.type === 'link').slice(0, 7).map((step, index) => (
                    <DashboardButton
                        key={`link${index}`}
                        label={step.short}
                        icon={step?.icon}
                        onClick={() => { navigate(`/help/${step.link}`) }}
                    />
                ))}
                <DashboardButton
                    key={`help/documentation`}
                    label={'Több'}
                    icon={'MoreHoriz'}
                    onClick={() => { navigate(`/help/documentation`) }}
                />
            </Grid>
        </div>
    );
};

export default HelpIcons;