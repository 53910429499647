import React, { useContext } from 'react';
import { apps } from '../../data/apps';
import { BottomNavigation, BottomNavigationAction, Grid } from '@mui/material';
import { AppContext } from '../../services/appContext';
import { initializeFirebaseApp } from '../../firebase.config';
import AppIcon from './AppIcon';

const AppSelector = () => {
    // const storedApps = apps;
    const { selectedApp, setSelectedApp, firebaseApps, setFirebaseApps } = useContext(AppContext);
    const storedApps = firebaseApps ?? apps;
    const setApp = (app) => {
        setSelectedApp(app);
        localStorage.setItem('selectedApp', app);
        initializeFirebaseApp();
    }

    return (
        <div>
            {storedApps &&
                <Grid container spacing={1} justifyContent="center">
                    {Object.keys(storedApps).map((appName, index) => (
                        <Grid item key={index} style={{ maxWidth: 100 }}>
                            <BottomNavigation
                                style={{ background: 'transparent', marginBottom: '1em' }}
                                showLabels
                                value={selectedApp}
                                onChange={(event, newValue) => {
                                    setApp(newValue)
                                }}>
                                <BottomNavigationAction
                                    label={storedApps[appName].label}
                                    value={appName}
                                    style={{ color: selectedApp === appName ? "#00cc66" : '' }}
                                    sx={{
                                        bgcolor: '#daaaa',
                                    }}
                                    icon={
                                        <AppIcon icon={storedApps[appName]?.logo} active={selectedApp === appName} />
                                        // <CIcon icon={storedApps[appName].icon} active={selectedApp == appName} />
                                    } />
                            </BottomNavigation>
                        </Grid>
                    ))}
                </Grid>
            }
        </div>
    );
};

export default AppSelector;