import { images } from "./images"

export const schedules = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Időzített kérdőívek',
    description: `Az időzített kérdőívek egy eszköz a csoportok kérdőíveinek időzített készítésére és kezelésére. Ezt a funkciót a csoportok oldalán, a beállítások menüpont alatt, az időzítések fülön találhatják meg a felhasználók. Az eszközt a csoport ügyintézők és az intézeti adminisztrátorok használhatják. 
`,
    steps: [
        {
            type: 'title', // slide, link
            selector: '.dashboard1',
            content: 'Időzített kérdőívek megtekintése',
            description: 'Az időzítés funkció egy olyan automatizált eszköz, amely lehetőséget biztosít a felhasználóknak arra, hogy előre beállítsák és automatizálják a kérdőívek készítését csoportjuk számára. Ennek révén a felhasználók naponta meghatározott időpontokban, (például: hétfő, 9:00), automatikusan létrehozhatják és elkészíthetik a kérdőíveket. Ez a funkció jelentősen megkönnyíti a felhasználók számára a kérdőívek kezelését, mivel nem szükséges manuálisan beállítani és indítani minden egyes kérdőívet.',
            // image: images.dashboard2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás a beállításokhoz',
            description: 'Lépjen a Csoport oldalára. Válassza ki a jobb alsó sarokban lévő Beállítások ikont.',
            image: images.mygroupsUsersList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 83,
            markYPercent: 85,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            animations: [
                { name: 'cursor', action: 'move' },
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás az időzítésekhez',
            description: `Kattintson az időzítések fülre. Itt láthatja az összes meglévő 
    időzített kérdőívet.`,
            image: images.schedulesList,
            help: null,
            mark: 'rect',
            markRadius: 20,
            markXPercent: 65,
            markYPercent: 32,
            markRectangleWidth: 88,
            markRectangleHeight: 40,
            animations: [
                { name: 'cursor', action: 'move' },
            ]
        },
        {
            type: 'title', // slide, link
            selector: '.dashboard1',
            content: 'Új időzítés létrehozása',
            description: 'Új időzítés hozzáadásához kövesse az alábbi lépéseket:',
            // image: images.dashboard2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Új időzítés',
            description: 'Kattintson az "Időzítés készítés" gombra, amely a jobb alsó sarokban található plusz ikon.',
            image: images.schedulesList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 83,
            markYPercent: 85,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Időzítés szerkesztése',
            description: 'Ekkor megnyílik az időzítések szerkesztő felülete egy felugró ablakban, amely lehetővé teszi az új időzítés adatainak szerkesztése.',
            image: images.schedulesCreate,
            help: null,
            // mark: 'rect',
            // markRadius: 30,
            // markXPercent: 11,
            // markYPercent: 73,
            // markRectangleWidth: 89,
            // markRectangleHeight: 77,
            // animations: [
            //     { name: 'cursor', action: 'move' }
            // ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Időzítés adatainak szerkesztése',
            description: `Az időzítések szerkesztő felületén a következő adatokat kell megadni:
            <ul>
                <li>Kezdés: Válassza ki az időzítés kezdési dátumát és idejét.</li>
                <li>Befejezés: Adja meg az időzítés befejezési dátumát és idejét.</li>
                <li>Nap: Válassza ki a hét napját.</li>
                <li>Időpont: Adja meg a pontos időpontot.</li>
                <li>Státusz: Válassza ki az időzítés státuszát (pl. Aktív, Befejezve, stb.).</li>
                <li>Csoport: Válassza ki a megfelelő csoportot (ez automatikusan kitöltődik az aktuális csoport alapján).</li>
                <li>Sablon: Válassza ki a használni kívánt sablont.</li>
            </ul>`,
            image: images.schedulesCreate2,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 12,
            markYPercent: 13,
            markRectangleWidth: 86,
            markRectangleHeight: 70,
            // animations: [
            //     { name: 'cursor', action: 'move' }
            // ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Dátumok szerkesztése',
            description: `Az időzítésekhez kapcsolódó dátumokat a szerkesztő felület segítségével szerkesztheti:
A szerkesztő felületen találja az időzítés elemeit.
Az egyes elemek melletti szerkesztés ikonra kattintva nyithatja meg a szerkesztési űrlapot.
A dátumot és időt módosíthatja, majd mentse el a változtatásokat.
`,
            image: images.schedulesEdit,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 72,
            markYPercent: 76,
            markRectangleWidth: 89,
            markRectangleHeight: 77,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Dátum szerkesztése',
            description: 'Ekkor megnyílik az időzítések szerkesztő felülete egy felugró ablakban, amely lehetővé teszi az új időzítés dátumának szerkesztése.',
            image: images.schedulesDateEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 30,
            markYPercent: 48,
            markRectangleWidth: 71,
            markRectangleHeight: 53,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'title', // slide, link
            selector: '.dashboard1',
            content: 'Időzítés szerkesztése',
            description: 'Egy meglévő időzítés szerkesztéséhez:',
            // image: images.dashboard2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Időzítés kiválasztása',
            description: 'Az ütemezés listájában kattintson a szerkeszteni kívánt időzítésre.',
            image: images.schedulesList,
            help: null,
            mark: 'rect',
            markRadius: 20,
            markXPercent: 11,
            markYPercent: 47,
            markRectangleWidth: 83,
            markRectangleHeight: 57,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Időzítés adatainak szerkesztése',
            description: `Az időzítések szerkesztő felületén a következő adatokat kell megadni:
            <ul>
                <li>Kezdés: Válassza ki az időzítés kezdési dátumát és idejét.</li>
                <li>Befejezés: Adja meg az időzítés befejezési dátumát és idejét.</li>
                <li>Nap: Válassza ki a hét napját.</li>
                <li>Időpont: Adja meg a pontos időpontot.</li>
                <li>Státusz: Válassza ki az időzítés státuszát (pl. Aktív, Befejezve, stb.).</li>
                <li>Csoport: Válassza ki a megfelelő csoportot (ez automatikusan kitöltődik az aktuális csoport alapján).</li>
                <li>Sablon: Válassza ki a használni kívánt sablont.</li>
            </ul>`,
            image: images.schedulesCreate2,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 12,
            markYPercent: 13,
            markRectangleWidth: 86,
            markRectangleHeight: 70,
            // animations: [
            //     { name: 'cursor', action: 'move' }
            // ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Időzítés törlése',
            description: 'Egy időzítés törléséhez kattintson a törlés ikonra ("X" ikon) az időzítés mellett az ütemezés listájában. Ezt követően erősítse meg a törlési szándékát a megjelenő megerősítő ablakban.',
            image: images.schedulesList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 80,
            markYPercent: 53,
            markRectangleWidth: 83,
            markRectangleHeight: 57,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Időzítés futtatása',
            description: `Lehetőség van az időzítés kézi futtatására. Egy időzítés elindításához kattintson a futtatás ikonra ("⮞" ikon) az időzítés mellett az ütemezés listájában. Ezt követően erősítse meg a szándékát a megjelenő megerősítő ablakban.`,
            image: images.schedulesList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 71,
            markYPercent: 53,
            markRectangleWidth: 83,
            markRectangleHeight: 57,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
    ]
}
