import React, { useState, useEffect } from 'react';
import { TextField, Switch, FormControlLabel, Button, Box } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Spinner from '../../../components/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateGroupAction from '../../../services/actions/CreateGroupAction';

const CreateGroupForm = ({ open, setOpen }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(true);
    const [isModified, setIsModified] = useState(false);
    const { subscribe } = CreateGroupAction();

    useEffect(() => {
        setIsModified(name?.length > 8);
    }, [name]);

    const handleSave = async () => {
        setLoading(true);
        try {
            await subscribe({ name, active });
            setOpen(false);
            setLoading(false);
            toast.success('Mentés sikeres!');
        } catch (error) {
            toast.error('Mentés sikertelen!');
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            {loading &&
                <Spinner />
            }
            <DialogTitle>Új csoport</DialogTitle>
            <ToastContainer />
            <DialogContent>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Csoport neve"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={name.length > 0 && name.length < 6}
                        helperText={name.length > 0 && name.length < 6 ? "Min. 6 karakter hosszúnak kell lennie" : ""}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                            />
                        }
                        label="Aktív"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    // disabled={name.length < 6 }
                    onClick={handleCancel}
                >
                    Mégsem
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={name.length < 6 }
                    onClick={handleSave}
                >
                    Mentés
                </Button>

            </DialogActions>
        </Dialog >
    );
};

export default CreateGroupForm;
