import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const CreateFCMessage = () => {
    const { createDocument } = useContext(AppContext);

    const subscribe = async ({ name, icon, url }) => {
        const newRecord = {
            name: name,
            icon: icon,
            url: url,
        }

        const result = await createDocument({ table: 'messages', record: newRecord })
        return result;
    }

    return {
        subscribe
    }
};

export default CreateFCMessage;