import React, { useEffect, useState } from 'react';

const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState({});

    const setSubscription = ({ key, value }) => {
        setSubscriptions((prevState) => ({
            ...prevState,
            [`${key}`]: value,
        }));
    };
    const deleteSubscription = ({ key }) => {
        setSubscriptions((prevState) => {
            const { [key]: deleted, ...rest } = prevState;
            return rest;
        });
    }

    return {
        subscriptions,
        setSubscription,
        deleteSubscription
    };
};

export default Subscriptions;