export const formQuick = {
    name: 'Kérdőív készítés',
    description: 'Az értékelő kérdőív készítés modul lehetővé teszi az oktatók számára, hogy értékeléseket indítsanak el. Az oktató értékelő lap és kurzus kiválasztásával elindíthatja az értékelési folyamatot.',
    "section": [
        {
            "name": 'Kérdőív adatok',
            "fields": [
                {
                    "label": "Sablon",
                    "required": "true",
                    "fields": {},
                    "_id": "item_1693774737428",
                    "helper": "Kérem válassza ki a megfelelő kérdés sablont!",
                    "indexFields": [],
                    "name": "template",
                    "type": "document_select_template",
                    "updatefields": [],
                    "model": "qtemplates",
                    "valuesKey": "id",
                    "valuesLabel": "name"
                }
            ]
        }
    ]
}