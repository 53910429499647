import Document4 from '../../hooks/document4';

const GetQTemplate = () => {

    const table = 'qtemplates';

    const model = Document4({
        table: table
    });

    const subscribe = async ({ id, setter, refresh, notFromCache }) => {
        let unsubscribe2;
        unsubscribe2 = await model.subscribe({
            id: id,
            setter: setter,
            refresh: false,
            notFromCache: false,
            key: 'qtemplates-' + id,
            filter: [{
                field: "id",
                operation: '==',
                data: id,
            }]
        });

        if (unsubscribe2) {
            // console.log('SIKERES  LEKÉRDEZÉS', unsubscribe2)
        }
        else {
            console.log('HIBA')
        }
        return unsubscribe2;
    }
    return { subscribe };
};

export default GetQTemplate;