import React from 'react';
import { Accordion, AccordionSummary, Chip, Rating } from '@mui/material';
import { ArrowBack, ExpandMore } from '@mui/icons-material';
import EvaluationValues from './EvaluationValues';
import EvaluationEmotion from './EvaluationEmotion';

const EvaluationsValues = ({ values, questions, scales }) => {
    const [expanded, setExpanded] = React.useState('panel000');

    const handleChange = (panel) => (event, isExpanded) => {
        // console.log(panel);
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            {values && Object.keys(values).map((id, index) => {
                const evaluationValues = values[id]?.values;
                return (
                    <Accordion
                        key={index}
                        expanded={expanded === 'panel' + index}
                        onChange={handleChange('panel' + index)}
                    >
                        <AccordionSummary
                            key={`AccordionSummary${index}`}
                            expandIcon={<ExpandMore key={`ExpandMore${index}`}/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <h3 key={`h3${index}`}>Értékelés {index + 1}.</h3>
                            <EvaluationEmotion key={`EvaluationEmotion${index}`} value={values[id]?.emotional} />
                        </AccordionSummary>
                        <EvaluationValues
                            key={`EvaluationValues${index}`}
                            questions={questions}
                            scales={scales}
                            values={evaluationValues}
                        />
                    </Accordion>
                )
            })
            }
        </div>
    );
};

export default EvaluationsValues;