import { functions, httpsCallable } from '../../firebase.config';

const GetGroupAnonymousEvaluated = async () => {
    const getFunction = httpsCallable(functions, 'getGroupAnonymousEvaluated');

    const hasOneDayPassed = (timestamp) => {
        const currentDate = new Date();
        const inputDate = new Date(timestamp * 1000); // A timestamp másodpercekben van, ezért szorozzuk 1000-rel

        // Különbség az időpontok között milliszekundumban
        const timeDifference = currentDate - inputDate;

        // Átalakítás napokra
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

        // Ellenőrzés, hogy eltelt-e egy nap
        return timeDifference >= oneDayInMilliseconds;
    };

    const getData = async (group, refresh = false) => {
        const old = JSON.parse(localStorage.getItem('groupAnonymousEvaluated-' + group));
        if (!refresh && old?.date && !hasOneDayPassed(old?.date)) {
            console.log('not refresh stat');
            return old;
        } else {
            console.log('refresh stat');
            try {
                const result = await getFunction({
                    group: group,
                });
                console.log('Firebase Function Response:', result?.data);
                const newData = {
                    date: new Date(),
                    data: result?.data
                };
                localStorage.setItem('groupAnonymousEvaluated-' + group,
                    JSON.stringify(
                        newData
                    ));
                return newData;
            } catch (error) {
                console.error('Error calling Firebase Function:', error);
                throw error;
            }
        }

    };
    return { getData };
};

export default GetGroupAnonymousEvaluated;