import { ArrowBackIosNew, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackToSignInButton = ({ steps }) => {
    const navigate = useNavigate();
    return (
        <div className="tour">
            <Button onClick={() => navigate(`/sign-in`)} ><Close color="disabled" /></Button>
        </div>
    );
};

export default BackToSignInButton;