import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';

const MultiSelectDirection = ({ selectedAxes, handleAxesChange }) => {
    return (
        <FormControl>
            <InputLabel id="axes-label">Értékelési szempontok</InputLabel>
            <Select
                multiple
                value={selectedAxes}
                onChange={handleAxesChange}
                renderValue={(selected) => selected.map((axis) => ({
                    'self': 'Önértékelés',
                    'evaluator': 'Értékelő',
                    'evaluated': 'Értékelt',
                    'other': 'Egyéb',
                }[axis])).join(', ')}
                style={{ minWidth: '300px', maxWidth: '90vw' }}
            >
                {['self', 'evaluator', 'evaluated', 'other'].map((axis) => (
                    <MenuItem key={axis} value={axis}>
                        <Checkbox checked={selectedAxes.indexOf(axis) > -1} />
                        <ListItemText primary={{
                            'self': 'Önértékelés',
                            'evaluator': 'Értékelő',
                            'evaluated': 'Értékelt',
                            'other': 'Egyéb',
                        }[axis]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectDirection;