import React from "react";
import { Link } from "react-router-dom";
import { getStringDate } from "../../commons/Utility";
import StampImage from "../firebase/StampImage";

const QuestionnaireItemList = ({ show = true, questionnaire, group, selectItem, profile }) => {
    const id = questionnaire.id;
    const started = JSON.parse(localStorage.getItem('sampling-' + questionnaire?.id));

    return (
        <>
            {show && questionnaire && group && (
                <li className="categoryListing">
                    <Link to={`/${selectItem}/${id}`} className="categoryListingLink">
                        {group.Profilkép && (
                            <div >
                                <StampImage
                                    value={group.Profilkép}
                                    bucket={'group_images'}
                                    className="categoryListingImg"
                                    size="xlarge"
                                    variant="rounded"
                                    status={started ? 'progress' : 'new'}
                                    statusText={started ? 'elkezdve' : 'új'}
                                />
                            </div>
                        )}

                        <div className="ecolumn2">
                                <div>
                                    {getStringDate(questionnaire?.data?.from).substring(5, 10)}
                                </div>
                                <div style={{ color: "#999999" }}>
                                    {getStringDate(questionnaire?.data?.from).substring(0, 4)}
                                </div>
                        </div>

                        <div className="ecolumn3">
                            <div className="categoryListingName">
                                {group?.name}
                            </div>
                            <div className="categoryListingLocation">
                                {questionnaire?.data?.qtemplate?.description ?? questionnaire?.data?.description}
                            </div>
                        </div>
                    </Link>
                </li>
            )}
        </>
    );
};

export default QuestionnaireItemList;
