import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { auth, db, initializeFirebaseApp } from "../../firebase.config";
import OAuth from "../../components/OAuth";
import { ReactComponent as ArrowRightIcon } from "../../assets/svg/keyboardArrowRightIcon.svg";
import visibilityIcon from "../../assets/svg/visibilityIcon.svg";

import React from "react";
import AppSelector from "../../components/apps/AppSelector";
import { Grid } from "@mui/material";
import NextButton from "../../components/buttons/NextButton";
import { AppContext } from "../../services/appContext";
import { apps } from "../../data/apps";

function SignUp() {
  // Component Level State
  const [showPassword, setShowPassword] = useState(false);
  const { selectedApp, setSelectedApp } = useContext(AppContext);
  const storedApps = apps;
  /*
    Form Data state -> object
    Email password as state
*/
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  // Destructure formData
  const { name, email, password } = formData;

  // Initialize the navigate
  const navigate = useNavigate();

  // Initialize the function -> update form data state
  const onChange = (e) => {
    // Update the formData state
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    // console.log(formData);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{6,}$/;
  return passwordRegex.test(password);
};
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validáció
    if (name.length < 6) {
      toast.error("A névnek legalább 6 karakter hosszúnak kell lennie.");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Érvénytelen email formátum.");
      return;
    }

    if (!validatePassword(password)) {
      toast.error("A jelszónak legalább 6 karakter hosszúnak kell lennie, és tartalmaznia kell számot és speciális karaktert.");
      return;
    }

    try {
      // const auth = getAuth();
      // Register user; returns promise
      initializeFirebaseApp();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Get user info
      const user = userCredential.user;

      updateProfile(auth.currentUser, {
        displayName: name,
      });
      // Copy Form Data State to new object (don't want to change the form data state)
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp(); // Add timestamp on add to server

      //   Return promise
      await setDoc(doc(db, "users", user.uid), formDataCopy);
      //   console.table = formDataCopy;
      // redirect user
      navigate("/");
      console.log("User registered successfully");
    } catch (error) {
      //   console.log(error);
      toast.error("Regisztráció közben hiba lépett fel. Lehet, hogy ez az e-mail cím már regisztrálva van a rendszerben. Kérjük, próbálja meg újra, vagy válassza a 'Elfelejtett jelszó' lehetőséget.");
    }
  };

  return (
    <>
      <div className="pageContainer">
        <div className="loginLayout">
          <header className="logoHeader">
            <img
              // alt={translate('education_2030')}
              // className={classes.logo}
              src={storedApps[selectedApp].logo || '/logo.png'}
              width={100}
            />
            <p className="pageHeader">{storedApps[selectedApp].label}</p>
            <AppSelector />
          </header>
          <div className="profileList">
            <form onSubmit={onSubmit}>
              <input
                type="text"
                className="nameInput"
                placeholder="Név"
                id="name"
                email={name}
                onChange={onChange}
              />
              <input
                type="email"
                className="emailInput"
                placeholder="Email"
                id="email"
                email={email}
                name="email"
                onChange={onChange}
              />
              <div className="passwordInputDiv">
                <input
                  type={showPassword ? "text" : "password"}
                  className="passwordInput"
                  placeholder="Jelszó"
                  id="password"
                  value={password}
                  name="password"
                  onChange={onChange}
                />

                <img
                  src={visibilityIcon}
                  alt="Show Password"
                  className="showPassword"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {/* <div className="signUpBar">
              <p className="signUpText">Regisztráció</p>
              <button className="signUpButton">
                <ArrowRightIcon
                  fill="#fffff"
                  width="34px"
                  height="34px"
                ></ArrowRightIcon>
              </button>
            </div> */}
              <NextButton
                name={'Regisztráció'}
              // className={option.className ?? className}
              // onClick={() => { typeof option.onClick === 'function' ? option.onClick() : navigate(option.link) }}
              // disabled={!values[field.name] || (Array.isArray(values[field.name]) && values[field.name].length < 1)}
              />
            </form>


            {/* <OAuth /> */}
            <div style={{ paddingTop: 30 }}>
              <Grid container>
                <Grid item xs>
                  <Link to="/sign-in" className="registerLink2">
                    Bejelentkezés
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/forgot-password" className="registerLink2">
                    Elfelejtett jelszó
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
