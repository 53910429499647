import React from 'react';

const EvaluationItem = ({ evaluation }) => {
    // console.log('fsfdsfs evaluation', evaluation)
    const labels = {
        admins: 'Oktatók ért.',
        members: 'Hallgatók ért.',
        360: '360 fokos ért.',
        self: 'Önért.',
        project: 'Projekt ért.',
    }
    return (<>
        {evaluation &&
            <span className="discountPrice">
                {labels[evaluation]}
            </span>
        }
    </>
    );
};

export default EvaluationItem;