import React from 'react';
import Icons from '../../commons/MIIcons';

const customIcons = {
    1: 'SentimentVeryDissatisfied',
    2: 'SentimentDissatisfied',
    3: 'SentimentSatisfied',
    4: 'SentimentSatisfiedAlt',
    5: 'SentimentVerySatisfied'
};
const colors = {
    1: 'error',
    2: 'error',
    3: 'warning',
    4: 'success',
    5: 'success'
}

const ProgressEmotion = ({ title, value, big = false }) => {
    const IconComponent = Icons[customIcons[value]];
    return (
        <div className={'centerContent2'} >
            <div className={' progressEmotion3'}>
                {/* <EvaluationEmotion big={big} value={value} /> */}
                <IconComponent sx={{ maxHeight: '240px',maxWidth: '240px',fontSize: '45vw' }} color={colors[value]} />
            </div>
            <div className="progressEmotion4">{title}</div>

        </div >
    );
};

export default ProgressEmotion;