import React, { useEffect, useState } from 'react';
import bedIcon from "../../assets/svg/bedIcon.svg";
import bathtubIcon from "../../assets/svg/bathtubIcon.svg";
import { AcUnit, AddAPhoto, ExitToApp, Person, PersonAdd, Subscriptions, Unsubscribe } from '@mui/icons-material';
import StorageAvatar from '../firebase/StorageAvatar';
import CButton from '../buttons/CButton';
import { toast } from 'react-toastify';
import SubscribeAction from '../../services/actions/SubscribeAction';
import DeleteSubscribeAction from '../../services/actions/DeleteSubscribeAction';
import DeleteMemberAction from '../../services/actions/DeleteMemberAction';
import { Badge, Stack } from '@mui/material';
import CButton2 from '../buttons/CButton2';

const GroupHeader = ({ user, groupId, group, instructor, coverPhoto }) => {
    const [loading, setLoading] = useState(false);
    const { subscribe } = SubscribeAction();
    const { deleteAction } = DeleteSubscribeAction();
    const { deleteMemberAction } = DeleteMemberAction();
    const isMember = group?.Members?.indexOf(user) > -1;
    const isAdmin = group?.admins?.indexOf(user) > -1;
    const isSubscribed = group?.subscribed?.indexOf(user) > -1;

    const subscribeToGroup = async ({ groupId }) => {
        setLoading(true);
        const a = await subscribe({ groupId: groupId });
        if (a) {
            toast.success(`Sikeres jelentkezés`);
        }
        else {
            toast.error(`Hiba történt a jelentkezésnél`);
        }
    }

    const deleteSubscribeToGroup = async ({ groupId }) => {
        setLoading(true);
        const a = await deleteAction({ groupId: groupId });
        if (a) {
            toast.success(`Sikeres jelentkezés`);
        }
        else {

            toast.error(`Hiba történt a jelentkezésnél`);
        }
    }

    useEffect(() => {
        setLoading(false);
    }, [group]);

    return (
        <>
            {/* <p className="listingDetailsList">
                Oktató: {instructor?.name}
            </p>
            <p className="listingDetailsList">
                E-mail: {instructor?.email}
            </p> */}


            {/* <Grid className="profileButton" item xs={3}>
                    <BottomNavigationAction
                        showLabel
                        // onClick={() => { setShow('profile') }}
                        label="Új profilkép"
                        icon={
                            <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
                                <AddAPhoto />
                            </Avatar>
                        } />
                </Grid> */}
            <CButton2
                name={'Jelentkezem'}
                color={'#1ABC9C'}
                icon={<Subscriptions />}
                variant={'contained'}
                disabled={!group || loading || isMember}
                // confirm={true}
                onClick={() => { subscribeToGroup({ groupId: groupId }) }}
                show={!isSubscribed && !isMember}
            />
            <CButton2
                name={'Leiratkozom'}
                color={'#1ABC9C'}
                icon={<Unsubscribe />}
                variant={'contained'}
                disabled={loading || isMember}
                confirm={true}
                onClick={() => { deleteSubscribeToGroup({ groupId: groupId }) }}
                show={isSubscribed}
            />
            <CButton2
                name={'Kilépek'}
                color={'#1ABC9C'}
                icon={<ExitToApp />}
                variant={'outlined'}
                disabled={loading || isSubscribed}
                confirm={true}
                onClick={() => { deleteMemberAction({ groupId: groupId }) }}
                show={isMember}
            />
        </>
    );
};

export default GroupHeader;