import { Apps } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppsButton = ({ steps }) => {
    const navigate = useNavigate();
    return (
        <div className="tour">
            <Button onClick={() => navigate(`/apps`)} ><Apps color="disabled" /></Button>
        </div>
    );
};

export default AppsButton;