import React, { useState } from 'react';
import PopUp from '../../../../../../../commons/PopUp';
import ChartLabel from '../../../../anonimousData/ChartLabel';
import { Typography, Button } from '@mui/material';

const HeaderSimple3 = ({ helpData, help, setViewMode }) => {
    const { title, description, analysisAndInterpretation, chartDescription } = helpData;
    const [showDetails, setShowDetails] = useState(false);
    const { horizontalAxis, verticalAxis, series, filters } = chartDescription;
    return (
        <>
            <ChartLabel label={title} setViewMode={(x) => { setViewMode(x) }} />


            <Typography variant="body1">
                {description}

                {!showDetails && (
                    <Button variant="text" color="info" onClick={() => setShowDetails(true)}>
                        További részletek...
                    </Button>
                )}
                {showDetails && (
                    <>
                        <h4>Kérdés</h4>
                        <p>{analysisAndInterpretation.question}</p>
                        <h4>Diagram leírása</h4>
                        <ul>
                            <li><strong>Vízszintes tengely:</strong> {horizontalAxis}</li>
                            <li><strong>Függőleges tengely:</strong> {verticalAxis}</li>
                            {series && (
                                <li><strong>Sorozatok:</strong> {series}</li>
                            )}
                            {filters && filters !== 'Nincs szűrő' && (
                                <li><strong>Szűrők:</strong> {filters}</li>
                            )}
                        </ul>


                    </>
                )}
                {/* <PopUp
                        help={help}
                    /> */}
            </Typography>




        </>
    );
};

export default HeaderSimple3;
