import { sum, mean, median } from "stats-lite"

export const evaluatedBaseData = async (values) => {
    // console.log('values123', values);
    let data = [];
    let num = 0;
    data['emotional'] = [];
    let emotionals = []
    Object.keys(values).forEach((value, index) => {
        data['emotional'].push(values[value].emotional)
        const v = values[value].values;
        Object.keys(v).forEach(e => {
            if (!data[e]) {
                data[e] = [];
            }
            data[e].push(v[e])

        });
        num = num + 1;
    });
    data['num'] = num;
    return data;
};

export const evaluatedBaseDataMonth = async (values) => {
    // console.log('values123', values);
    let data = [];
    let num = 0;
    data['emotional'] = [];
    let emotionals = []
    Object.keys(values).forEach((value, index) => {
        data['emotional'].push(values[value].emotional)
        const v = values[value].values;
        Object.keys(v).forEach(e => {
            if (!data[e]) {
                data[e] = [];
            }
            data[e].push(v[e])

        });
        num = num + 1;
    });
    data['num'] = num;
    return data;
};

export const evaluatedCount = (values) => {
    let num = 0;
    if (values) {
        Object.keys(values).forEach((value, index) => {
            num = num + 1;
        });
    }
    return num;
};

export const evaluatedStat = async (values, questions, scales) => {
    let data = [];
    data['values'] = [];
    Object.keys(values).forEach(e => {
        if (e === 'emotional' || e === 'num') {
            if (e === 'emotional') {
                data['emotional'] = mean(values[e])
            } else {
                data['num'] = values[e]
            }
        } else {
            const question = questions[e];
            const scaleType = scales[question['Skála']].type;
            switch (scaleType) {
                case 'szam':
                    data.values[e] = mean(values[e])
                    break;
                case 'betu':
                    data.values[e] = values[e]
                    break;
                case 'tobbvalasztos':
                    let d = [];
                    const value = values[e];
                    Object.keys(value).forEach(i => {
                        d = d.concat(value[i]);
                    })
                    data.values[e] = d;
                    break;
                case 'ertekeles':
                    data.values[e] = values[e]
                    break;
                default:
                    break;
            }
        }
    });
    return data;
}