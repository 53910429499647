import React, { useState } from 'react';
import StorageAvatar from '../firebase/StorageAvatar';
import ScopeItem from './ScopeItem';
import EvaluationItem from './EvaluationItem';
import StorageImage from '../firebase/StorageImage';

const MemberCover = ({ mini = false, profile, group }) => {
    return (
        <div className="groupHeaderDiv">
            <StorageImage
                value={group?.Profilkép}
                bucket={'group_images'}
                className="groupHeaderImg"
                height={mini ? "100px" : "200px"}
                width="100%"
            // presence={isMember || isSubscribed ? "online" : "busy"}
            />
            {/* <img src={coverPhoto} className="groupHeaderImg" height={mini ? "100px" : "200px"} width="100%" /> */}
            <div className={mini ? 'groupHeaderPriceMini' : 'groupHeaderPrice'}>
                <StorageAvatar
                    value={profile?.id}
                    bucket={'profile_images'}
                    className="categoryListingImg"
                />
                <div className='groupHeaderTitle'>
                    <h2>{group?.name}</h2>
                </div>
            </div>
        </div>
    );
};

export default MemberCover;