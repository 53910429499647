import React, { useState, useEffect } from 'react';
import ApexChart from 'react-apexcharts';
import SelectGroup from './components/SelectGroup';
import SelectLabel from './components/SelectLabel';

const StatApexChart = ({ groups, questions, data }) => {
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('');

  const handleGroupChange = (event) => {
    setSelectedGroupName(event.target.value);
  };

  const handleQuestionChange = (event) => {
    setSelectedQuestion(event.target.value);
  };

  const getFilteredData = () => {
    const selectedGroupId = groups.find((group) => group.data.name === selectedGroupName)?.id;
    return data.filter(
      (item) => item.groupId === selectedGroupId && item.question === selectedQuestion
    );
  };

  const getCategories = () => {
    let categories = [];
    data.forEach((item) => {
      const month = item.updatedAt;
      categories[month] = month;
    });
    return Object.keys(categories);
  };

  const getChartConfig = () => {
    const filteredData = getFilteredData();
    const chartData = {
      options: {
        xaxis: {
          categories: getCategories(),
          title: {
            text: 'Hónap',
          },
        },
        yaxis: {
          title: {
            text: 'Érték',
          },
          labels: {
            formatter: (value) => parseFloat(value).toFixed(1), // Kerekítés egy tizedes jegyre
          },
        },
      },
      series: [
        {
          name: 'Önértékelés',
          data: filteredData
            .filter((item) => item.groupAuthor === 'self')
            .map((item) => parseFloat(item.average_evaluated_value).toFixed(1)),
        },
        {
          name: 'Értékelő',
          data: filteredData
            .filter((item) => item.groupAuthor === 'evaluator')
            .map((item) => parseFloat(item.average_evaluated_value).toFixed(1)),
        },
        {
          name: 'Értékelt',
          data: filteredData
            .filter((item) => item.groupAuthor === 'evaluated')
            .map((item) => parseFloat(item.average_evaluated_value).toFixed(1)),
        },
        {
          name: 'Egyéb',
          data: filteredData
            .filter((item) => item.groupAuthor === 'other')
            .map((item) => parseFloat(item.average_evaluated_value).toFixed(1)),
        },
      ],
    };
    return chartData;
  };

  useEffect(() => {
    let sortedQuestions = [];
    Object.keys(questions).forEach((element) => {
      sortedQuestions[element] = questions[element];
    });
    setSelectedQuestion(Object.values(sortedQuestions)[0]);
  }, [questions]);

  useEffect(() => {
    // Set default values for group and question if available
    if (groups.length > 0) {
      setSelectedGroupName(groups[0].data.name);
    }
  }, [groups]);

  return (
    <div>
      <SelectGroup
        selectedGroupName={selectedGroupName}
        handleGroupChange={handleGroupChange}
        groups={groups}
      />
      <SelectLabel
        label={'Kérdés'}
        selectedQuestion={selectedQuestion}
        handleQuestionChange={handleQuestionChange}
        labels={questions}
      />
      <ApexChart options={getChartConfig().options} series={getChartConfig().series} type="bar" />
    </div>
  );
};

export default StatApexChart;
