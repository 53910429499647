import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import QuestionItem from './QuestionItem';

const QuestionsView = ({ localLabels, localQuestions, setLocalQuestions, newLabelQuestionId, setNewLabelQuestionId, newLabelId, setNewLabelId }) => {
    return (
        <>
            {Object.keys(localQuestions).map(questionId => {
                const question = localQuestions[questionId];
                const availableLabels = Object.keys(localLabels).filter(labelId => !question?.labels?.includes(labelId));
                return (
                    <Paper key={questionId} sx={{ p: 2, mb: 2 }}>
                        <Typography variant="h6">{question.name}</Typography>
                        <Box>
                            <QuestionItem
                                questionId={questionId}
                                localLabels={localLabels}
                                localQuestions={localQuestions}
                                setLocalQuestions={setLocalQuestions}
                                availableLabels={availableLabels}
                                newLabelQuestionId={newLabelQuestionId}
                                setNewLabelQuestionId={setNewLabelQuestionId}
                                newLabelId={newLabelId}
                                setNewLabelId={setNewLabelId}
                            />
                        </Box>
                    </Paper>
                );
            })}
        </>
    );
};

export default QuestionsView;
