import React, { useContext, useEffect, useState } from 'react';
import StorageImage from '../firebase/StorageImage';
import StorageAvatar from '../firebase/StorageAvatar';
import { AppContext } from '../../services/appContext';
import { convertArrayById3 } from '../../commons/Utility';

const UserCover = ({ userId, profile }) => {
    const [myGroups, setMyGroups] = useState([]);
    const { snapshots } = useContext(AppContext);
    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
            // console.log(snapshots['allgroups']);
        }
    }, [snapshots['allgroups']]);

    return (
        <div className="groupHeaderDiv">
            <StorageImage
                value={myGroups[0]?.data?.Profilkép}
                bucket={'group_images'}
                className="groupHeaderImg"
                height="200px"
                width="100%"
            // presence={isMember || isSubscribed ? "online" : "busy"}
            />
            <div className='groupCoverAvatar'>
                <StorageAvatar
                    value={userId}
                    bucket={'profile_images'}
                    className="categoryListingImg"
                // status={user === instructor ? 'admin' : 'member'}
                />
            </div>
            <div className='userCoverName'>
                {profile?.name}
            </div>
            <div className='userCoverEmail'>
                {profile?.email}
            </div>
        </div>
    );
};

export default UserCover;