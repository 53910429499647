import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import 'firebase/storage';
import { auth, storage } from '../../firebase.config';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import AvatarEditor from 'react-avatar-editor'
import { Button, CircularProgress, Slider } from '@mui/material';
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { getAuth, updateProfile } from "firebase/auth";
import { AddAPhoto } from '@mui/icons-material';
import { AppContext } from '../../services/appContext';

const UploadAvatar = ({ userId, exit = () => console.log('x') }) => {
    // const auth = getAuth(); // Get the auth object
    const storageRef = ref(storage, 'profile_images/' + userId);
    const [selectedImage, setSelectedImage] = useState(null);
    const cropRef = useRef(null);
    const [preview, setPreview] = useState(null);
    const [slideValue, setSlideValue] = useState(10);
    const { images, setImage } = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(file, 300, 300, "JPEG", 20, 0, (uri) => { console.log(uri); resolve(uri); }, "blob",);;
        });

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handleSave = async () => {
        setLoading(true);
        if (cropRef) {
            setLoading(true);
            const dataUrl = cropRef.current.getImage().toDataURL();
            // console.log('yyyyy', dataUrl)
            const result = await fetch(dataUrl);
            // console.log('yyyyy2', result)
            const blob1 = await result.blob();
            // console.log('yyyyy3', blob1)
            const blob = await resizeFile(blob1);


            const x = await uploadBytes(storageRef, blob);
            const t = await getDownloadURL(storageRef);
            // console.log('x', x)
            // console.log('yyyyy', dataUrl)
            setPreview(URL.createObjectURL(blob));
            // console.log('yyyyy3', storageRef)
            // const t = await getDownloadURL(storageRef);
            // console.log('yyyyy4', t)
            updateProfile(auth.currentUser, {
                photoURL: t,
            });

            const userRef = doc(db, "users", auth.currentUser.uid);
            await updateDoc(userRef, {
                updatedAvatar: new Date().toISOString(),
                photoURL: t,
            });
            localStorage.removeItem('profile_images' + auth.currentUser.uid);
            setImage({
                bucket: 'profile_images',
                value: auth.currentUser.uid,
                notFromCache: true,
                notFromLocalStorage: true
            })
            exit();
        }
    };

    return (
        <div>
            <h2 className="">Profilkép módosítása</h2>
            <p>Koppints a Fénykép feltöltése gombra! </p>
            <p>Válassz ki egy fényképet a telefonodról, vagy készíts egy új fényképet a telefonod kamerájával!</p>
            <p>Igazítsd a megfelelő méretűre és koppints a Mentés gombra!</p>
            <label>
                {!selectedImage && <AddAPhoto
                    sx={{

                        width: 250,
                        height: 250,
                        position: 'relative',

                        top: 0,
                        left: 0,
                    }}
                />}
                {selectedImage && <AvatarEditor
                    ref={cropRef}
                    width={250}
                    height={250}
                    image={selectedImage}
                    style={{ width: "70%", height: "70%" }}
                    border={50}
                    borderRadius={150}
                    color={[0, 0, 0, 0.72]}
                    scale={slideValue / 10}
                    rotate={0}
                />
                }
                <input style={{ display: "none" }} placeholder="Password" type="file" accept="image/*" onChange={handleImageSelect} ></input>
            </label>
            <div>

                {/* Ne töröld!!! */}
                {/* <img
                    src={
                        preview ||
                        " https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
                    }
                    alt=""
                    width="200"
                    height="200"
                /> */}
                <Slider
                    min={10}
                    max={50}
                    sx={{
                        margin: "0 auto",
                        width: "80%",
                        // color: "cyan"
                    }}
                    size="medium"
                    defaultValue={slideValue}
                    value={slideValue}
                    onChange={(e) => setSlideValue(e.target.value)}
                />

                {loading ? <CircularProgress /> :
                    <Button
                        sx={{ background: "#5596e6" }}
                        size="small"
                        variant="contained"
                        onClick={handleSave}
                        disabled={!selectedImage || loading}
                    >
                        Mentés
                    </Button>
                }
            </div>
        </div>
    );
};

export default UploadAvatar;
