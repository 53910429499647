import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import Tada from 'react-reveal/Tada';
import ResultDialog from './ResultDialog';
import Icons from '../MIIcons';
// import * as Icons from '@mui/icons-material';

const StyledCard = styled(Card)(({ bgcolor, color }) => ({
    backgroundColor: bgcolor,
    color: color,
    width: '100%',
    height: '100%', // Módosítás: A kártya és a dialógus teljes képernyősége
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const FlexContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
});

const Category = styled(Typography)({
    fontSize: '0.9rem',
    fontWeight: 'bold',
    marginBottom: '8px',
});

const Message = styled(Typography)({
    fontSize: '0.8rem',
    marginBottom: '8px',
});

const ResultCard = ({ categoryName = 'Eredmény', percent, icon, color, bgcolor, category, message, result, explanation }) => {
    const [open, setOpen] = useState(false);
    const IconComponent = Icons[icon];

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid className="" item xs={6}>
            <StyledCard bgcolor={bgcolor} color={color} onClick={handleOpen}>
                <CardContent>
                    <FlexContainer>
                        <Category>{category}</Category>
                        <Box>
                            {/* <Tada><IconComponent sx={{ fontSize: 70 }} /></Tada> */}
                            <IconComponent sx={{ fontSize: 70 }} />
                        </Box>
                        {/* <Message>{message}</Message> */}
                        <Tada><Message>{message}</Message></Tada>
                    </FlexContainer>
                </CardContent>
            </StyledCard>

            <ResultDialog
                open={open}
                handleClose={handleClose}
                bgcolor={bgcolor}
                color={color}
                icon={icon}
                category={category}
                message={message}
                categoryName={categoryName}
                result={result}
                percent={percent}
                explanation={explanation}
            />
        </Grid>
    );
};

export default ResultCard;
