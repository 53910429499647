import { useContext } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const DeleteToken = () => {
    const { deleteDocument } = useContext(AppContext);

    const deleteAction = async ({ id, token }) => {
        // const user = auth?.currentUser;
        // const userId = user?.uid;

        // const filter1 = { field: "uid", operation: '==', data: id }
        // const filter2 = { field: "token", operation: '==', data: token }

        const result = await deleteDocument({
            table: 'tokens',
            id: id
        })
        return result;
    }

    return {
        deleteAction
    }
};

export default DeleteToken;