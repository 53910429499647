import React from 'react';

const AppIcon = ({ icon, active = false }) => {
    const filterStyle = active ? 'grayscale(0%)' : 'grayscale(100%)';

    if (icon) {
        return (
            <img
                alt={'PR-VET'}
                style={{ filter: filterStyle, height: 50, color: active ? "#00cc66" : '' }}
                src={icon || '/logo.png'}
            />
        );
    } else {
        return null;
    }
};

export default AppIcon;
