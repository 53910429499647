import React from 'react';
import { FormControl, FormControlLabel, List, Radio, RadioGroup } from '@mui/material';

const CListSelect = ({
    field,
    handleChange,
    value,
    options,
}) => {

    const setValue = (v) => {
        handleChange(field, v.target.value)
    }

    return (
        <List
            dense
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
                // width: '90%',
                // maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 360,
                '& ul': { margin: 5 },
                '& li': {
                    padding: 0,
                    margin: 0,
                    fontweight: 600,
                    // height:50,
                    fontSize: '1.25rem',
                },
            }}
        >
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={value}
                    name="radio-buttons-group"
                    onChange={setValue}
                    sx={{
                    }}
                // className="optionsButton"
                >
                    {options && options.map((option, index) => (
                        <FormControlLabel
                            key={`FormControlLabel${index}`}
                            value={option.value}
                            control={
                                <Radio
                                    key={`Radio${index}`}
                                />
                            }
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </List>
    )
}
export default CListSelect
