import { images } from "./images"

export const install = {
    type: 'help',
    isAdmin: true,
    icon: 'Quiz',
    content: 'Applikáció telepítése',
    description: `A PR-VET kérdőív alkalmazásának telepítésével az ikon megjelenik a kezdőképernyőn, így a kérdőívek könnyedén és gyorsan elérhetők lesznek. Az ikonra koppintva az alkalmazás gyorsan elindul, hasonlóan egy hagyományos alkalmazáshoz, javítva ezzel a kezelhetőséget és az indítási sebességet.`,
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.dashboard2',
            content: 'Android készülékek',
            description: `
            <ul>
                <li>Chrome böngésző indítása: A Chrome böngésző megnyitása Android készüléken.</li>
                <li>Weboldal megnyitása: A https://kerdoiv.b612.hu oldal meglátogatása.</li>
                <li>Jelentkezés: A fiókba történő bejelentkezés.</li>
                <li>Applikáció telepítése: Az "Applikáció telepítése" gombra történő koppintás a nyitóoldalon vagy a profil oldalon.</li>
                <li>Telepítés befejezése: A képernyőn megjelenő utasítások követése a telepítés befejezéséhez.</li>
            </ul>`,
            image: images.install2,
            help: null,
            mark: 'circle',
            markRadius: 21,
            markXPercent: 78,
            markYPercent: 44,
            markRectangleWidth: 38,
            markRectangleHeight: 97
        },
        {
            type: 'slide', // slide, link
            selector: '.dashboard2',
            content: 'iPhone vagy iPad készülékek.',
            description: `
            <ul>
                <li>Safari böngésző indítása: A Safari böngésző megnyitása iPhone vagy iPad készüléken.</li>
                <li>Weboldal megnyitása: A https://kerdoiv.b612.hu oldal meglátogatása.</li>
                <li>Megosztás lehetőség kiválasztása: A címsor jobb felső sarkában a megosztás ikonra (négyzet felfelé mutató nyíllal) történő koppintás.</li>
                <li>Hozzáadás a kezdőképernyőhöz: A "Hozzáadás a kezdőképernyőhöz" opció megkeresése és kiválasztása.</li>
                <li>Részletek ellenőrzése: Az oldal részleteinek ellenőrzése vagy szerkesztése, például az ikon nevének módosítása.</li>
                <li>Hozzáadás befejezése: A "Hozzáadás" gombra történő koppintás. Az alkalmazás ikonja megjelenik a kezdőképernyőn, és készen áll a használatra.</li>
            </ul>
            `,
            image: images.install1,
            help: null,
            mark: 'circle',
            markRadius: 21,
            markXPercent: 81,
            markYPercent: 9,
            markRectangleWidth: 38,
            markRectangleHeight: 97
        },
    ]
}
