import React, { useState } from 'react';
import StorageAvatar from '../firebase/StorageAvatar';
import shareIcon from "../../assets/svg/shareIcon.svg";
import ScopeItem from './ScopeItem';
import EvaluationItem from './EvaluationItem';
import UserRolesOfQuestionnaire from '../groups/UserRolesOfQuestionnaire';
import StorageImage from '../firebase/StorageImage';
import { getStringDate } from '../../commons/Utility';

const QuestionnaireCover = ({ mini = false, profile, coverPhoto, questionnaire, group, groupUrl, name = 'csoport' }) => {
    const [shareLinkCopied, setShareLinkCopied] = useState(false);
    // console.log('!!!!!', profile)
    return (
        <div className="groupHeaderDiv">
            <StorageImage
                value={group?.Profilkép}
                bucket={'group_images'}
                className="groupHeaderImg"
                height={mini ? "100px" : "200px"}
                width="100%"
            // presence={isMember || isSubscribed ? "online" : "busy"}
            />
            {/* <img src={coverPhoto} className="groupHeaderImg" height={mini ? "100px" : "200px"} width="100%" /> */}
            <div className={mini ? 'groupCoverAvatar' : 'groupHeaderPrice'}>
                <StorageAvatar
                    value={profile?.id}
                    bucket={'profile_images'}
                    className="categoryListingImg"
                />

            </div>
            <div className='questionnaireCoverName'>
            <div>{group?.name}</div>
            <div>{getStringDate(questionnaire?.from)}</div>
            </div>

            {/* <div className='questionnaireRolesName'>
                <UserRolesOfQuestionnaire profile={profile} questionnaire={questionnaire} />
            </div> */}

            <div className='questionnaireRolesName'>
                <ScopeItem scope={questionnaire?.scope} />
                <EvaluationItem evaluation={questionnaire?.evaluate ?? questionnaire?.type} />
            </div>
        </div>
    );
};

export default QuestionnaireCover;