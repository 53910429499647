import { images } from "./images"

export const updateUser = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Felhasználói adatok szerkesztése',
    description: `Ez az útmutató lépésről lépésre segít a felhasználói adatok szerkesztésében, amely kizárólag ügyintéző jogosultsággal rendelkező felhasználók számára érhető el. A felhasználói adatok szerkesztése során módosítható: 
    <ul>
    <li>felhasználó neve, </li>
    <li>oktatói jogosultsága, </li>
    <li>ügyintézői jogosultsága és </li>
    <li>intézeti adminisztrációs joga</li>
    </ul>
    .`,
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás a felhasználóhoz',
            description: 'Lépjen a Csoport oldalára. Válassza ki a szerkeszteni kívánt felhasználót. ',
            image: images.mygroupsUsersList,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 12,
            markYPercent: 58,
            markRectangleWidth: 72,
            markRectangleHeight: 65,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás a felhasználó beállításaihoz',
            description: 'Kattintson a Beállítások fülre.',
            image: images.userEdit1,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 61,
            markYPercent: 34,
            markRectangleWidth: 83,
            markRectangleHeight: 40,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsEdit',
            content: 'Felhasználó nevének módosítása',
            description: 'Kattintson a Felhasználó neve mezőre. Írja be az új nevet a mezőbe. Győződjön meg róla, hogy a név legalább 6 karakter hosszú. Kattintson a Mentés gombra a név módosításának mentéséhez.',
            image: images.userEdit2,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 15,
            markYPercent: 46,
            markRectangleWidth: 66,
            markRectangleHeight: 51,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Oktatói jogosultság módosítása',
            description: 'Kapcsolja be vagy ki az Oktató kapcsolót a jogosultság módosításához. Kattintson az Alkalmaz gombra a változtatások mentéséhez.',
            image: images.userEdit2,
            help: null,
            mark: 'rect',
            markRadius: 15,
            markXPercent: 15,
            markYPercent: 57,
            markRectangleWidth: 66,
            markRectangleHeight: 61,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Ügyintézői jogosultság módosítása',
            description: 'Kapcsolja be vagy ki az Ügyintéző kapcsolót a jogosultság módosításához. Kattintson az Alkalmaz gombra a változtatások mentéséhez.',
            image: images.userEdit2,
            help: null,
            mark: 'rect',
            markRadius: 15,
            markXPercent: 15,
            markYPercent: 61,
            markRectangleWidth: 66,
            markRectangleHeight: 65,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Intézményi ügyintéző jogosultság módosítása',
            description: 'Kapcsolja be vagy ki az Intézményi ügyintéző kapcsolót a jogosultság módosításához. Kattintson az Alkalmaz gombra a változtatások mentéséhez.',
            image: images.userEdit2,
            help: null,
            mark: 'rect',
            markRadius: 15,
            markXPercent: 15,
            markYPercent: 65,
            markRectangleWidth: 66,
            markRectangleHeight: 69,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        }
    ]
}
