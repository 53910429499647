import GetQTemplate from '../../../services/documents/GetQTemplate';
import GetUser2 from '../../../services/documents/GetUser2';

const Utility = () => {
    const profileData = GetUser2()
    const templateData = GetQTemplate()

    const getProfile = async ({ id }) => {
        const a = await profileData.subscribe({
            id: id,
            setter: (model) => { }
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
        return a;
    }

    const getTemplate = async ({ id }) => {
        const a = await templateData.subscribe({
            id: id,
            setter: (model) => { },
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
        return a;
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
    return {
        getProfile,
        getTemplate,
        formatTimestamp
    }
};

export default Utility;