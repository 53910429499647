import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import View from './View';
import { AppContext } from '../../services/appContext';
import { convertArrayById3 } from '../../commons/Utility';
import { auth } from '../../firebase.config';
import InfoMessage from '../../commons/InfoMessage';
import Spinner from '../../components/Spinner';

const GroupEditor = () => {
    const params = useParams();
    const { groupId } = params;
    const user = auth?.currentUser?.uid;
    const [myGroup, setMyGroup] = useState();
    const [myGroups, setMyGroups] = useState([]);
    const { isSuperAdmin, isManager } = useContext(AppContext);
    const { snapshots, loadings } = useContext(AppContext);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(convertArrayById3(snapshots['allgroups'], 'id'));
        }
    }, [snapshots['allgroups']]);

    useEffect(() => {
        setMyGroup(myGroups[groupId]);
    }, [myGroups, groupId]);

    useEffect(() => {
        setMyGroup(myGroups[groupId]);
    }, [myGroups, groupId]);

    const isGroupAdmin = myGroup?.admins?.indexOf(user) > -1;
    const isGroupManager = isManager && (myGroup?.managers?.indexOf(user) > -1);
    return (
        <>
            {myGroup && groupId && (isGroupAdmin || isSuperAdmin || isGroupManager) && (
                <View
                    groupId={groupId}
                    user={user}
                    group={myGroup}
                    instructor={myGroup?.Oktató}
                    isSuperAdmin={isSuperAdmin}
                    isManager={isManager}
                />
            )
            }
            {myGroup && groupId && !(isGroupAdmin || isSuperAdmin || isGroupManager) && (
                <InfoMessage
                    color='error'
                    icon='Error'
                    label='Nincs jogosultsága az oldal megtekintéséhez'
                    description={` 
             Az oldal tartalma vagy szolgáltatásai csak bizonyos jogosultságokkal 
             rendelkező felhasználók számára elérhetők. Kérjük, 
             vegye fel a kapcsolatot az oldal adminisztrátorával vagy a 
             támogatási csapattal annak érdekében, hogy további információkat
             és segítséget kaphasson a hozzáférési jogosultságokkal kapcsolatban. 
             `}
                    options={[
                        { name: 'Vissza a csoport oldalához', link: `/mygroup/${groupId}` },
                    ]}
                />
            )
            }
            {(!myGroup) &&
                <Spinner />
            }
        </>
    );
};

export default GroupEditor;