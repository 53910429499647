import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import EvaluationEmotion from '../../../components/evaluations/EvaluationEmotion';

const LogRow = ({ log }) => {
  return (
    <TableRow>
      <TableCell>{log?.updatedAt}</TableCell>
      <TableCell>{log.evaluated_value}</TableCell>
      <TableCell><EvaluationEmotion value={log?.emotional} /></TableCell>
      <TableCell>{log?.template}</TableCell>
      <TableCell>{log?.evaluated}</TableCell>
      <TableCell>{log?.question}</TableCell>
    </TableRow>
  );
};

export default LogRow;