import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import ConfirmDialog from '../../commons/ConfirmDialog';

const CIconButton = ({ icon, size = 'large', style, variant = 'outlined', confirm = false, color = 'primary', onClick, disabled, show = true }) => {
    const [open, setOpen] = useState(false);

    return (<>
        {
            <IconButton
                type="button"
                variant={variant}
                color={color}
                id="type"
                disabled={disabled}
                style={style}
                size={size}
                // onClick={() => confirmOnClick()}
                onClick={() => confirm ? setOpen(true) : onClick()}
            >
                {icon}
            </IconButton>
        }
        <ConfirmDialog
            open={open}
            question={"Biztos végrehajtja a műveletet?"}
            handleConfirm={() => { setOpen(false); onClick() }}
            handleCancel={() => setOpen(false)}
        />
    </>
    );
};

export default CIconButton;