import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../services/appContext';

const Document2 = ({ setState, key, table, refresh = false, notFromCache = false }) => {
    const {
        documents,
        queryDoc
    } = useContext(AppContext);
    const [id2, setId] = useState(null);

    const subscribe = async ({ id }) => {
        setState([{
            id: id2,
            data: null
        }]);
        setId(id)
        // console.log('id', id);
        // console.log('key', key);
        // console.log('table', table);
        // setLoading({ key: key, status: true });
        // console.log('LOADINGS', loadings);
        // console.log('isLoading', isLoading);
        const result = await queryDoc({
            key: key,
            table: table,
            id: id,
            refresh: refresh,
            notFromCache: notFromCache
        })
        // console.log('result', result);
        setState([{
            id: id,
            data: result
        }]);
        return result
    }

    useEffect(() => {
        // console.log('documents[key]', documents[key]);
        // setLoading({ key: key, status: false });
        // console.log('LOADINGS', loadings);
        // console.log('isLoading', isLoading);
        setState([{
            id: id2,
            data: documents[key]
        }]);
    }, [documents[key]]);

    return {
        subscribe
    };
};

export default Document2;