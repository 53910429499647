import { help6 } from "../../anonimousData/Helps";

export const chart_grouptime3 = {
    "chartType": "BSChart",
    "defaultCategoryField": "updatedAt",
    "defaultSeriesField": "evaluatedUser",
    "defaultValuesField": 'evaluated_value_normalized',
    "defaultChartType": "bar",
    "filterFields": [
        // { "field": 'evaluated' },
        // { "field": 'label' }
    ],
    "title": "V/3. Csoport perspektíva szerinti csoportosítás időbeli trendje",
    "description": "Ez a diagram az egyes felhasználók által végzett és róluk készült értékelések számát mutatja a különböző értékelő típusok szerint.",
    "help": help6
};
