import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import CNumberSelect from '../../../../../commons/CNumberSelect';
import Instruction from './components/Instruction';
import QuestionPopup from '../../../../../commons/QuestionPopup';
// import { tables } from '../../../../../models/tables';
import LightSpeed from 'react-reveal/Pulse';
import Question from './components/Question';

const FSPFieldNumber = ({
    field,
    handleChange,
    options,
    value,
    page
}) => {
    // console.log('table_scales', tables.scales);
    // console.log('tables.questions', tables.questions);
    return (
        <div className='questionPanel'>
            <Question field={field} />
            <CNumberSelect
                key={uuidv4()}
                field={field.name}
                handleChange={handleChange}
                value={value}
                options={options && Object.keys(options).map((option) => (
                    { value: options[option].value, label: options[option].name }
                ))}
            // options={[
            //     { value: 1, label: '1' },
            //     { value: 2, label: '2' },
            //     { value: 3, label: '3' },
            //     { value: 4, label: '4' },
            //     { value: 5, label: '5' },
            //     { value: 6, label: '6' },
            //     { value: 7, label: '7' },
            //     { value: 8, label: '8' },
            //     { value: 9, label: '9' },
            //     { value: 10, label: '10' }
            // ]} 
            />
        </div>
    );
};

export default FSPFieldNumber;