import React from 'react';
import ProgressFilling from '../../../components/questionnaire/ProgressFilling';
import ProgressEmotion from '../../../components/emotions/ProgressEmotion';

const StateCharts = ({ stat, questionnaire, group }) => {
    let scopeNumber = 100;
    switch (questionnaire?.scope) {
        case 'admins':
            scopeNumber = group?.admins.length
            break;
        case 'members':
            scopeNumber = group?.Members?.length
            break;
        case 'all':
            scopeNumber = group?.admins?.length + group?.Members?.length
            break;
        default:
            break;
    }
    let evaluateNumber = 100;
    switch (questionnaire?.evaluate) {
        case 'admins':
            evaluateNumber = group?.admins.length
            break;
        case 'members':
            evaluateNumber = group?.Members?.length
            break;
        case '360':
            evaluateNumber = group?.admins?.length + group?.Members?.length
            break;
        case 'self':
            evaluateNumber = scopeNumber;
            scopeNumber = 1;
            break;
        case 'project':
            evaluateNumber = scopeNumber;
            scopeNumber = 1;
            break;
        default:
            break;
    }
    return (
        <>
            {stat?.emotional &&
                <div className="exploreCategories3">
                    <ProgressFilling title={'Kitöltések'} value={stat?.num} maxValue={scopeNumber} />
                    <ProgressEmotion title={'Érzelmi állapot'} big={true} value={Math.round(stat?.emotional)} />
                </div>
            }
        </>
    );
};

export default StateCharts;