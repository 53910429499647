import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const DeleteMemberAction = () => {
    const { deleteDocuments } = useContext(AppContext);

    const action = async ({ userId = null, groupId }) => {
        const user = auth.currentUser ? auth.currentUser : { uid: null }
        const userId2 = userId ? userId : user.uid;

        const filter1 = { field: "groupId", operation: '==', data: groupId }
        const filter2 = { field: "userId", operation: '==', data: userId2 }

        const result = await deleteDocuments({
            table: 'groupmembers',
            filter1: filter1,
            filter2: filter2
        })
        return result;
    }

    return {
        deleteMemberAction: action
    }
};

export default DeleteMemberAction;