import React from 'react';
import coverPhoto from "../../../assets/jpg/group2.jpg";
import QRCode from 'react-qr-code';
import GroupHeader from '../../../components/groups/GroupHeader';
import { Avatar, BottomNavigationAction, Grid } from '@mui/material';
import { CropFree, PlayArrow } from '@mui/icons-material';
import UploadGroupImage from '../../../components/firebase/UploadGroupImage';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";

const QRPage = ({
    user,
    group,
    groupId,
    instructor,
    isGroupAdmin,
    isSuperAdmin,
    isGroupManager,
    groupUrl
}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="qrcodes">
                <Grid container spacing={2}>
                    <Grid className="profileButton" item xs={3}>
                        <GroupHeader
                            user={user}
                            group={group}
                            groupId={groupId}
                            instructor={instructor}
                            coverPhoto={coverPhoto}
                        />
                    </Grid>
                    {(isGroupAdmin || isSuperAdmin || isGroupManager) &&
                        <>
                            <Grid className="profileButton" item xs={3}>
                                <BottomNavigationAction
                                    showLabel
                                    onClick={() => { navigate(`/start/${groupId}`) }}
                                    label="Gyors tesztindítás"
                                    icon={
                                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
                                            <PlayArrow />
                                        </Avatar>
                                    } />
                            </Grid>
                            <Grid className="profileButton" item xs={3}>
                                <BottomNavigationAction
                                    showLabel
                                    // onClick={() => { setShow('profile') }}
                                    label="Borítókép feltöltés"
                                    icon={
                                        <Avatar sx={{ bgcolor: '#1ABC9C' }} variant="square">
                                            <UploadGroupImage groupId={groupId} group={group} />
                                        </Avatar>
                                    } />
                            </Grid>
                        </>
                    }
                </Grid>
            </div>
            <div style={{ height: "auto", paddingTop: 30, margin: "0 auto", maxWidth: 256, width: "60%" }}>
                <QRCode
                    size={250}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={groupUrl}
                    viewBox={`0 0 250 250`}
                />
                {(isGroupAdmin || isSuperAdmin || isGroupManager) &&

                    <div className='centerContent3' onClick={() => (navigate("/subscribe"))} >

                        <CropFree style={{ verticalAlign: "-6px" }} />

                        Felhasználó felvétele
                    </div >

                    // <DashboardButton
                    //     label={'Felhasználó felvétele'}
                    //     icon={'GroupAdd'}
                    //     onClick={() => (navigate("/subscribe"))}
                    //     isAvatar={true}
                    //     bgcolor={'#9B59B6'}
                    // />
                }
            </div>
        </>
    );
};

export default QRPage;