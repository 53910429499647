import React from 'react';
import { Chip } from '@mui/material';
import LabelSelector from './LabelSelector';

const QuestionItem = ({
    questionId,
    localLabels,
    localQuestions,
    setLocalQuestions,
    availableLabels,
    newLabelQuestionId,
    setNewLabelQuestionId,
    newLabelId,
    setNewLabelId
}) => {

    const handleLabelDelete = (questionId, labelId) => {
        const updatedLabels = localQuestions[questionId].labels.filter(id => id !== labelId);
        setLocalQuestions({
            ...localQuestions,
            [questionId]: {
                ...localQuestions[questionId],
                labels: updatedLabels
            }
        });
    };

    const handleLabelAdd = () => {
        if (newLabelQuestionId && newLabelId) {
            const updatedLabels = localQuestions[newLabelQuestionId]?.labels ? [...localQuestions[newLabelQuestionId].labels, newLabelId] : [newLabelId];
            setLocalQuestions({
                ...localQuestions,
                [newLabelQuestionId]: {
                    ...localQuestions[newLabelQuestionId],
                    labels: updatedLabels
                }
            });
            setNewLabelQuestionId(null);
            setNewLabelId('');
        }
    };

    return (
        <>
            {localQuestions[questionId]?.labels?.map(labelId => {
                const label = localLabels[labelId];
                return (
                    <Chip
                        key={labelId}
                        label={label ? label.name : 'Unknown Label'}
                        onDelete={() => handleLabelDelete(questionId, labelId)}
                        sx={{ m: 0.5 }}
                    />
                );
            })}
            <LabelSelector
                availableLabels={availableLabels}
                newLabelQuestionId={newLabelQuestionId}
                setNewLabelQuestionId={setNewLabelQuestionId}
                newLabelId={newLabelId}
                setNewLabelId={setNewLabelId}
                questionId={questionId}
                handleLabelAdd={handleLabelAdd}
                localLabels={localLabels}
            />
        </>
    );
};

export default QuestionItem;
