import React from 'react';
import coverPhoto from "../../assets/jpg/group2.jpg";
import GroupCover from '../../components/groups/GroupCover';
import { Tab, Tabs } from '@mui/material';
import { AccessAlarms, Groups, Tune } from '@mui/icons-material';
import AdminEditor from './components/AdminEditor';
import Stats from './components/Stats';
import Content from './components/Content';
import Setup from './components/Setup';
import Schedules from './components/Schedules';

const View = ({ children, groupId, user, group, instructor, isSuperAdmin, isManager }) => {
    const groupUrl = `https://kerdoiv.b612.hu/subscribe/${groupId}`;

    const handleChange = (event, newValue) => { setValue(newValue); };
    const isGroupAdmin = group?.admins?.indexOf(user) > -1;
    const isGroupManager = isManager && (group?.managers?.indexOf(user) > -1);
    const isMember = group?.Members?.indexOf(user) > -1;
    const [value, setValue] = React.useState(isGroupManager || isSuperAdmin ? 'admin' : 'setup');

    return (
        <div className="profile">
            <GroupCover
                user={user}
                coverPhoto={coverPhoto}
                group={group}
                groupUrl={groupUrl}
                instructor={instructor}
                isManager={isManager}
            />
            <div className="listingDetails">
                {/* <p className="listingName">
                    {group?.name}
                </p> */}
                <Tabs
                    // variant="scrollable"
                    allowScrollButtonsMobile
                    // scrollButtons="auto"
                    value={value}
                    variant="fullWidth"
                    onChange={handleChange}
                    // showlabels={false}
                    aria-label="icon tabs example">
                    {/* {(isGroupAdmin || isSuperAdmin || isMember || isGroupManager) &&
                        <Tab value = 'stat' icon={<Groups />} label="Stat." />
                    }
                    {(isGroupAdmin || isSuperAdmin || isMember || isGroupManager) &&
                        <Tab value = 'content' icon={<Groups />} label="Tartalom" />
                    } */}
                    {(isSuperAdmin || isGroupManager) &&
                        <Tab value='setup' icon={<Tune />} label="Beállítások" />
                    }
                    {(isSuperAdmin || isGroupManager) &&
                        <Tab value='admin' icon={<Groups />} label="Felhasználók" />
                    }
                    {(isSuperAdmin || isGroupManager) &&
                        <Tab value='schedules' icon={<AccessAlarms />} label="Időzítések" />
                    }
                </Tabs>
                {value === 'stat' &&
                    <>
                        <Stats />
                    </>
                }
                {
                    value === 'content' &&
                    <>
                        <Content />
                    </>
                }
                {value === 'setup' &&
                    <>
                        <Setup group={group} />
                    </>
                }
                {value === 'schedules' &&
                    <>
                        <Schedules group={group} />
                    </>
                }

                {
                    value === 'admin' &&
                    <>
                        <AdminEditor
                            groupId={groupId}
                            user={user}
                            group={group}
                            instructor={group?.Oktató}
                            isSuperAdmin={isSuperAdmin}
                            isGroupAdmin={isGroupAdmin}
                            isGroupManager={isGroupManager}
                        />
                    </>
                }
            </div >
        </div >
    );;
};

export default View;