import React from 'react';
import { getStringDate } from '../../../commons/Utility';

const Header = ({ questionnaire, template, group }) => {
    return (
        <>
            {/* <p className="discountPrice">
                {getStringDate(questionnaire?.from)}
            </p> */}
            <h2>{template?.name}</h2>
            {/* <p className="ListingLocation">{group?.name}</p> */}
        </>
    );
};

export default Header;