const QuestionsUtitity = () => {

    const findFirstUnFilledQuestion = ({ pages, values }) => {
        let lastUnFilled = -1;
        let filled = 0;
        let persons = 0;
        Object.keys(pages).forEach((i, index) => {
            const page = pages[i];

            if (page.type !== 'person') {
                const categoryValues = values[page?.category_id];
                const value = categoryValues && categoryValues[page?.question];
                const isvalid = isValidValue({ page: page, value: value });

                if (!isvalid && lastUnFilled === -1) {
                    lastUnFilled = index;
                }
                if (isvalid) {
                    filled = filled + 1;
                }
            } else {
                persons = persons + 1;
            }
        })
        // ha nem találtunk kitöltetlent és mind ki an töltve
        if (Object.keys(pages).length === filled + persons)
            lastUnFilled = Object.keys(pages).length;
        return lastUnFilled;
    };

    const generateQuestions = ({ questionnaire, categories, person = false }) => {
        const form = questionnaire?.qtemplate;
        const meta = form?.meta;
        const sections = form?.section;
        const questions = [];
        let index = 0;
        if (categories && sections) {
            Object.keys(categories).forEach((c, index1) => {
                const category = categories[c]
                if (person) {
                    questions.push(
                        {
                            type: 'person',
                            category: category,
                            category_id: category.id,
                        }
                    );
                }
                sections.forEach((section, index2) => {
                    const fields = section.fields ? section.fields : section.questions;
                    fields.forEach((field, index3) => {
                        const question = meta?.questions[field?.question];
                        const questionType = question && meta && meta?.scales ? meta?.scales[question['Skála']].type : null;
                        const min = question && meta && meta?.scales ? meta?.scales[question['Skála']].min : null;
                        const max = question && meta && meta?.scales ? meta?.scales[question['Skála']].max : null;
                        const instruction = question && meta && meta?.scales ? meta?.scales[question['Skála']].instruction : null;
                        // TODO: generálás személyekre...
                        // console.log('*****', instruction);
                        // console.log('*****', meta);
                        questions.push({
                            ...field,
                            id: index,
                            id_category: index1,
                            id_section: index2,
                            id_question: index3,
                            category: category,
                            category_id: category.id,
                            section: section.name,
                            questionType: questionType,
                            min: min ?? 1,
                            max: max ?? 10000,
                            instruction: instruction,
                            data: []
                        })
                        index++;
                    })
                })
            })
        }
        return questions;
    }

    const generateStepper = ({ slideItems }) => {
        const steps = [];
        Object.keys(slideItems).forEach((field) => {
            steps.push(slideItems[field].data)
        })
        return steps;
    }

    const isValidValue = ({ page, value }) => {
        if (page?.questionType === 'ertekeles') {
            return typeof value === 'string' && value.length > 0;
        };

        if (page?.questionType === 'tobbvalasztos') {
            const min = page?.min ? page?.min : 1;
            return (Array.isArray(value) && value.length >= min) || page.type === 'person';
        } else {
            return value > -1 || page.type === 'person';
        }
    }

    const isValid = ({
        pages, categories, values, id,
    }) => {
        const page = pages[id];
        const categoryId = page?.category_id;
        const category = categories && categoryId ? categories[categoryId].data : null;
        const categoryValues = category ? values[category.id] : null;
        const value = categoryValues ? categoryValues[page.question] : null;

        return (
            isValidValue({ page: page, value: value })
        )
    }

    const saveToLocalstorage = ({
        questionnaire,
        values,
        emotional,
        persons,
        pages,
        actual,
        // activeStep,
        categories,
        filledQuestionnaire_id
    }) => {
        if (Object.keys(values).length > 0) {
            const data = {
                questionnaire: { ...questionnaire },
                values: { ...values },
                emotional: emotional,
                persons: { ...persons },
                pages: { ...pages },
                actual: actual,
                // activeStep: activeStep,
                categories: { ...categories }
            }
            localStorage.setItem('sampling-' + filledQuestionnaire_id, JSON.stringify(data));
        }
    };

    const loadFromLocalstorage = ({
        setEmotional,
        setPersons,
        setPages,
        setValues,
        // setActiveStep,
        setCategories,
        setLoad,
        filledQuestionnaire_id
    }) => {
        const data = JSON.parse(localStorage.getItem('sampling-' + filledQuestionnaire_id));
        if (data) {
            setEmotional(data.emotional);
            setPersons(data.persons);
            setPages(data.pages);
            setValues(data.values);
            // setActiveStep(data.activeStep);
            setCategories(data.categories);
            setLoad(true);
        }
    }
    return {
        generateQuestions, generateStepper, findFirstUnFilledQuestion,
        isValid, loadFromLocalstorage, saveToLocalstorage
    };
};


export default QuestionsUtitity;