import { images } from "./images"

export const createGroup = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Új csoport létrehozása',
    description: 'Ez az útmutató lépésről lépésre segít az új csoport létrehozásában, amely kizárólag ügyintéző jogosultsággal rendelkező felhasználók számára érhető el. A csoport létrehozásakor a létrehozó automatikusan a csoport oktatója (tulajdonosa) és ügyintézője lesz.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás a Csoportjaim menüponthoz',
            description: 'Lépjen a Profil oldalára. Válassza ki a Csoportjaim menüpontot. Kattintson a jobb alsó sarokban lévő plusz ikonra az új csoport létrehozásához.',
            image: images.mygroupsList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 83,
            markYPercent: 85,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            animations: [
                { name: 'cursor', action: 'move', fromX: 50, fromY: 50, toX: 83, toY: 85, duration: 1, clickDuration: 0.5, delay: 1, repeatDelay: 0.5 }
            ]

        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsEdit',
            content: 'Csoport nevének megadása',
            description: 'Kattintson a Csoport neve mezőre. Írja be az új csoport nevét a mezőbe. Győződjön meg róla, hogy a név legalább 6 karakter hosszú.',
            image: images.mygroupsCreate,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 25,
            markYPercent: 47,
            markRectangleWidth: 75,
            markRectangleHeight: 52,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Csoport állapotának beállítása',
            description: 'Kapcsolja be vagy ki az Aktív kapcsolót a csoport állapotának beállításához. Ha a kapcsoló be van kapcsolva, a csoport aktív, ha nincs bekapcsolva, a csoport inaktív.',
            image: images.mygroupsCreate,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 25,
            markYPercent: 54,
            markRectangleWidth: 45,
            markRectangleHeight: 58,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Mentés',
            description: 'Kattintson a Mentés gombra a módosítások mentéséhez. Sikeres mentés esetén egy értesítés jelenik meg a képernyőn.',
            image: images.mygroupsCreate,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 60,
            markYPercent: 61,
            markRectangleWidth: 78,
            markRectangleHeight: 65,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
    ]
}
