import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import swDev from "./swDev";
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Add the "Telepítés" button and related event handlers
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  deferredPrompt = event;

  // const installButton = document.createElement('button');
  // installButton.textContent = 'PR-VET kitöltő alkalmazás telepítése';
  // installButton.addEventListener('click', () => {
  //   deferredPrompt.prompt();
  //   deferredPrompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //       console.log('User accepted the installation');
  //     } else {
  //       console.log('User dismissed the installation');
  //     }
  //     deferredPrompt = null;
  //     document.body.removeChild(installButton);
  //   });
  // });

  // document.body.appendChild(installButton);
});

const APP_NAME = "PR-VET";
const APP_VER = "v0.03.12";
const CACHE_NAME = APP_NAME + "-" + APP_VER;
const urlsToCache = [
  '/',
];


// Service Worker telepítése
window.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open(CACHE_NAME)
      .then((cache) => {
        return cache.addAll(urlsToCache);
      })
  );
});

// Service Worker aktiválása és régi gyorsítótárazott verziók törlése
window.addEventListener('activate', (event) => {
  event.waitUntil(
    caches.keys().then((cacheNames) => {
      return Promise.all(
        cacheNames.filter((cacheName) => {
          return cacheName !== CACHE_NAME;
        }).map((cacheName) => {
          return caches.delete(cacheName);
        })
      );
    })
  );
});

// Fetch esemény kezelése a gyorsítótárazott tartalommal
window.addEventListener('fetch', (event) => {
  event.respondWith(
    caches.match(event.request)
      .then((response) => {
        // Ha a gyorsítótárban van találat, visszatérítjük azt
        if (response) {
          return response;
        }
        // Különben élő lekérdezést indítunk
        return fetch(event.request);
      })
  );
});

// window.addEventListener('push', function(event) {
//   const title = 'New Message';
//   const options = {
//     body: 'Hello, world!',
//   };
// event.waitUntil(window.registration.showNotification(title, options));
// });

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

// Progressive Enhancement (SW supported)
// if (navigator.serviceWorker) {
//   // Register the SW
//   navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function(registration){
//   }).catch(console.log);
// }


// Notification support

function showNotification() {
  let notificationOptions = {
    body: 'Some Notification information',
    icon: '/thumb.png'
  }
  let notif = new Notification('My New Notification', notificationOptions);

  notif.onclick = () => {
    console.log('Notification clicked');
  }
}

if(window.Notification) {
  if(Notification.permission === 'granted') {
  } else if(Notification.permission !== 'denied') {
    Notification.requestPermission(permission => {
      if(permission === 'granted') {
      }
    })
  }
}


reportWebVitals();
