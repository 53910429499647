import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../services/appContext';
import { snapshotSetState } from '../commons/Utility';

const Snapshot2 = ({ setState, key, table }) => {

    const {
        snapshots,
        setSnapshot,
        subscribeToSnapshot,
    } = useContext(AppContext);

    const subscribe = ({ filter, orderBy = null, limit = null, }) => {
        // console.log('allGroups4', filter);
        const unsubscribe = subscribeToSnapshot({
            key: key,
            table: table,
            filter: filter,
            qOrderBy: orderBy,
            qLimit: limit,
        })
        return unsubscribe
    }
    const clear = ({ value }) => {
        setSnapshot({ key: key, value: value })
    }

    useEffect(() => {
        snapshotSetState({
            snapshot: snapshots[key],
            callback: setState
        })
    }, [snapshots[key]]);

    return {
        subscribe, clear
    };
};

export default Snapshot2;