import React from 'react';
import { v4 as uuidv4 } from "uuid";
import { Box, Fab } from '@mui/material';

const CNumberSelect = ({
    field,
    handleChange,
    value,
    options
}) => {
    const setValue = (v) => {
        handleChange(field, v)
    }
    const screenHeight = window.screen.height;
    return (
        <Box sx={{ '& > :not(style)': { m: 1 }, marginLeft: '0em', marginRight: "0em" }}>
            {options.map((option, index) => (
                <Fab
                    key={uuidv4()}
                    color={value === option.value ? "primary" : '#fff'}
                    size={screenHeight > 600 ? "medium" : "small"}
                    sx={value === option.value
                        ? { mr: 10, backgroundColor: "primary" }
                        : { mr: 10, backgroundColor: '#fff' }}
                    onClick={() => { setValue(option.value) }}>

                    {option.label}
                </Fab>
            ))}
        </Box>
    )
}
export default CNumberSelect
