import GetQTemplate from '../../../services/documents/GetQTemplate';
import GetUser2 from '../../../services/documents/GetUser2';

const Utility = () => {
    const profileData = GetUser2()
    const templateData = GetQTemplate()

    const getProfile = async ({ id }) => {
        const a = await profileData.subscribe({
            id: id,
            setter: (model) => { }
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
        return a;
    }

    const getTemplate = async ({ id }) => {
        const a = await templateData.subscribe({
            id: id,
            setter: (model) => { },
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('Error')
        }
        return a;
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const getLabelsStat = (data) => {
        // Inicializáljuk a csoportosított statisztikát
        const labelsStat = {};

        // Iterálunk az adatokon
        data.forEach((item) => {
            // Ellenőrizzük, hogy az elemnek van-e címke
            if (item.labels && item.labels.length > 0) {
                // Iterálunk az elem címkéin
                item.labels.forEach((label) => {
                    // Ha a címke még nem szerepelt a statisztikában, létrehozzuk az objektumot
                    const d = item.evaluatedMonth ?? item.evaluatedWeek;
                    const labelName = item.groupAuthor + '-' + item.evaluatedYear + '-' + d + '-' + item.groupId + '-' + '-' + label;

                    if (!labelsStat[labelName]) {
                        labelsStat[labelName] = {
                            count: item['count(evaluated_value)'],
                            sum: parseFloat(item['sum_evaluated_value']),
                            average: parseFloat(item['average_evaluated_value']),
                            question: label,
                            groupAuthor: item["groupAuthor"],
                            evaluatedYear: item["evaluatedYear"],
                            evaluatedMonth: item["evaluatedMonth"] ?? item["evaluatedWeek"],
                            groupId: item["groupId"],
                            // template: item["template"],
                            updatedAt: item["updatedAt"]
                        }
                    } else {
                        // Ha már szerepel a címke, frissítjük a statisztikát az aktuális elem adataival
                        labelsStat[labelName].count += item['count(evaluated_value)'];
                        labelsStat[labelName].sum += parseFloat(item['sum_evaluated_value']);
                        labelsStat[labelName].average = labelsStat[labelName].sum / labelsStat[labelName].count;
                    }
                });
            }
        });
        return labelsStat;
    };

    const getLabelsStat2 = (data, groupId = 'groupId') => {
        // Inicializáljuk a csoportosított statisztikát
        const labelsStat = {};
        console.log('wwww', data);
        // Iterálunk az adatokon
        data.forEach((item) => {
            // Ellenőrizzük, hogy az elemnek van-e címke
            if (item.labels && item.labels.length > 0) {
                // Iterálunk az elem címkéin
                item.labels.forEach((label, index) => {
                    // Ha a címke még nem szerepelt a statisztikában, létrehozzuk az objektumot
                    const d = item.evaluatedMonth ?? item.evaluatedWeek;
                    const g = item.groupId ?? groupId
                    const labelName = item.groupAuthor + '-' + item.evaluatedYear + '-' + d + '-' + g + '-' + '-' + label;

                    if (!labelsStat[labelName]) {
                        labelsStat[labelName] = {
                            count: item['count(evaluated_value)'],
                            sum: parseFloat(item['sum_evaluated_value']),
                            average: parseFloat(item['average_evaluated_value']),
                            question: label,
                            questionId: item['labelsId'][index],
                            groupAuthor: item["groupAuthor"],
                            evaluatedYear: item["evaluatedYear"],
                            evaluatedMonth: item["evaluatedMonth"] ?? item["evaluatedWeek"],
                            groupId: g,
                            // template: item["template"],
                            timeselect: item["timeselect"],
                            updatedAt: item["updatedAt"]
                        }
                    } else {
                        // Ha már szerepel a címke, frissítjük a statisztikát az aktuális elem adataival
                        labelsStat[labelName].count += item['count(evaluated_value)'];
                        labelsStat[labelName].sum += parseFloat(item['sum_evaluated_value']);
                        labelsStat[labelName].average = labelsStat[labelName].sum / labelsStat[labelName].count;
                    }
                });
            }
        });
        return labelsStat;
    };

    return {
        getProfile,
        getTemplate,
        formatTimestamp,
        getLabelsStat,
        getLabelsStat2
    }
};

export default Utility;