import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import InfoCards from './InfoCards';
import { helps } from '../data/helps';

const InfoLink = ({ step, dialog = false }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {dialog ? (
                <>
                    <li className="helpLink" onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
                        <span className='helpDescription' dangerouslySetInnerHTML={{ __html: step.content }} />
                    </li>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>{step.title}</DialogTitle>
                        <DialogContent
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                height: '100%',
                                overflowY: 'auto',
                                paddingTop: '20px',
                                '& > div': {
                                    maxWidth: '600px',
                                    width: '100%',
                                },
                            }}
                        >
                            <div>
                                <h1>{step.content}</h1>
                                <div className='helpDescription' dangerouslySetInnerHTML={{ __html: helps[step.link].description }} />
                                <InfoCards steps={helps[step.link].steps} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Bezárás
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <Link to={`/help/${step.link}`} className="helpLink">
                    <li>
                        <span className='helpDescription' dangerouslySetInnerHTML={{ __html: step.content }} />
                    </li>
                </Link>
            )}
        </>
    );
};

export default InfoLink;
