import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import html2canvas from 'html2canvas';
import { Announcement, Chat, ChatBubbleOutline, Fullscreen, Mms } from '@mui/icons-material';
import ErrorForm from './components/ErrorForm';
import Resizer from 'react-image-file-resizer';
import InfoMessage from '../InfoMessage';
import { AppContext } from '../../services/appContext';
import { Avatar, BottomNavigationAction, Grid } from '@mui/material';

const ErrorReport = ({ screenshot = false, style = '' }) => {
    const [content, setContent] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [blob, setBlob] = useState();
    const { showErrorIcon, isSuperAdmin, isAdmin, isManager } = useContext(AppContext);

    const captureContent = async () => {
        setShowForm(true);
        if (screenshot) {
            const canvas = await html2canvas(document.body);
            setContent(canvas.toDataURL('image/png'));
            const blob = await resizeFile(canvas.toDataURL('image/png'));
            setBlob(blob);
        }
    };

    const resizeFile = async (dataUrl) => {
        return new Promise((resolve) => {
            fetch(dataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    Resizer.imageFileResizer(blob, 1024, 1024, "JPEG", 90, 0, (uri) => {
                        // console.log(uri);
                        resolve(uri);
                    }, "blob");
                });
        });
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setSubmissionStatus();
        // További szükséges lépések a form bezárása után
    };

    return (
        <div>
            {screenshot && !showForm && showErrorIcon && (isSuperAdmin || isAdmin || isManager) &&
                <Mms onClick={captureContent} />
            }
            {!screenshot && (isSuperAdmin || isAdmin || isManager) &&
                <BottomNavigationAction showLabel onClick={() => { captureContent() }} label="Visszajelzés" icon={
                    <Avatar sx={{ bgcolor: '#1ABC9C' }} variant="square">
                        <Mms />
                    </Avatar>

                } />
            }
            <ErrorForm
                content={content}
                setContent={setContent}
                setSubmissionStatus={setSubmissionStatus}
                onClose={handleCloseForm}
                showForm={showForm}
                handleCloseForm={handleCloseForm}
                blob={blob}
                setBlob={setBlob}
                screenshot={screenshot}
            // setSuccess={setSuccess}
            />
            {submissionStatus &&
                <InfoMessage
                    color='success'
                    icon='Error'
                    label='Sikeres visszajelzés'
                    description={`Köszönjük a visszajelzést! \n Az észrevételeit gondosan megvizsgáljuk, és a lehető leghamarabb igyekszünk válaszolni a szükséges információkkal vagy szükséges intézkedésekkel kapcsolatban. \n
                <br><h4>Kód:${submissionStatus}</h4>`}
                    options={[
                        { name: 'Tovább', onClick: () => handleCloseForm() }

                    ]}
                />
            }
            {submissionStatus && <div>{submissionStatus}</div>}
        </div>
    );
};

export default ErrorReport;
