import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../services/appContext';

const Document = ({ setState, key, table, id, refresh = false }) => {

    const {
        documents,
        queryDoc
    } = useContext(AppContext);

    const subscribe = () => {
        // console.log('id', id);
        // console.log('key', key);
        // console.log('table', table);
        queryDoc({
            key: key,
            table: table,
            id: id,
            refresh: refresh
        })
        return () => { }
    }

    useEffect(() => {
        // console.log('documents[key]', documents[key]);
        setState([{
            id: id,
            data: documents[key]
        }]);
    }, [documents[key]]);

    return {
        subscribe
    };
};

export default Document;