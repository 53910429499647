import Snapshot2 from '../../hooks/snapshot2';

const MyGroups = ({ setter }) => {

    const model = Snapshot2({
        setState: setter,
        key: 'allgroups',
        table: 'groups',
        refresh: true,
    });

    const subscribe = ({ allGroups }) => {
        // console.log('allGroups', allGroups)
        let unsubscribe2;
        if (allGroups.length > 0) {
            unsubscribe2 = model.subscribe({
                filter: {
                    field: "uid",
                    operation: 'in',
                    data: allGroups
                }
            })
        } else {
            console.log('még nincs csoport', allGroups)
            unsubscribe2 = () => { console.log('még nincs csoport') }
            model.clear({ value: [] })
        }
        return unsubscribe2;
    }
    return { subscribe };
};

export default MyGroups;