import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../services/appContext';
import { Badge } from "@mui/material";
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';

const StampImage = ({ countStatus = 0, status, statusText, value, bucket,
    className = "categoryListingImg", size = "xlarge",
    presence = null, notFromCache = false, notFromLocalStorage = false,
    variant = "circular"
}) => {
    const { images, setImage } = useContext(AppContext);

    useEffect(() => {
        setImage({ bucket: bucket, value: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    let bcolor = "info";
    let bcontent = 0;
    let bvariant = 'standard';
    if (countStatus > 0) {
        bcolor = "info";
        bcontent = countStatus;
    }
    const StyledAvatar = styled(Avatar)(({ theme }) => ({
        border: '2px solid white',
        '@media (max-width: 375px)': {
            maxWidth: 70,
            maxHeight: 70
        },
    }));
    let height = 40;
    switch (size) {
        case 'xxxlarge':
            height = 150;
            break;

        case 'xlarge':
            height = 100;
            break;

        default:
            break;
    }
    return (
        <>

            <Badge
                badgeContent={bcontent}
                color={bcolor}
                variant={bvariant}>
                <>
                    <StyledAvatar
                        appearance="circle"
                        src={images[bucket + '/' + value]}
                        // src={"https://pbs.twimg.com/profile_images/803832195970433027/aaoG6PJI_400x400.jpg"}
                        // size={size}
                        sx={{ width: height, height: height, border: '2px solid white' }}
                        // borderColor="#0000"
                        name="Csoport"
                        label="Név"
                        // status={status}
                        presence={presence}
                        variant={variant}
                    />
                    {status === 'progress' && (
                        <div className={status !== 'progress' ? 'questionnaireItemProgress' : 'questionnaireItemSuccess'} >
                            {statusText}
                        </div>
                    )}
                    {/* {status === 'new' && (
                        <div className={status !== 'progress' ? 'questionnaireItemProgress' : 'questionnaireItemSuccess'} >
                            {statusText}
                        </div>
                    )} */}
                </>
            </Badge>
        </>
    );
};

export default StampImage;