// src/serviceWorkerRegistration.js
export default function registerServiceWorker(selectedProject) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw2.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
  
          // Elküldjük a kiválasztott projekt adatait a service workernek
          registration.active.postMessage({
            type: 'SELECT_FIREBASE_PROJECT',
            project: selectedProject,
          });
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }
  