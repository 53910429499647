import React from 'react';
import Pulse from 'react-reveal/Pulse';

const NextButton = ({ name, onClick = () => { }, className = 'primaryButton', variant, disabled = false }) => {
    return (
        <div className="next3">
            {!disabled &&
                <Pulse>
                    <button
                        disabled={disabled}
                        className={disabled ? "secondaryButton" : className}
                        onClick={() => { onClick() }}
                    >
                        {name}
                    </button>
                </Pulse>
            }

        </div>
    );
};

export default NextButton;