import React, { useContext, useState } from 'react';
import StorageAvatar from '../firebase/StorageAvatar';
import shareIcon from "../../assets/svg/shareIcon.svg";
import StorageImage from '../firebase/StorageImage';
import { AvatarGroup, Chip } from '@mui/material';
import UploadGroupImage from '../firebase/UploadGroupImage';
import { AppContext } from '../../services/appContext';
import { deepOrange } from '@mui/material/colors';
import { IconButton } from '@mui/material';

const GroupCover = ({ coverPhoto, group, user, groupUrl, isManager = false, instructor }) => {
    const isMember = group?.Members?.indexOf(user) > -1;
    const isAdmin = group?.admins?.indexOf(user) > -1;
    const isGroupManager = isManager && (group?.managers?.indexOf(user) > -1);
    const isSubscribed = group?.subscribed?.indexOf(user) > -1;
    const [shareLinkCopied, setShareLinkCopied] = useState(false);
    const { isSuperAdmin } = useContext(AppContext);
    return (
        <div className="groupHeaderDiv">
            <StorageImage
                value={group?.Profilkép}
                bucket={'group_images'}
                className="groupHeaderImg"
                height="200px"
                width="100%"
            // presence={isMember || isSubscribed ? "online" : "busy"}
            />
            {/* <img src={coverPhoto} className="groupHeaderImg" height="200px" width="100%" /> */}
            {/* <div className='groupHeaderPrice3'>
                <StorageAvatar
                    value={instructor?.icon ?? instructor}
                    bucket={'profile_images'}
                    className="categoryListingImg"
                    status={user === instructor ? 'admin' : 'member'}
                />
            </div> */}
            {(isAdmin || isSuperAdmin || isGroupManager) &&
                <div className='groupHeaderText3'>
                    <IconButton
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.7)'
                        }}>
                        <UploadGroupImage groupId={group?.id} group={group} />
                    </IconButton>
                </div>
            }
            <div className='groupCoverAvatar'>
                <StorageAvatar
                    value={instructor?.icon ?? instructor}
                    bucket={'profile_images'}
                    className="categoryListingImg"
                    status={user === instructor ? 'admin' : 'member'}
                />
            </div>

            <div className='groupCoverName'>
                <h3>{group?.name}{group && !group?.active && <Chip label="Nem aktív" color="error" />}</h3>
            </div>


            {(isAdmin || isMember || isSuperAdmin || isGroupManager) &&
                <div className='groupCoverMembers'>
                    <AvatarGroup max={5}>

                        {user !== instructor && (isMember || isAdmin) &&
                            <StorageAvatar
                                sx={{ bgcolor: deepOrange[500] }}
                                value={user}
                                bucket={'profile_images'}
                                className="aaa"
                                size={'medium'}
                                status={isSubscribed ? 'subscribed' : (isAdmin ? 'admin' : 'member')}
                            />}
                        {group?.Members?.map(x => (
                            x !== user && x !== instructor.id &&
                            <StorageAvatar
                                key={`StorageAvatar1${x}`}
                                value={x}
                                bucket={'profile_images'}
                                className="aaa"
                                size={'medium'}
                            />

                        ))
                        }
                        {group?.admins?.map(x => (
                            x !== user && x !== instructor.id &&
                            <StorageAvatar
                                key={`StorageAvatar2${x}`}
                                value={x}
                                bucket={'profile_images'}
                                className="aaa"
                                size={'medium'}
                            />
                        ))
                        }
                    </AvatarGroup>
                </div>
            }
            {(isSubscribed) &&
                <div className='groupHeaderTextRight3'>
                    <AvatarGroup max={5}>
                        <StorageAvatar
                            value={user}
                            bucket={'profile_images'}
                            className="aaa"
                            size={'medium'}
                            status={isSubscribed ? 'subscribed' : (isAdmin ? 'admin' : 'member')}
                        />
                    </AvatarGroup>
                </div>
            }

            <div
                className="shareIconDiv"
                onClick={() => {
                    navigator.clipboard.writeText(groupUrl);
                    setShareLinkCopied(true);
                    setTimeout(() => {
                        setShareLinkCopied(false);
                    }, 2000);
                }}>
                <img src={shareIcon} alt="Share" />
            </div>
            {shareLinkCopied && <p className="linkCopied">A csoport kódját kimásoltuk a vágólapra</p>}
        </div>
    );
};

export default GroupCover;