import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';

const AddManagerAction = () => {
    const { createDocument } = useContext(AppContext);
    const subscribe = async ({ userId, groupId }) => {
        console.log('userId', userId);
        console.log('groupId', groupId);
        const newRecord = {
            groupId: groupId,
            userId: userId
        }
        const result = await createDocument({ table: 'groupmanagers', record: newRecord })
        return result;
    }

    return {
        subscribe
    }
};

export default AddManagerAction;