import { images } from "./images"

export const authentication = {
  isAdmin: false,
  icon: 'PhonelinkLock',
  type: 'help',
  content: 'Hitelesítés',
  description: `A hitelesítés folyamata alapvető ahhoz, hogy biztonságosan hozzáférhessen 
  a kívánt rendszerhez. 
  Itt részletesen bemutatjuk a bejelentkezés, regisztráció és elfelejtett jelszó lépéseit.`,
  steps: [
    {
      type: 'slide', // slide, link
      selector: '.selectApplication',
      content: 'Rendszer kiválasztása',
      description: `Mielőtt bejelentkezne / regisztrálna / visszaállítaná jelszavát, 
      kérjük, válassza ki azt a rendszert, amelyhez hozzá szeretne férni. 
      Ez biztosítja, hogy az adott rendszer specifikus bejelentkezési adatait használja.`,
      image: images.signin,
      help: null,
      mark: 'rect',
      markRadius: 30,
      markXPercent: 60,
      markYPercent: 24,
      markRectangleWidth: 77,
      markRectangleHeight: 35,
      animations: [
        { name: 'cursor', action: 'move' }
      ]
    },
    {
      type: 'slide', // slide, link
      selector: '.login',
      content: 'Bejelentkezés',
      description: `
      A bejelentkező oldalon egyszerűen és biztonságosan léphet be fiókjába az e-mail címével és jelszavával. 
      A bejelentkezéshez kövesse az alábbi lépéseket:
        <ul>
        <li>Adja meg e-mail címét.</li>
        <li>Írja be jelszavát.</li>
        <li>Kattintson a "Bejelentkezés" gombra.</li>
        </ul>
      `,
      image: images.signin,
      help: null,
      animations: [
        { name: 'cursor', action: 'move', fromX: 52, fromY: 37, toX: 52, toY: 55},
    ]
    },
    {
      type: 'slide', // slide, link
      selector: '.login',
      content: 'Regisztráció',
      description: `Ha még nincs regisztrált fiókja, 
      könnyedén létrehozhatja azt a regisztrációs oldalunkon. A regisztrációhoz szükséges lépések:
        <ul>
        <li>Adja meg nevét és e-mail címét.</li>
        <li>Válasszon egy biztonságos jelszót.</li>
        <li>Fogadja el a felhasználási feltételeket.</li>
        <li>Kattintson a "Regisztráció" gombra.</li>
        </ul>
      `,
      image: images.signup,
      help: null,
    },
    {
      type: 'slide', // slide, link
      selector: '.login',
      content: 'E-mail cím megerősítése',
      description: `Az e-mail cím megerősítése biztonsági okokból és a kommunikáció 
      hatékonyságának növelése érdekében szükséges. 
      Az e-mail cím megerősítése nélkül az alkalmazás csak ötször indítható el.

<ul>
<li>Regisztráljon a kívánt rendszerbe.</li>
<li>Kattintson a megjelenő képernyőn az E-mail újraküldése gombra, majd ezt követően a küldött megerősítő e-mailben található linkre.</li>
<li>Ezzel hitelesíti e-mail címét és teljes hozzáférést kap a rendszerhez.</li>
</ul>
Felhasználási feltételek elfogadása: az alkalmazás használatához minden felhasználónak el kell fogadnia a felhasználási feltételeket.
      `,
      image: images.confirm,
      help: null,
    },
    {
      type: 'slide', // slide, link
      selector: '.forgotpassword',
      content: 'Elfelejtett jelszó',
      description: `
      Ha elfelejtette jelszavát az elfelejtett jelszó oldalon, könnyedén visszaállíthatja azt. A jelszó visszaállításához:
        <ul>
        <li>A bejelentkező oldalon kattintson az elfelejtett jelszó gombra.</li>
        <li>Adja meg regisztrált e-mail címét.</li>
        <li>Kattintson a "Új jelszó beállítása" gombra.</li>
        <li>E-mailben kap egy útmutatót a jelszó visszaállításához szükséges lépésekről.</li>
      </ul>`,
      image: images.forgotpassword,
      help: null,
    }
    // {
    //   type: 'slide', // slide, link
    //   selector: '.registrationGoogle',
    //   content: 'Regisztráció Google azonosítóval',
    //   description: 'Regisztrációkor lehetősége van választani, hogy hagyományos e-mail és jelszó kombinációval szeretne regisztrálni, vagy egyszerűen használja Google fiókját a regisztrációhoz.',
    //   image: images.signup,
    //   help: null,
    //   mark: 'rect',
    //   markRadius: 30,
    //   markXPercent: 13,
    //   markYPercent: 73,
    //   markRectangleWidth: 87,
    //   markRectangleHeight: 83
    // },
  ]
}
