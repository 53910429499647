import React from 'react';
import NextButton from '../../buttons/NextButton';
import FSPFormField from './FSPForm/FSPField/OLD/FSPFormField';
import QQuestion from '../../stepperSampling/components/ui/QQuestion';
// import QQuestion from './ui/QQuestion';

const FieldPage = ({
    fields,
    actual,
    next,
    values,
    meta,
    setValues
}) => {
    const field = fields[actual];
    // const metaT = field.model ? field.model : field.question;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    }

    const handleChangeTable = (name, value) => {
        setValues({ ...values, [name]: value });
    }
    // console.log('field', field);
    // console.log('meta', meta);
    return (
        <QQuestion overflow='auto'>
            <div style={{ minWidth: "900", margin: "auto" }}>
                <h1>{field.label}</h1>
                <p>{field.helper}</p>

                <FSPFormField
                    field={field}
                    meta={meta}
                    newRecord={values}
                    handleChange={handleChange}
                    handleChangeArray={handleChangeTable}
                // metaArray={meta[metaT]} 
                />

                <NextButton
                    name={'Tovább'}
                    variant={'contained'}
                    onClick={() => { next() }}
                    disabled={!values[field.name] || (Array.isArray(values[field.name]) && values[field.name].length < 1)}
                />
            </div>
        </QQuestion>
    );
};

export default FieldPage;