import { images } from "./images"

export const activeGroup = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Aktív/inaktív csoportok',
    description: `Minden csoport rendelkezik egy aktív vagy nem aktív státusszal.
    Ez lehetővé teszi a csoport ügyintézői számára a régi, nem aktív csoportok archiválását. 
    A nem aktív csoportok esetében bizonyos funkciók korlátozottan érhetők el. Például a nem aktív csoportok nem indíthatnak új teszteket, nem vehetnek fel új tagokat. 
    A kezdő oldalon megjelenik egy új kapcsoló, amely lehetővé teszi az aktív és nem aktív csoportok külön-külön történő megjelenítését. 
    Ez segíti az felhasználókat abban, hogy könnyebben áttekintsék és kezeljék a csoportokat.`,
    steps: [
        {
            type: 'slide', // slide, link
            version: 2,
            selector: '.mygroupsUsersList',
            content: 'Aktív/inaktív kapcsoló a kezdő oldalon',
            description: 'A kezdő oldalon megjelenik egy új kapcsoló, amely lehetővé teszi az aktív és az inaktív csoportok külön-külön történő megjelenítését. Ez segíti az felhasználókat abban, hogy könnyebben áttekintsék és kezeljék a csoportokat.',
            image: images.inactiveDashboard,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 73,
            markYPercent: 29,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            elements: [
                {
                    id: 'circle1',
                    type: 'circle',
                    show: true
                },
                {
                    id: 'cursor1',
                    type: 'cursor'
                },
                {
                    id: 'image1',
                    type: 'image',
                    url: images.inactiveDashboard2 // URL az új Image2 komponenshez
                }
            ],
            animations: [
                { id: 'circle1', name: 'circle', action: 'show', show: true },
                { id: 'cursor1', name: 'cursor', action: 'move', show: true },
                { id: 'image1', name: 'image', action: 'show', show: true },
                { id: 'circle1', action: 'hide' },
                { id: 'cursor1', name: 'cursor', action: 'move', show: true },
                { id: 'image1', action: 'hide' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Inaktív csoportok',
            description: 'Az inaktív csoportok esetében bizonyos funkciók korlátozottan érhetők el. Például a nem aktív csoportok nem indíthatnak új teszteket, nem vehetnek fel új tagokat.',
            image: images.inactiveMyGroup,
            help: null,
            // mark: 'circle',
            // markRadius: 20,
            // markXPercent: 68,
            // markYPercent: 30,
            // markRectangleWidth: 71,
            // markRectangleHeight: 34,
            // animations: [
            //     { name: 'cursor', action: 'move' },
            // ]
        },
        {
            type: 'title', // slide, link
            selector: '.dashboard1',
            content: 'Aktív/inaktív státusz beállítása',
            description: 'A rendszer lehetővé teszi a csoport ügyintézői számára, hogy szerkesszék a csoport adatait, beleértve a csoport nevét és aktív státuszát.',
            // image: images.dashboard2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás a beállításokhoz',
            description: 'Lépjen a Csoport oldalára. Válassza ki a jobb alsó sarokban lévő Beállítások ikont.',
            image: images.mygroupsUsersList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 83,
            markYPercent: 85,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            animations: [
                { name: 'cursor', action: 'move' },
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsEdit',
            content: 'Beállítások',
            description: 'Kattintson a Beállítások fülre.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 34,
            markRectangleWidth: 36,
            markRectangleHeight: 40,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Csoport állapotának szerkesztése',
            description: 'Kapcsolja be vagy ki az Aktív kapcsolót a csoport állapotának módosításához.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 53,
            markRectangleWidth: 36,
            markRectangleHeight: 58,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Mentés',
            description: 'Kattintson a Mentés gombra a módosítások mentéséhez.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 73,
            markRectangleWidth: 89,
            markRectangleHeight: 77,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        }
    ]
}
