import React from 'react';
import GroupForm from './GroupForm';
import BSAccordion from '../../myGroup/components/groupStats/components/BSAccordion';
import InfoCards from '../../../commons/help/widgets/InfoCards';
import { stepsGroupSetup } from '../../../commons/help/data/stepsGroupSetup';

const Setup = ({ group }) => {
    return (
        <div>
            <BSAccordion label='Súgók' title='Csoport beállítások'>
                <InfoCards steps={stepsGroupSetup} dialog={true} />
            </BSAccordion>
            <h3>Beállítások</h3>

            <GroupForm
                group={group}
            />
        </div>
    );
};

export default Setup;