import React from 'react';
import NextButton from '../../buttons/NextButton';
import QMultiSelect from './ui/QMultiSelect';
import QSelect from './ui/QSelect';

const SubmitPage = ({
    fields,
    meta,
    values,
    submitCallback,
    started
}) => {
    // console.log('meta', meta);
    // console.log('values', values);

    return (
        <div style={{ width: "100%" }}>
            <h1>Összefoglalás</h1>
            {fields && Object.keys(fields).map((id, index) => (
                <p key={index}><p>{fields[id].label}</p>
                    {fields[id].type === 'document_select_template' &&
                        <QSelect
                            meta={meta[fields[id].model]}
                            value={values[fields[id].name]}
                        />
                    }
                    {fields[id].type === 'document_multiselect_group' &&
                        <QMultiSelect
                            meta={meta[fields[id].model]}
                            values={values[fields[id].name]}
                        />
                    }
                    {fields[id].type === 'date' &&
                        <h3>
                            {values[fields[id].name]}
                        </h3>
                    }
                </p>
            ))
            }

            <NextButton
                disabled={started}
                name={'Indítás'}
                variant={'contained'}
                onClick={() => { submitCallback({ values: values }) }}
            />
        </div>
    );
};

export default SubmitPage;