import { images } from "./images"

export const evaluations = {
    type: 'help',
    isAdmin: false,
    icon: 'BarChart',
    content: 'Értékelések',
    description: 'Az értékelések a főmenüből érhetőek el',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.evaluations1',
            content: 'Funkciók',
            description: 'Az "Értékelések" menüpont alatt könnyen navigálhat az összes elérhető értékeléshez. Itt találja meg azokat a visszajelzéseket, amelyeket a felhasználók vagy más résztvevők adtak.',
            image: images.dashboard2,
            help: null,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 59,
            markYPercent: 91,
            markRectangleWidth: 76,
            markRectangleHeight: 97
        },
        {
            type: 'slide', // slide, link
            selector: '.evaluations2',
            content: 'Olvasatlan értékelések',
            description: ' A menüpont mellett található egy "badge", amely az olvasatlan értékelések számát mutatja. Ez a vizuális jelzés segíti Önt azonosítani azokat az értékeléseket, amelyek még elolvasatlanok',
            image: images.evaluations1,
            help: null,
            mark: 'circle',
            markRadius: 14,
            markXPercent: 71.3,
            markYPercent: 92.4,
            markRectangleWidth: 76,
            markRectangleHeight: 97
        },
        {
            type: 'slide', // slide, link
            selector: '.evaluations3',
            content: 'Értékelés részletei',
            description: 'Amikor egy értékelést kiválaszt, láthatóvá válnak annak főbb statisztikai adatai. Ez lehetőséget biztosít a gyors áttekintésre és az értékelés főbb jellemzőinek megértésére.',
            image: images.evaluations2,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 8,
            markYPercent: 42,
            markRectangleWidth: 92,
            markRectangleHeight: 90
        },
        {
            type: 'slide', // slide, link
            selector: '.evaluations4',
            content: 'Egyes értékelések Eredményei',
            description: 'Az értékelés részletes nézetében a felhasználóknak lehetőségük van megtekinteni az egyes értékelések eredményeit.',
            image: images.evaluations3,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 8,
            markYPercent: 66,
            markRectangleWidth: 92,
            markRectangleHeight: 89
        },
    
    ]
}
