import React from 'react';
import { ThumbUpAlt } from '@mui/icons-material';
import Tada from 'react-reveal/Tada';

const Thanks = ({ form, navigateAfterSubmit, success, myGroups }) => {
    return (
        <div className='centerContent' onClick={() => { navigateAfterSubmit() }}>
            <h2>Sikeres mentés</h2>
            <p>A kérdőívet sikeresen elkészítettük.</p>
            <Tada>
                <ThumbUpAlt sx={{ fontSize: 170 }} color="success" />
            </Tada>
            {Object.keys(success).map((s, index) =>
                <h3>{myGroups[s].name}</h3>
            )}
            <p className='infoContent'>Tovább a kezdőoldalra</p>
        </div>
    );
};

export default Thanks;