import {
    collection, setDoc, getDocs,
    deleteDoc, addDoc, doc, getDoc, query, where, documentId
} from "firebase/firestore";
import { db, auth } from "../../firebase.config";
import { useState } from "react";

const Documents = ({ setLoading }) => {
    const [documents, setDocuments] = useState([])

    const setDocument = ({ key, value }) => {
        setDocuments((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const getDocument = async ({ key, table, id }) => {
        const userRef = doc(db, table, id);
        try {
            const result = await getDoc(userRef)
            // .then((snap) => {
            //     setDocument({ key: key, value: snap.data() });
            //     setLoading({ key: key, status: false })
            //     console.log('66666-1', snap.data());
            //     return snap;
            // })
            setDocument({ key: key, value: result.data() });
            setLoading({ key: key, status: false })
            return result.data();
        } catch (error) {
            console.error("Error getDoc document: ", error);
            return null;
        }
    }

    const getDocumentByFilter = async ({ key, table, filter }) => {
        // const userRef = doc(db, table, id);
        const queryConstraints = [];
        if (filter) {
            // Ha a filters paraméter egy objektum, akkor konvertáljuk egy tömbbé
            let filters
            if (!Array.isArray(filter)) {
                filters = [filter];
            } else {
                filters = filter;
            }
            filters.forEach((f) => {
                queryConstraints.push(where(
                    f.field === 'uid' ? documentId() : f.field,
                    f.operation,
                    f.data
                ))
            });
        }
        const q = query(collection(db, table), ...queryConstraints);
        console.log('filter12', queryConstraints);
        try {
            const result = await getDocs(q)
                .then((snap) => {
                    const a = (snap && snap.docs && snap.docs[0]) ? snap.docs[0] : null;
                    const b = { ...a?.data(), id: a?.id }
                    if (a) {
                        setDocument({ key: key, value: b });
                        localStorage.setItem(key, JSON.stringify(b));
                    } else {
                        localStorage.setItem(key, JSON.stringify({}));
                    }
                    return b;
                })
                .catch((err) => {
                    console.error('error', err);
                    throw err;
                });

            setLoading({ key: key, status: false })
            return result;
        } catch (error) {
            console.error("Error getDoc document: ", error);
            return null;
        }
    }

    const queryDocByFilter = async ({ key, id, table, filter, refresh = false, notFromCache = true }) => {
        console.log('IDD6', id);
        console.log('filter12', filter);
        setLoading({ key: key, status: true })
        const localStorageKey = key + id;
        const lsDoc = JSON.parse(localStorage.getItem(localStorageKey));
        if (!lsDoc || notFromCache) {
            try {
                if (key && table && filter) {
                    if (!documents[localStorageKey] || refresh) {
                        const result = await getDocumentByFilter({ key: localStorageKey, table: table, filter: filter })
                        return result;
                    } else {
                        console.log('CACHED! The DOCUMENT has already been downloaded from the server.');
                        return documents[localStorageKey];
                    }
                }
            } catch (error) {
                console.error("Error adding document: ", error);
                return null;
            }
        } else {
            console.log('LS! The image has already been downloaded from the LS.');
            setDocument({ key: key, value: lsDoc });
            return lsDoc;
        }
    }
    function isJson(str) {
        try {
            const lsDoc = JSON.parse(str);
            return lsDoc
        } catch (e) {
            return null;
        }
        
    }
    const queryDoc = async ({ key, table, id, refresh = false, notFromCache = true }) => {
        setLoading({ key: key, status: true })
        const localStorageKey = key + '-' + id;
        const lsdata = localStorage.getItem(localStorageKey) || '';
        const lsDoc = isJson(lsdata);
        if (!lsDoc || notFromCache) {
            try {
                if (key && table && id) {
                    if (!documents[key] || refresh) {
                        const result = await getDocument({ key: key, table: table, id: id })
                        localStorage.setItem(localStorageKey, JSON.stringify(result));
                        return result;
                    } else {
                        console.log('CACHED! The DOCUMENT has already been downloaded from the server.');
                        return documents[key];
                    }
                }
            } catch (error) {
                console.error("Error adding document: ", error);
                return null;
            }
        } else {
            console.log('LS! The image has already been downloaded from the LS.');
            return lsDoc;
        }
    }

    async function deleteDocuments({ table, filter1, filter2 }) {
        const myCollection = collection(db, table)
        const q = query(myCollection, where(
            filter1.field === 'uid' ? documentId() : filter1.field,
            filter1.operation,
            filter1.data
        ),
            where(
                filter2.field === 'uid' ? documentId() : filter2.field,
                filter2.operation,
                filter2.data
            )
        );

        try {
            const querySnapshot = await getDocs(q);
            const deletePromises = [];

            querySnapshot.forEach((d) => {
                const docRef = doc(myCollection, d.id);
                deletePromises.push(deleteDoc(docRef));
            });

            const results = await Promise.all(deletePromises);
            return results;
        } catch (error) {
            console.log('error', error);
            return null;
        }
    }

    const deleteDocument = async ({ table, id }) => {
        try {
            const result = await deleteDoc(doc(db, table, id)).then(() => {
                console.log("deleted");
                return true;
            });
            return result;
        } catch (e) {
            console.error("Error deleting document: ", e);
            return null;
        }
    };

    const createDocument = async ({ table, record }) => {
        const user = auth ? auth.currentUser : { uid: null }
        var data = {
            ...record,
            authorId: user?.uid,
            createdAt: new Date(),
            updatedAt: new Date(),
        };
        const userRef = collection(db, table);
        try {
            const result = await addDoc(userRef, data)
                .then((snap) => {
                    // console.log('rrrrrrrr', snap?.id);
                    return snap;
                })
            // console.log('rrrrrrrr2', result?.id);
            return result;
        } catch (e) {
            console.error("Error adding document: ", e);
            return null;
        }
    }

    // const createDocumentTest = async ({ table, record }) => {
    //     const user = auth ? auth.currentUser : { uid: null };
    //     const userRef = collection(db, table);
    //     console.log('A következő 9 dokumentum az utóbbi 7 napban');
    //     // Egy nappal kiszámolva
    //     const oneDay = 24 * 60 * 60 * 1000 * 30;

    //     const documentsToCreate = [];

    //     // Az első dokumentum a mai dátummal
    //     documentsToCreate.push({
    //         ...record,
    //         authorId: user?.uid,
    //         createdAt: new Date(),
    //         from: new Date(),
    //         to: new Date(),
    //         updatedAt: new Date(),
    //     });

    //     // A következő 9 dokumentum az utóbbi 7 napban
    //     for (let i = 1; i <= 11; i++) {
    //         const previousDate = new Date(Date.now() - i * oneDay);
    //         console.log('A következő 9 dokumentum az utóbbi 7 napban', previousDate);
    //         documentsToCreate.push({
    //             ...record,
    //             authorId: user?.uid,
    //             from: previousDate,
    //             to: previousDate,
    //             createdAt: previousDate,
    //             updatedAt: previousDate,
    //         });
    //     }
    //     console.log('A következő 9 dokumentum az utóbbi 7 napban', documentsToCreate);
    //     try {
    //         const results = await Promise.all(
    //             documentsToCreate.map(async (data) => {
    //                 const result = await addDoc(userRef, data);
    //                 console.log('A következő 9 dokumentum az utóbbi 7 napban', result);
    //                 return result;
    //             })
    //         );

    //         return results;
    //     } catch (e) {
    //         console.error("Error adding documents: ", e);
    //         return null;
    //     }
    // };

    const updateDocument = async ({ id, table, record, fields }) => {
        try {
            const userRef = doc(db, table, id);
            var fieldvalues = {};
            fieldvalues['updatedAt'] = new Date();
            fields.forEach(f => (
                fieldvalues[f] = record[f]
            ));
            // console.log('id', id);
            // console.log('table', table);
            // console.log('fieldvalues', fieldvalues)
            await setDoc(userRef, fieldvalues, { merge: true })
                .then((snap) => {
                    return true;
                })
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return null;
        }
    }

    return {
        documents, setDocument, queryDoc, createDocument, updateDocument, deleteDocument, deleteDocuments,
        queryDocByFilter

    };
};

export default Documents;