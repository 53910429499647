import React from 'react';
import { PlayArrow } from '@mui/icons-material';
import Tada from 'react-reveal/Tada';
import Pulse from 'react-reveal/Pulse';
const StartPage = ({
    form,
    next
}) => {
    return (<>

        <div className='centerContent' onClick={() => { next() }}>
            <h1>{form.name}</h1>
            <p>{form.description}</p>
            <Pulse>
                <Tada>
                    <PlayArrow sx={{ fontSize: 170 }} color="success" />
                </Tada>
            </Pulse>
            <p className='infoContent'>Tovább</p>
        </div>
    </>
    );
};

export default StartPage;