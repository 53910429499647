import React, { useEffect, useState } from 'react';
import { Button, Select, MenuItem, TextField, Checkbox, FormControlLabel, FormControl, InputLabel, FormHelperText } from '@mui/material';
import CapturePhoto from './CapturePhoto';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AddAPhoto } from '@mui/icons-material';
import CreateErrorReport from '../../../services/actions/CreateErrorReport';
import { toast } from "react-toastify";
import Resizer from 'react-image-file-resizer';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../firebase.config';
import Spinner from '../../../components/Spinner';

const ErrorForm = ({
    content,
    setContent,
    setSubmissionStatus,
    onClose,
    showForm,
    handleCloseForm,
    blob, setBlob,
    screenshot
}) => {
    const { subscribe } = CreateErrorReport();
    const [selectedTheme, setSelectedTheme] = useState('feedback');
    const [description, setDescription] = useState('');

    const [sendScreenshot, setSendScreenshot] = useState(screenshot);
    const [openCaptureDialog, setOpenCaptureDialog] = useState(false);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file && isImageFile(file) && isFileSizeValid(file)) {
            setContent(URL.createObjectURL(file));
            const blob = await resizeFile(file);
            setBlob(blob);
            setSendScreenshot(true);
        } else {
            toast.error('Hiba: Érvénytelen fájl formátum vagy méret!');
        }
    };
    useEffect(() => {
        setSelectedTheme('feedback')
        setDescription('');
    }, [])

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(file, 1024, 1024, "JPEG", 90, 0, (uri) => { console.log(uri); resolve(uri); }, "blob",);;
        });

    const isImageFile = (file) => {
        return file.type.startsWith('image/');
    };

    const isFileSizeValid = (file) => {
        return file.size <= 500 * 2000 * 1024;
    };

    const handleSubmit = async () => {

        // Simulate saving to ErrorReport collection (replace with your actual logic)
        try {
            const result = await subscribe(
                {
                    subject: selectedTheme,
                    description: description,
                    sendScreenshot: sendScreenshot
                }
            );
            if (result) {
                // console.log('rrrr', result?.id);
                // toast.success(`Siker`);
                const storageRef = ref(storage, 'error_images/' + result?.id);
                // console.log(blob);
                if (sendScreenshot)
                    await uploadBytes(storageRef, blob);
            }
            else {
                console.log('HIBA')
                // toast.error(`Hiba a dokumentum létrehozásakor`);
            }
            setSubmissionStatus(result?.id);
            // setSuccess(true);
            // onClose(); // Zárja be a dialógust a sikeres beküldés után
        } catch (error) {
            setSubmissionStatus('Error during submission.');
        }
    };

    const handleSendScreenshotChange = (event) => {
        setSendScreenshot(event.target.checked);
    };

    const handleCapturePhoto = (photoDataUrl) => {
        setContent(photoDataUrl);
        setOpenCaptureDialog(false);
    };

    const openCaptureDialogHandler = () => {
        setOpenCaptureDialog(true);
    };

    const closeCaptureDialogHandler = () => {
        setOpenCaptureDialog(false);
    };

    return (
        <Dialog
            open={showForm}
            onClose={handleCloseForm}
            sx={{ width: '100%', height: '100%', maxWidth: 'none', maxHeight: 'none', margin: 0, padding: 0 }}
            fullScreen
        >
            <DialogTitle>Kapcsolat</DialogTitle>
            <DialogContent>
                <form>
                    <FormControl fullWidth>
                        <InputLabel id="theme-select-label">Téma</InputLabel>
                        <Select
                            labelId="theme-select-label"
                            id="theme-select"
                            value={selectedTheme}
                            onChange={(e) => setSelectedTheme(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="feedback">Visszajelzés</MenuItem>
                            <MenuItem value="question">Kérdés</MenuItem>
                            <MenuItem value="error">Hiba</MenuItem>
                            <MenuItem value="suggestion">Javaslat</MenuItem>
                        </Select>
                        <FormHelperText>Téma választása</FormHelperText>
                    </FormControl>

                    <TextField
                        label="Üzenet"
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                    />

                    <FormControlLabel
                        disabled={!content}
                        control={<Checkbox checked={sendScreenshot} onChange={handleSendScreenshotChange} />}
                        label="Kép küldése"
                    />
                    <div className='centerContent2'>
                        <label>
                            <div className='MuiButtonBase MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-sghohy-MuiButtonBase-root-MuiButton-root'>
                                Kép feltöltése
                            </div>

                            <input style={{ display: "none" }} placeholder="Password" type="file" accept="image/*" onChange={handleFileChange} ></input>
                        </label>

                    </div>

                    {/* <input type="file" accept="image/*" onChange={handleFileChange} /> */}
                    <div className='centerContent2'>
                        {sendScreenshot && (
                            content ? <img src={content} alt="Selected File" style={{ maxHeight: '50vh', marginTop: '10px' }} /> : <Spinner />
                        )
                        }
                    </div>
                    {/* <Button variant="contained" color="primary" onClick={openCaptureDialogHandler} style={{ marginTop: '10px' }}>
                        Fotó készítése
                    </Button> */}

                    <CapturePhoto open={openCaptureDialog} onClose={closeCaptureDialogHandler} onCapturePhoto={handleCapturePhoto} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={onClose}>
                    Mégsem
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
            {/* Opcionálisan hozzáadhatod a DialgActions részt is, például egy "Cancel" gombbal */}
        </Dialog>
    );
};

export default ErrorForm;
