import React from 'react';

const Info = ({ group, groupId }) => {
    return (
        <div>
            Info
        </div>
    );
};

export default Info;