import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import View from './View';
import Document from '../../hooks/document';
import { AppContext } from '../../services/appContext';
import { convertArrayById3 } from '../../commons/Utility';
import { auth } from '../../firebase.config';

const MyGroup = () => {
    const params = useParams();
    const { groupId } = params;
    // const auth = getAuth();
    const user = auth?.currentUser?.uid;
    const [myGroup, setMyGroup] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    const [instructor, setInstructor] = useState([]);
    const { isSuperAdmin, isManager } = useContext(AppContext);
    const { queries, snapshots, isLoading, loadings, setLoading, groupTab, setGroupTab } = useContext(AppContext);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(convertArrayById3(snapshots['allgroups'], 'id'));
        }
    }, [snapshots['allgroups']]);

    useEffect(() => {
        setMyGroup(myGroups[groupId]);
    }, [myGroups, groupId]);

    const groupinstructor = Document({
        setState: setInstructor,
        key: myGroup?.Oktató,
        table: 'users',
        id: myGroup?.Oktató,
        refresh: false
    });

    useEffect(() => {
        if (myGroup) {
            const unsubscribe = groupinstructor.subscribe();
        }
    }, [myGroup]);

    return (
        <View
            groupId={groupId}
            user={user}
            group={myGroup}
            instructor={myGroup?.Oktató}
            isSuperAdmin={isSuperAdmin}
            isManager={isManager}
            groupTab={groupTab}
            setGroupTab={setGroupTab}
        />
    );
};

export default MyGroup;