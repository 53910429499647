import { Box } from '@mui/material';
import React from 'react';
import StorageAvatar from '../../firebase/StorageAvatar';

const NextPerson2 = ({ name, icon, onClick }) => {
    return (

        <Box
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
                width: '100%',
                minHeight: `calc(100vh - 200px)`,
                bgcolor: 'background.paper',
                position: 'relative',
                padding: 0,
            }}
        >
            <div className='centerContent' onClick={() => onClick()}>
                <h3>Következő értékelt</h3>
                <h1>{name}</h1>
                <div className='avatar-container'>
                    <StorageAvatar
                        value={icon}
                        bucket={'profile_images'}
                        size='xxxlarge'
                    // className="categoryListingImg"
                    />
                </div>
                <p className='infoContent'>Tovább az értékeléshez</p>
            </div>

        </Box>
    );
};

export default NextPerson2;