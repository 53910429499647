import React from 'react';
import GroupItemMini from '../../../groups/GroupItemMini';

const QMultiSelect = ({ meta, values }) => {
    return (
        <ul>
            {
                values &&
                meta &&
                values.map((value, index) => (
                    <GroupItemMini
                        key={`GroupItemMini${index}`}
                        group={meta[value]}
                    />

                ))
            }
        </ul>
    );
};

export default QMultiSelect;