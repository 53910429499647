import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmDialog = ({ open, question, handleConfirm, handleCancel }) => {
    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{question}</DialogTitle>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Nem
                </Button>
                <Button variant="contained" onClick={handleConfirm} color="primary" autoFocus>
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;