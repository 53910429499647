import React from 'react';
import Icons from './MIIcons';
// import * as Icons from '@mui/icons-material';

const CIcon2 = ({ icon, fontSize = '30', color = '#ffffff', rotated = false }) => {
    if (icon) {
        const IconComponent = Icons[icon];
        return (
            <IconComponent
                fontSize={fontSize}
                sx={{
                    color: { color },
                    transform: rotated ? 'rotate(90deg)' : null,
                }}
                color={color}
            />
        );
    } else {
        return null;
    }

};

export default CIcon2;