import { images } from "./images"

export const stepsSchedules =
    [
        {
            type: 'link', // slide, link
            selector: '.schedules',
            content: 'Időzített kérdőívek',
            short: 'Időzített kérdőívek',
            description: '',
            link: 'schedules',
            isAdmin: true,
            icon: 'DateRange',
        },
    ]

