import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


export const help1 = (
    <div>
        <Typography variant="h4" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="h5" gutterBottom>
            Értékelés típusok: önértékelés/értékelés
        </Typography>
        <img src="https://kerdoiv.b612.hu/help/stat1.png" alt="Diagram Explanation" style={{ width: '80%', maxWidth: '400px', height: 'auto' }} />
        <Typography variant="body2" gutterBottom>
            Két alapvető értékelési típus létezik: az önértékelés és az értékelés.
        </Typography>
        <Typography variant="h6" gutterBottom>
            Típusok
        </Typography>
        <Typography component="ul">
            <li>Önértékelés</li>
            <li>Értékelés</li>
        </Typography>

        <Typography variant="h5" gutterBottom>
            Értékelő perspektíva
        </Typography>
        <img src="https://kerdoiv.b612.hu/help/stat2.png" alt="Diagram Explanation" style={{ width: '80%', maxWidth: '400px', height: 'auto' }} />
        <Typography variant="body2" gutterBottom>
            Az értékelő perspektívából nézve három fajta értékelési típus különböztethető meg. Az értékelést tovább csoportosítjuk az értékelő szempontjától.
            <ul>
                <li>Önértékelés</li>
                <li>Értékelés
                    <ul>
                        <li>Értékelésem</li>
                        <li>Mások értékelései</li>
                    </ul>
                </li>
            </ul>
        </Typography>
        <Typography variant="h5" gutterBottom>
            Csoport perspektívája
        </Typography>
        <img src="https://kerdoiv.b612.hu/help/stat3.png" alt="Diagram Explanation" style={{ width: '80%', maxWidth: '400px', height: 'auto' }} />
        <Typography variant="body2" gutterBottom>
            A csoport perspektívájából nézve a következő értékelési típusok különböztethetők meg:
            <ul>
                <li>Önértékelés
                    <ul>
                        <li>Az értékelt önértékelése</li>
                        <li>Többiek önértékelése</li>
                        <li><span style={{ color: 'grey' }}>Önértékelésem</span> <em>(Jelenleg a statisztikákban nem használjuk)</em></li>
                    </ul>
                </li>
                <li>Értékelés
                    <ul>
                        <li>Értékelésem</li>
                        <li>Mások értékelései</li>
                        <li>Értékelésem másokra</li>
                        <li>Egyéb (más felhasználó értékel mást)</li>
                        <li><span style={{ color: 'grey' }}>Engem értékelnek</span> <em>(Jelenleg a statisztikákban nem használjuk)</em></li>
                    </ul>
                </li>
            </ul>
        </Typography>

        xxxx
        <Typography variant="h6" gutterBottom>
            Heti Kérdőív Kitöltések Száma és Érzelmi Állapot Időbeli Alakulása
        </Typography>
        <Typography variant="body1" gutterBottom>
            Ez a diagram azt mutatja be, hogyan változott a diákok heti kérdőív kitöltéseinek száma és a kitöltések során megadott érzelmi állapotok különböző statisztikai mutatói az idő múlásával. A diagram vízszintes tengelyén az idő/dátum szerepel heti bontásban. A függőleges tengelyen több különböző mérőszám szerepelhet a kiválasztott beállításoknak megfelelően: kitöltések száma, érzelmi állapot átlagos értéke, minimum és maximum értékek, medián érték, illetve a szórás.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>A diagram válaszokat adhat a következő kérdésekre:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            <strong>Hogyan változik a kitöltések száma az idő függvényében?</strong> Ez a mérőszám segíthet megérteni, hogy mikor töltik ki a diákok a legtöbb kérdőívet (pl. vizsgaidőszakban, félév végén stb.).
        </Typography>
        <Typography variant="body2" gutterBottom>
            <strong>Mi az átlagos érzelmi állapot a kitöltések során az idő folyamán?</strong> Ez az adat segíthet azonosítani azokat az időszakokat, amikor a diákok jobban vagy rosszabbul érzik magukat a kérdőívek kitöltésekor.
        </Typography>
        <Typography variant="body2" gutterBottom>
            <strong>Milyen a minimum és maximum érzelmi állapot értékek időbeli alakulása?</strong> Ezek az értékek segítenek megérteni, hogy vannak-e szélsőséges érzelmi állapotok a kitöltések során bizonyos időszakokban.
        </Typography>
        <Typography variant="body2" gutterBottom>
            <strong>Mi a medián érzelmi állapot a kitöltések során az idő folyamán?</strong> A medián érték megmutatja, hogy a diákok középértéke hogyan változik az idő függvényében, ami stabilabb képet adhat az érzelmi állapotok trendjeiről.
        </Typography>
        <Typography variant="body2" gutterBottom>
            <strong>Mi a szórás az érzelmi állapotok között a kitöltések során az idő folyamán?</strong> A szórás érték megmutatja, hogy mennyire változékonyak az érzelmi állapotok, és segít azonosítani, hogy vannak-e nagyobb ingadozások bizonyos időszakokban.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Használat:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            A diagram segíthet az oktatóknak azonosítani, hogy mikor vannak a diákok stresszesebbek vagy nyugodtabbak a kérdőívek kitöltésekor.
        </Typography>
        <Typography variant="body2" gutterBottom>
            Segítséget nyújthat a tanulási módszerek és támogatási stratégiák optimalizálásában.
        </Typography>
        <Typography variant="body2" gutterBottom>
            Az eredmények alapján célzott beavatkozásokat lehet tervezni a diákok támogatására, különösen azokban az időszakokban, amikor a kitöltések száma vagy az érzelmi állapot romlik.
        </Typography>
        <Typography variant="body1" gutterBottom>
            Ez a diagram hasznos eszköz lehet az oktatási folyamatok elemzésében és a diákok jólétének nyomon követésében.
        </Typography>
    </div>
);



export const help2 = `Használati útmutató: értékelési típusok megjelenítése: A vízszintes tengelyen két típusú értékelés található: értékelés és önértékelés.\n
                        Értékelések megtekintése: A diagram oszlopai az adott típusú értékelések számát jelölik.`

export const help3 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen különböző típusú értékelések találhatóak:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelésem:</strong> Az önértékeléseim száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Más személyek önértékeléseinek száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő:</strong> Az általam végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelt:</strong> Az engem értékeltek száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Egyéb:</strong> Egy másik felhasználó által végzett értékelések száma.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsuk össze az egyes típusú értékelések mennyiségét, hogy azonosítsuk az esetleges eltéréseket vagy trendeket.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik típusú értékelésből van több, és ez alapján fejleszthetjük a teljesítményértékelési folyamatokat.
        </Typography>
    </div>
)

export const help4 = `Használati útmutató: kérdőívek megjelenítése: A vízszintes tengelyen a kérdőívek találhatóak.\n
                        Értékelések megtekintése: A diagram oszlopai az adott típusú értékelések számát jelölik.`


export const help5 = `Használati útmutató: Felhasználók megjelenítése: A vízszintes tengelyen a csoport értékelt felhasználói találhatóak.\n
                        Értékelések megtekintése: A diagram oszlopai az adott típusú értékelések számát jelölik.`

export const help6 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen különböző típusú értékelések találhatóak:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelésem:</strong> Az általam végzett önértékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Más személyek által végzett önértékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő:</strong> Az általam másokról végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelt:</strong> Az engem értékelők száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Egyéb:</strong> Egy másik felhasználó által végzett egyéb értékelések száma.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik felhasználónként.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsa össze az egyes felhasználók által végzett és róluk készült értékelések mennyiségét a különböző típusok szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik felhasználó milyen típusú értékelést végzett és kapott, és ez alapján fejlesztheti a teljesítményértékelési folyamatokat.
        </Typography>
    </div>
)


export const help7 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen különböző típusú értékelések találhatóak:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelésem:</strong> Az általam végzett önértékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Más személyek által végzett önértékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő:</strong> Az általam másokról végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelt:</strong> Az engem értékelők száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Egyéb:</strong> Egy másik felhasználó által végzett egyéb értékelések száma.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik kérdőívenként.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsa össze az egyes kérdőívek által végzett és róluk készült értékelések mennyiségét a különböző típusok szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik kérdőív milyen típusú értékelést végzett és kapott, és ez alapján fejlesztheti a teljesítményértékelési folyamatokat.
        </Typography>
    </div>
)

export const help8 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen különböző típusú értékelések találhatóak:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelés:</strong> Az általam másokról végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Az általam végzett önértékelések száma.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik címkék szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsa össze az egyes címkék alapján végzett és róluk készült értékelések mennyiségét a különböző típusok szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik címke milyen típusú értékelést végzett és kapott, és ez alapján fejlesztheti a teljesítményértékelési folyamatokat.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Szűrés:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Felhasználó szűrő:</strong> Válassza ki, melyik felhasználó adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Címke választása:</strong> Válassza ki, melyik címkék adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő típusa választása:</strong> Válassza ki, melyik értékelő típusának adatait szeretné megjeleníteni.
        </Typography>
    </div>
)

export const help9 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen különböző típusú értékelések találhatóak:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelésem:</strong> Az általam végzett önértékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Más személyek által végzett önértékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő:</strong> Az általam másokról végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelt:</strong> Az engem értékelők száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Egyéb:</strong> Egy másik felhasználó által végzett egyéb értékelések száma.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik címkék szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsa össze az egyes címkék alapján végzett és róluk készült értékelések mennyiségét a különböző típusok szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik címke milyen típusú értékelést végzett és kapott, és ez alapján fejlesztheti a teljesítményértékelési folyamatokat.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Szűrés:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Felhasználó szűrő:</strong> Válassza ki, melyik felhasználó adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Címke választása:</strong> Válassza ki, melyik címkék adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő típusa választása:</strong> Válassza ki, melyik értékelő típusának adatait szeretné megjeleníteni.
        </Typography>
    </div>
)

export const help10 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen két típusú értékelés található:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelés:</strong> Az egyéb személyek által végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Az önértékelések száma.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik címkék szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsa össze az egyes címkék alapján végzett és róluk készült értékelések mennyiségét a különböző típusok szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik címke milyen típusú értékelést végzett és kapott, és ez alapján fejlesztheti a teljesítményértékelési folyamatokat.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Szűrés:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Felhasználó szűrő:</strong> Válassza ki, melyik felhasználó adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Címke választása:</strong> Válassza ki, melyik címkék adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő típusa választása:</strong> Válassza ki, melyik értékelő típusának adatait szeretné megjeleníteni.
        </Typography>
    </div>
)

export const help11 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelési típusok megjelenítése:</strong> A vízszintes tengelyen az értékelő típusok találhatók:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Egyéb:</strong> Mások által végzett értékelések száma.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Más személy önértékelése.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő:</strong> Én értékeltem másokat.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelt:</strong> Engem értékeltek.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelésem:</strong> Az én önértékelésem.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott típusú értékelések számát jelölik címkék szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Elemzés:</strong> Hasonlítsa össze az egyes címkék alapján végzett és kapott értékelések mennyiségét a különböző típusok szerint.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Felhasználás:</strong> A diagram segít megérteni, hogy melyik címke milyen típusú értékelést végzett és kapott, és ez alapján fejlesztheti a teljesítményértékelési folyamatokat.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Szűrés:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Felhasználó szűrő:</strong> Válassza ki, melyik felhasználó adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Címke választása:</strong> Válassza ki, melyik címkék adatait szeretné megjeleníteni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő típusa választása:</strong> Válassza ki, melyik értékelő típusának adatait szeretné megjeleníteni.
        </Typography>
    </div>
)

export const help12 = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések időbeli megoszlása:</strong> A vízszintes tengelyen a dátum található, választhatunk nap, hét vagy hónap közül.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelő típusok:</strong> A diagram különböző sorozatai az alábbi értékelő típusokat jelölik:
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Egyéb:</strong> Mások által végzett értékelések.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelés:</strong> Más személy önértékelése.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő:</strong> Én értékeltem másokat.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelt:</strong> Engem értékeltek.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Önértékelésem:</strong> Az én önértékelésem.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Értékelések megtekintése:</strong> A diagram oszlopai az adott időszakban és értékelő típusban végzett értékelések számát jelölik.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Szűrés:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Címke választása:</strong> Válassza ki, melyik címkék adatait szeretné megjeleníteni (többszörös választási lehetőség).
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Értékelő típusa szűrő:</strong> Válassza ki, melyik értékelő típus adatait szeretné megjeleníteni.
        </Typography>
    </div>
)
export const help13 = (
    <></>
)

export const helpMetaEditor = (
    <div>
        <Typography variant="h6" gutterBottom>
            Használati útmutató
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Kérdések és címkék kezelése:</strong> A MetaEditor felületen lehetőséged van kezelni a címkékhez tartozó kérdéseket, valamint új címkéket létrehozni.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Címkék listázása:</strong> A párbeszédablakban a címkék nevei jelennek meg, és minden címkéhez tartozó kérdések Chip formájában jelennek meg a címkék alatt.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Kérdések hozzáadása:</strong> A címkék listájában lévő "+" gombra kattintva új kérdést adhatsz hozzá a címkéhez. A hozzáadni kívánt kérdést a lenyíló menüből választhatod ki, amely csak azokat a kérdéseket listázza, amelyek még nincsenek hozzárendelve a címkéhez.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Kérdések törlése:</strong> A meglévő kérdések mellett található "x" gombra kattintva törölheted az adott kérdést a címkéből.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Új címke létrehozása:</strong> A párbeszédablak alján található "Új címke" gombra kattintva új címkét hozhatsz létre. Az új címke nevét és leírását megadhatod a megjelenő űrlapon.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Nézet váltás:</strong> A "Címkék nézet" vagy "Kérdések nézet" gombra kattintva válthatsz a címkék és a kérdések nézete között.
        </Typography>
        <Typography variant="body1" gutterBottom>
            <strong>Változtatások mentése vagy elvetése:</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Mentés:</strong> Az "Ok" gombra kattintva mentheted a változtatásokat, amelyek a <code>setQuestions</code> és <code>setLabels</code> metódusokon keresztül kerülnek alkalmazásra.
        </Typography>
        <Typography variant="body2" gutterBottom>
            - <strong>Elvetés:</strong> A "Mégsem" gombra kattintva elvetheted a változtatásokat, és visszatérhetsz az előző nézethez.
        </Typography>
    </div>
);
