import { SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';
import { Box, Rating } from '@mui/material';
import React from 'react';
import Swing from 'react-reveal/Swing';

const EmotionalState = ({ setShow, emotional, setEmotional }) => {
    const screenWidth = window.screen.width;
    const size = Math.min(screenWidth / 6, 150);
    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfied sx={{ fontSize: size }} color="error" />,
            iconBig: <SentimentVeryDissatisfied sx={{ fontSize: 170 }} color="error" />,
            label: 'Előfordult már veled, hogy úgy hatott a kritika/vélemény, mintha hideg vízzel öntöttek volna nyakon?',
        },
        2: {
            icon: <SentimentDissatisfied sx={{ fontSize: size }} color="error" />,
            iconBig: <SentimentDissatisfied sx={{ fontSize: 170 }} color="error" />,
            label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, hogy ez túlzás, nem reális, túl szép, hogy igaz legyen?',
        },
        3: {
            icon: <SentimentSatisfied sx={{ fontSize: size }} color="warning" />,
            iconBig: <SentimentSatisfied sx={{ fontSize: 170 }} color="warning" />,
            label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, hogy ez túlzás, nem reális, túl szép, hogy igaz legyen?',
        },
        4: {
            icon: <SentimentSatisfiedAlt sx={{ fontSize: size }} color="success" />,
            iconBig: <SentimentSatisfiedAlt sx={{ fontSize: 170 }} color="success" />,
            label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, mintha egy erős energialöketet kaptál volna?',
        },
        5: {
            icon: <SentimentVerySatisfied sx={{ fontSize: size }} color="success" />,
            iconBig: <SentimentVerySatisfied sx={{ fontSize: 170 }} color="success" />,
            label: 'Előfordult már veled, hogy úgy hatott rád egy visszajelzés, hogy azt mondtad magadban, ezen érdemes gondolkodnom?',
        },
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span className='listOption' {...other}>{customIcons[value].icon}</span>;
    }

    return (
        <Box
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                padding: 0,
            }}>
            {emotional ?
                <div className='centerContent' onClick={() => setShow(false)}>
                    <h1 className='infoContent'> Érzelmi állapot</h1 >
                    <h3 className='infoContent'>
                        {customIcons[emotional].label}
                    </h3>
                    <Swing>
                        {customIcons[emotional].iconBig}
                    </Swing>
                    <p className='infoContent'>Tovább az értékeléshez</p>
                </div >
                :
                <div className='centerContent'>
                    <h1 className='infoContent'>Érzelmi állapot</h1>
                    <h2 className='infoContent'>Milyen érzelmi állapotban vagy most amikor értékelsz</h2>
                    <div className='infoContent'>
                        <Rating
                            name="highlight-selected-only"
                            defaultValue={2}
                            IconContainerComponent={IconContainer}
                            getLabelText={(value) => customIcons[value].label}
                            highlightSelectedOnly
                            size="xlarge"
                            onChange={(event, newValue) => {
                                // console.log(newValue);
                                setEmotional(newValue)
                            }}
                        />
                    </div>
                </div>
            }
        </Box>
    )
}

export default EmotionalState;