import { Avatar, BottomNavigationAction, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import ConfirmDialog from '../../commons/ConfirmDialog';

const CButton2 = ({ icon, classname, name, variant = 'outlined', confirm = false, color = 'primary', onClick, disabled, show = true }) => {
    const [open, setOpen] = useState(false);

    return (<>
        {
            show &&
            <Grid className="profileButton" item xs={3}>
                <BottomNavigationAction
                    showLabel
                    onClick={() => setOpen(!disabled)}
                    label={name}
                    icon={
                        <Avatar sx={{ bgcolor: color }} variant="square">
                            {icon}
                        </Avatar>
                    } />
            </Grid>
        }
        <ConfirmDialog
            open={open}
            question={"Biztos végrehajtja a műveletet?"}
            handleConfirm={() => { setOpen(false); onClick() }}
            handleCancel={() => setOpen(false)}
        />
    </>
    );
};

export default CButton2;