import React, { useEffect, useState } from 'react';
import StatUtility from '../../anonimousData/StatUtility';
import { v4 as uuidv4 } from "uuid";
import StatSelect from './components/components/StatSelect';
import ValueSelect from './components/components/ValueSelect';
import { FormControl, Stack } from '@mui/material';
import FiltersBox from '../components/FiltersBox';
import { chartTypeFilters2, dateFilters2, dateFilters3, statFunctions } from '../../anonimousData/Constansts';
import SelectLabel4 from '../../../../statistics/components/components/SelectLabel4';
import FiltersBox2 from '../components/FiltersBox2';
import MyToggleButtonGroup from '../../MyToggleButtonGroup';
import CategoryApexChartSeries2 from './components/CategoryApexChartSeries2';
import HeaderSimple3 from './components/components/HeaderSimple3';

const LabelsSeriesFilterChart1 = ({
    data,
    categoryFields,
    seriesFields,
    valuesFields,
    filterFields = [],
    defaultCategoryField,
    defaultSeriesField,
    defaultValuesField,
    meta,

    // title,
    // description,
    help,
    helpData,
    defaultChartType = 'bar',
    showCategoryPanel = true,
    showSeriesPanel = true,
    showValuesPanel = true,
    showFilterPanel = true
}) => {
    const [selectedChartType, setSelectedChartType] = React.useState(chartTypeFilters2[defaultChartType ?? 'bar']);
    const [chartData, setChartData] = useState({
        seriesField: null,
        data: null,
    });
    const [chartData2, setChartData2] = useState({
        options: {},
        series: []
    });
    const { calculateStatistics3, addMetaToData, getLabelsStat5 } = StatUtility();
    const [selectedValue, setSelectedValue] = useState(defaultValuesField ?? 0);
    const [selectedStat, setSelectedStat] = useState(valuesFields[selectedValue].defaultValue ?? valuesFields[selectedValue].stat[0]);
    const [categoryField, setCategoryField] = useState(defaultCategoryField);
    const [seriesField, setSeriesField] = useState(defaultSeriesField);
    const [selectedCategoryField, setSelectedCategoryField] = useState(defaultCategoryField?.field === 'updatedAt' ? 'updatedAt' : defaultCategoryField?.field);
    const [selectedSeriesField, setSelectedSeriesField] = useState(defaultSeriesField?.field);
    const [selectedDateFilter, setSelectedDateFilter] = useState(dateFilters2.updatedAtWeek);
    const [viewMode, setViewMode] = React.useState('minimal');
    const [selectedFilterFields, setSelectedFilterFields] = useState(
        filterFields?.map(field => ({ field: field.field, value: field.multiselect ? [field?.meta ? Object.keys(field.meta)[0] : null] : field.meta ? Object.keys(field.meta)[0] : null }))
    );

    const getData = async (data) => {
        if (data) {
            let data1;
            const userFilterIndex = filterFields?.map(x => x.field).indexOf('evaluated');
            const user = userFilterIndex > -1 && selectedFilterFields ? selectedFilterFields[userFilterIndex].value : null;

            const data3 = userFilterIndex > -1 && user
                ? data.map(x => ({
                    ...x,
                    evaluatedUser: x.evaluated === user ? x.groupAuthor + '_user' : x.groupAuthor + '_notuser'
                }))
                : data.map(x => ({
                    ...x,
                    evaluatedUser: x.groupAuthor + '_notuser'
                }))

            if (categoryField?.field === 'label' || seriesField?.field === 'label' || filterFields?.some(field => field.field === 'label')) {
                data1 = await getLabelsStat5(data3, meta);
            } else {
                data1 = [...data3];
            }

            const x = seriesFields[selectedSeriesField]?.notUserFilter || categoryFields[selectedCategoryField]?.notUserFilter;

            const filterFields2 = x ? filterFields.filter(x => (x.field !== 'evaluated')) : filterFields;
            const selectedFilterFields2 = x ? selectedFilterFields.filter(x => (x.field !== 'evaluated')) : selectedFilterFields;

            const filteredData = filterFields2 && Array.isArray(filterFields2) && filterFields2.length > 0
                ? data1.filter(item =>
                    selectedFilterFields2.every(filter =>
                        filterFields2.find(field => field.field === filter.field).multiselect
                            ? filter.value.includes(item[filter.field])
                            : item[filter.field] === filter.value
                    )
                )
                : [...data1];

            const groupByFields2 = valuesFields[selectedValue]?.groupByFields;
            const isgroupByFields = Array.isArray(groupByFields2);
            const categoryFieldDateFormat = categoryField?.isDate ? selectedDateFilter : categoryField;

            const statistics3a = isgroupByFields
                ? await calculateStatistics3(
                    {
                        data: filteredData,
                        groupByFields: [...groupByFields2, seriesField ?? categoryFieldDateFormat, categoryFieldDateFormat ?? seriesField],
                        valuesFields: valuesFields
                    }
                )
                : [...filteredData];

            const groupedValuesFields = valuesFields.map((x) => ({ ...x, value: x.value + '_' + selectedStat, field: x.field + '_' + selectedStat }));
            const statistics3 = await calculateStatistics3(
                {
                    data: statistics3a,
                    groupByFields: [seriesField ?? categoryFieldDateFormat, categoryFieldDateFormat ?? seriesField],
                    valuesFields: isgroupByFields ? groupedValuesFields : valuesFields
                }
            );

            let categoryFieldLabel;
            let seriesFieldLabel

            const statistics3WithLabel = seriesField?.field ? await addMetaToData(
                statistics3, seriesField.meta, seriesField.field, 'name'
            ) : statistics3;

            const statistics3WithLabel2 = categoryField?.field && !categoryField.isDate ? await addMetaToData(
                statistics3WithLabel, categoryField.meta, categoryField.field, 'name'
            ) : statistics3WithLabel;

            seriesFieldLabel = {
                field: seriesField?.field ? seriesField?.field + "_name" : 'series',
                label: seriesField?.label ? seriesField?.label : 'series',
            };

            categoryFieldLabel = {
                field: categoryField?.isDate ? categoryFieldDateFormat?.value : categoryField?.field ? categoryField?.field + "_name" : 'category',
                label: categoryFieldDateFormat?.label ? categoryFieldDateFormat?.label : 'category',
                isDate: categoryField?.isDate
            }

            setChartData({
                ...chartData,
                valuesField: isgroupByFields
                    ? `${valuesFields[selectedValue].field}_${selectedStat}_${selectedStat}`
                    : `${valuesFields[selectedValue].field}_${selectedStat}`,
                data: statistics3WithLabel2,
                categoryFieldLabel: categoryFieldLabel,
                seriesFieldLabel: seriesFieldLabel
            });
        }
    }

    useEffect(() => {
        const value = valuesFields[selectedValue];
        const options = value.stat;
        if (options.indexOf(selectedStat) < 0 || value?.defaultValue) {
            setSelectedStat(value?.defaultValue ? value?.defaultValue : options[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    useEffect(() => {
        setSeriesField(seriesFields[selectedSeriesField]);
    }, [selectedSeriesField]);

    useEffect(() => {
        if (categoryFields[selectedCategoryField])
            setCategoryField(categoryFields[selectedCategoryField]);
        else {
            if (selectedCategoryField === 'updatedAt')
                setCategoryField(categoryFields['updatedAt']);
        }
    }, [selectedCategoryField]);

    useEffect(() => {
        getData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, categoryField, seriesField,
        selectedFilterFields, selectedValue,
        selectedStat, selectedDateFilter]);

    const handleFilterChange = (index, event) => {
        const newFilterFields = [...selectedFilterFields];
        newFilterFields[index].value = event.target.value;
        setSelectedFilterFields(newFilterFields);
    };

    const handleFilterChange2 = (event) => {
        setSelectedDateFilter(event);
    };

    return (
        <div>
            {chartData?.data &&
                <Stack spacing={0}>
                    <HeaderSimple3
                        helpData={helpData}
                        help={help}
                        chartData={chartData}
                        setViewMode={(x) => { setViewMode(x) }}
                    />
                    <FormControl>
                        {filterFields.map((filter, index) => (
                            <SelectLabel4
                                key={filter.field}
                                label={filter.label}
                                selectedQuestion={selectedFilterFields[index].value}
                                handleQuestionChange={(event) => handleFilterChange(index, event)}
                                labels={filter.meta}
                                multiselect={filter.multiselect}
                            />
                        ))}
                    </FormControl>
                    {(showCategoryPanel || viewMode === 'standard' || viewMode === 'setup') &&
                        <>
                            <ValueSelect
                                selected={selectedCategoryField}
                                setSelected={setSelectedCategoryField}
                                options={categoryFields}
                            />
                            {selectedCategoryField === 'updatedAt' &&
                                <>
                                    <MyToggleButtonGroup
                                        selectedFilter={selectedDateFilter}
                                        handleFilterChange={handleFilterChange2}
                                        filters={dateFilters2}
                                        title={'Értékelés (napi/heti/havi bontás)'}
                                    />
                                    <MyToggleButtonGroup
                                        selectedFilter={selectedDateFilter}
                                        handleFilterChange={handleFilterChange2}
                                        filters={dateFilters3}
                                        title={'Kitöltési időszak (napi/heti/havi bontás)'}
                                    />
                                </>
                            }
                        </>
                    }
                    <FiltersBox2 show={(showSeriesPanel || viewMode === 'setup')} label='Csoportosítás'>
                        <ValueSelect
                            selected={selectedSeriesField}
                            setSelected={setSelectedSeriesField}
                            options={seriesFields}
                        />
                    </FiltersBox2>
                    <FiltersBox show={showValuesPanel || viewMode === 'setup'} label='Adatok'>
                        <ValueSelect
                            selected={selectedValue}
                            setSelected={setSelectedValue}
                            options={valuesFields}
                        />
                        <StatSelect
                            selected={selectedStat}
                            setSelected={setSelectedStat}
                            options={valuesFields[selectedValue].stat}
                        />
                        <MyToggleButtonGroup
                            title='Diagram típusa'
                            selectedFilter={selectedChartType}
                            handleFilterChange={setSelectedChartType}
                            filters={chartTypeFilters2}
                        />
                    </FiltersBox>
                    <CategoryApexChartSeries2
                        key={uuidv4()}
                        data={chartData?.data}
                        categoryField={chartData?.categoryFieldLabel}
                        valuesField={{
                            // ...chartData?.seriesFieldLabel,
                            field: chartData?.valuesField,
                            label: `${valuesFields[selectedValue]?.label} (${statFunctions[selectedStat]?.label})`
                        }}
                        seriesField={chartData?.seriesFieldLabel}
                        filterFieldValues={selectedFilterFields}
                        filterFields={filterFields}
                        decimal={statFunctions[selectedStat]?.decimal ?? valuesFields[selectedValue]?.decimal ?? 1}
                        type={selectedChartType}
                        chartData2={chartData2}
                        setChartData2={setChartData2}
                        showFilterPanel={showFilterPanel || viewMode === 'setup'}
                    />
                </Stack>
            }
        </div>
    );
};

export default LabelsSeriesFilterChart1;
