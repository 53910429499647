import { useEffect, useState } from 'react';
import { getFirestore, collection, onSnapshot, query, where, documentId, orderBy, limit } from 'firebase/firestore';
import { db, auth, storage } from "../../firebase.config";
import { getPropArray } from '../../commons/Utility';

const Snapshots = ({
    setLoading,
    subscriptions,
    setSubscription,
    deleteSubscription }) => {

    const [snapshots, setSnapshots] = useState([]);

    const getData = (values) => {
        let listings = [];
        if (values) {
            values.forEach((doc) => {
                return listings.push({
                    id: doc.id,
                    data: { ...doc.data(), id: doc.id },
                });
            });
        }
        return listings;

    }
    const setSnapshot = ({ key, value }) => {
        const v = getData(value);
        setSnapshots((prevState) => ({
            ...prevState,
            [key]: v,
        }));
    };

    const subscribeToSnapshot = ({ key, table, filter, qOrderBy = null, qLimit = null, }) => {
        setLoading({ key: key, status: true })
        const collectionRef = collection(db, table);
        let q = ''
        const queryConstraints = [];
        if (filter) {
            // Ha a filters paraméter egy objektum, akkor konvertáljuk egy tömbbé
            let filters
            if (!Array.isArray(filter)) {
                filters = [filter];
            } else {
                filters = filter;
            }
            filters.forEach((f) => {
                queryConstraints.push(where(
                    f.field === 'uid' ? documentId() : f.field,
                    f.operation,
                    f.data
                ))
            });
        }
        if (qOrderBy != null) {
            const o = orderBy(qOrderBy.field, qOrderBy.direction);
            queryConstraints.push(o);
        }
        if (qLimit != null) {
            queryConstraints.push(limit(qLimit));
        }
        // const q = query(collectionRef, where(
        //     filter.field === 'uid' ? documentId() : filter.field,
        //     filter.operation,
        //     filter.data
        // ));
        let unsubscribe;
        try {
            q = query(collection(db, table), ...queryConstraints);
            unsubscribe = onSnapshot(q, docsSnap => {
                const fromCache = getPropArray(docsSnap, ['_snapshot', 'fromCache']);
                // if (!fromCache)
                setLoading({ key: key, status: false })
                setSnapshot({ key: key, value: docsSnap })
            });     
        } catch (error) {
           console.log('error1', error);
        }
        
        return unsubscribe
    };

    const unsubscribeFromSnapshot = ({ key }) => {
        if (subscriptions[key]) {
            subscriptions[key]();
            deleteSubscription({ key: key })
        }

    }


    useEffect(() => {
        // console.log('subscriptions', subscriptions)
    }, [subscriptions]);

    return {
        subscribeToSnapshot, unsubscribeFromSnapshot,
        subscriptions, snapshots, setSnapshot
    };
};

export default Snapshots;