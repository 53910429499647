import React from "react";
import { useContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { auth, initializeFirebaseApp } from "../../firebase.config";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../services/appContext";
import visibilityIcon from "../../assets/svg/visibilityIcon.svg";
import AppSelector from "../../components/apps/AppSelector";
import NextButton from "../../components/buttons/NextButton";
// import AppsButton from "../../components/apps/AppsButton";
import { apps } from "../../data/apps";
import AppsButton from "../../components/apps/AppsButton";
// import HelpButton from "../../commons/help/HelpButton";

function SignIn() {
  // Component Level State
  const [showPassword, setShowPassword] = useState(false);
  // const [selectedApp, setSelectedApp] = useState(localStorage.getItem('selectedApp') ?? 'firstApp');
  const { firebaseApps, selectedApp } = useContext(AppContext);
  const [user] = useAuthState(auth);
  const storedApps = firebaseApps ?? apps;
  /*
    Form Data state -> object
    Email password as state
*/
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // Destructure formData
  const { email, password } = formData;

  // Initialize the navigate
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  // Initialize the function -> update form data state
  const onChange = (e) => {
    // Update the formData state
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    // console.log(formData);
  };

  const checkFrom = (inputString) => {
    const keywords = [
      "/mygroup/",
      "/questionnaires",
      "/questionnaire/",
      "/evaluations",
      "/evaluation/"
    ];

    // Ellenőrizzük, hogy az inputString tartalmazza-e valamelyik kulcsszót
    for (let i = 0; i < keywords.length; i++) {
      if (inputString.includes(keywords[i])) {
        return true;
      }
    }

    return false;
  }

  // Login Functionality
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // const auth = getAuth();
      initializeFirebaseApp();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        console.log("User logged in successfully", from);
        if (checkFrom(from)) {
          navigate(from);
        } else {
          navigate("/");
        }
        // console.log("User logged in successfully");
        // toast.success(`Üdv ${userCredential.user.displayName}`);
        // console.log(userCredential.user.displayName);
      }
    } catch (error) {
      //   console.log(error);
      toast.error("Hibás e-mail cím vagy jelszó.");
    }
  };

  if (user != null) return navigate(from);
  return (
    <div className="pageContainer">
      <div className="loginLayout">
        <header className="logoHeader">
          {/* <HelpButton steps='documentation' /> */}
          <AppsButton />
          <img
            alt={'PR-VET'}
            // className={classes.logo}
            src={storedApps[selectedApp]?.logo || '/logo.png'}
            width={100}
          />
          <p className="pageHeader">{storedApps[selectedApp]?.label}</p>
          <AppSelector />
        </header>
        <div className="profileList">
          <form onSubmit={onSubmit}>
            <input
              type="email"
              className="emailInput"
              placeholder="Email"
              id="email"
              email={email}
              name="email"
              onChange={onChange}
            />
            <div className="passwordInputDiv">
              <input
                type={showPassword ? "text" : "password"}
                className="passwordInput"
                placeholder="Jelszó"
                id="password"
                value={password}
                name="password"
                onChange={onChange}
              />
              <img
                src={visibilityIcon}
                alt="jelszó megjelenítése"
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <NextButton
              name={'Bejelentkezés'}
            />
          </form>
          {/* <OAuth /> */}
          <div style={{ paddingTop: 30, paddingBottom: 30 }}>
            <Grid container>
              <Grid item xs>
                <Link to="/sign-up" className="registerLink2">
                  Regisztráció
                </Link>
              </Grid>
              <Grid item>
                <Link to="/forgot-password" className="registerLink2">
                  Elfelejtett jelszó
                </Link>
              </Grid>
            </Grid>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
