import React from "react";
import { Badge, BottomNavigation, BottomNavigationAction, Paper, Stack, styled } from "@mui/material";
import { Home, Person, ThumbsUpDown, Quiz } from "@mui/icons-material";
import EvaluationBadge from "../../badges/EvaluationBadge";

const StyledBottomNavigation = styled(BottomNavigation)({
    width: "100%",
    position: "sticky",
    bottom: "0",
    top: "auto",
    backgroundColor: "#fff",
    borderRadius: "5px 5px 0 0", // Módosítás: kevesebb border-radius
    margin: "auto",
    textAlign: "center",
    maxWidth: "30em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2000, // Módosítás: magasabb z-index
});

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
    minWidth: 0,
    padding: "1em",
    margin: "0.5em",
    borderRadius: "50%",
    border: "5px solid #fff",
    fontSize: "0.8em",
    height: "55px", // Módosítás: kisebb magasság
    maxWidth: "55px",
    marginTop: "5px",
    transition: "0.5s ease-in-out",
    "&:not(.Mui-selected)": {
        backgroundColor: "#fff",
    },
    "&.Mui-selected": {
        backgroundColor: "#6589ff",
        transform: "scale(1.25) translateY(-0.5em)", // Módosítás: kisebb transzformáció
        border: "5px solid #eee",
        color: "white",
    },
}));

function Modern({
    user,
    profile,
    handleSubmit,
    location,
    newQuestionnaires
}) {

    return (
        <>
            {user ? (
                <Paper sx={{ zIndex: "1", position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <StyledBottomNavigation

                        value={location.pathname}
                        onChange={(event, newValue) => {
                            handleSubmit(newValue);
                        }}
                    >
                        <StyledBottomNavigationAction value="/" icon={<Home />} />
                        {profile?.[0]?.data?.isAdmin && (
                            <StyledBottomNavigationAction value="/manage" icon={<Quiz />} />
                        )}
                        <StyledBottomNavigationAction
                            value="/questionnaires"

                            icon={
                                <Stack spacing={2} direction="row">
                                    <Badge badgeContent={newQuestionnaires} color="error">
                                        <ThumbsUpDown />
                                    </Badge>
                                </Stack>
                            }
                        />
                        <StyledBottomNavigationAction value="/evaluations" icon={<EvaluationBadge />} />
                        <StyledBottomNavigationAction value="/profile" icon={<Person />} />
                    </StyledBottomNavigation>
                </Paper>
            ) : (
                <p></p>
            )}
        </>
    );
}

export default Modern;
