import { functions, httpsCallable } from '../../firebase.config';

const GetUserEvaluated = async () => {
    const getFunction = httpsCallable(functions, 'getUserEvaluated');

    const getData = async ({groups, timeDivison = null}) => {
        try {
            const result = await getFunction({ groups: groups, timeDivison: timeDivison });
            console.log('Firebase Function Response:', result?.data);
            return result?.data;
        } catch (error) {
            console.error('Error calling Firebase Function:', error);
            throw error;
        }
    };
    return { getData };
};

export default GetUserEvaluated;