import { images } from "./images"

export const stepsGroupStats =
    [
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Csoport statisztikák',
            description: ``,
            image: images.stat1,
            help: null,
        },
        {
            type: 'link', // slide, link
            selector: '.groupStatTypes',
            content: 'Értékelés típusok',
            short: 'Értékelés típusok',
            description: '',
            link: 'groupStatTypes',
            isAdmin: false,
            icon: 'BarChart',
        },
        {
            type: 'link', // slide, link
            selector: '.groupStatTypes',
            content: 'Címkék',
            short: 'Címkék',
            description: '',
            link: 'labels',
            isAdmin: false,
            icon: 'Label',
        },
        {
            type: 'link', // slide, link
            selector: '.dataGrouping',
            content: 'Adatok csoportosítása, elemzése és vizualizálása',
            short: 'Adatok csoportosítása',
            description: '',
            link: 'dataGrouping',
            isAdmin: false,
            icon: 'Label',
        },
        {
            type: 'link', // slide, link
            selector: '.groupStatCharts',
            content: 'Diagram részei',
            short: 'Diagram részei',
            description: '',
            link: 'groupStatCharts',
            isAdmin: false,
            icon: 'Label',
        },
        {
            type: 'link', // slide, link
            selector: '.groupStatAnimation',
            content: 'Diagram használata',
            short: 'Diagram használata',
            description: '',
            link: 'groupStatAnimation',
            isAdmin: false,
            icon: 'Label',
        }
    ]

