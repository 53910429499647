import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';

const UpdateGroupNameAndActive = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, name, active, oktato }) => {
        const newRecord = {
            avatarUpdatedAt: new Date(),
            name: name,
            active: active,
            [`Oktató`]: oktato
        }

        const result = await updateDocument({
            id: id,
            table: 'groups',
            record: newRecord,
            fields: ['name', 'active', 'Oktató'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateGroupNameAndActive;