import React, { useEffect, useState } from 'react';
import View from './View';
import GetUserHistory from '../../services/firebase/GetUserHistory';
import Utility from './components/Utility';
import Spinner from '../../components/Spinner';
import { auth, selectedAppName } from '../../firebase.config';

const History = () => {
    const [logData, setLogData] = useState(JSON.parse(localStorage.getItem('history-' + selectedAppName + '-' + auth?.currentUser?.uid)) ?? []);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const getData = async (filter) => {
        setLoading(true);
        const { getData } = await GetUserHistory();
        try {
            const logs = await getData(filter);
            // console.log(logs?.response?.result);
            setLogData(logs?.response?.result);
            localStorage.setItem('history-' + selectedAppName + '-' + auth?.currentUser?.uid, JSON.stringify(logs?.response?.result));
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
        setLoading(false);
    };

    const utility = Utility();

    const getAllData = async () => {
        const data = [];
        const templates = [];
        const evaluated = [];
        setLoading(true);
        await Promise.all(
            logData?.map(async (line) => {
                const a = await utility.getProfile({ id: line?.evaluated });
                const b = await utility.getTemplate({ id: line?.template });
                templates[line?.template] = b;
                evaluated[line?.evaluated] = a;
                data.push({
                    ...line,
                    updatedAt: utility.formatTimestamp(line?.updatedAt * 1000),
                    template: templates[line?.template]?.description || '',
                    evaluated: evaluated[line?.evaluated]?.name || '',
                    question: templates[line?.template]?.meta?.questions[line?.question]?.name || '',
                });
            })
        );
        setLoading(false);
        setData(data);;
        // console.log(data);
    };

    useEffect(() => {
        getAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logData]);


    useEffect(() => {
        setLogData(JSON.parse(localStorage.getItem('history-' + selectedAppName + '-' + auth?.currentUser?.uid)) ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, selectedAppName]);

    return (<>
        {loading ? <Spinner /> :
            <View
                getData={getData}
                data={data}
            />
        }
    </>
    );
};

export default History;