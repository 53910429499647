import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PopUp from '../../../../../commons/PopUp';
import helpIcons from './HelpIcons';

const FiltersBox = ({ children, label = 'Beállítások', show = true }) => {
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    return (
        <> {show &&
            <Card style={{
                padding: '0px',
                backgroundColor: showAdvancedOptions ? "rgba(128, 128, 128, 0.1)" : '#eee', // szürke 50% átlátszósággal
                boxShadow: 'none',
            }}>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        color="primary"
                        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                        endIcon={showAdvancedOptions ? <ExpandLess /> : <ExpandMore />}
                    >
                        {`${label} `} {showAdvancedOptions ? 'elrejtése' : 'megjelenítése'}
                    </Button>
                </div>

                {showAdvancedOptions &&
                    <CardContent style={{
                        padding: '5px',
                    }} >
                        <div style={{ marginRight: '-0px', marginTop: '-5px', textAlign: 'right', float: 'right' }}> {/* float jobb */}
                            <div style={{ float: 'right' }}> {/* float jobb */}
                                <PopUp
                                    help={helpIcons}
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            {children}
                        </div>
                    </CardContent>
                }
            </Card >
        }
        </>
    );
};

export default FiltersBox;
