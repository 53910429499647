import React from "react";
import { useState, useEffect, useContext } from "react";
import { QrCode2, Groups, AdminPanelSettings } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AppContext } from "../../services/appContext";
import { Tab, Tabs } from '@mui/material';
import GetUser from "../../services/documents/GetUser";
import StorageAvatar from "../firebase/StorageAvatar";
import { useParams } from 'react-router-dom';
import UserCover from "./UserCover";
import UserGroups from "./UserGroups";
import UserQRCode from "./UserQRCode";
import UserData from "./UserData";
import GetUser2 from "../../services/documents/GetUser2";

const UserProfile = () => {
    const params = useParams();
    const { userId } = params;
    const [userProfile, setUserProfile] = useState(null);
    const [profile, setProfile] = useState(null);
    const handleChangeValue = (event, newValue) => { setProfileUserTab(newValue); };
    const { snapshots, profileUserTab, setProfileUserTab } = useContext(AppContext);

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    const profileData = GetUser2()

    const getProfile = async (refresh = false, notFromCache = false) => {
        // console.log('profileData', userId);
        const a = await profileData.subscribe({
            setter: (model) => { setUserProfile(getData(model)) },
            id: userId,
            refresh: refresh,
            notFromCache: notFromCache
        });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS111', a)
        }
        else {
            console.log('HIBA111')
        }
    }

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (snapshots && snapshots['profile'] && snapshots['profile'][0] && snapshots['profile'][0].data) {
            setProfile(snapshots['profile'][0].data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['profile']]);

    useEffect(() => {
        // console.log(profile);
        // console.log(userProfile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, userProfile]);
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                padding: 0,

            }}>
            <div className="profile">
                <UserCover
                    userId={userId}
                    profile={userProfile}
                />
                <div className='centerContent2'>
                    <div className="avatar-container">
                        {/* <StorageAvatar
                            value={userId}
                            bucket={'profile_images'}
                            size='xlarge'
                        /> */}

                        <div className="listingDetails">

                            <div>
                                <Tabs
                                    allowScrollButtonsMobile
                                    value={profileUserTab}
                                    variant="fullWidth"
                                    onChange={handleChangeValue}
                                    aria-label="icon tabs example">
                                    <Tab value='qr' icon={<QrCode2 />} label="QR kód" />
                                    <Tab value='groups' icon={<Groups />} label="Csoportok" />
                                    <Tab value='setting' icon={<AdminPanelSettings />} label="Beállítások" />
                                </Tabs>
                                {profileUserTab === 'qr' &&
                                    <UserQRCode
                                        uid={userId}
                                    />
                                }
                                {profileUserTab === 'groups' &&
                                    <UserGroups
                                        profile={userProfile}
                                    />
                                }
                                {profileUserTab === 'setting' &&
                                    <UserData
                                        userProfile={userProfile}
                                        getProfile={getProfile}
                                        profile={profile}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Box >
    );
};

export default UserProfile;