import React from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Badge } from '@mui/material';
import MemberUsers from '../../../components/subscribed/MemberUsers';
import AdminUsers from '../../../components/subscribed/AdminUsers';
import DashboardButton from '../../../commons/buttons/DashboardButton';
import CreateFirebaseUser from '../../auth/CreateFirebaseUser';
import ManagerUsers from '../../../components/subscribed/ManagerUsers';
import SubscribedUsers from '../../../components/subscribed/SubscribedUsers';

const GroupsPage = ({
    isGroupAdmin,
    isSuperAdmin,
    isGroupManager,
    groupId,
    group,
    isGroupEditor,
    isActive
}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="qrcodes">
                <Grid container spacing={2}>
                    {(isGroupAdmin || isSuperAdmin || isGroupManager) && isActive &&
                        <>
                            <Grid className="profileButton" item xs={3}>
                                <DashboardButton
                                    label={'Felhasználó felvétele'}
                                    icon={'CropFree'}
                                    onClick={() => (navigate("/subscribe"))}
                                    isAvatar={true}
                                    bgcolor={'#9B59B6'}
                                />
                            </Grid>
                            <Grid className="profileButton" item xs={3}>
                                <CreateFirebaseUser
                                    groupId={groupId}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </div>
            {(isGroupManager || isGroupAdmin) && group?.subscribed?.length > 0 &&
                <><h3>
                    <Badge badgeContent={group && group.subscribed && group.subscribed.length > 0
                        ? `${group.subscribed.length}`
                        : 0} color="warning">
                        Jelentkezők
                    </Badge>
                </h3>
                    <SubscribedUsers
                        group={group}
                        users={group?.subscribed}
                        groupId={groupId}
                        isAdmin={isGroupEditor}
                        navigateToUser={isGroupEditor}
                    />
                </>
            }
            <h3>Hallgatók</h3>
            <MemberUsers
                users={group?.Members}
                groupId={groupId}
                // isAdmin={isGroupEditor}
                group={group}
                navigateToUser={isGroupEditor}
            />
            <h3>Oktatók</h3>
            <AdminUsers
                users={group?.admins}
                groupId={groupId}
                instructor={group ? group['Oktató'] : null}
                // isAdmin={isGroupManager || group && group['Oktató'] === user}
                group={group}
                navigateToUser={isGroupEditor}
            />
            {(isGroupManager || isGroupAdmin) &&
                <>
                    <h3>Ügyintézők</h3>
                    <ManagerUsers
                        users={group?.managers}
                        groupId={groupId}
                        instructor={group ? group['Oktató'] : null}
                        // isAdmin={isGroupManager || group && group['Oktató'] === user}
                        group={group}
                        navigateToUser={isGroupEditor}
                    />
                </>

            }
        </>
    );
};

export default GroupsPage;