import React from 'react';
import { groupingData, groupingData2D } from '../../../components/charts/CChartConstats';
import CPieChart from '../../../components/charts/CPieChart';

const PieCharts = ({ meta, group, templateData, questionnaire }) => {
    const allData2 = groupingData(
        templateData,
        "question",
        ["count(evaluated_value)",
            "average_evaluated_value",
        ],
    );
    const allData3 = groupingData2D(
        templateData,
        ["groupId", "question"],
        ["count(evaluated_value)",
            "average_evaluated_value",
        ]
    );
    return (
        <>
            <CPieChart
                name={`A csoport összes értékelése: ${group?.id && allData3 && allData3?.fieldsSum && allData3?.fieldsSum[group?.id] ? allData3?.fieldsSum[group?.id]['count(evaluated_value)'] : '?'}`}
                data={allData3?.mean[group?.id]}
                meta={meta}
                questionnaire={questionnaire}
                field='average_evaluated_value'
            />
            <CPieChart
                name={`Az kérdőív sablon összes értékelése: ${allData2?.fieldsSum['count(evaluated_value)']}`}
                data={allData2?.mean}
                meta={meta}
                questionnaire={questionnaire}
                field='average_evaluated_value'
            />
        </>
    );
};

export default PieCharts;