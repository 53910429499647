import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../appContext';

const AppEvaluationsSetter = () => {
    const {
        snapshots, setAllEvaluationsData, evaluations,
        oldEvaluations, setNewEvaluationMonth, setEvaluations
    } = useContext(AppContext);

    const analite = (evaluation, monthData, allQuestionnaires, showQuestionnaires, allTemplates, all, read, doc, allNew) => {
        if (evaluation) {
            // questionnares
            const qId = evaluation.questionnaire;
            if (!allQuestionnaires[qId]) {
                allQuestionnaires[qId] = [];
                showQuestionnaires.push(evaluation);
                read[qId] = 0;
                all[qId] = 0;
                doc[qId] = { monthData };
            }
            allQuestionnaires[qId].push(evaluation);
            // templates
            if (!allTemplates[evaluation.template]) {
                allTemplates[evaluation.template] = [];
            }
            allTemplates[evaluation.template].push(evaluation);
            const a = monthData?.read && monthData?.read[qId] ? monthData?.read[qId] : 0;
            read[qId] = a;
            all[qId] = all[qId] + 1;
            allNew = a;
        }
        return allNew
    }
    const analiteEvaluation = () => {
        const lsData = JSON.parse(localStorage.getItem('analiteEvaluationData'));
        const last = evaluations && evaluations[0] && evaluations[0].id;
        const allRead = evaluations && evaluations[0] && evaluations[0].allRead ? evaluations[0].allRead : 0;
        if (lsData?.last && lsData?.data && lsData?.last === last && allRead === lsData?.allRead && false) {
            return lsData.data
        } else {
            let allQuestionnaires = {};
            let showQuestionnaires = [];
            let allTemplates = {};
            let templates = {};
            let read = {};
            let all = {};
            let doc = {}
            let allNew = 0;
            if (evaluations) {
                evaluations.forEach(evaluation => {
                    const monthData = evaluation ? evaluation.data : null;
                    if (monthData?.templates) {
                        Object.keys(monthData?.templates).forEach(key => {
                            templates = { ...templates, [key]: monthData?.templates[key] }
                        })
                    }
                    if (monthData?.templates) {
                        Object.keys(monthData?.templates).forEach(key => {
                            templates = { ...templates, [key]: monthData?.templates[key] }
                        })
                    }
                    if (monthData) {
                        monthData && monthData?.evaluations && monthData?.evaluations?.slice(0)?.reverse().forEach(evaluation => {
                            allNew = + analite(evaluation, monthData, allQuestionnaires, showQuestionnaires, allTemplates, all, read, doc, allNew);
                        });
                    }
                })
            }
            let countAllEvaluation = 0;
            Object.keys(all).forEach(n => {
                countAllEvaluation += all[n];
            });

            let countAllReadEvaluation = 0;
            Object.keys(read).forEach(n => {
                countAllReadEvaluation += read[n];
            });
            setNewEvaluationMonth(countAllEvaluation - countAllReadEvaluation);
            const data = {
                showQuestionnaires: showQuestionnaires,
                allQuestionnaires: allQuestionnaires,
                allTemplates: allTemplates,
                read: read,
                all: all,
                allRead: allRead,
                templates: templates,
                doc: doc
            };
            if (last && data) {
                const b = ({
                    last: last,
                    data: data,
                    allRead: allRead
                });
                localStorage.setItem('analiteEvaluationData', JSON.stringify(b))
            }
            return data
        }
    }

    const analiteOldEvaluation = (oldEvaluations) => {
        const lsData = JSON.parse(localStorage.getItem('analiteOldEvaluationData'));
        const first = oldEvaluations && typeof oldEvaluations === 'object' ? Object.keys(oldEvaluations)[0] : null;
        const count = oldEvaluations && typeof oldEvaluations === 'object' ? Object.keys(oldEvaluations).length : null;
        if (lsData?.last && lsData?.data && lsData?.first === first && lsData?.count === count) {
            console.log('LS! The image has already been downloaded from the LS.', lsData.data);
            return lsData.data
        } else {
            let allQuestionnaires = {};
            let showQuestionnaires = [];
            let allTemplates = {};
            let templates = {};
            let read = {};
            let all = {};
            let doc = {};
            let allNew = 0;
            if (oldEvaluations) {
                const today = new Date();
                for (let i = 1; i < 12; i++) {
                    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1; // Hónapok 0-tól indexelődnek, ezért +1
                    const formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
                    const monthData = oldEvaluations[formattedDate];
                    if (monthData?.templates) {
                        Object.keys(monthData?.templates).forEach(key => {
                            templates = { ...templates, [key]: monthData?.templates[key] }
                        })
                    }
                    monthData && monthData?.evaluations && monthData?.evaluations?.slice(0)?.reverse().forEach(evaluation => {
                        analite(evaluation, monthData, allQuestionnaires, showQuestionnaires, allTemplates, all, read, doc, allNew);
                    });
                };
            }
            const data = {
                showQuestionnaires: showQuestionnaires,
                allQuestionnaires: allQuestionnaires,
                allTemplates: allTemplates,
                oldEvaluations: oldEvaluations,
                read: read,
                all: all,
                templates: templates,
                doc: doc
            };
            if (first && count && data) {
                localStorage.setItem('analiteOldEvaluationData', JSON.stringify({
                    first: first,
                    data: data,
                    count: count,
                }))
            }
            return data
        }
    }
    useEffect(() => {
        let allQuestionnaires = {};
        let showQuestionnaires = [];
        let allTemplates = {};
        let templates = {};
        let read = {};
        let all = {};
        let doc = {}
        const valuationData = analiteEvaluation(evaluations);
        if (valuationData) {
            allQuestionnaires = valuationData?.allQuestionnaires;
            showQuestionnaires = valuationData?.showQuestionnaires;
            allTemplates = valuationData?.allTemplates;
            templates = valuationData?.templates;
            read = valuationData?.read;
            all = valuationData?.all;
            doc = valuationData?.doc
        }
        const oldEvaluationData = analiteOldEvaluation(oldEvaluations);
        if (oldEvaluationData) {
            showQuestionnaires = showQuestionnaires.concat(oldEvaluationData.showQuestionnaires);
            allQuestionnaires = { ...allQuestionnaires, ...oldEvaluationData.allQuestionnaires };
            allTemplates = { ...allTemplates, ...oldEvaluationData.allTemplates };
            read = { ...read, ...oldEvaluationData.read };
            all = { ...all, ...oldEvaluationData.all };
            templates = { ...templates, ...oldEvaluationData.templates };
            doc = { ...doc, ...oldEvaluationData?.doc };
        }
        const allEvaluationsData = {
            showQuestionnaires: showQuestionnaires,
            allQuestionnaires: allQuestionnaires,
            allTemplates: allTemplates,
            read: read,
            all: all,
            templates: templates,
            doc: doc
        };
        setAllEvaluationsData(allEvaluationsData);
    }, [evaluations, oldEvaluations]);

    useEffect(() => {
        setEvaluations(snapshots['myevaluationsMonths']);
    }, [snapshots['myevaluationsMonths']]);

};

export default AppEvaluationsSetter;