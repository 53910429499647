import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const UpdateEvaluated = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, read }) => {
        const newRecord = {
            read: read,
        }

        const result = await updateDocument({
            id: id,
            table: 'evaluated',
            record: newRecord,
            fields: ['read'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateEvaluated;