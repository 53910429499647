import React from 'react';

import GroupItemMini2 from '../components/groups/GroupItemMini2';
import { List } from '@mui/material';

const CGroupMultiSelect = ({
    field,
    meta,
    handleChange,
    values,
    options
}) => {
    const setValue = (v) => {
        if (values.indexOf(v) < 0) {
            handleChange(field, [...values, v])
        } else {
            const rest = values.filter(i => i !== v)
            handleChange(field, rest)
        }
    }
    return (
        <>
            <List
                dense
                sx={{
                    maxWidth: '100%',
                    width: `calc(80vw)`,
                    bgcolor: 'background.paper'
                }}
            // sx={{
            //     width: '90%',
            //     maxWidth: 360,
            //     bgcolor: 'background.paper',
            //     position: 'relative',
            //     overflow: 'auto',
            //     maxHeight: `calc(100vh - 280px)`,
            //     '& ul': { padding: 0 },
            // }}
            >
                {options.map((option, index) => (
                    <GroupItemMini2
                        option={option}
                        key={index}
                        id={option.id}
                        values={values}
                        meta={meta}
                        onClick={setValue}
                    />
                ))}
            </List>
        </>
    )
}
export default CGroupMultiSelect
