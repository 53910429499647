import React from 'react';
import NextPerson2 from '../NextPerson2';
import MemberItemBig from '../../../members/MemberItemBig';
import FSPField from '../FSPField/FSPField';
import NextGroup from '../NextGroup';
import QuestionPopup from '../../../../commons/QuestionPopup';

const FSPFields = ({
    index,
    page,
    next2,
    category,
    valid,
    pages,
    meta,
    values,
    setValues,
    actual,
    setActual,
    questionnaire,
    group
}) => {
    // console.log(questionnaire);
    return (<>
        {page?.type === 'person' ?
            (
                questionnaire?.evaluate === 'project' ?
                    <NextGroup
                        group={group}
                        onClick={() => next2()}
                    />
                    :
                    <NextPerson2
                        key={`NextPerson2${index}`}
                        id={page.id}
                        name={page.category.data.name}
                        icon={page.category.data.id}
                        onClick={() => next2()}
                        questionnaire={questionnaire}
                    />
            )
            : (
                <>
                    {
                        questionnaire?.evaluate === 'project' ?
                            <>
                                <MemberItemBig
                                    key={`MemberItemBig${index}`}
                                    name={group?.data?.name}
                                    icon={group?.data?.Profilkép}
                                    bucket='group_images'
                                />
                            </> :
                            <>
                                <MemberItemBig
                                    key={`MemberItemBig${index}`}
                                    name={category.name}
                                    icon={category.id}
                                />
                            </>
                    }
                    {/* <QuestionPopup text={page?.instruction} /> */}
                    <FSPField
                        key={`FSPFormFieldSampling${index}`}
                        valid={valid}
                        page={page}
                        pages={pages}
                        questions={meta.questions}
                        scales={meta.scales}
                        newRecord={values}
                        next={next2}
                        setValues={setValues}
                        actual={actual}
                        setActual={setActual}
                        values={values}
                    />

                </>
            )
        }
    </>);
};

export default FSPFields;