import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

const RadarChartGroups = ({ name, templateData, groups, meta }) => {
  const [selectedGroups, setSelectedGroups] = useState(Object.keys(groups));
  const [selectedQuestions, setSelectedQuestions] = useState(Object.keys(meta.questions));
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (templateData && groups && meta) {
      const filteredData = templateData.filter(
        (item) => selectedGroups.includes(item.groupId) && selectedQuestions.includes(item.question)
      );

      const seriesData = selectedGroups.map((groupId) => {
        const group = groups[groupId];
        const dataPoints = selectedQuestions.map((questionId) => {
          const question = meta.questions[questionId];
          const groupData = filteredData.filter(
            (item) => item.groupId === groupId && item.question === questionId
          );
          const averageValue = groupData.reduce(
            (sum, item) => sum + parseFloat(item.average_evaluated_value),
            0
          );
          const average = averageValue / groupData.length || 0;
          return average;
        });

        return {
          name: group.name,
          data: dataPoints,
        };
      });

      const categories = selectedQuestions.map((questionId) => meta.questions[questionId].name);

      setChartData({
        options: {
          xaxis: {
            categories,
            labels: {
              style: {
                fontSize: '6px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(2);
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2);
              },
            },
          },
        },
        series: seriesData,
      });
    }
  }, [selectedGroups, selectedQuestions, templateData, groups, meta]);

  return (
    <Stack spacing={2}>
      {chartData && chartData?.series && chartData?.options && (
        <>
          <h3>{name}</h3>
          <FormControl>
            <InputLabel id="question-label">Válassz kérdéseket</InputLabel>
            <Select
              labelId="question-label"
              id="question-select"
              multiple
              value={selectedQuestions}
              label="Kérdések"
              onChange={(e) => setSelectedQuestions(e.target.value)}
            >
              {Object.keys(meta.questions).map((questionId) => (
                <MenuItem key={questionId} value={questionId}>
                  {meta.questions[questionId].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="group-label">Válassz csoportokat</InputLabel>
            <Select
              labelId="group-label"
              id="group-select"
              multiple
              value={selectedGroups}
              label="Csoportok"
              onChange={(e) => setSelectedGroups(e.target.value)}
            >
              {Object.keys(groups).map((groupId) => (
                <MenuItem key={groupId} value={groupId}>
                  {groups[groupId].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ApexCharts
            type="radar"
            width="99%"
            height={600}
            options={chartData.options}
            series={chartData.series}
          />
        </>
      )}
    </Stack>
  );
};

export default RadarChartGroups;
