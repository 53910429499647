import React, { useContext, useEffect, useState } from 'react';
import GroupQrCode from '../../components/groups/GroupQrCode';
import { convertArrayById3 } from '../../commons/Utility';
import { auth } from '../../firebase.config';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../services/appContext';

const GroupQR = () => {
    const params = useParams();
    const { groupId } = params;
    const [myGroup, setMyGroup] = useState();
    const { snapshots, isAdmin, isSuperAdmin } = useContext(AppContext);
    const navigate = useNavigate();
    const user = auth?.currentUser?.uid;

    useEffect(() => {
        if (snapshots && snapshots['allgroups'] && groupId) {
            const myGroups = convertArrayById3(snapshots['allgroups'], 'id');
            if (myGroups) setMyGroup(myGroups[groupId]);
        }
    }, [snapshots['allgroups'], groupId]);

    const isSubscribed = user && myGroup?.subscribed && myGroup?.subscribed.indexOf(user) > -1;
    const isMember = user && myGroup?.Members && myGroup?.Members.indexOf(user) > -1;
    const isGroupAdmin = myGroup?.admins?.indexOf(user) > -1;
    // console.log(myGroup)
    return (
        <GroupQrCode
            setShow={() => { navigate("/") }}
            group={{ id: groupId, data: myGroup }}
            isMember={isMember}
            isAdmin={isGroupAdmin}
            isSubscribed={isSubscribed}
        />
    );
};

export default GroupQR;