import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../appContext';
import { evaluatedCount as count } from '../../commons/StatUtility';

const AppVariablesSetters = () => {
    const {
        setNewQuestionnaires, snapshots, setIsAdmin, setIsManager,
        setNewEvaluation,
        setIsSuperAdmin, setProfile } = useContext(AppContext);

    useEffect(() => {
        if (snapshots && snapshots['profile'] && snapshots['profile'][0]) {
            const profile = snapshots['profile'];
            const profileData = profile[0]?.data;
            const isSuperAdmin = profileData?.isSuperAdmin
            const isManager = profileData?.isManager;
            setIsSuperAdmin(isSuperAdmin);
            //setIsSuperAdmin(true); //teszteléshez
            setIsManager(isManager);
            const isAdmin = profileData.isAdmin && profileData.admins;
            setIsAdmin(isAdmin);
        }
        return () => {
            setIsSuperAdmin(false);
            setIsAdmin(false);
        }
    }, [snapshots['profile']]);

    useEffect(() => {
        const filledQuestionnaires = snapshots['filledquestionnaires'];
        const last = localStorage.getItem('lastSampling');
        const filled = filledQuestionnaires ? filledQuestionnaires.filter(x => x?.data?.status === 'new' && x?.id != last) : []
        setNewQuestionnaires(filled && Array.isArray(filled) ? filled?.length : 0)
        return () => {
            setNewQuestionnaires(null);
        }
    }, [snapshots['filledquestionnaires']]);

    useEffect(() => {
        const filledQuestionnaires = snapshots['filledquestionnaires'];
        const last = localStorage.getItem('lastSampling');
        const filled = filledQuestionnaires ? filledQuestionnaires.filter(x => x?.data?.status === 'new' && x?.id != last) : []
        setNewQuestionnaires(filled && Array.isArray(filled) ? filled?.length : 0)
    }, [snapshots['filledquestionnaires']]);

    const evaluatedCountUpdate = (values) => {
        let num = 0;
        let num2 = 0;
        if (values) {
            Object.keys(values).forEach((value, index) => {
                num = num + count(values[value]?.data?.evaluations)
                num2 = num2 + (values[value]?.data?.read ? values[value]?.data?.read : 0);
            });
        }
        return num - num2;
    };

    useEffect(() => {
        const x = (snapshots['myevaluations']) ? evaluatedCountUpdate(snapshots['myevaluations']) : 0;
        setNewEvaluation(x);
    }, [snapshots['myevaluations']]);

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
    }, [snapshots['profile']]);

};

export default AppVariablesSetters;