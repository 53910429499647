import React from 'react';
import CBarChart2 from '../../../components/charts/CBarChart2';
import { addMetaInfo, createDataToChart } from '../../../components/charts/CChartConstats';
import CPieChart from '../../../components/charts/CPieChart';
import ApexBarChart from '../../../components/charts/ApexBarChart';
import ApexAreaChart from '../../../components/charts/ApexAreaChart';
import MyChartComponent from '../../../components/charts/MyChartComponent';
import GroupQuestionChart from '../../../components/charts/GroupQuestionChart';
import RadarChart from './RadarChart';
import PieChart from './PieChart';
import RadarChartGroups from './RadarChartGroups';

const BarCharts = ({ templateData, templateData2, groups, meta, questions }) => {

    const data = addMetaInfo(
        {
            model: templateData,
            meta: { questions: meta.questions, groups: groups },
            info: {
                name: { meta: 'questions', keyField: 'question', keyLabel: 'name' },
                labels: { meta: 'questions', keyField: 'question', keyLabel: 'labels' },
                group: { meta: 'groups', keyField: 'groupId', keyLabel: 'name' },
                date: { transform: (field) => (field.evaluatedYear + '-' + field.evaluatedWeek) }
            }
        });
    // console.log('templateData1', templateData);
    // console.log('groups', groups);
    // console.log('meta', meta);
    // console.log('questions', questions);
    // const data1 = templateData.map(item => ({
    //     x: item.groupId,  // Vagy használhatsz más tulajdonságot az x tengelyért
    //     y: parseFloat(item.average_evaluated_value),
    //   }));

    // console.log('data1', data1);
    // const data0 = createDataToChart(
    //     {
    //         data: data,
    //         category: 'groupId',
    //         categories: { model: groups, field: 'name' },
    //         meta: { ...meta, groups: groups },
    //         groupByFields: ['question'],
    //         field: 'average_evaluated_value'
    //     });

    // console.log('data0', data0);
    // const groups1 = { '1CYj0fJ5WYZisLLWaJ9J': groups['1CYj0fJ5WYZisLLWaJ9J'] };
    // console.log('groups1', groups1);
    return (
        <>
            {/* <ApexBarChart
                name={'Csoportok értékelései 1x.'}
                categories={{ model: groups, field: 'name' }}
                data={data1}
            /> */}
            <PieChart
                name={'Átlagos értékelések'}
                templateData={templateData}
                groups={groups}
                meta={meta}
            />
            <MyChartComponent
                name={'Értékelések és csoportok trendek'}
                templateData={templateData}
                templateData2={templateData2}
                groups={groups}
                meta={meta}
            />
            <GroupQuestionChart
                name={'Csoport értékelés trendek'}
                templateData={templateData}
                templateData2={templateData2}
                groups={groups}
                meta={meta}
            />

            <RadarChart
                name={'Csoporton és értékelések radar diagram'}
                templateData={templateData}
                groups={groups}
                meta={meta}
            />
            <RadarChartGroups
                name={'Csoporton és értékelések radar diagram 2.'}
                templateData={templateData}
                groups={groups}
                meta={meta}
            />
            {/* <CBarChart2
                name={'Csoportok értékelései 1.'}
                categories={{ model: groups, field: 'name' }}
                data={createDataToChart(
                    {
                        data: data,
                        category: 'groupId',
                        categories: { model: groups, field: 'name' },
                        meta: { ...meta, groups: groups },
                        groupByFields: ['question'],
                        field: 'average_evaluated_value'
                    })
                }
            /> */}
            {/* <ApexAreaChart
                data={createDataToChart(
                    {
                        data: data,
                        category: 'groupId',
                        categories: { model: groups1, field: 'name' },
                        meta: { ...meta, groups: groups1 },
                        groupByFields: ['question'],
                        field: 'average_evaluated_value'
                    })}
                name={'Csoportok értékelései 1.'}
                categories={{ model: groups1, field: 'name' }}
                // categories={groups}
                field="name"
            /> */}

            {/* <CBarChart2
                name={'Csoportok értékelései heti bontásban 1.'}
                categories={{ model: groups, field: 'name' }}
                data={createDataToChart(
                    {
                        data: data,
                        category: 'groupId',
                        categories: { model: groups, field: 'name' },
                        meta: { ...meta, groups: groups },
                        groupByFields: ['question', 'date'],
                        field: 'average_evaluated_value'
                    })
                }
            /> */}
            {/* <ApexAreaChart
                name={'Csoportok értékelései heti bontásban 1.'}
                categories={{ model: groups1, field: 'name' }}
                data={createDataToChart(
                    {
                        data: data,
                        category: 'groupId',
                        categories: { model: groups1, field: 'name' },
                        meta: { ...meta, groups: groups1 },
                        groupByFields: ['question', 'date'],
                        field: 'average_evaluated_value'
                    })
                }
                field='name'
            /> */}

            {/* <CBarChart2
                name={'Csoportok értékelései heti bontásban 2.'}
                categories={{ model: questions, field: 'name' }}
                data={createDataToChart(
                    {
                        data: data,
                        category: 'question',
                        categories: { model: questions, field: 'name' },
                        meta: { ...meta, groups: groups },
                        groupByFields: ['groupId', 'date'],
                        field: 'average_evaluated_value'
                    })
                }
            /> */}
            {/* <ApexAreaChart
                name={'Csoportok értékelései heti bontásban 1.'}
                categories={{ model: questions, field: 'name' }}
                data={createDataToChart(
                    {
                        data: data,
                        category: 'question',
                        categories: { model: groups1, field: 'name' },
                        meta: { ...meta, groups: groups1 },
                        groupByFields: ['groupId', 'date'],
                        field: 'average_evaluated_value'
                    })
                }
                field='name'
            /> */}
        </>
    );
};

export default BarCharts;