import React, { useState, useEffect } from 'react';
import { Dialog, Typography } from '@mui/material';
import Spinner from '../Spinner';
import { Add, Close } from '@mui/icons-material';
import FindForm from './components/FindForm';
import { IconButton } from '@mui/material';
import CreateManager from './components/CreateManager';

const AddUserToGroupManagers = ({ groupId, group }) => {

    const [user, setUser] = useState();
    // const [error, setError] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUser();
    }, []);

    const handleOpenDialog = () => {
        setUser();
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <IconButton
                onClick={() => (handleOpenDialog())}
                style={{
                    backgroundColor: '#3498db',
                    color: '#ffffff'
                }}>
                <Add sx={{ fontSize: '0.7em' }} />
            </IconButton>
            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}
                style={{ backgroundColor: '#eee' }}
                PaperProps={{
                    style: { backgroundColor: '#eee' },
                }}
            >
                <div onClick={() => handleCloseDialog()} className="helpButton2">
                    <Close />
                </div>
                <div className='centerContent2'>
                    {loading ? (
                        <Spinner />
                    ) : user ? (
                        <Typography variant="body1">
                            <CreateManager
                                user={user}
                                group={group}
                                groupId={groupId}
                                cancel={handleCloseDialog}
                            />
                        </Typography>
                    ) : (<>
                        <FindForm
                            title = 'Felhasználó hozzáadása a csoport ügyintőihez'
                            openDialog={openDialog}
                            setUser={setUser} />
                    </>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default AddUserToGroupManagers;
