import { images } from "./images"

export const subcribe = {
    type: 'help',
    isAdmin: false,
    icon: 'GroupAdd',
    content: 'Csoport feliratkozás',
    description: 'A Csoport feliratkozás lehetősége az alkalmazás főoldaláról és a felhasználó profiljából is gyorsan elérhető, biztosítva a könnyű és intuitív feliratkozási folyamatot.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Feliratkozás',
            description: 'A feliratkozás opciója mind a főoldalon, mind a felhasználó profiljában szerepel, így bárhonnan könnyen elérhető.',
            animation: images.subscribeToGroup4,
            help: null,
            // mark: 'rect',
            // markRadius: 41,
            // markXPercent: 73.8,
            // markYPercent: 15.6,
            // markRectangleWidth: 89,
            // markRectangleHeight: 24
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Csoport QR kódjának beolvasása',
            description: 'Az új csoportokhoz való csatlakozáshoz egyszerűen olvassuk le a csoport QR kódját.',
            image: images.qr2,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Kamera kiválasztása',
            description: 'A kamera kiválasztása gomb segítségével választhatunk a rendelkezésre álló kamerák közül',
            image: images.mobile1,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Jelentkezés megerősítése',
            description: ' Amennyiben sikeres a beolvasás és még nem vagyunk tagok, megjelenik egy jelentkezési megerősítési ablak. Kattintsunk a "Jelentkezem" gombra',
            image: images.subscribe2b,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Sikertelen jelentkezés',
            description: 'Ha már tagok vagyunk, vagy már jelentkeztünk, egy hibaüzenet tájékoztat minket erről.',
            image: images.subscribe3,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Jelentkezés nyomonkövetése',
            description: 'A sikeres jelentkezés megjelenik a csoport oldalán, a tagok listájában.',
            image: images.subscribe5b,
            help: null,
            mark: 'rect',
            markXPercent: 8,
            markYPercent: 57,
            markRectangleWidth: 85,
            markRectangleHeight: 65
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: '',
            description: 'A kezdőoldalon, a csoportok között is megjelenik a csoport',
            image: images.subscribe4b,
            help: null,
            mark: 'rect',
            markXPercent: 37,
            markYPercent: 43,
            markRectangleWidth: 62,
            markRectangleHeight: 57
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Értesítés a jelentkezésről',
            description: 'A csoport adminisztrátorai értesítést kapnak a jelentkezésről, és lehetőségük van elfogadni vagy elutasítani azt.',
            image: images.subscribe5,
            help: null,
            mark: 'rect',
            markXPercent: 11,
            markYPercent: 55,
            markRectangleWidth: 87,
            markRectangleHeight: 62
        }
    ]
}

