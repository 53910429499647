import React, { useEffect } from 'react';

const Circle2 = ({ context, canvas, imageElement, element, imageHeight, markRadius, markXPercent, markYPercent, action, next, show, hide, name, index, actual }) => {
    useEffect(() => {
        if (actual) {
            if (action === 'show') {
                show(name);
            }
            if (action === 'hide') {
                hide(name);
            }
            next();
        }
    }, [action, actual, hide, name, next, show]);

    useEffect(() => {
        if ((context || canvas) && imageElement) {
            const ctx = context || canvas.getContext('2d');
            const centerX = (markXPercent / 100) * imageElement.width;
            const centerY = (markYPercent / 100) * imageElement.height;
            const percent = imageHeight / imageElement.height;

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.drawImage(imageElement, 0, 0, imageElement.width * percent, imageElement.height * percent);

            if (element.show) {
                ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
                ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
                ctx.beginPath();
                ctx.arc(centerX * percent, centerY * percent, markRadius * percent, 0, 2 * Math.PI, false);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();
            }
        }
    }, [context, canvas, imageElement, imageHeight, markRadius, markXPercent, markYPercent, element]);

    return null;
};

export default Circle2;
