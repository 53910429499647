import { Typography } from "@mui/material"
import { images } from "./images"

export const dataGrouping = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Adatok csoportosítása, elemzése és vizualizálása',
    description: ``,
    steps: [
        {
            type: 'title', // slide, link
            selector: '.stat2',
            content: 'Adatok csoportosítása',
            description:`
                <Typography variant="h4" gutterBottom>
                    Az adatok csoportosítása lehetővé teszi számunkra, hogy különböző szempontok alapján strukturált és részletes információkat kapjunk a teljesítményről, a fejlődésről és egyéb fontos mutatókról. Az értékelések és visszajelzések elemzése révén például feltérképezhetjük, hogyan alakulnak a diákok önértékelései az adott címkékhez kapcsolódó kérdésekre adott válaszok függvényében.
                </Typography>`
            ,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Kategóriák',
            description: 'Az adatokat csoportosíthatjuk a következő kategóriák szerint:',
            image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Értékelés típusok és címkék szerint',
            description: `Az előző fejezetekben tárgyalt értékelés típusok és címkék segítségével csoportosíthatjuk az adatokat. Például az "Igényes munka" vagy "Kitartás" címkék alapján vizsgálhatjuk az önértékelés és értékelések kapcsolatát.`,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Értékeltek szerint',
            description: `Az adatokat az értékelt személyek alapján is csoportosíthatjuk, ami lehetővé teszi számunkra, hogy egy diák értékeléseit elemezzük, illetve összehasonlíthassuk más diákok értékelésével.`,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Kérdések szerint',
            description: `Kérdések alapján is csoportosíthatjuk az értékeléseket.`,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Kérdőívek szerint',
            description: `Az adatokat a kérdőívek szerint is csoportosíthatjuk, ami lehetővé teszi számunkra, hogy egy adott kérdőív teljesítményét és eredményeit elemezzük.`,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Dátum szerint',
            description: `Az adatokat időbeli felbontásban is csoportosíthatjuk, például napi, heti vagy havi bontásban. Ez lehetővé teszi számunkra, hogy időbeli trendeket és változásokat kövessünk nyomon az értékelésekben és visszajelzésekben. Az adatokat megtekinthetjük az értékelés és a kérdőív kitöltési időszakának kezdete szerinti dátum alapján is.`,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Másodlagos csoportosítás',
            description: ``,
            image: images.groupStatSetup2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Sorozatok',
            description: `A másodlagos csoportosítás lehetőségei, amelyek jellemzően a diagramok halmozott oszlopait vagy sorozatait alkotják:
            <ul>
                <li>önértékelés/értékelés</li>
                <li>értékelő perspektíva</li>
                <li>csoport perspektíva</li>
                <li>címke</li>
                <li>értékeltek</li>
                <li>kérdések</li>
                <li>kérdőívek</li>
                <li>nincs: nincs második csoportosítási szempont</li>
            <ul>
`,
            image: images.groupStatSetup9,
            help: null,
        },
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Elemzések adatai',
            description: `Az applikáció lehetőséget biztosít arra, hogy különböző típusú adatokat elemezzünk, amelyek a kérdőívek kitöltése során keletkeznek.`,
            image: images.groupStatSetup2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: '',
            description: ``,
            image: images.groupStatSetup2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: '',
            description: `Ezek az adatok tartalmazzák: kérdőívek;
kitöltések;
értékelések;
normalizált értékek;
érzelmi állapot adatok
`,
            // image: images.groupStatSetup2,
            help: null,
        },
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Statisztikai adatok',
            description: `A statisztikai adatok elemzése során számos mutatót vehetünk figyelembe, amelyek részletes betekintést nyújtanak az értékelések és visszajelzések alakulásába. Az elemezhető statisztikai adatok közé tartoznak:
`,
            image: images.groupStatSetup3,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            // content: 'Statisztikai adatok',
            description: `
`,
            image: images.groupStatSetup3,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Elemezhető statisztikai adatok',
            description: `<ul>
            <li>darabszám: a kérdőívek/kitöltések/értékelések száma</li>
<li>minimum értékek: a legalacsonyabb értékelés</li>
<li>maximum értékek: a legmagasabb értékelés</li>
<li>átlag: az összes értékelés átlagértéke</li>
<li>medián: a középső érték az értékelések sorában</li>
<li>szórás: az értékelések közötti variabilitás, amely megmutatja, mennyire szóródnak az értékek az átlag körül</li>
</ul>
`,
            // image: images.groupStatSetup3,
            help: null,
        },
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Diagram típusok',
            description: ``,
            image: images.groupStatSetup4,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Adatok vizualizálása',
            description: `Az adatok vizualizálása különböző típusú diagramok segítségével történik, amelyek megkönnyítik az értékelések és elemzések értelmezését. Jelenleg a rendszer az alábbi diagram típusokat támogatja:`,
            image: images.groupStatSetup4,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: '',
            description: `
            <ul>
            <li>oszlopdiagram: az értékeket függőleges oszlopok formájában jeleníti meg</li>  
            <li>halmozott oszlopdiagram: az egyes kategóriák összetevőinek arányait mutatja meg egymásra halmozva</li>
            <li>horizontális diagram: az oszlopdiagram vízszintes változata, amely alkalmasabb lehet bizonyos adatok ábrázolására</li>
            <li>vonaldiagram: elsősorban az értékek időbeli változásánál lehet hasznos, amivel jól követhetők a trendek</li>
            <li>radardiagram: több dimenzió adatait ábrázolja egy kör alakú grafikonon, amely segít az összetett kapcsolatok megértésében</li>
            </ul>`,
            image: images.groupStatSetup5,
            help: null,
        },
    ]
}
