import { images } from "./images"

export const start = {
    type: 'help',
    isAdmin: true,
    icon: 'Quiz',
    content: 'Kérdőívek készítése',
    description: 'Üdvözöljük a kérdőívek skészítésének dokumentációjában, ahol részletes leírást talál arról, hogyan hozhat létre és kezelhet kérdőíveket az alkalmazásban. Az alábbiakban áttekintjük a fő lépéseket, amelyekkel könnyedén elkészítheti saját kérdőíveit.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.dashboard2',
            content: 'Kérdőívek Menüpont',
            description: 'A "Kérdőívek" menüponton keresztül érhető el az összes létrehozott kérdőív. Itt kezelheti a már meglévő kérdőíveket vagy készíthet újat',
            image: images.dashboard2,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 25,
            markYPercent: 91,
            markRectangleWidth: 38,
            markRectangleHeight: 97
        },
        {
            type: 'slide', // slide, link
            selector: '.manage1',
            content: 'Kérdőívek listája',
            description: 'A "Kérdőívek" menüpont egy könnyen navigálható felhasználói felületet kínál, ahol az összes már elkészített kérdőív listázva van.',
            image: images.manage1,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 7,
            markYPercent: 17,
            markRectangleWidth: 88,
            markRectangleHeight: 83
        },
        {
            type: 'slide', // slide, link
            selector: '.manage2',
            content: 'Kérdőív készítése',
            description: 'A jobb alsó sarokban található egy "+" gomb, amely lehetővé teszi az új kérdőív létrehozását. Egyetlen kattintással elindíthatja a kérdőív készítésének folyamatát',
            image: images.manage1,
            help: null,
            mark: 'circle',
            markRadius: 27,
            markXPercent: 84.4,
            markYPercent: 85.5,
            markRectangleWidth: 38,
            markRectangleHeight: 97
        },
        {
            type: 'slide', // slide, link
            selector: '.start1',
            content: 'Kérdőívek Menüpont',
            description: 'Indítás után egy rövid ismertetővel találkozik, amely segít a kérdőív létrehozásában.',
            image: images.start1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.start2',
            content: 'Sablon választása',
            description: 'A következő oldalon válassza ki a megfelelő kérdőív sablont.',
            image: images.start2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.start3',
            content: 'Csoport(ok) kiválasztása',
            description: 'Adja meg a csoportot vagy csoportokat, akiknek a kérdőívet kívánja küldeni.',
            image: images.start3,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.start4',
            content: 'Kezdődátum Beállítása',
            description: 'Válassza ki a kitöltés kezdődátumát.',
            image: images.start4,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.start5',
            content: 'Határidő Megadása',
            description: 'Állítsa be a kitöltés határidejét.',
            image: images.start5,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.start6',
            content: 'Összefoglaló',
            description: 'A következő oldalon összefoglalhatja a beállított adatokat, majd az "Indítás" gombbal elkészítheti a kérdőívet.',
            image: images.start6,
            help: null,
        },
        {
            type: 'title', // slide, link
            selector: '.title',
            content: 'Sikeres mentés utáni események',
            description: 'A kérdőív létrehozása után több "esemény" történik.'
        },
        {
            type: 'slide', // slide, link
            selector: '.start7',
            content: 'Sikeres mentés',
            description: 'A kérdőív létrehozása után egy sikeres mentés oldalon találja magát',
            image: images.start7,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.start8',
            content: 'Kérdőívek Listájának frissítése',
            description: 'A létrehozott kérdőív megjelenik a "Kérdőívek" menüpontban.',
            image: images.start8,
            help: null,
            mark: 'rect',
            markRadius: 8,
            markXPercent: 7,
            markYPercent: 17,
            markRectangleWidth: 88,
            markRectangleHeight: 31
        },
        {
            type: 'slide', // slide, link
            selector: '.start9',
            content: 'Csoport tagjainak értesítése',
            description: 'A kiválasztott csoport tagjai értesítést kapnak a kitöltés lehetőségéről, és megkezdhetik a kérdőív kitöltését.',
            image: images.start9,
            help: null,
            mark: 'rect',
            markRadius: 8,
            markXPercent: 7,
            markYPercent: 17,
            markRectangleWidth: 88,
            markRectangleHeight: 31
        },
    ]
}
