import React from 'react';
import SubscribedUser from './SubscribedUser';
import AcceptSubscribeAction from '../../services/actions/AcceptSubscribedAction';
import DeleteSubscribeAction from '../../services/actions/DeleteSubscribeAction';

const SubscribedUsers = ({ users, groupId = null, isAdmin = false, navigateToUser = false }) => {
    const { subscribe } = AcceptSubscribeAction();
    const { deleteAction } = DeleteSubscribeAction();
    return (
        <div>
            {users?.map((user, index) => {
                return (
                    <SubscribedUser
                        level='member'
                        key={index}
                        user={user}
                        groupId={groupId}
                        acceptAction={isAdmin ? subscribe : undefined}
                        deleteAction={isAdmin ? deleteAction : undefined}
                        navigateToUser={navigateToUser}
                    />)
            })}
        </div>
    );
};

export default SubscribedUsers;