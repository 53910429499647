import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import { v4 as uuidv4 } from "uuid";

const SelectLabel4 = ({ label, selectedQuestion, handleQuestionChange, labels, multiselect = false }) => {
    return (
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel key={uuidv4()}>{label ?? 'Címke'}</InputLabel>
            <Select
                value={selectedQuestion}
                onChange={handleQuestionChange}
                multiple={multiselect}
                renderValue={(selected) => multiselect ? selected.map(val => labels[val]?.name).join(', ') : labels[selected]?.name}
            >
                {Object.keys(labels).map((question, index) => (
                    <MenuItem key={index} value={question}>
                        {multiselect ? (
                            <>
                                <Checkbox checked={selectedQuestion.indexOf(question) > -1} />
                                <ListItemText primary={labels[question]?.name} />
                            </>
                        ) : (
                            labels[question].name
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectLabel4;
