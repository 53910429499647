import { images } from "./images"

export const members = {
    "type": "help",
    "icon": "Users",
    "content": "Csoport hallgatóinak kezelése",
    "description": `<p>Ez a dokumentáció részletes útmutatót nyújt a hallgatók kezeléséhez, beleértve a hallgatók felvételét, jelentkezését egy csoportba, valamint hallgatók törlését csoportból. A funkciók használatának előzetes feltétele (“felhasználók létrehozása regisztráció nélkül” kivételével) a hallgatók regisztrációja a rendszerbe.</p>`,
    "steps": [
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Hallgatók felvétele csoportba",
            "description": `
                <ul>
                    <li>Hallgató felvétele a (hallgató) QR kódjával</li>
                    <li>Hallgató jelentkezése a csoport QR kódjával</li>
                    <li>Felhasználók létrehozása regisztráció nélkül</li>
                </ul>
            `,
            "image": null,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Hallgató felvétele a (hallgató) QR kódjával",
            "description": `
                <p>A hallgató nyissa meg a kezdőoldalon található "QR kódom" gombot, hogy megjelenítse a QR kódját.</p>
                <ul>
                    <li>Az oktató olvassa be a hallgató QR kódját a nyitóoldalon vagy a csoport oldalán található Felhasználó felvétele gomb segítségével.</li>
                    <li>Az oktató adja hozzá a hallgatót a csoportjához.</li>
                </ul>
            `,
            "image": images.qr2,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Hallgató jelentkezése a csoport QR kódjával",
            "description": `
            <p>Az oktató nyissa meg a csoport QR kódját. (Az oktató megoszthatja, bemutathatja a kivetítőn, vagy a telefonján megmutathatja a QR kódot.)</p>
            `,
            "image": images.groupQR,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Kamera kiválasztása',
            description: 'A kamera kiválasztása gomb segítségével választhatunk a rendelkezésre álló kamerák közül',
            image: images.mobile1,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Jelentkezés megerősítése',
            description: ' Amennyiben sikeres a beolvasás és még nem vagyunk tagok, megjelenik egy jelentkezési megerősítési ablak. Kattintsunk a "Jelentkezem" gombra',
            image: images.subscribe2b,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Sikertelen jelentkezés',
            description: 'Ha már tagok vagyunk, vagy már jelentkeztünk, egy hibaüzenet tájékoztat minket erről.',
            image: images.subscribe3,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Jelentkezés nyomonkövetése',
            description: 'A sikeres jelentkezés megjelenik a csoport oldalán, a tagok listájában.',
            image: images.subscribe5b,
            help: null,
            mark: 'rect',
            markXPercent: 8,
            markYPercent: 57,
            markRectangleWidth: 85,
            markRectangleHeight: 65
        },
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: '',
            description: 'A kezdőoldalon, a csoportok között is megjelenik a csoport',
            image: images.subscribe4b,
            help: null,
            mark: 'rect',
            markXPercent: 37,
            markYPercent: 43,
            markRectangleWidth: 62,
            markRectangleHeight: 57
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `
                <ul>
                    <li>Az oktató a nyitó oldalon vagy a csoport oldalán észleli a jelentkezést, majd elbírálja azt.</li>
                </ul>
            `,
            "image": images.subscribe5,
            "help": null,
            "mark": 'rect',
            "markXPercent": 11,
            "markYPercent": 55,
            "markRectangleWidth": 87,
            "markRectangleHeight": 62
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Felhasználók létrehozása regisztráció nélkül",
            "description": `
                <p>A csoport oldalán lehetőség van hallgatók létrehozására regisztráció nélkül is. Ennek köszönhetően az oktatók azonnal elkezdhetik a hallgatók értékelését, anélkül hogy azokat bevonnák az értékelési folyamatba. A későbbiekben, a hallgatók bevonása során a hallgatók a 'elfelejtett jelszó' funkcióval tudnak jelszót létrehozni és belépni a rendszerbe. A létrehozott hallgató automatikusan a csoport tagja lesz. Ha a felhasználó (e-mail cím) létezik, akkor nem jön létre új felhasználó, hanem a meglévő felhasználó lesz hozzáadva a csoporthoz. Ez egy új lehetőség meglévő felhasználók csoporthoz történő hozzáadásához.</p>
                <ul>
                    <li>Nyissa meg a csoport oldalát.</li>
                    <li>Válassza a "Felhasználók létrehozása" gombot a Tagok fülön.</li>
                </ul>
            `,
            "image": images.groupMembers,
            "help": null,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 33,
            "markYPercent": 45,
            "markRectangleWidth": 47,
            "markRectangleHeight": 52
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "",
            "description": `               
                <ul>
                    <li>Töltse ki az új felhasználó adatait (e-mail cím, név).</li>
                    <li>Mentse el a Létrehozás gombbal.</li>
                </ul>
            `,
            "image": images.createUser1,
            "help": null,
            "mark": 'rect',
            "markRadius": null,
            "markXPercent": 11,
            "markYPercent": 42,
            "markRectangleWidth": 89,
            "markRectangleHeight": 69
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Hallgatók törlése csoportból",
            "description": `
                <p>Jogosultságok: Csoport ügyintézői jogosultság szükséges ezen műveletek végrehajtásához.</p>
                <p>Elérési Út: A funkciók a csoport oldalán belül, a beállítások menüpontban (jobb alsó sarokban lévő ikon) a felhasználók fülön találhatóak.</p>
                <ul>
                    <li>Nyissa meg a csoport oldalát.</li>
                    <li>Menjen a beállítások menüpontra.</li>
                    <li>Válassza a "Felhasználók" fület.</li>
                    <li>Keresse meg a törölni kívánt hallgatót, majd távolítsa el őt a csoportból.</li>
                </ul>
            `,
            "image": images.userCRUD,
            "help": null,
            "mark": 'circle',
            "markRadius": 20,
            "markXPercent": 81,
            "markYPercent": 50,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        }
    ]
}

