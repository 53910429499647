import { BarChart } from '@mui/icons-material';
import { Badge } from '@mui/material';
import React, { useContext } from 'react';
import { AppContext } from '../../services/appContext';

const EvaluationBadge = ({ value }) => {
    const { newEvaluation } = useContext(AppContext);
    return (
        <Badge badgeContent={value != null ? value : newEvaluation} color="info">
            <BarChart />
        </Badge>
    );
};

export default EvaluationBadge;