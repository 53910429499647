import React, { useEffect } from 'react';

const Circle = ({ context, canvas, imageElement, imageHeight, markRadius, markXPercent, markYPercent }) => {
    useEffect(() => {
        if ((context || canvas) && imageElement) {
            const ctx = context || canvas.getContext('2d');
            const centerX = (markXPercent / 100) * imageElement.width;
            const centerY = (markYPercent / 100) * imageElement.height;
            const percent = imageHeight / imageElement.height;

            // Clear canvas before drawing
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // Draw the image
            ctx.drawImage(imageElement, 0, 0, imageElement.width * percent, imageElement.height * percent);

            // Draw circle
            ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
            ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
            ctx.beginPath();
            ctx.arc(centerX * percent, centerY * percent, markRadius * percent, 0, 2 * Math.PI, false);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
        }
    }, [context, canvas, imageElement, imageHeight, markRadius, markXPercent, markYPercent]);

    return null; // This component does not render anything itself
};

export default Circle;
