import React, { useContext, useEffect, useState } from 'react';
import View from './View';
import Utility from './components/Utility';
import Spinner from '../../components/Spinner';
import { auth, selectedAppName } from '../../firebase.config';
import GetUserEvaluated from '../../services/firebase/GetUserEvaluated';
import { AppContext } from '../../services/appContext';

const Statistics = () => {
    const { snapshots } = useContext(AppContext);
    const [logData, setLogData] = useState(JSON.parse(localStorage.getItem('statistics-' + selectedAppName + '-' + auth?.currentUser?.uid)) ?? []);
    const [data, setData] = useState();
    const [labelsData, setLabelsData] = useState();
    const [loading, setLoading] = useState(false);
    const [myGroups, setMyGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [labels, setLabels] = useState([]);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (snapshots && Array.isArray(snapshots['allgroups'])) {
            const all = snapshots['allgroups'];
            const ids = all.map(g => g.id);
            setMyGroups(ids.toString());
            setGroups(snapshots['allgroups']);
        }
    }, [snapshots['allgroups']]);

    const getData = async (filter) => {
        setLoading(true);
        const { getData } = await GetUserEvaluated();
        try {
            const groups = 'a';
            const logs = await getData({ groups: myGroups, timeDivison: 'month' });
            setLogData(logs?.response?.result);
            localStorage.setItem('statistics-' + selectedAppName + '-' + auth?.currentUser?.uid, JSON.stringify(logs?.response?.result));
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
        setLoading(false);
    };
    const utility = Utility();


    const getLabelsStat = (data) => {
        // Inicializáljuk a csoportosított statisztikát
        const labelsStat = {};

        // Iterálunk az adatokon
        data.forEach((item) => {
            // Ellenőrizzük, hogy az elemnek van-e címke
            if (item.labels && item.labels.length > 0) {
                // Iterálunk az elem címkéin
                item.labels.forEach((label) => {
                    // Ha a címke még nem szerepelt a statisztikában, létrehozzuk az objektumot
                    const labelName = item.groupAuthor + '-' + item.evaluatedYear + '-' + item.evaluatedMonth + '-' + item.groupId + '-' + '-' + label;
                    if (!labelsStat[labelName]) {
                        labelsStat[labelName] = {
                            count: item['count(evaluated_value)'],
                            sum: parseFloat(item['sum_evaluated_value']),
                            average: parseFloat(item['average_evaluated_value']),
                            question: label,
                            groupAuthor: item["groupAuthor"],
                            evaluatedYear: item["evaluatedYear"],
                            evaluatedMonth: item["evaluatedMonth"],
                            groupId: item["groupId"],
                            // template: item["template"],
                            updatedAt: item["updatedAt"]
                        }
                    } else {
                        // Ha már szerepel a címke, frissítjük a statisztikát az aktuális elem adataival
                        labelsStat[labelName].count += item['count(evaluated_value)'];
                        labelsStat[labelName].sum += parseFloat(item['sum_evaluated_value']);
                        labelsStat[labelName].average = labelsStat[labelName].sum / labelsStat[labelName].count;
                    }
                });
            }
        });
        return labelsStat;
    };


    const getAllData = async () => {
        const data = [];
        let allLabels = [];
        let allQuestions = [];
        const templates = [];
        const evaluated = [];
        setLoading(true);
        await Promise.all(
            logData?.map(async (line) => {
                const b = await utility.getTemplate({ id: line?.template });
                templates[line?.template] = b;
                const labels = templates[line?.template]?.meta?.questions[line?.question]?.labels || [];
                const labelsFull = labels.map(l => templates[line?.template]?.meta?.labels[l].name);
                labels.forEach(element => {
                    allLabels[element] = templates[line?.template]?.meta?.labels[element].name;
                });
                allQuestions[line?.question] = templates[line?.template]?.meta?.questions[line?.question]?.name || '';
                data.push({
                    ...line,
                    updatedAt: line?.evaluatedYear + '-' + line?.evaluatedMonth,
                    template: templates[line?.template]?.description || '',
                    evaluated: evaluated[line?.evaluated]?.name || '',
                    question: templates[line?.template]?.meta?.questions[line?.question]?.name || '',
                    labels: labelsFull,
                });
            })
        );

        setData(data);;
        setLabels(allLabels);
        setQuestions(allQuestions);
        setLoading(false);
        const labelsStat = getLabelsStat(data);
        setLabelsData(labelsStat);
    };

    useEffect(() => {
        getAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logData]);

    useEffect(() => {
        setLogData(JSON.parse(localStorage.getItem('statistics-' + selectedAppName + '-' + auth?.currentUser?.uid)) ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, selectedAppName]);


    return (<>
        {(loading && !data && !labelsData && !groups && questions?.length < 1 && !labels) ? <Spinner /> :
            <View
                getData={getData}
                data={data}
                labelsData={labelsData}
                questions={questions}
                labels={labels}
                groups={groups}
            />
        }
    </>
    );
};

export default Statistics;