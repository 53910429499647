import React  from 'react';
import CPersonalMultiSelect from '../../../../commons/CPersonalMultiSelect';

const UserSelect = ({
    evaluate,
    values,
    handleChange,
    members,
    admins
}) => {
    const showMembers = evaluate === 'members' || evaluate === 'all';
    const showAdmins = evaluate === 'admins' || evaluate === 'all';
    const options = members.map((i) => ({ value: i.id, label: i.data.name, icon: i.data.id }))
    const optionsAdmin = admins.map((i) => ({ value: i.id, label: i.data.name, icon: i.data.id }))

    return (
        <>
            {showMembers && <CPersonalMultiSelect
                field={'members'}
                label='Értékelendő hallgatók'
                handleChange={handleChange}
                values={values?.members ? values.members : []}
                options={options} />
            }
            {showAdmins && <CPersonalMultiSelect
                field={'admins'}
                label='Értékelendő oktatók'
                handleChange={handleChange}
                values={values?.admins ? values.admins : []}
                options={optionsAdmin} />}
        </>
    );
};

export default UserSelect;