import React from 'react';
import QuestionnaireItemList from '../../components/questionnaire/QuestionnaireListItem';
import { convertArrayById3 } from '../../commons/Utility';
import DoneMessage from '../../commons/DoneMessage';
import ScrollToBottomIcon from '../../components/buttons/RBButton';
import { History } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const View = ({ questionnaires, groups, selectItem, profile, lastSampling }) => {
    const profileGroups = convertArrayById3(groups, 'id');
    const navigate = useNavigate();
    return (
        <div className="profileList">
            <header className="profileHeader">
                <p className="pageHeader">Kitöltés</p>
            </header>
            <main>
                {typeof questionnaires == 'object' && Array.isArray(questionnaires) && questionnaires.length < 1 &&
                    <DoneMessage icon='ThumbsUpDown' label='Jelenleg nincs kitöltendő értékelés' />
                }
                <div className="listingsList">
                    {questionnaires && groups &&
                        questionnaires.map((element, index) => {
                            const show = (element.data.status === 'new') && (element.id != lastSampling);
                            return (
                                <QuestionnaireItemList
                                    key={index}
                                    profile={profile}
                                    questionnaire={element}
                                    group={profileGroups[element.data.group]}
                                    selectItem={selectItem}
                                    show={show}
                                />
                            )
                        })}
                </div>
            </main>
            <ScrollToBottomIcon
                name={'Kitöltéseim'}
                icon={<History />}
                onClick={() => { navigate('/history'); }}
            />
        </div>
    );
};

export default View;