import { images } from "./images"

export const roles = {
    type: 'help',
    isAdmin: true,
    icon: 'Quiz',
    content: 'Jogosultsági szintek',
    description: `<p>Az alkalmazásban különböző jogosultsági szintek érhetők el, amelyek meghatározzák, hogy az egyes felhasználók milyen funkciókat érhetnek el és milyen feladatokat végezhetnek el. Ezek a jogosultsági szintek biztosítják a rendszer biztonságos és hatékony működését.</p>`,
    steps: [
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Hallgató",
            "description": `
                A hallgatók számára biztosított jogosultságok lehetővé teszik, hogy jelentkezzenek csoportokba, tagjai legyenek ezeknek a csoportoknak, és kitöltsék a számukra kijelölt kérdőíveket. A hallgatói szint főként a tanulási és részvételi funkciókra összpontosít.
                <ul>
                <li>Jelentkezhet csoportba</li>
                <li>Csoport tagja lehet</li>
                <li>Kitölthet kérdőíveket</li>
            </ul>`,
            "image": images.roles2,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Oktató",
            "description": `
            
                Az oktatók szerepe kiterjed a csoportok kezelésére és a hallgatók irányítására. Az oktatók nemcsak tagjai, hanem vezetői is lehetnek csoportoknak, ahol kezelhetik a kérdőíveket és hallgatókat vehetnek fel a csoportjaikba. Az oktatói jogosultságok lehetővé teszik a tanítási folyamatok hatékony irányítását.
                <ul>
                <li>Csoport tagja és oktatója lehet</li>
                <li>Csoport oktatójaként kezelheti a kérdőíveket</li>
                <li>Felvehet hallgatót a csoportba (jelentkezést elfogadhatja, hozzáadhatja)</li>
            </ul>`,
            "image": null,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Csoport ügyintéző",
            "description": `
            
                A csoport ügyintézői széles körű jogosultságokkal rendelkeznek, amelyek lehetővé teszik számukra a csoportok teljes körű kezelését. Ezek közé tartozik a kérdőívek kezelése, a hallgatók és oktatók adminisztrálása, valamint új csoportok létrehozása és a meglévő csoportok adatainak módosítása. A csoport ügyintézők fontos szerepet játszanak a csoportok zökkenőmentes működésében és a szervezési feladatok ellátásában.
                <ul>
                <li>Csoport tagja, oktatója és ügyintézője lehet</li>
                <li>Csoport ügyintézőjeként kezelheti a kérdőíveket</li>
                <li>Kezelheti a hallgatókat a csoportban (jelentkezést elfogadhatja, hozzáadhatja és törölheti)</li>
                <li>Beállíthat oktatói jogosultságot</li>
                <li>Kezelheti a csoportokat (új csoport létrehozása, csoportadatok módosítása)</li>
            </ul>`,
            "image": null,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        },
        {
            "type": "slide",
            "selector": ".dashboard2",
            "content": "Intézményi ügyintéző",
            "description": `
            
                Az intézményi ügyintézők rendelkeznek a legszélesebb körű jogosultságokkal, amelyek lehetővé teszik számukra az egész intézmény felhasználóinak és csoportjainak kezelését. Beállíthatják az oktatói és ügyintézői jogosultságokat, valamint teljes rálátással és hozzáféréssel rendelkeznek az összes csoporthoz és felhasználóhoz. Ez a szint biztosítja, hogy az intézményi folyamatok hatékonyan és koordináltan működjenek.
            <ul>
                <li>Beállíthat oktatói és ügyintézői jogosultságot</li>
                <li>Kezelheti az összes csoportot és felhasználót</li>
            </ul>`,
            "image": null,
            "help": null,
            "mark": null,
            "markRadius": null,
            "markXPercent": null,
            "markYPercent": null,
            "markRectangleWidth": null,
            "markRectangleHeight": null
        }
    ]
    
}
