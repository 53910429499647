import { Typography } from "@mui/material"
import { images } from "./images"

export const groupStatCharts = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Diagram részei',
    description: `Mielőtt részletesen bemutatnánk a diagramokat, érdemes kitérni néhány fontos részletre a diagramok felépítésével kapcsolatban.`,
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.stat2',
            content: '',
            description:``
            ,
            image: images.groupStatSetup6,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Nézet menü',
            description: `
            A jobb felső sarokban található, és három opcióval rendelkezik:
            <ul>
            <li>egyszerű: minimális beállítási lehetőséget kínál</li>
            <li>interaktív: lehetővé teszi a megjelenített kategóriák változtatását</li>
            <li>haladó: az összes lehetséges beállítás megjelenik</li>
            </ul>
            `,
            image: images.groupStatSetup7,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Kategóriák',
            description: `
            Az adatok elsődleges csoportosítása kategóriák szerint történik, amelyek jellemzően a diagramok oszlopait alkotják. Az alkalmazásban az alábbi kategóriák használatosak:
            <ul>
                <li>önértékelés/értékelés</li>
                <li>értékelő perspektíva</li>
                <li>csoport perspektíva</li>
                <li>címke</li>
                <li>értékeltek</li>
                <li>kérdések</li>
                <li>kérdőívek</li>
                <li>dátum</li>
            </ul>
            `,
            image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Csoportosítás panel',
            description: `A másodlagos csoportosítás lehetőségei, amelyek jellemzően a diagramok halmozott oszlopait vagy sorozatait alkotják:
            <ul>
                <li>önértékelés/értékelés</li>
                <li>értékelő perspektíva</li>
                <li>csoport perspektíva</li>
                <li>címke</li>
                <li>értékeltek</li>
                <li>kérdések</li>
                <li>kérdőívek</li>
                <li>nincs: nincs második csoportosítási szempont</li>
            <ul>
`,
            image: images.groupStatSetup9,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Adatok panel',
            description: `
Az adatok panel a következő beállítási lehetőségeket tartalmazza:
<ul>
        <li>elemzések adatai</li>
        <ul>
        <li>kérdőívek</li>
        <li>kitöltések</li>
        <li>értékelések</li>
        <li>normalizált értékek</li>
        <li>érzelmi állapot adatok</li>
        </ul>
        <li>statisztikai adatok</li>
        <ul>
        <li>darabszám</li>
        <li>minimum</li>
        <li>maximum</li>
        <li>átlag</li>
        <li>medián</li>
        <li>szórás</li>
        </ul>
        <li>diagram típusok</li>
        
        <ul>
        <li>oszlopdiagram</li>
        <li>halmozott oszlopdiagram</li>
        <li>horizontális diagram</li>
        <li>halmozott horizontális diagram</li>
        <li>vonaldiagram</li>
        <li>radardiagram</li>
        </ul>
    </ul>
        `,
            // image: images.groupStatSetup1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Elemzések adatai',
            description: `
            Az applikáció lehetőséget biztosít arra, hogy különböző típusú adatokat elemezzünk, amelyek a kérdőívek kitöltése során keletkeznek.
            <ul>
                <li>kitöltések;</li>
                <li>értékelések;</li>
                <li>normalizált értékek;</li>
                <li>érzelmi állapot adatok</li>
            </ul>
            `,
            image: images.groupStatSetup2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Statisztikai adatok',
            description: `
            A statisztikai adatok elemzése során számos mutatót vehetünk figyelembe, amelyek részletes betekintést nyújtanak az értékelések és visszajelzések alakulásába. Az elemezhető statisztikai adatok közé tartoznak:
            <ul>
                <li>darabszám: a kérdőívek/kitöltések/értékelések száma</li>
                <li>minimum értékek: a legalacsonyabb értékelés</li>
                <li>maximum értékek: a legmagasabb értékelés</li>
                <li>átlag: az összes értékelés átlagértéke</li>
                <li>medián: a középső érték az értékelések sorában</li>
                <li>szórás: az értékelések közötti variabilitás, amely megmutatja, mennyire szóródnak az értékek az átlag körül</li>
            </ul>
`,
            image: images.groupStatSetup3,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Diagram típusok',
            description: `Az adatok vizualizálása különböző típusú diagramok segítségével történik, amelyek megkönnyítik az értékelések és elemzések értelmezését. Jelenleg a rendszer az alábbi diagram típusokat támogatja:`,
            image: images.groupStatSetup4,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: '',
            description: `
            <ul>
            <li>oszlopdiagram: az értékeket függőleges oszlopok formájában jeleníti meg</li>  
            <li>halmozott oszlopdiagram: az egyes kategóriák összetevőinek arányait mutatja meg egymásra halmozva</li>
            <li>horizontális diagram: az oszlopdiagram vízszintes változata, amely alkalmasabb lehet bizonyos adatok ábrázolására</li>
            <li>vonaldiagram: elsősorban az értékek időbeli változásánál lehet hasznos, amivel jól követhetők a trendek</li>
            <li>radardiagram: több dimenzió adatait ábrázolja egy kör alakú grafikonon, amely segít az összetett kapcsolatok megértésében</li>
            </ul>`,
            image: images.groupStatSetup5,
            help: null,
        },

        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Szűrők panel',
            description: `A szűrők panelen további szűrőket találhatunk, amelyekkel a megjelenített adatok körét módosíthatjuk.
`,
            image: images.groupStatSetup11,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Szűrők',
            description: `A címsor alatt helyezkednek el, és lehetővé teszik az adatok különböző szempontok szerinti szűrését.
`,
            image: images.groupStatSetup12,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Diagram menü',
            description: `A szűrők alatt található, itt lehet letölteni a diagram képét és a diagram adatait SVG, PNG és CSV formátumban.

`,
            image: images.groupStatSetup13,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Jelmagyarázat',
            description: `Legalul található, kattintható elemekkel, amelyek segítségével csak a kiválasztott kategóriák jelennek meg a diagramon.
`,
            image: images.groupStatSetup14,
            help: null,
        },
    ]
}
