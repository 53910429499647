import React from 'react';
import Qrscan from '../../components/qr/Qrscan';

const
    View = () => {
        return (
            <div className="profileList">
                <header>
                    <p className="pageHeader">QR kód beolvasása</p>
                </header>
                <Qrscan value={`https://kerdoiv.b612.hu/subscribe/`} />
            </div>
        );
    };

export default View;