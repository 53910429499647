import React from 'react';
import Calendar from 'react-calendar';
import './Calendar.css'

const FSPFieldDate = ({
    field,
    handleChange,
    value
}) => {
    // const locale = 'hu-HU';
    // const [date, setDate] = useState();
    const onChange = ((value) => {

        const now = value;
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Hónap 0-tól kezdődik, így +1, majd 2 számjegyűvé formázás
        const day = now.getDate().toString().padStart(2, '0'); // Nap 2 számjegyűvé formázás

        const formattedDate = `${year}-${month}-${day}`;
        // setDate(formattedDate);
        // console.log(formattedDate);
        handleChange(field.name, formattedDate);
    });

    return (
        <div>
            <div>
                {field.label}
            </div>
            <Calendar
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

export default FSPFieldDate;