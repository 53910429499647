import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../services/appContext';
import Query from '../../../hooks/query';
import GroupSchedules from '../../../services/snapshots/GroupSchedules';
import SchedulesEditor from './SchedulesEditor';
import BSAccordion from '../../myGroup/components/groupStats/components/BSAccordion';
import InfoCards from '../../../commons/help/widgets/InfoCards';
import { stepsSchedules } from '../../../commons/help/data/stepsSchedules';

const Schedules = ({ group }) => {
    const { snapshots } = useContext(AppContext);
    const [myGroups, setMyGroups] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const { subscribe } = GroupSchedules({ setter: setSchedules });

    const qtemplates = Query({
        setState: setTemplates,
        key: 'qtemplates',
        table: 'qtemplates',
        filter: null
    });

    useEffect(() => {
        const unsubscribe = subscribe({ group: group.id });
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group]);

    useEffect(() => {
        const unsubscribe2 = qtemplates.subscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    return (
        <div>
            <BSAccordion label='Súgók' title='Időzítések'>
                <InfoCards steps={stepsSchedules} dialog={true} />
            </BSAccordion>
            <h3>Időzítések</h3>
            <SchedulesEditor
                group={group}
                groups={myGroups}
                templates={templates}
                schedules={schedules}
            />
        </div>
    );
};

export default Schedules;