import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import SelectGroup from './components/SelectGroup';
import MultiSelectQuestions from './components/MultiSelectQuestions';
import MultiSelectDirection from './components/MultiSelectDirection';

const RadarChart = ({ groups, questions, data }) => {
    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedAxes, setSelectedAxes] = useState(['self', 'evaluator', 'evaluated', 'other']);
    const [availableQuestions, setAvailableQuestions] = useState([]);
    const [radarChartData, setRadarChartData] = useState({
        options: {
            scale: {
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 10,
                },
            },
            labels: ['self', 'evaluator', 'evaluated', 'other'],
        },
        series: [],
    });

    const handleGroupChange = (event) => {
        const selectedGroupName = event.target.value;
        setSelectedGroupName(selectedGroupName);

        const selectedGroup = groups.find((group) => group.data.name === selectedGroupName);
        setSelectedGroup(selectedGroup);
    };

    const handleQuestionChange = (event) => {
        setSelectedQuestions(event.target.value);
    };

    const handleAxesChange = (event) => {
        setSelectedAxes(event.target.value);
    };

    const getRadarChartData = () => {
        const seriesData = {
            options: {
                scale: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 10,
                    },
                },
                labels: selectedAxes.map((axis) => ({
                    'self': 'Önértékelés',
                    'evaluator': 'Értékelő',
                    'evaluated': 'Értékelt',
                    'other': 'Egyéb',
                }[axis])),
            },
            series: [],
        };

        // Iterate over selectedQuestions and create series for each
        selectedQuestions.forEach((selectedQuestion) => {

            const series = {
                name: selectedQuestion,
                data: selectedAxes.map((groupAuthor) => {
                    const filteredData = data.filter(
                        (item) =>
                            item.groupId === selectedGroup.id &&
                            item.question === selectedQuestion &&
                            item.groupAuthor === groupAuthor
                    );

                    if (filteredData.length === 0) {
                        // Alapértelmezett érték, például 0
                        return 0;
                    }

                    const averageValue =
                        filteredData.reduce((sum, item) => sum + parseFloat(item.average), 0) /
                        filteredData.length;

                    // Kerekítés két tizedesre
                    return parseFloat(averageValue.toFixed(2));
                }),
            };

            seriesData.series.push(series);
        });

        // Update options to include line
        seriesData.options.stroke = {
            width: 2,
        };

        return seriesData;
    };

    useEffect(() => {
        setAvailableQuestions(Object.values(questions));
    }, [questions]);

    useEffect(() => {
        setRadarChartData(getRadarChartData());
    }, [selectedQuestions, selectedAxes, selectedGroup, data]);

    return (
        <div>
            <SelectGroup
                selectedGroupName={selectedGroupName}
                handleGroupChange={handleGroupChange}
                groups={groups}
            />
            <MultiSelectQuestions
                selectedQuestions={selectedQuestions}
                handleQuestionChange={handleQuestionChange}
                availableQuestions={availableQuestions}
            />
            <MultiSelectDirection
                selectedAxes={selectedAxes}
                handleAxesChange={handleAxesChange}
            />
            <ApexCharts
                type="radar"
                width="99%"
                height={600}
                options={radarChartData.options}
                series={radarChartData.series}
            />
        </div>
    );
};

export default RadarChart;
