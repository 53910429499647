import React from 'react';
import { Box } from '@mui/material';

const QFooter = ({ children, show, styles }) => {
    return (
        <>
            {show &&
                < Box sx={styles.paperChin}>
                    {children}
                </Box >

            }
        </>
    )

};

export default QFooter;