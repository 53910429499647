import { useState, useEffect } from "react";

export function useLocalStorageSize() {
    const [localStorageSize, setLocalStorageSize] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        let data = [];
        const calculateLocalStorageSize = () => {
            let totalSize = 0;
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                const value = localStorage.getItem(key);
                const size = (value.length + key.length) * 2;
                totalSize += size;
                data[key] = size;
            }
            setData(data);
            // console.log(data);
            return totalSize / 1024; // Convert to kb
        };

        const updateLocalStorageSize = () => {
            const size = calculateLocalStorageSize();
            setLocalStorageSize(size.toFixed(2));
        };

        // Listen for changes to storage event
        window.addEventListener("storage", updateLocalStorageSize);

        // Calculate initial size
        updateLocalStorageSize();

        // Cleanup
        return () => {
            window.removeEventListener("storage", updateLocalStorageSize);
        };
    }, []);

    return `${localStorageSize} kb`;
}