import React from 'react';
import { Box, Chip, Select, MenuItem, IconButton, OutlinedInput } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const QuestionSelector = ({
    newQuestionLabelId, labelId, newQuestionId, setNewQuestionLabelId,
    setNewQuestionId, localQuestions, availableQuestions,
    handleQuestionAdd
}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', m: 0.5 }}>
            <Select
                value={newQuestionLabelId === labelId ? newQuestionId : ''}
                onChange={(e) => {
                    setNewQuestionLabelId(labelId);
                    setNewQuestionId(e.target.value);
                }}
                displayEmpty
                renderValue={(selected) => {
                    if (!selected) {
                        return <em>Kérdés kiválasztása</em>;
                    }
                    return localQuestions[selected]?.name || 'Ismeretlen kérdés';
                }}
                input={<OutlinedInput />}
            >
                <MenuItem value="" disabled>
                    Kérdés kiválasztása
                </MenuItem>
                {availableQuestions.map(questionId => (
                    <MenuItem key={questionId} value={questionId}>
                        {localQuestions[questionId].name}
                    </MenuItem>
                ))}
            </Select>
            <IconButton
                color="primary"
                onClick={handleQuestionAdd}
                disabled={!newQuestionId || newQuestionLabelId !== labelId}
            >
                <AddIcon />
            </IconButton>
        </Box>
    );
};

export default QuestionSelector;