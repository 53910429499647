import React, { useContext } from 'react';
import QRCode from 'react-qr-code';
import { AppContext } from '../../services/appContext';
// import { apps } from '../../data/apps';

const AppQRCode = ({ app = null }) => {
    const { selectedApp } = useContext(AppContext);
    // const storedApps = firebaseApps ?? apps;
    const code = `${window.location.origin}/app/${app?.id ?? selectedApp?.id}`;
    return (
        <>
            <QRCode
                size={512}
                style={{ height: "auto", maxWidth: "100%", width: "80%" }}
                value={code}
                viewBox={`0 0 512 512`}
            />
            <p>{code.id}</p>
        </>
    );
};

export default AppQRCode;