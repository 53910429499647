import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectGroup = ({ selectedGroupName, handleGroupChange, groups }) => {
    return (
        <div>
            <FormControl>
                <InputLabel id="group-label">Csoport</InputLabel>
                <Select
                    value={selectedGroupName}
                    onChange={handleGroupChange}
                    style={{ minWidth: '300px', maxWidth: '90vw' }}
                >
                    {groups.map((group) => (
                        <MenuItem key={group.id} value={group.data.name}>
                            {group.data.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectGroup;