import { images } from "./images"

export const dashboard = {
    type: 'help',
    isAdmin: false,
    icon: 'House',
    content: 'Kezdőoldal',
    description: 'A kezdőoldalon elérhető funkciók és információk áttekintésével könnyedén hozzáférhet az applikáció legfontosabb részeihez, javítva ezzel a felhasználói élményt.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.dashboard1',
            content: 'Funkciók és információk',
            description: 'A kezdőoldalon tetején hozzáférhet az applikáció legfontosabb információihoz és funkcióihoz, így minden lényeges adat és eszköz egy helyen megtalálható.',
            image: images.dashboard2b,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 8,
            markYPercent: 14,
            markRectangleWidth: 92,
            markRectangleHeight: 26
        },
        {
            type: 'slide', // slide, link
            selector: '.dashboardQRCode',
            content: 'Csoportjaim',
            description: `A képernyő közepén találhatók a csoportok, amelyek gyors hozzáférést biztosítanak a csoportokkal kapcsolatos funkciókhoz. Ezek lehetővé teszik a csoportok egyszerű kezelését és a csoporttevékenységek nyomon követését.`,
            image: images.dashboard2b,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 8,
            markYPercent: 30,
            markRectangleWidth: 92,
            markRectangleHeight: 59
        },
        {
            type: 'slide', // slide, link
            version: 2,
            selector: '.mygroupsUsersList',
            content: 'Aktív/inaktív kapcsoló a kezdő oldalon',
            description: 'A kezdő oldalon megjelenik egy új kapcsoló, amely lehetővé teszi az aktív és az inaktív csoportok külön-külön történő megjelenítését. Ez segíti az felhasználókat abban, hogy könnyebben áttekintsék és kezeljék a csoportokat.',
            image: images.inactiveDashboard,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 73,
            markYPercent: 29,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            elements: [
                {
                    id: 'circle1',
                    type: 'circle',
                    show: true
                },
                {
                    id: 'cursor1',
                    type: 'cursor'
                },
                {
                    id: 'image1',
                    type: 'image',
                    url: images.inactiveDashboard2 // URL az új Image2 komponenshez
                }
            ],
            animations: [
                { id: 'circle1', name: 'circle', action: 'show', show: true },
                { id: 'cursor1', name: 'cursor', action: 'move', show: true },
                { id: 'image1', name: 'image', action: 'show', show: true },
                { id: 'circle1', action: 'hide' },
                { id: 'cursor1', name: 'cursor', action: 'move', show: true },
                { id: 'image1', action: 'hide' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.dashboard4',
            content: 'Súgók',
            description: 'A nyitó képernyő alsó részén találhatók a súgó linkek és információk, amelyek segítséget nyújtanak az applikáció használata során felmerülő kérdések megválaszolásában.',
            image: images.dashboard2b,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 8,
            markYPercent: 60,
            markRectangleWidth: 92,
            markRectangleHeight: 81
        },
        {
            type: 'slide', // slide, link
            selector: '.dashboard4',
            content: 'Menüsáv',
            description: `Az oldal alján könnyen elérheti a rendszer főbb menüpontjait, amelyek közvetlenül kapcsolódnak az Ön felhasználói élményéhez. Itt találja meg a <ul>
            <li>kitöltendő kérdőíveket, </li>
            <li>az értékeléseket és a </li>
            <li>profil menüt.</li>
            </ul>
            `,
            image: images.dashboard2b,
            help: null,
            mark: 'rect',
            markRadius: 11,
            markXPercent: 8,
            markYPercent: 91,
            markRectangleWidth: 92,
            markRectangleHeight: 98
        },
        // {
        //     type: 'slide', // slide, link
        //     selector: '.dashboard2b',
        //     content: 'Jelentkezésekről szóló értesítések',
        //     description: ' Mindig naprakész lehet a legújabb fejleményekkel kapcsolatban, hiszen itt értesülhet a beérkező jelentkezésekről.',
        //     image: images.dashboard2b,
        //     help: null,
        //     mark: 'rect',
        //     markRadius: 9,
        //     markXPercent: 8,
        //     markYPercent: 43,
        //     markRectangleWidth: 90,
        //     markRectangleHeight: 52
        // },
        // {
        //     type: 'slide', // slide, link
        //     selector: '.dashboard3',
        //     content: 'Kérdőívek és értékelések értesítései',
        //     description: 'Ne maradjon le semmiről! A kezdőoldalon könnyen megtalálja azokat az értesítéseket, amelyek a kitöltendő kérdőívekről és kapott értékelésekről szólnak.',
        //     image: images.dashboard2b,
        //     help: null,
        //     mark: 'rect',
        //     markRadius: 11,
        //     markXPercent: 8,
        //     markYPercent: 30,
        //     markRectangleWidth: 92,
        //     markRectangleHeight: 42
        // },
    ]
}
