import React, { useEffect } from 'react';

const Rect = ({ context, canvas, imageElement, imageHeight, markXPercent, markYPercent, markRectangleWidth, markRectangleHeight }) => {
    useEffect(() => {
        if ((context || canvas) && imageElement) {
            const ctx = context || canvas.getContext('2d');
            const centerX = (markXPercent / 100) * imageElement.width;
            const centerY = (markYPercent / 100) * imageElement.height;
            const percent = imageHeight / imageElement.height;
            const centerXX = (markRectangleWidth / 100) * imageElement.width;
            const centerYY = (markRectangleHeight / 100) * imageElement.height;

            // Clear canvas before drawing
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // Draw the image
            ctx.drawImage(imageElement, 0, 0, imageElement.width * percent, imageElement.height * percent);

            // Calculate rectangle position and size
            const rectX = centerX;
            const rectY = centerY;
            const rectWidth = (centerXX - rectX) * percent;
            const rectHeight = (centerYY - rectY) * percent;

            // Draw rectangle
            ctx.fillStyle = 'rgba(255, 0, 0, 0.1)';
            ctx.strokeStyle = 'rgba(255, 0, 0, 0.9)';
            ctx.lineWidth = 2;
            ctx.fillRect(rectX * percent, rectY * percent, rectWidth, rectHeight);
            ctx.strokeRect(rectX * percent, rectY * percent, rectWidth, rectHeight);
        }
    }, [context, canvas, imageElement, imageHeight, markXPercent, markYPercent, markRectangleWidth, markRectangleHeight]);

    return null; // This component does not render anything itself
};

export default Rect;
