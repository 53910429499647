import React from 'react';
import PopUp from '../../../../../commons/PopUp';

const HeaderSimple = ({ title, description, help }) => {
    return (
        <div style={{
            padding: '20px', // hozzáadott padding a jobb megjelenés érdekében
        }}>
            <h2>{title} </h2>
            <p>{description}
                <PopUp
                    help={help}
                />
            </p>
        </div>
    );
};

export default HeaderSimple;