import { images } from "./images"

export const sampling = {
    type: 'help',
    isAdmin: false,
    icon: 'ThumbsUpDown',
    content: 'Kérdőív kitöltése',
    description: 'A kitöltés menüpont az alkalmazásban lehetővé teszi a felhasználók számára, hogy könnyen részt vegyenek az értékelési folyamatban, amely számos lépést tartalmaz a felhasználók érzelmi állapotától a visszajelzésig.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.sampling1',
            content: 'Kérdőív kiválasztása',
            description: 'A kitölthető kérdőívek listája a kitöltés menüpontban található. A listából válaszd ki a kérdőívet!',
            image: images.sampling1,
            help: null,
            mark: 'rect',
            markRadius: 12,
            markXPercent: 43,
            markYPercent: 91,
            markRectangleWidth: 56,
            markRectangleHeight: 98
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Érzelmi állapot értékelése',
            description: 'A folyamat első lépéseként a felhasználóknak értékelniük kell az érzelmi állapotukat, ami a kitöltés kiindulópontja.',
            image: images.sampling2,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Visszajelzés',
            description: 'Az értékelés után a felhasználók azonnal kapnak egy visszajelzést érzelmi állapotukról, ami segít a tudatosabb érzések és gondolatok kialakításában.',
            image: images.sampling3,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelendő személyek kiválasztása',
            description: 'A felhasználóknak lehetőségük van kiválasztani az értékelni kívánt személyeket.',
            image: images.sampling4,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelés elkezdése',
            description: 'A kitöltés elején megjelenik az első értékelendő személy neve és profilképe.',
            image: images.sampling5,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelés',
            description: 'Majd a felhasználók kitölthetik az értékelést.',
            image: images.sampling6,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelt változása',
            description: 'A folyamat során a következő értékelendő személyek is megjelennek.',
            image: images.sampling7,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelés folytatása',
            description: 'Majd a felhasználók kitölthetik az új értékelt személy értékelését.',
            image: images.sampling8,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelés nyomonkövetése',
            description: 'Az értékelés folyamatát alul láthatjuk, így könnyen nyomon követhetjük, hogy hány értékelést adtunk már le.',
            image: images.sampling9,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Összefoglaló és mentés',
            description: 'A végén egy összefoglaló oldalon láthatjuk az összes kitöltött értékelést. Az "Indítás" gombbal sikeresen menthetjük az értékeléseinket.',
            image: images.sampling10,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Sikeres entés',
            description: 'A sikeres mentés után megjelenik egy üzenet, amely megerősíti, hogy az értékelés sikeresen mentésre került.',
            image: images.sampling11,
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értesítés az értékelt személyeknek',
            description: 'Az értékelt személyek értesítést kapnak arról, hogy új értékelés érkezett. Az értékelés megjelenik a listájukban.',
            image: images.sampling14,
            help: null,
            mark: 'rect',
            markRadius: 12,
            markXPercent: 52,
            markYPercent: 91,
            markRectangleWidth: 70,
            markRectangleHeight: 98
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értesítés a kezdő oldalon',
            description: 'Az értesítés a kezdőoldalon is megjelenik',
            image: images.sampling13,
            help: null,
            mark: 'rect',
            markRadius: 12,
            markXPercent: 9,
            markYPercent: 16,
            markRectangleWidth: 90,
            markRectangleHeight: 26
        },
        {
            type: 'slide', // slide, link
            selector: '.sampling2',
            content: 'Értékelés megtekintése',
            description: 'Az értékelt személyek ANONÍM MÓDON megtekinthetik az értékelést, biztosítva ezzel a személyes adatok védelmét és a bizalmi légkört az értékelési folyamatban.',
            image: images.sampling15,
        },
    ]
}
