import React, { useEffect, useState } from 'react';
import { Button, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import CIconButton from '../../../components/buttons/CIconButton';
import { Alarm, AlarmOff, Close, PlayArrow } from '@mui/icons-material';
import StartSchedule from '../../../services/firebase/StartSchedule';
import { toast } from 'react-toastify';

const days = {
    'monday': 'Hétfő',
    'tuesday': 'Kedd',
    'wednesday': 'Szerda',
    'thursday': 'Csütörtök',
    'friday': 'Péntek',
    'saturday': 'Szombat',
    'sunday': 'Vasárnap',
};

const getData = async (id) => {
    console.log('id', id);
    const { getData } = await StartSchedule();
    try {
        const data = await getData(id);
        console.log('StartSchedule', data);
        if (data.statusCode == 200) {
            toast.success(data?.messages);
        } else {
            toast.error(data?.messages);
        }
    } catch (error) {
        console.error('Error fetching logs:', error);
    }
};


const SchedulesList = ({ schedules, handleEdit, handleDelete }) => {
    return (
        <List>
            {schedules.map(schedule => (
                <ListItem key={schedule.id}>
                    <ListItemAvatar>
                        <h4
                            style={{
                                // backgroundColor: schedule?.data?.status === 'active' ? 'green' : 'inherit',
                                textAlign: 'center',
                                // padding: '5px', // hozzáadott padding a jobb megjelenés érdekében
                                color: schedule?.data?.status === 'active' ? 'green' : 'red' // fehér szöveg, ha aktív
                            }}
                        >
                            {days[schedule?.data?.day]}<br />
                            {schedule?.data?.time}<br />

                        </h4>
                    </ListItemAvatar>
                    <ListItemText
                        style={{
                            paddingLeft: '5px', // hozzáadott padding a jobb megjelenés érdekében
                        }}
                        onClick={() => handleEdit(schedule)}
                        primary={`${schedule?.data?.status === 'active' ? `[${schedule?.data?.index + 1}/${schedule?.data?.all}] ${new Date(schedule?.data?.date?.seconds * 1000).toLocaleString()}` : 'Nem aktív'} `}
                        secondary={schedule?.data?.qtemplate?.name}
                    />
                    <CIconButton
                        icon={<PlayArrow />}
                        confirm={true}
                        onClick={() => getData(schedule.id)}
                    />
                    <CIconButton
                        icon={<Close />}
                        confirm={true}
                        onClick={() => handleDelete(schedule.id)}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default SchedulesList;