import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Radio, List, ListItem, ListItemText, ListItemIcon, TextField } from '@mui/material';

const SeriesOrderSelectDialog = ({ field, options, selected, setSelected }) => {
    const [open, setOpen] = useState(false);
    const [tempSelected, setTempSelected] = useState(selected);

    const handleToggle = (index) => {
        setTempSelected(index);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTempSelected(selected); // Reset tempSelected to the original selected
    };

    const handleOk = () => {
        setSelected(tempSelected);
        setOpen(false);
    };

    return (
        <>
            <TextField
                label={field.label}
                value={options[selected]}
                onClick={handleClickOpen}
                InputProps={{
                    readOnly: true,
                }}
                fullWidth
                variant="standard"
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{field.label}</DialogTitle>
                <DialogContent>
                    <List>
                        {options.map((option, index) => (
                            <ListItem key={option} button onClick={() => handleToggle(index)}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={tempSelected === index}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Mégsem
                    </Button>
                    <Button
                        onClick={handleOk}
                        color="primary"
                        disabled={tempSelected === -1}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SeriesOrderSelectDialog;
