import { stepsGroupStats } from "./stepsGroupStats"
import { images } from "./images"
import { stepsSchedules } from "./stepsSchedules"
import { stepsGroupSetup } from "./stepsGroupSetup"

export const documentation = {
    type: 'help',
    isAdmin: false,
    icon: 'TextSnippet',
    content: 'PR-VET',
    description: `
    Üdvözöljük a dokumentációban, amely részletes útmutatást nyújt a 
    PR-VET rendszer használatához. Az alábbiakban röviden ismertetjük 
    a kulcsfontosságú funkciókat.`,
    steps: [
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Az alkalmazás használata',
            description: ``,
            image: images.stat1,
            help: null,
        },
        {
            type: 'link', // slide, link
            selector: '.authentication',
            content: 'Bejelentkezés, regisztráció, elfelejtett jelszó',
            short: 'Bejelentkezés, regisztráció',
            description: '',
            link: 'authentication',
            isAdmin: false,
            icon: 'Phonelink',
        },
        {
            type: 'link', // slide, link
            selector: '.install',
            content: 'Applikáció telepítése',
            short: 'Applikáció telepítése',
            description: '',
            link: 'install',
            isAdmin: false,
            icon: 'InstallMobile',
        },
        {
            type: 'link', // slide, link
            selector: '.dashboard',
            content: 'Kezdőoldal felépítés',
            short: 'Kezdőoldal felépítése',
            description: '',
            link: 'dashboard',
            isAdmin: false,
            icon: 'House',
        },
        {
            // type: 'link', // slide, link
            selector: '.avatar',
            content: 'Profilkép módosítása',
            short: 'Profilkép módosítása',
            description: '',
            link: 'avatar',
            icon: 'AccountCircle',
            type: 'help',
            isAdmin: false,
            // icon: 'Person',
        },
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Csoportok kezelése',
            description: ``,
            image: images.stat1,
            help: null,
        },
        {
            type: 'link', // slide, link
            selector: '.group',
            content: 'Csoportok kezelése',
            short: 'Csoportok kezelése',
            description: '',
            link: 'group',
            isAdmin: false,
            icon: 'Groups',
        },
        ...stepsGroupSetup,
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Csoporttagság',
            description: ``,
            image: images.stat1,
            help: null,
        },
        {
            type: 'link', // slide, link
            selector: '.subscribe',
            content: 'Jogosultsági szintek',
            short: 'Jogosultsági szintek',
            description: '',
            link: 'roles',
            isAdmin: false,
            icon: 'GroupAdd',
        },
        {
            type: 'link', // slide, link
            selector: '.subscribe',
            content: 'Jelentkezés csoportba',
            short: 'Jelentkezés csoportba',
            description: '',
            link: 'subscribe',
            isAdmin: false,
            icon: 'GroupAdd',
        },
        {
            type: 'link', // slide, link
            selector: '.subscribe',
            content: 'Csoport hallgatóinak kezelése',
            short: 'Csoport hallgatóinak kezelése',
            description: '',
            link: 'members',
            isAdmin: false,
            icon: 'GroupAdd',
        },
        {
            type: 'link', // slide, link
            selector: '.subscribe',
            content: 'Csoport oktatóinak és ügyintézőinek kezelése',
            short: 'Csoport oktatóinak és ügyintézőinek kezelése',
            description: '',
            link: 'admins',
            isAdmin: true,
            icon: 'GroupAdd',
        },
        {
            type: 'link', // slide, link
            selector: '.updateGroup',
            content: 'Felhasználói adatok szerkesztése',
            short: 'Felhasználói adatok szerkesztése',
            description: '',
            link: 'updateUser',
            isAdmin: true,
            icon: 'Groups',
        },
        ...stepsGroupStats,
        {
            type: 'title', // slide, link
            selector: '.stat1',
            content: 'Kérdőívek',
            description: ``,
            image: images.stat1,
            help: null,
        },
        ...stepsSchedules,
        {
            type: 'link', // slide, link
            selector: '.start',
            content: 'Kérdőívek kezelése ',
            short: 'Kérdőívek kezelése',
            description: '',
            link: 'start',
            isAdmin: true,
            icon: 'Quiz',
        },
        {
            type: 'link', // slide, link
            selector: '.sampling',
            content: 'Kérdőív kitöltése',
            short: 'Kérdőív kitöltése',
            description: '',
            link: 'sampling',
            isAdmin: false,
            icon: 'ThumbsUpDown',
        },
        {
            type: 'link', // slide, link
            selector: '.evaluations',
            content: 'Értékelések',
            short: 'Értékelések',
            description: 'Bejelentkezés, regisztráció, elfelejtett jelszó',
            link: 'evaluations',
            isAdmin: false,
            icon: 'BarChart',
        },
        {
            type: 'link', // slide, link
            selector: '.version01',
            content: 'Újdonságok 2023.10.10',
            short: 'Újdonságok',
            description: '',
            link: 'version01',
            isAdmin: false,
            icon: 'PendingActions',
        },
    ]
}

