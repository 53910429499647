import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';

const AddAdminAction = () => {
    const { createDocument } = useContext(AppContext);
    const subscribe = async ({ userId, groupId }) => {
        const newRecord = {
            groupId: groupId,
            userId: userId
        }
        const result = await createDocument({ table: 'groupadmins', record: newRecord })
        return result;
    }

    return {
        subscribe
    }
};

export default AddAdminAction;