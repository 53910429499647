import React, { useContext, useEffect, useState } from 'react';
import View from './View';
import { auth } from '../../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AppContext } from '../../services/appContext';

const ManageQuestionnaires = () => {
    const { queries, snapshots, isLoading, loadings, setLoading } = useContext(AppContext);
    const [user, loading, error] = useAuthState(auth);
    const [questionnaires, setQuestionnaires] = useState(null);
    const [profile, setProfile] = useState(null);
    const [groupList, setGroupList] = useState(null);
    const [myGroups, setMyGroups] = useState([]);

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    useEffect(() => {
        // ADMINS
        if (profile && profile.admins) {
            setGroupList(profile.admins)
        }
    }, [profile]);

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
    }, [snapshots['profile']]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
        }
    }, [snapshots['allgroups']]);

    useEffect(() => {
        setQuestionnaires(snapshots['questionnaires']);
    }, [snapshots['questionnaires']]);

    return (
        <View
            profile={profile}
            questionnaires={questionnaires}
            groups={myGroups}
            selectItem={'questionnaire'}
        />
    );
};

export default ManageQuestionnaires;