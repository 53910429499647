import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const UpdateFilledQuestionnaires = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, questionnaire, template, values, persons, group, type, admin, emotional }) => {
        const newRecord = {
            //questionnaire: questionnaire,
            values: values,
            persons: persons,
            emotional: emotional,
            status: 'finish',
            template: template
            // group: group,
            // type: type,
            // admin: admin,
        }
        const result = await updateDocument({
            id: id,
            table: 'filledquestionnaires',
            record: newRecord,
            fields: ['values', 'persons', 'emotional', 'status', 'template'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateFilledQuestionnaires;