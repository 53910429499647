export const convertObjectToArray = (model) => {
    let converted = [];
    model && Object.keys(model).forEach(record => {
        converted.push(model[record]);
    })

    return converted;
}
export const convertObjectById = (model, field = 'id') => {
    let converted = {};
    model && Object.keys(model).forEach(record => {
        converted[model[record][field]] = model[record];
    })

    return converted;
}
export const convertArrayById = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        converted[record[field]] = record;
    })

    return converted;
}
export const convertArrayById2 = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        //  converted[record[field]] = { ...record.data(), id: record[field] };
        converted.push({ ...record.data(), id: record[field] });
    })

    return converted;
}

export const convertArrayById3 = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        converted[record[field]] = record.data;
    })

    return converted;
}

export const convertArrayById4 = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        if (!converted[record[field]]) {
            converted[record[field]] = [];
        }
        converted[record[field]].push(record);
    })
    return converted;
}

export const convertArrayById5 = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        converted[record?.data[field]] = record.data;
    })

    return converted;
}

export const convertObjectById4 = (model, fields) => {
    let converted = [];
    model && Object.keys(model).forEach(record => {
        // console.log('record', record);
        // console.log('record', model[record]);
        const a = getPropArray(model[record], fields);
        // console.log('bbbbbbbbbbbbb', a);
        converted[a] = model[record];
    })

    return converted;
}

export const getProp = (model, field) => {
    const prop = model && field ? model[field] : null;
    return prop;
}
export const getPropArray = (model, fields) => {
    let data = model;
    if (typeof model == 'object' && Array.isArray(fields)) {
        fields.forEach(element => {
            if (typeof element == 'string') {
                data = getProp(data, element)
            }
            else {
                if (Array.isArray(element)) {
                    data = convertArrayById(data, element[0])
                }
            }
        });
    }
    else data = null;
    return data;
}

export const getPropArrayParams = (model, params, fields) => {
    let data = model;
    if (typeof model == 'object' && Array.isArray(fields)) {
        fields.forEach(e => {
            if (typeof e == 'string') {
                var element = params[e];
                data = getProp(data, element)
            }
            else {
                if (Array.isArray(e)) {
                    var element = params[e[0]];
                    data = convertArrayById(data, element)
                }
            }
        });
    }
    else data = null;
    return data;
}

export const getPropArray2 = (model, fields) => {
    let data = model;
    if (typeof model == 'object' && Array.isArray(fields)) {
        fields.forEach(element => {
            if (typeof data[element] != 'function') {
                data = getProp(data, element)
            }
            else {
                data = data[element]()
            }
        });
    }
    else data = null;
    return data;
}

export const snapshotSetState = ({ snapshot, callback }) => {
    let listings = [];
    if (snapshot) {
        snapshot.forEach((doc) => {
            return listings.push({
                id: doc.id,
                data: doc.data,
            });
        });
    }
    callback(listings);
}

export const getKeysFromArray = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        converted.push(record[field]);
    })

    return converted;
}

export const getKeysFromArray2 = (model, field = 'id') => {
    let converted = [];
    model && Object.keys(model).forEach(record => {
        converted.push(model[record].data[field]);
    })

    return converted;
}

export const getStringDate = (x) => {
    if (typeof x === 'string')
        return x
    else
        if (typeof x === 'object') {
            const y = new Date(x.seconds * 1000);
            return y.toISOString().split('T')[0]
        }


        else return ''
}

export const formatDate = (date) => {
    if (!date) return "";

    const dateObj = date instanceof Date ? date : new Date(date?.seconds * 1000);

    if (isNaN(dateObj.getTime())) {
        return "";
    }

    const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
    };

    const formattedDate = dateObj.toLocaleDateString(undefined, options);
    return formattedDate;
};



export const convertToNum = (x) => {
    if (isNaN(parseInt(x)))
        return x
    else
        return parseInt(x)
}
export const getItemId = () => `item_${+new Date()}`;

export const removeDuplicates = (inputArray) => {
    // Egyedi értékek kiválogatása a tömbből a Set objektum segítségével
    let uniqueSet = new Set(inputArray);

    // Az egyedi értékeket tartalmazó Set objektum visszaalakítása tömbbé
    let uniqueArray = Array.from(uniqueSet);

    return uniqueArray;
}