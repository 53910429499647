import {
    AlternateEmail,
    ArrowCircleRightRounded,
    ArrowDownward,
    Article,
    AddCircleOutline,
    AccountCircle,
    BarChart,
    CalendarToday,
    Check,
    Close,
    CropFree,
    CropRotate,
    DateRange,
    Done,
    Download,
    Email,
    EmojiEvents,
    Error,
    Groups,
    GroupAdd,
    Help,
    HelpOutline,
    House,
    InstallMobile,
    Label,
    MoreHoriz,
    Phonelink,
    PhonelinkLock,
    PendingActions,
    PersonAdd,
    Refresh,
    TextSnippet,
    ThumbsUpDown,
    Today,
    TrendingUp,
    TrendingDown,
    SentimentDissatisfied,
    SentimentVeryDissatisfied,
    SentimentSatisfied,
    SentimentSatisfiedAlt,
    SentimentVerySatisfied,
    Stairs,
    Quiz,
    QrCode,
    CameraAlt,
    CameraFront,
    ArrowRight,
    ArrowLeft,
    Camera,
    ShowChart,
    Radar,
    Tune,
    FilterAlt,
    AlignHorizontalLeft,
    StackedBarChart,
    Toc
} from '@mui/icons-material';

const Icons = [];
Icons['AddCircleOutline'] = AddCircleOutline;
Icons['AccountCircle'] = AccountCircle;
Icons['AlternateEmail'] = AlternateEmail;
Icons['ArrowCircleRightRounded'] = ArrowCircleRightRounded;
Icons['ArrowDownward'] = ArrowDownward;
Icons['Article'] = Article;
Icons['ArrowRight'] = ArrowRight;
Icons['ArrowLeft'] = ArrowLeft;
Icons['BarChart'] = BarChart;
Icons['Camera'] = Camera;
Icons['CameraAlt'] = CameraAlt;
Icons['CameraFront'] = CameraFront;
Icons['CropRotate'] = CropRotate;
Icons['CalendarToday'] = CalendarToday;
Icons['Check'] = Check;
Icons['Close'] = Close;
Icons['CropFree'] = CropFree;
Icons['DateRange'] = DateRange;
Icons['Done'] = Done;
Icons['Download'] = Download;
Icons['Email'] = Email;
Icons['EmojiEvents'] = EmojiEvents;
Icons['Error'] = Error;
Icons['FilterAlt'] = FilterAlt;
Icons['Groups'] = Groups;
Icons['GroupAdd'] = GroupAdd;
Icons['Help'] = Help;
Icons['House'] = House;
Icons['HelpOutline'] = HelpOutline;
Icons['InstallMobile'] = InstallMobile;
Icons['MoreHoriz'] = MoreHoriz;
Icons['AlignHorizontalLeft'] = AlignHorizontalLeft;

Icons['Label'] = Label;
Icons['PhonelinkLock'] = PhonelinkLock;
Icons['Phonelink'] = Phonelink;
Icons['PendingActions'] = PendingActions;
Icons['PersonAdd'] = PersonAdd;
Icons['Radar'] = Radar;
Icons['Refresh'] = Refresh;
Icons['TextSnippet'] = TextSnippet;
Icons['ThumbsUpDown'] = ThumbsUpDown;
Icons['Today'] = Today;
Icons['TrendingUp'] = TrendingUp;
Icons['TrendingDown'] = TrendingDown;
Icons['Tune'] = Tune;
Icons['Toc'] = Toc;
Icons['ShowChart'] = ShowChart;
Icons['SentimentDissatisfied'] = SentimentDissatisfied;
Icons['SentimentVeryDissatisfied'] = SentimentVeryDissatisfied;
Icons['SentimentSatisfied'] = SentimentSatisfied;
Icons['SentimentSatisfiedAlt'] = SentimentSatisfiedAlt;
Icons['SentimentVerySatisfied'] = SentimentVerySatisfied;
Icons['StackedBarChart'] = StackedBarChart;

Icons['Stairs'] = Stairs;
Icons['Quiz'] = Quiz;
Icons['QrCode'] = QrCode;

export default Icons;
