import { images } from "./images"

export const updateGroup = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Csoport adatainak módosítása',
    description: 'A csoport adatainak szerkesztése kizárólag a csoport ügyintézői jogosultságokkal rendelkező felhasználók számára érhető el. A szerkesztés során módosítható a csoport neve, állapota (aktív/inaktív) és oktatója. Az alábbi lépések segítségével elvégezheti a szükséges módosításokat a csoport oldalán található Beállítások menüpontban.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.mygroupsUsersList',
            content: 'Navigálás a beállításokhoz',
            description: 'Lépjen a Csoport oldalára. Válassza ki a jobb alsó sarokban lévő Beállítások ikont.',
            image: images.mygroupsUsersList,
            help: null,
            mark: 'circle',
            markRadius: 20,
            markXPercent: 83,
            markYPercent: 85,
            markRectangleWidth: 71,
            markRectangleHeight: 34,
            animations: [
                { name: 'cursor', action: 'move' },
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsEdit',
            content: 'Beállítások',
            description: 'Kattintson a Beállítások fülre.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 34,
            markRectangleWidth: 36,
            markRectangleHeight: 40,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Csoport nevének szerkesztése',
            description: 'Kattintson a Csoport neve mezőre. Írja be az új nevet a mezőbe.Győződjön meg róla, hogy a név legalább 6 karakter hosszú. ',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 46,
            markRectangleWidth: 89,
            markRectangleHeight: 52,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Csoport állapotának szerkesztése',
            description: 'Kapcsolja be vagy ki az Aktív kapcsolót a csoport állapotának módosításához.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 53,
            markRectangleWidth: 36,
            markRectangleHeight: 58,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Oktató kiválasztása',
            description: 'Kattintson az Oktató legördülő menüre. Válassza ki a megfelelő oktatót a csoport oktatóinak és az ügyintézőinek listájából.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 59,
            markRectangleWidth: 89,
            markRectangleHeight: 73,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.forgotpassword',
            content: 'Mentés',
            description: 'Kattintson a Mentés gombra a módosítások mentéséhez.',
            image: images.mygroupsEdit,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 11,
            markYPercent: 73,
            markRectangleWidth: 89,
            markRectangleHeight: 77,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        }
    ]
}
