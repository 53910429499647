import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ScrollToBottomIcon = ({ name, icon, onClick }) => {
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowIcon(true);
      } else {
        setShowIcon(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    if (onClick) {
      onClick();
    }
  };

  return (
    
      <Fab
        sx={{
          position: 'fixed',
          bottom: '5.0rem',
          right: '1rem',
        }}
        color="primary"
        onClick={scrollToBottom}
      >
        {icon || <KeyboardArrowDownIcon />}
      </Fab>
    
  );
};

export default ScrollToBottomIcon;
