import React from 'react';
import { Badge } from '@mui/material';
import coverPhoto from "../../assets/jpg/questionnaire1.jpg";
import GoBackButton from '../../commons/GoBackButton';
import { getStringDate } from '../../commons/Utility';
import QuestionnaireCover from '../../components/questionnaire/QuestionnaireCover';
import EvaluationEmotion from '../../components/evaluations/EvaluationEmotion';
import EvaluationsValues from '../../components/evaluations/EvaluationsValues';
import EvaluationValues from '../../components/evaluations/EvaluationValues';
import ProgressFilling from '../../components/questionnaire/ProgressFilling';
import ProgressEmotion from '../../components/emotions/ProgressEmotion';
import Spinner from '../../components/Spinner';
import Header from '../evaluation/components/Header';

const View = ({ loading, id, questionnaire, group, profile,
    user, stat, emotional, fillings, emotionals, values }) => {
    const groupId = group?.id;
    const groupUrl = `https://kerdoiv.b612.hu/subscribe/${groupId}`;
    let scopeNumber = 100;
    switch (questionnaire?.scope) {
        case 'admins':
            scopeNumber = group?.data?.admins.length
            break;
        case 'members':
            scopeNumber = group?.data?.Members?.length
            break;
        case 'all':
            scopeNumber = group?.data?.admins?.length + group?.data?.Members?.length
            break;
        default:
            break;
    }
    let evaluateNumber = 100;
    switch (questionnaire?.evaluate) {
        case 'admins':
            evaluateNumber = group?.data?.admins.length
            break;
        case 'members':
            evaluateNumber = group?.data?.Members?.length
            break;
        case '360':
            evaluateNumber = group?.data?.admins?.length + group?.data?.Members?.length
            break;
        case 'self':
            evaluateNumber = 1
            break;
        case 'project':
            evaluateNumber = 1
            break;
        default:
            break;
    }
    return (<>
        {!loading
            ? (
                <div className="profile">
                    {/* <GoBackButton /> */}
                    <QuestionnaireCover
                        questionnaire={questionnaire}
                        coverPhoto={coverPhoto}
                        group={group?.data}
                        groupUrl={groupUrl}
                        profile={profile}
                        name={questionnaire.qtemplate.description}
                        mini={true}
                    />
                    <div className="listingDetails">
                        <Header
                            questionnaire={questionnaire}
                            template={questionnaire?.qtemplate}
                            group={group}
                        />

                        <h3>Statisztika</h3>

                        {stat?.num && fillings > 0 && scopeNumber && evaluateNumber ?
                            <div className="exploreCategories">
                                <ProgressFilling title={'Kitöltések'} value={fillings} maxValue={scopeNumber} />
                                <ProgressFilling title={'Értékelések'} value={stat?.num} maxValue={scopeNumber * evaluateNumber} />
                            </div>
                            : 'Még nem érkezett értékelés'
                        }
                        {fillings > 0 && emotional && stat?.emotional &&
                            <div className="exploreCategories">
                                <div className={'centerContent2'} style={{ width: '40%' }}>
                                    <ProgressEmotion title={'Érzelmi állapot'} big={true} value={Math.round(stat?.emotional)} />
                                </div>
                            </div>
                        }
                        <div className="centerContent2">
                            {fillings > 0 && emotionals && Object.keys(emotionals).map((id, index) => {
                                return (
                                    <Badge key={index} color="primary" badgeContent={emotionals[id]}>
                                        <EvaluationEmotion key={`EvaluationEmotion${index}`} value={id} />
                                    </Badge>
                                )
                            })}

                        </div>
                        Értékelések száma: {stat?.num}

                        {stat && questionnaire && questionnaire?.qtemplate && questionnaire?.qtemplate?.meta?.scales &&
                            <EvaluationValues
                                questions={questionnaire?.qtemplate?.meta?.questions}
                                scales={questionnaire?.qtemplate?.meta?.scales}
                                values={stat.values}
                            />
                        }
                        {questionnaire && questionnaire?.qtemplate && values && questionnaire?.qtemplate?.meta?.scales &&
                            <EvaluationsValues
                                questions={questionnaire?.qtemplate?.meta?.questions}
                                scales={questionnaire?.qtemplate?.meta?.scales}
                                values={values}
                            />
                        }
                        {/* <Link
                            to={`/contact/}?listingName=sdfd`}
                            className="primaryButton"
                        >
                            Kérdőív törlése
                        </Link> */}

                    </div>
                </div>
            ) : <Spinner />
        }
    </>
    );
};

export default View;