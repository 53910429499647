import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ElementsEditor = ({ elements, setElements }) => {
  const [open, setOpen] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);
  const [editDate, setEditDate] = useState('');

  const handleEditOpen = (element) => {
    setCurrentElement(element);
    setEditDate(convertToLocalTime(element));
    setOpen(true);
  };

  const handleEditClose = () => {
    setOpen(false);
    setCurrentElement(null);
    setEditDate('');
  };

  const handleDelete = (element) => {
    setElements(elements.filter(el => el !== element));
  };

  const handleSave = () => {
    const updatedElement = new Date(editDate);
    setElements(elements.map(el => el === currentElement ? updatedElement : el));
    handleEditClose();
  };

  const convertToLocalTime = (date) => {
    const local = new Date(date);
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toISOString().slice(0, 16);
  };

  return (
    <div>
      <List>
        {elements.map((element, index) => (
          <ListItem key={index}>
            <ListItemText primary={new Date(element).toLocaleString()} />
            <IconButton onClick={() => handleEditOpen(element)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(element)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleEditClose}>
        <DialogTitle>Szerkesztés</DialogTitle>
        <DialogContent>
          <TextField
            label="Dátum és idő"
            type="datetime-local"
            fullWidth
            value={editDate}
            onChange={(e) => setEditDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Mégse
          </Button>
          <Button onClick={handleSave} color="primary">
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ElementsEditor;
