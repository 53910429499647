import React from 'react';
import CListSelect from '../../../../../commons/CListSelect';
import QuestionsUtitity from '../../../QuestionsUtitity';

// const findProjects = (arrayOfArrays) => {
//     if (!Array.isArray(arrayOfArrays) || arrayOfArrays.length === 0) {
//         return [];
//     }
//     const alapTomb = arrayOfArrays[0];
//     const kozosStringek = alapTomb.filter((elem) => {
//         return arrayOfArrays.every((tomb) => tomb.includes(elem));
//     });

//     return kozosStringek;
// }

// const filterQtemplatesByProjects = (projects, qtemplates) => {
//     if (!Array.isArray(projects) || !Array.isArray(qtemplates)) {
//         // Ellenőrizzük, hogy mindkét bemeneti érték tömb-e
//         return [];
//     }

//     // Szűrjük az 'qtemplates' tömböt azokra az objektumokra, amelyeknél a 'data.projects' tömb tartalmazza az egyik 'projects' elemet
//     const filteredQtemplates = qtemplates.filter((qtemplate) => {
//         return qtemplate.data.projects.some((project) => projects.includes(project));
//     });

//     return filteredQtemplates;
// }

const FSPFieldSelectTemplate = ({
    id,
    // options,
    valuesKey,
    valuesLabel,
    handleChange,
    value,
    meta,
    newRecord
}) => {
    const { findProjects, filterQtemplatesByProjects } = QuestionsUtitity()
    const groups = newRecord?.group;
    // console.log('groups', groups);
    const metaGroups = meta?.groups;
    // console.log('metaGroups', metaGroups);
    let filteredGroups = [];

    Object.keys(metaGroups).forEach((option) => {
        if (groups.indexOf(option) > -1)
            filteredGroups.push(metaGroups[option].projects)
    });

    const projects = findProjects(filteredGroups);
    // console.log('projects', projects);
    const filteredTemplates = filterQtemplatesByProjects(projects, meta?.qtemplates);
    // console.log('filteredTemplates', filteredTemplates);
    // console.log('meta', meta);
    const options1 = Object.keys(filteredTemplates).map((option) =>
    ({
        value: filteredTemplates[option][valuesKey],
        label: filteredTemplates[option].data ? filteredTemplates[option]['data'][valuesLabel] : filteredTemplates[option][valuesLabel]
    }));

    return (
        <CListSelect
            field={id}
            handleChange={handleChange}
            value={value}
            options={options1}
            valuesKey={valuesKey}
            valuesLabel={valuesLabel}
        />
    );
};

export default FSPFieldSelectTemplate;