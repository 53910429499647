import React from 'react';
import { Avatar, BottomNavigationAction, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
// import * as Icons from '@mui/icons-material';
import { blueGrey, grey } from '@mui/material/colors';
import Icons from '../MIIcons';

const DashboardButton = ({ label, onClick, icon, bgcolor, color = grey, isAvatar = false }) => {
    // console.log(icon);
    const IconComponent = Icons[icon];
    return (
        <Grid className="profileButton" item xs={3}>
            {isAvatar ?
                <BottomNavigationAction
                    showLabel
                    onClick={onClick}
                    label={label}
                    icon={
                        <Avatar sx={{ bgcolor: bgcolor }} variant="square">
                            <IconComponent
                                // color={"primary"} 
                                // fontSize={22}
                                sx={{
                                    // fontSize: 32,
                                    // color: '#00cc66'
                                    // color: { color },

                                }}
                            />
                        </Avatar>
                    } />
                :
                <BottomNavigationAction
                    showLabel
                    onClick={onClick}
                    label={label}
                    icon={
                        <IconComponent
                            // color={"primary"} 
                            // fontSize={22}
                            sx={{
                                // fontSize: 32,
                                color: color
                                // color: { color },

                            }}
                        />
                    } />
            }
        </Grid>
    );
};

export default DashboardButton;