import React, { useState, useEffect } from 'react';
import { Dialog, Typography } from '@mui/material';
import Spinner from '../Spinner';
import { Add, Close } from '@mui/icons-material';
import DashboardButton from '../../commons/buttons/DashboardButton';
import FindForm from './components/FindForm';
import CreateAdmin from './components/CreateAdmin';
import { IconButton } from '@mui/material';

const AddUserToGroupAdmins = ({ groupId, group }) => {

  const [user, setUser] = useState();
  // const [error, setError] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUser();
  }, []);

  const handleOpenDialog = () => {
    setUser();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {/* Gomb, amelyre kattintva megjelenik a form */}
      {/* <DashboardButton
        label={'Felhasználó hozzáadása a csoport Oktatóihoz'}
        icon={'PersonAdd'}
        onClick={() => (handleOpenDialog())}
        isAvatar={true}
        bgcolor={'#1ABC9C'}
      /> */}
      <IconButton
        onClick={() => (handleOpenDialog())}
        style={{
          backgroundColor: '#3498db',
          color: '#ffffff'
        }}>
        <Add sx={{ fontSize: '0.7em' }} />
      </IconButton>
      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}
        style={{ backgroundColor: '#eee' }}
        PaperProps={{
          style: { backgroundColor: '#eee' },
        }}
      >
        <div onClick={() => handleCloseDialog()} className="helpButton2">
          <Close />
        </div>
        <div className='centerContent2'>
          {loading ? (
            <Spinner />
          ) : user ? (
            <Typography variant="body1">
              <CreateAdmin
                user={user}
                group={group}
                groupId={groupId}
                cancel={handleCloseDialog}
              />
            </Typography>
          ) : (<>
            <FindForm
              openDialog={openDialog}
              setUser={setUser} />
          </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AddUserToGroupAdmins;
