import React, { useEffect, useRef, useState } from 'react';
import FindUser from '../../../services/firebase/FindUser';
import { toast } from "react-toastify";
import Spinner from '../../Spinner';
import { GroupAdd } from '@mui/icons-material';
import Tada from 'react-reveal/Tada';

const FindForm = ({ title = 'Felhasználó hozzáadása a csoport Oktatóihoz', setUser, openDialog }) => {
    const emailInputRef = useRef(null);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const getData = async (email) => {
        setLoading(true);
        const { getData } = await FindUser();
        try {
            const logs = await getData(email);
            console.log(logs);
            if (logs?.error) {
                toast.error(logs?.error);
                setUser();
            } else {
                if (logs?.user) {
                    setUser(logs?.user);
                }
            }
            return logs;
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
        setLoading(false);
    };

    const findFirebaseUser = async () => {
        // Validáció
        if (!validateEmail(email)) {
            toast.error("Érvénytelen email formátum.");
            return;
        }
        try {
            setLoading(true);
            await getData(email);
        } catch (error) {
            console.error('Hiba történt a felhasználó létrehozása során:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Megnyitáskor az email mező fókuszba állítása
        setEmail('');
        emailInputRef.current?.focus();
    }, [openDialog]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (<>
        {loading &&
            <Spinner />
        }
        <h2>{title}</h2>
        <Tada>
            <GroupAdd sx={{ fontSize: 170 }} color="success" />
        </Tada>
        <div className="pageContainer">
            <div className="loginLayout">
                <div className="profileList">
                    <input
                        label="Email"
                        type="text"
                        value={email}
                        className="emailInput"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        inputRef={emailInputRef}
                    />
                    <button
                        variant="contained"
                        color="primary"
                        className={"primaryButton passwordInputDiv"}
                        onClick={findFirebaseUser}>
                        Keresés
                    </button>
                </div>
            </div>
        </div>
    </>
    );
};

export default FindForm;