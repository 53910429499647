import React from 'react';
import ResultCard from '../../commons/cards/ResultCard';
import { Grid } from '@mui/material';
import { groupingData } from '../charts/CChartConstats';

const ResultCards = ({ values, templateData, stat, meta }) => {
    const scales = meta?.scales;
    const questions = meta?.questions;
    const labels = meta?.labels;
    const allData1 = groupingData(
        templateData,
        "question",
        ["count(evaluated_value)",
            "average_evaluated_value",
        ]
    );
    const data = {
        JTHKnhjLjXV2zRlLEi8m: 8.5,
        U0nA8uZ3x4tF6D9CKTWT: 7,
        VIhIV3K8YOBHaErFg8gZ: 5
    };

    console.log('allData1', allData1);
    console.log('values', values);
    console.log('stat', stat);
    console.log('questions', questions);

    let allDataResults = [];
    if (allData1?.mean) {
        Object.keys(allData1?.mean).forEach(element => {
            allDataResults[element] = allData1?.mean[element]['average_evaluated_value'];
        });
    }

    console.log('x', allDataResults);


    const data1 = stat?.values;
    const data2 = allDataResults;

    // const data1 = {
    //     JTHKnhjLjXV2zRlLEi8m: 0,
    //     U0nA8uZ3x4tF6D9CKTWT: 1,
    //     VIhIV3K8YOBHaErFg8gZ: 2
    // };

    // const data2 = {
    //     JTHKnhjLjXV2zRlLEi8m: 3,
    //     U0nA8uZ3x4tF6D9CKTWT: 2,
    //     VIhIV3K8YOBHaErFg8gZ: 1
    // };
    let minData1;
    let maxData1;
    let maxDifference;
    let minDifference;
    if (data1 && data2) {
        minData1 = Object.entries(data1).reduce((min, [key, value]) => value < min.value ? { key, value } : min, { key: null, value: Infinity });

        // Legnagyobb érték data1-ből
        maxData1 = Object.entries(data1).reduce((max, [key, value]) => value > max.value ? { key, value } : max, { key: null, value: -Infinity });

        // Legnagyobb különbség data1 és data2 között
        maxDifference = Object.entries(data1).reduce((maxDiff, [key, value]) => {
            if (data2[key] !== undefined) {
                const difference = data1[key] - data2[key];
                return difference > maxDiff.value ? { key, value: difference } : maxDiff;
            }
            return maxDiff;
        }, { key: null, value: -Infinity });

        // Legkisebb különbség data2 és data1 között
        minDifference = Object.entries(data1).reduce((minDiff, [key, value]) => {
            if (data2[key] !== undefined) {
                const difference = data1[key] - data2[key];
                return difference < minDiff.value ? { key, value: difference } : minDiff;
            }
            return minDiff;
        }, { key: null, value: Infinity });

    }

    // console.log("Legkisebb érték data1-ből:", minData1);
    // console.log("Legnagyobb érték data1-ből:", maxData1);
    // console.log("Legnagyobb különbség data1 és data2 között:", maxDifference);
    // console.log("Legkisebb különbség data1 és data2 között:", minDifference);

    return (
        <Grid container spacing={2}>
            {minData1 && maxData1 && minData1?.key != maxData1?.key &&
                <>
                    <ResultCard
                        category='Legjobb eredmény'
                        icon='EmojiEvents'
                        color='#ecf0f1'
                        bgcolor='#2ecc71'
                        categoryName='Eredmény'
                        message={questions[maxData1.key]?.name}
                        result={parseFloat(maxData1.value).toFixed(1)}
                        explanation={'A kitöltők ebben a kérdésben találták a teljesítményedet a legjobbnak'}
                    />
                    <ResultCard
                        category='Kihívás'
                        icon='Stairs'
                        color='#ecf0f1'
                        bgcolor='#1ABC9C'
                        categoryName='Eredmény'
                        message={questions[minData1.key]?.name}
                        result={parseFloat(minData1.value).toFixed(1)}
                        explanation={'A kitöltők ebben a kérdésben értékelték úgy, hogy a teljesítményedet érdemes fejleszteni'}
                    />
                </>
            }
            {maxDifference && minDifference && maxDifference?.key != minDifference?.key &&
                <> {maxDifference.value > 0 &&
                    <ResultCard
                        category='Pozitív fejlődés'
                        icon='TrendingUp'
                        color='#ecf0f1'
                        bgcolor='#9B59B6'
                        categoryName='Változás'
                        message={questions[maxDifference.key]?.name}
                        result={parseFloat(maxDifference.value).toFixed(1)}
                        percent={`${parseFloat(((data1[maxDifference.key] / data2[maxDifference.key]) - 1) * 100).toFixed(1)}`}
                        explanation={'A kitöltők ebben a kérdésben magasabb pontszámot adtak, mint korábban'}
                    />
                }
                    {minDifference.value < 0 &&
                        <ResultCard
                            category='Kihívásokkal teli változás'
                            icon='TrendingDown'
                            color='#ecf0f1'
                            bgcolor='#E74C3C'
                            categoryName='Változás'
                            message={questions[minDifference.key]?.name}
                            result={parseFloat(minDifference.value).toFixed(1)}
                            percent={`${parseFloat(((data1[minDifference.key] / data2[minDifference.key]) - 1) * 100).toFixed(1)}`}
                            explanation={'A kitöltők ebben a kérdésben alacsonyabb pontszámot adtak, mint korábban'}
                        />
                    }
                </>
            }
        </Grid>
    );
};

export default ResultCards;