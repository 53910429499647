import React from 'react';
import { EmojiEvents } from '@mui/icons-material';
import Tada from 'react-reveal/Tada';

const Thanks = ({
    navigateAfterSubmit,
    success,
    myGroups,
}) => {
    return (
        <div className='centerContent' onClick={() => { navigateAfterSubmit() }}>
            <h1>Sikeres kitöltés</h1>
            <Tada>
                <EmojiEvents sx={{ fontSize: 170 }} color="success" />
            </Tada>

            {Object.keys(success).map((s, index) =>
                <p>{myGroups[s].name}</p>
            )}
            <p className='infoContent'>Tovább a kezdőoldalra</p>
        </div>
    );
};

export default Thanks;