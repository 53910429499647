import React from "react";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import { Virtual } from 'swiper/modules';
import FSPFields from "./FSPFields/FSPFields";

function FSPQuestion({
    pages,
    actual,
    next,
    meta,
    values,
    setValues,
    setActual,
    valid,
    categories,
    questionnaire,
    group
}) {
    const [checked, setChecked] = React.useState(false);
    const [swiper, setSwiper] = useState();

    useEffect(() => {
        if (swiper) swiper.slideTo(actual)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actual]);


    useEffect(() => {
        if (swiper) swiper.slideTo(actual)
        const page = pages[actual];
        const categoryId = pages[actual].category_id;
        const questionId = pages[actual].question;
        const value = values?.[categoryId]?.[questionId];
        setChecked(value || page.type === 'person');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actual, values]);

    if (pages.length === 0) {
        return <></>;
    }

    const next2 = () => {
        setChecked(true);
        next();
    }

    const page = pages[actual];
    const category = categories[page.category_id].data
    return (pages && (
        <Swiper
            initialSlide={actual}
            modules={[Virtual]}
            virtual
            slidesPerView={1}
            allowSlideNext={checked}
            onSwiper={(swiper) => setSwiper(swiper)}
            onSlideChange={(swiperCore) => {
                const {
                    activeIndex
                } = swiperCore;
                setActual(activeIndex);
            }}
            slides={[{ id: 1, name: 'egy' }, { id: 2, name: 'ketto' }, { id: 3, name: 'sss' }]}
            a11y={true}
            pagination={{ clickable: true }}
            style={{
                width: '100%',
                height: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                padding: 0,
            }}>
            {pages.map((page, index) => (
                <SwiperSlide
                    key={`SwiperSlide${index}`}
                    // onClick={() => navigate(`/category/${data.type}/${id}`)}
                    className="swiperSlideDiv"
                >
                    <FSPFields
                        key={`FSPFields${index}`}
                        index={index}
                        page={page}
                        next2={next2}
                        category={category}
                        valid={valid}
                        pages={pages}
                        meta={meta}
                        values={values}
                        setValues={setValues}
                        actual={actual}
                        setActual={setActual}
                        questionnaire={questionnaire}
                        group={group}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    ));
}

export default FSPQuestion;
