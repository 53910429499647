import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';

const CreateSchedule = () => {
    const { createDocument } = useContext(AppContext);

    const subscribe = async ({ date, status, group, from, to, scope,
        template, qtemplate, evaluate, elements, day, time, all, index }) => {
        const newRecord = {
            date: date,
            status: status,
            group: group,
            from: from,
            to: to,
            scope: scope,
            template: template,
            qtemplate: qtemplate,
            evaluate: evaluate,
            elements: elements,
            day: day,
            time: time,
            all: all,
            index: index
        }
        const result = await createDocument({ table: 'schedules', record: newRecord })
        return result;
    }

    return {
        subscribe
    }
};

export default CreateSchedule;