import React, { useEffect, useRef, useState } from 'react';
import StartPage from './components/StartPage';
import SubmitPage from './components/SubmitPage';
import FieldPage from './components/FieldPage';
import Thanks from './components/Thanks';
import { styles } from './styles'
import QPage from './components/ui/QPage';
import QQuestion from './components/ui/QQuestion';
import QFooter from './components/ui/QFooter';
import QStepper from './components/ui/QStepper';
import QButtons from './components/ui/QButtons';
import QuestionsUtitity from './QuestionsUtitity';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const startPageName = 'Infó';
const submitPageName = 'Összefoglalás';
const StepperForm = ({
    form,
    formQuick,
    quick,
    meta,
    submitCallback,
    navigateAfterSubmit,
    defaultValues,
    success,
    myGroups,
    started
}) => {
    const navigate = useNavigate();
    const carouselRef = useRef()
    const [loading, setLoading] = useState(false);
    const [actual, setActual] = useState(-1);
    const [activeStep, setActiveStep] = useState(startPageName);
    const [values, setValues] = useState(defaultValues ?? 0);
    const [useStepper, setUseStepper] = useState(true);
    const [id, setId] = useState(null);
    const { generateQuestions, generateStepper } = QuestionsUtitity()
    const formAsk = quick ? formQuick : form;
    const fields = generateQuestions({ form: formAsk, meta: meta })
    const fieldsData = generateQuestions({ form: form, meta: meta })
    const steps = generateStepper({ slideItems: fields, startPage: startPageName, submitPage: submitPageName })
    const [previousPath, setPreviousPath] = useState(null);
    const location = useLocation();
    console.log('location', location);
    const previous = () => {
        if (actual > -1)
            setActual(actual - 1);
    }

    const next = () => {
        if (actual < fields.length + 1) {
            setActual(actual + 1);
        }
    }

    const exit = () => {
        navigate(-1); 
    }

    useEffect(() => {
        // Frissítsük az előző útvonalat, amikor az útvonal változik
        setPreviousPath(location.pathname);
        console.log('location.pathname', location.pathname);
    }, [location.pathname]);

    const submited = id
    const filling = actual > -1 && !submited;
    const footerVisible = filling;
    const enabledNext = actual < 0 || (actual >= fields.length && id);

    const submit = async () => {
        const newId = await submitCallback({ values: values });
        setId(newId);
    }
    if (actual < 0 && activeStep !== startPageName) {
        setActiveStep(startPageName);
    }

    if (actual >= fields.length && activeStep !== submitPageName) {
        setActiveStep(submitPageName);
    }
    if (fields && fields[actual] && (actual < fields.length) && !id && activeStep !== fields[actual]['section']) {
        setActiveStep(fields[actual]['section']);
    }

    return (
        <QPage exit={exit}>
            <QQuestion carouselRef={carouselRef}>
                {actual < 0 &&
                    <StartPage
                        form={form}
                        next={next}
                        loading={loading}
                        setLoading={setLoading}
                    />
                }
                {actual > -1 && actual < fields.length &&
                    <FieldPage
                        fields={fields}
                        actual={actual}
                        previous={previous}
                        next={next}
                        values={values}
                        meta={meta}
                        setValues={setValues}
                        loading={loading}
                        setLoading={setLoading}
                    />
                }
                {actual >= fields.length && (
                    id ?
                        <Thanks
                            form={form}
                            id={id}
                            navigateAfterSubmit={navigateAfterSubmit}
                            success={success}
                            myGroups={myGroups}
                        />
                        :
                        <SubmitPage
                            form={form}
                            fields={fieldsData}
                            meta={meta}
                            values={values}
                            previous={previous}
                            submitCallback={submit}
                            loading={loading}
                            setLoading={setLoading}
                            success={success}
                            myGroups={myGroups}
                            started={started}
                        />
                )}
            </QQuestion>
            <QFooter show={footerVisible} styles={styles}>
                
                <QStepper
                    activeStep={steps.indexOf(activeStep)}
                    lastSlideIsVisible={footerVisible}
                    styles={styles}
                    steps={steps}
                />
                <QButtons
                    lastSlideIsVisible={footerVisible}
                    enabledNext={enabledNext}
                    useStepper={useStepper}
                    previous={previous}
                    next={next}
                    styles={styles}
                />
            </QFooter>
        </QPage>
    );
};

export default StepperForm;