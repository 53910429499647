import React from 'react';
import { convertArrayById } from '../../../../commons/Utility';

const QSelect = ({ meta, value }) => {
    const metaArray = convertArrayById(meta, 'id');
    return (
        <h3>
            {metaArray[value]?.data?.name}
        </h3>
    );
};

export default QSelect;