import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectLabel = ({ label, selectedQuestion, handleQuestionChange, labels }) => {
    return (
        <FormControl>
            <InputLabel
                id="label-label"
            >{label ?? 'Címke'}</InputLabel>
            <Select
                value={selectedQuestion}
                onChange={handleQuestionChange}
                style={{ minWidth: '300px', maxWidth: '90vw' }}
            >
                {Object.keys(labels).map((question, index) => (
                    <MenuItem key={index} value={labels[question]}>
                        {labels[question]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectLabel;