import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SchedulesEditorForm from './SchedulesEditorForm';
import DeleteSchedule from '../../../services/actions/DeleteSchedule';
import SchedulesList from './SchedulesList';
import ScrollToBottomIcon from '../../../components/buttons/RBButton';

const SchedulesEditor = ({ group, groups, templates, schedules }) => {
  const [open, setOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const { deleteAction } = DeleteSchedule();

  // Get current date and adjust for Budapest timezone
  const currentDate = new Date();
  const budapestOffset = new Date().getTimezoneOffset() * 60000 + 7200000; // Budapest is UTC+2
  const budapestDate = new Date(currentDate.getTime() + budapestOffset);

  const initialFormState = {
    id: '',
    date: new Date(budapestDate.getTime() + 3 * 60 * 60 * 1000).toISOString().slice(0, 16),  // +1 óra
    status: 'active',
    group: group.id,
    from: new Date(budapestDate.getTime() + 3 * 60 * 60 * 1000).toISOString().slice(0, 16),
    to: new Date(budapestDate.getTime() + 3 * 60 * 60 * 1000 + 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16),  // +1 nap
    qtemplate: templates?.[0]?.id,
    day: 'monday',
    time: '08:00'
  };

  const [formState, setFormState] = useState(initialFormState);
  const [elements, setElements] = useState([]);

  const handleOpen = () => {
    setEdited(true);
    setFormState(initialFormState);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdited(false);
    setElements([]);
  };

  const handleChange = (e) => {
    const element = e.target.name;
    if (element === 'day' || element === 'time' || element === 'time' || element === 'from' || element === 'to') {
      setEdited(true);
    }
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (schedule) => {
    setFormState({
      id: schedule.id,
      date: schedule?.data?.date.toDate().toISOString().slice(0, 16),
      status: schedule?.data?.status,
      group: schedule?.data?.group,
      from: schedule?.data?.from ? schedule?.data?.from?.toDate().toISOString().slice(0, 16) : '',
      to: schedule?.data?.to ? schedule?.data?.to?.toDate().toISOString().slice(0, 16) : '',
      qtemplate: schedule?.data?.qtemplate.id,
      day: schedule?.data?.day || 'monday',
      time: schedule?.data?.time || '08:00'
    });
    setElements(schedule?.data?.elements?.map(el => new Date(el.toDate())) || []);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    await deleteAction({ id });
  };

  return (
    <div>
      <SchedulesList
        schedules={schedules}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <ScrollToBottomIcon
        name={'Kérdőív készítés'}
        icon={<AddIcon />}
        onClick={() => { handleOpen() }}
      />
      <SchedulesEditorForm
        open={open}
        handleClose={handleClose}
        formState={formState}
        handleChange={handleChange}
        setOpen={setOpen}
        groups={groups}
        templates={templates}
        elements={elements}
        setElements={setElements}
        edited={edited}
        setEdited={setEdited}
      />
    </div>
  );
};

export default SchedulesEditor;
