import React from 'react';
import { Box } from '@mui/material';

const QQuestion = ({ children, carouselRef }) => {
    return (
        <Box
            id='carousel-wrapper'
            ref={carouselRef}
            sx={{
                margin: 2,
                overflow: 'hidden !important',
                // pointerEvents: 'none !important',
                display: 'flex',
                transition: 'all .5s ease-in-out',
                '&::-webkit-scrollbar': {
                    display: {
                        xs: 'none !important',
                        sm: 'none !important',
                        md: 'block',
                    },
                },
            }}>
            {children}
        </Box>
    );
};

export default QQuestion;