import React, { useContext, useEffect, useState } from 'react';
import View from './View';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../firebase.config';
import { AppContext } from '../../services/appContext';
import GetUser from '../../services/documents/GetUser';
import Spinner from '../../components/Spinner';
import AcceptSubscribeAction from '../../services/actions/AcceptSubscribedAction';
import { toast } from 'react-toastify';

const AddMember = () => {
    const { snapshots } = useContext(AppContext);
    const { subscribe } = AcceptSubscribeAction();
    const [myGroups, setMyGroups] = useState([]);
    const [group, setGroup] = useState();
    const [isLoading, setIsLoading] = useState();
    const [profile, setProfile] = useState();
    const params = useParams();
    const { app } = params;
    const { user } = params;
    const userId = auth?.currentUser?.uid;
    const navigate = useNavigate();

    const addUser = async ({ user, groupId }) => {
        if (user && groupId) {
            setIsLoading(true);
            const a = await subscribe({ userId: user, groupId: groupId });
            if (a) {
                console.log('SIKERES  LEKÉRDEZÉS111', a)
                toast.success(`Sikeres felvette a felhasználót a csoportba`);
            }
            else {
                console.log('HIBA111')
                toast.error(`Hiba történt a felvételnél`);
                setIsLoading(false);
            }
            navigate(`/mygroup/${groupId}`)
        }
    }
    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    const profileData = GetUser({
        setter: (model) => { setProfile(getData(model)) },
        user: user
    })

    const getProfile = async () => {
        const a = await profileData.subscribe();
        setIsLoading(true);
        if (a) {
            console.log('success', a)
            setIsLoading(false);
        }
        else {
            console.log('error')
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (user && app)
            getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            const all = snapshots['allgroups'];
            const adminGroups = all.filter((g) => (g.data?.admins?.indexOf(userId) > -1 || g.data?.managers?.indexOf(userId) > -1) && g?.data.Members?.indexOf(user) < 0)

            setMyGroups(adminGroups);
            if (adminGroups?.length === 1) {
                setGroup(adminGroups[0])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    const loading = !myGroups || !profile;
    const isUserError = !isLoading && !profile;
    const members = group?.data?.Members;
    const isMember = members?.indexOf(profile?.id) > -1;
    return (
        <div>{!loading || isUserError ?
            <View
                addUser={addUser}
                profile={profile}
                isLoading={isLoading}
                isUserError={isUserError}
                app={app}
                user={user}
                auth={auth}
                myGroups={myGroups}
                group={group}
                setGroup={setGroup}
                isMember={isMember}
            /> :
            <Spinner />
        }
        </div>
    );
};

export default AddMember;