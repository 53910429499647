import React, { useEffect, useState } from 'react';
import GroupItemMini4 from '../../groups/GroupItemMini4';
import SubscribedUser from '../../subscribed/SubscribedUser';
import { ListItem } from '@mui/material';
import Icons from '../../../commons/MIIcons';
import InfoMessage from '../../../commons/InfoMessage';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Spinner from '../../Spinner';
import AddManagerAction from '../../../services/actions/AddManagerAction';

const CreateManager = ({ cancel, group, groupId, user }) => {
    const { subscribe } = AddManagerAction();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const navigate = useNavigate();
    const IconComponent = Icons['ArrowDownward'];

    useEffect(() => {
        const isGroupAdmin = (group?.managers?.indexOf(user.uid) > -1);
        const isManager = user?.isManager;
        const isSuperAdmin = user?.isSuperAdmin;
        if (isGroupAdmin) {
            setError('A felhasználó már a csoport ügyintézője!');
        }
        if (!isManager && !isSuperAdmin) {
            setError('A felhasználónak nincs ügyintézői jogosultsága!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group, user]);

    const addUser = async () => {
        if (user && groupId) {
            setLoading(true);
            console.log('userId', user.uid);
            console.log('groupId', groupId);
            const a = await subscribe({ userId: user.uid, groupId: groupId });
            if (a) {
                console.log('SIKERES  LEKÉRDEZÉS111', a)
                toast.success(`Sikeres felvette a felhasználót a csoportba`);
                setError('Sikeres felvette a felhasználót a csoportba!');
            }
            else {
                console.log('HIBA111')
                toast.error(`Hiba történt a felvételnél`);
                setError('Hiba történt a felvételnél');
                setLoading(false);
            }
            navigate(`/groupEditor/${groupId}`)
        }
    }

    return (<>
        {loading &&
            <Spinner />
        }
        <div className="centerContent">
            {error ?
                <InfoMessage
                    color='success'
                    icon='Error'
                    label={error}
                    description={``}
                    options={[
                        { name: 'Tovább', onClick: () => cancel() }
                    ]} />
                : <>
                    <h2>Hozzáadja a felhasználót a csoport ügyintézői közé?</h2>
                    <SubscribedUser
                        level='member'
                        user={user.uid}
                        groupId={groupId}
                    />
                    <IconComponent sx={{ fontSize: 70 }} color="success" />
                    <ListItem
                        sx={{
                            textAlign: "center",
                            alignContent: "center",
                        }}
                        disablePadding>
                        <GroupItemMini4
                            key={groupId}
                            group={group}
                            id={group.id}
                            onDelete={() => { }}
                            onEdit={() => { }} />
                    </ListItem>
                    <button
                        className={"primaryButton"}
                        onClick={() => addUser()}>
                        Ok
                    </button>
                </>
            }


        </div>
    </>
    );
};

export default CreateManager;