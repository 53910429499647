import React, { useState } from 'react';
import { Box } from '@mui/material';
import NextButton from '../../buttons/NextButton';
import UserSelect from './scope/UserSelect';
import Info from './scope/Info';
import QQuestion from './ui/QQuestion';
import EmotionalState from './EmotionalState';
import ScopeItem from '../../questionnaire/ScopeItem';
import EvaluationItem from '../../questionnaire/EvaluationItem';
import CheckScreenSize from '../../CheckScreenSize';

const StartPage = ({
    next,
    admins,
    members,
    handleChange,
    values,
    questionnaire,
    emotional,
    setEmotional
}) => {
    const form = questionnaire?.qtemplate;
    const { scope, evaluate, qtemplate } = questionnaire;
    const [showEmotional, setShowEmotional] = useState(true);
    return (
        <>
            {showEmotional ?
                <EmotionalState
                    setShow={setShowEmotional}
                    emotional={emotional}
                    setEmotional={setEmotional}
                />
                : (<QQuestion>
                    {form && (<div className='centerContent2' >
                        <h2>{form.name}</h2>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                margin: 0,
                                padding: 0,
                                maxHeight: `calc(100vh - 100px)`,
                                '& ul': { padding: 0 },
                            }}>
                            <CheckScreenSize />
                            <ScopeItem scope={scope} />
                            <EvaluationItem evaluation={evaluate} />
                            {(evaluate === 'admin' || evaluate === 'members' || evaluate === '360') &&
                                <UserSelect
                                    evaluate={evaluate}
                                    members={members}
                                    admins={admins}
                                    handleChange={handleChange}
                                    values={values}
                                    next={next} />
                            }
                            {(evaluate === 'self' || evaluate === 'project') &&
                                <Info qtemplate={qtemplate} next={next} />
                            }
                        </Box>
                        <NextButton
                            disabled={(evaluate !== 'project' && evaluate !== 'self') && (values?.admins ?? []).length < 1 && (values?.members ?? []).length < 1}
                            name={'Értékelés indítása'}
                            variant={'contained'}
                            onClick={() => { next() }} />
                    </div>)}
                </QQuestion>)
            }
        </>
    );
};

export default StartPage;