import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/svg/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/editIcon.svg";
import bedIcon from "../../assets/svg/bedIcon.svg";
import bathtubIcon from "../../assets/svg/bathtubIcon.svg";

import React, { useEffect, useState } from "react";
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
import StorageImage from "../firebase/StorageImage";
import { Badge, ListItemAvatar, ListItemText, Stack } from "@mui/material";
import { Person, PersonAdd } from "@mui/icons-material";
import StorageAvatar from "../firebase/StorageAvatar";

function GroupItemMini3({ group }) {
    // console.log(group)
    return (
        <li className="categoryListing">
            <ListItemAvatar>
                <StorageAvatar
                    value={group?.Profilkép}
                    bucket={'group_images'}
                    size='large'
                />
            </ListItemAvatar>
            <ListItemText primary={group?.name} />
        </li>
    );
}

export default GroupItemMini3;
