import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useParams } from 'react-router-dom';
import View from './View';
import { AppContext } from '../../services/appContext';
import { convertObjectById } from '../../commons/Utility';
import InfoMessage from '../../commons/InfoMessage';
import { evaluatedBaseData, evaluatedStat, evaluatedStat2 } from '../../commons/StatUtility';

const Questionnaire = () => {
    const { queries, snapshots, isLoading, loadings, setLoading } = useContext(AppContext);
    const params = useParams();
    const { id } = params;
    const [user] = useAuthState(auth);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [profile, setProfile] = useState(null);
    const [myGroups, setMyGroups] = useState([]);
    const [group, setGroup] = useState(null);
    const [error, setError] = useState(false);
    const [stat, setStat] = useState();
    const [emotional, setEmotional] = useState();
    const [emotionals, setEmotionals] = useState();
    const [values, setValues] = useState();
    const [fillings, setFillings] = useState();

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    const getData3 = (model, id) => {
        if (model && id) {
            const convertedData = convertObjectById(model);
            return convertedData[id];
        }
    }
    const getEvaluationsValues = ({ questionnaire }) => {
        const evaluationValues = [];

        if (questionnaire && typeof questionnaire === 'object' && Object.keys(questionnaire).length > 0) {
            Object.keys(questionnaire).forEach(key => {
                if (key.startsWith('evaluation-')) {
                    evaluationValues.push(questionnaire[key]);
                }
            });
        }
        return evaluationValues;
    }

    const getEmotional = ({ evaluationValues }) => {
        if (!Array.isArray(evaluationValues) || evaluationValues.length === 0) {
            console.error('Invalid input. Please provide a valid array with emotional values.');
            return null;
        }
        const totalEmotional = evaluationValues.reduce((sum, item) => sum + (item.emotional || 0), 0);
        const averageEmotional = Math.round(totalEmotional / evaluationValues.length);

        return averageEmotional;
    }

    const countEmotions = ({ evaluationValues }) => {
        // Ellenőrizzük, hogy az evaluationValues egy érvényes tömb-e
        if (!Array.isArray(evaluationValues) || evaluationValues.length === 0) {
            console.error('Invalid input. Please provide a valid array with emotional values.');
            return null;
        }

        // Objektum inicializálása az értékek számolásához
        const emotionCounts = {};

        // Az evaluationValues tömb elemein végigiterálás
        evaluationValues.forEach(item => {
            const emotionalValue = item.emotional;

            // Növeljük az előfordulási számot a megfelelő kulcsnál
            if (emotionalValue !== undefined) {
                emotionCounts[emotionalValue] = (emotionCounts[emotionalValue] || 0) + 1;
            }
        });

        // Visszaadjuk az emotionCounts objektumot
        return emotionCounts;
    }

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['profile']]);

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    useEffect(() => {
        if (id && snapshots['questionnaires']) {
            const a = convertObjectById(snapshots['questionnaires']);
            const questionnaire = { ...a[id]?.data, id: id };
            setQuestionnaire(questionnaire);
            updateStat(questionnaire);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, snapshots['questionnaires']]);

    useEffect(() => {
        setGroup(getData3(myGroups, questionnaire?.group));
    }, [questionnaire, myGroups]);

    const updateStat = async (questionnaire) => {
        if (questionnaire) {
            const evaluationValues = getEvaluationsValues({ questionnaire: questionnaire });
            setValues(evaluationValues);
            const emotional = getEmotional({ evaluationValues: evaluationValues })
            setEmotional(emotional);
            setFillings(questionnaire?.count);
            const emotionals = countEmotions({ evaluationValues });
            setEmotionals(emotionals);
            if (evaluationValues) {
                const baseData = await evaluatedBaseData(
                    evaluationValues
                );
                const evaluatedData = await evaluatedStat(
                    baseData,
                    questionnaire?.qtemplate?.meta.questions,
                    questionnaire?.qtemplate?.meta.scales
                );
                setStat(evaluatedData);
            }
        }
    }

    const check = () => {
        if (questionnaire && group && profile &&
            questionnaire?.group === group?.id &&
            questionnaire?.id === id &&
            profile.id === user?.uid)
            return false
        else
            return true
    }

    return (<>
        {error ?
            <InfoMessage
                color='error'
                icon='Error'
                label='Valami probléma történt'
                description={`Elképzelhető, hogy hálózati probléma lépett fel, 
                vagy időközben lejárt egy kitöltési határidő, 
                vagy egy másik eszközön kitöltötted a kérdőívet, de lehet, hogy mi hibáztunk... \n
                <br><h4>A következő lehetőségek állnak rendelkezésre:</h4>`}
                options={[
                    { name: 'Kezdőlap', link: '/' },
                    { name: 'Bejelentkezés', onClick: () => auth.signOut(), className: 'secondaryButton' }
                ]}
            />
            : <View
                stat={stat}
                loading={check()}
                id={id}
                questionnaire={questionnaire}
                group={group}
                profile={profile}
                user={user?.uid}
                emotional={emotional}
                emotionals={emotionals}
                fillings={fillings}
                values={values}
            />}
    </>
    );
};

export default Questionnaire;