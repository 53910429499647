import Snapshot2 from '../../hooks/snapshot2';

const GroupSchedules = ({ setter }) => {

    const model = Snapshot2({
        setState: setter,
        key: 'schedules',
        table: 'schedules',
        refresh: true,
    });

    const subscribe = ({ group }) => {
        const unsubscribe2 = model.subscribe({
            filter: {
                field: "group",
                operation: '==',
                data: group
            }
        });
        return unsubscribe2;
    }
    return { subscribe };
};

export default GroupSchedules;