import React, { useState } from 'react';
import QPage from '../../../components/stepperForm/components/ui/QPage';
import QQuestion from '../../../components/stepperForm/components/ui/QQuestion';
import DetailsHeader from '../../../components/DetailsHeader';
import { getAuth, updateProfile } from "firebase/auth";
import {
    updateDoc,
    doc,
    collection,
    getDocs,
    query,
    where,
    orderBy,
    deleteDoc,
} from "firebase/firestore";
import { db } from '../../../firebase.config';
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@mui/material';


const Name = ({ auth, exit }) => {
    const [changeDetails, setChangeDetails] = useState(false); // Change Details; enable form; submit change; update user
    const [formData, setFormData] = useState({
        name: auth.currentUser.displayName,
        email: auth.currentUser.email,
    });
    const [loading, setLoading] = useState(false);

    // Destructure formData
    const { name, email } = formData;

    // Update
    const onSubmit = async () => {
        setLoading(true);
        try {
            if (auth.currentUser.displayName !== name) {
                //  update display name in firebase
                await updateProfile(auth.currentUser, {
                    displayName: name,
                });

                // Update display name in firestore
                const userRef = doc(db, "users", auth.currentUser.uid);
                await updateDoc(userRef, {
                    name: name,
                });
            }
            toast.success("Profile updated successfully");
            window.location.reload(true);
            exit();
        } catch (error) {
            console.log(error);
            toast.error("Could not update profile details");
        }
    };

    // Update the formData state
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
        if (name)
            setChangeDetails(true);
    };
    return (
        <QPage exit={() => exit(false)}>
            <QQuestion ><div>
                <p className="pageHeader">Profiladatok módosítása</p>
                <div className=''>
                    <div className='centerContent2'>
                        <div className="profileCard">

                            <form>
                                <input
                                    type="text"
                                    id="name"
                                    className={!changeDetails ? "profileName" : "profileNameActive"}
                                    // disabled={!changeDetails}
                                    value={name}
                                    onChange={onChange}
                                />
                                {/* <input
                                    type="text"
                                    id="email"
                                    className="profileEmail"
                                    disabled={true}
                                    value={email}
                                    onChange={onChange}
                                /> */}
                            </form>
                            {loading ? <CircularProgress /> :
                                <Button
                                    // className="changePersonalDetails"
                                    disabled={!changeDetails}
                                    onClick={() => {
                                        changeDetails && onSubmit();
                                        setChangeDetails((prevState) => !prevState);

                                    }}>
                                    Mentés
                                </Button>
                            }


                        </div>
                    </div>
                </div>
            </div>
            </QQuestion>
        </QPage>
    );
};

export default Name;