import React from "react";
import { useInView } from "react-intersection-observer";

const InfiniteScroll = ({ loadMore }) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 1.0
    });

    React.useEffect(() => {
        if (inView) {
            loadMore();
        }
    }, [inView, loadMore]);

    return <div ref={ref} style={{ height: "100px" }} />;
};

export default InfiniteScroll;
