import React, { useEffect } from 'react';
import { Button, FormControl } from '@mui/material';
import { statFunctions } from '../../../../anonimousData/Constansts';
import { v4 as uuidv4 } from "uuid";

const StatSelect = ({
    selected, setSelected, options, selectedValue
}) => {
    useEffect(() => {
        if (options.indexOf(selected) < 0) {
            setSelected(options[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '5px' }}>
            <FormControl variant="standard" sx={{ marginRight: 1, minWidth: 100, overflowY: 'auto', maxHeight: 200 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                    {options.map((option, index) => (
                        <Button
                            key={uuidv4()}
                            variant={selected === option ? "contained" : "outlined"}
                            onClick={() => setSelected(option)}
                            size="small" // Set button size to small
                        >
                            {statFunctions[option]?.label}
                        </Button>
                    ))}
                </div>
            </FormControl>
        </div>
    );
};

export default StatSelect;
