import { functions, httpsCallable } from '../../firebase.config';

const StartSchedule = async () => {
    const getFunction = httpsCallable(functions, 'startSchedule');

    const getData = async (id) => {
        console.log('id:', id);
        try {
            const result = await getFunction({ id: id });
            console.log('Firebase Function Response:', result?.data);
            return result?.data;
        } catch (error) {
            console.error('Error calling Firebase Function:', error);
        }
    };

    return { getData };
};

export default StartSchedule;
