import { help6 } from "../../anonimousData/Helps";

export const chart_LabelsSeriesFilterChart1 = {
    "chartType": "BSChart",
    "defaultCategoryField": "evaluated",
    "defaultSeriesField": null,
    "defaultValuesField": 'filledQuestionnaire_count',
    "filterFields": [
        // { "field": 'evaluated' },
        // { "field": 'question', multiselect: true }
    ],
    "title": "I/5. Értékeltek szerinti csoportosítás",
    "description": "Ez a diagram az egyes felhasználók által végzett és róluk készült értékelések számát mutatja a különböző értékelő típusok szerint.",
    "help": help6
};
