import { images } from "./images"

export const avatar = {
    isAdmin: false,
    icon: 'AccountCircle',
    type: 'help',
    content: 'Profilkép módosítása',
    description: 'A profilkép módosítása egyszerű és gyors folyamat az alkalmazásban. Kövesd az alábbi lépéseket a profil menüben.',
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.profile1',
            content: 'Lépj a profil menübe',
            description: 'Navigálj a profil menübe az alkalmazásban. Válaszd az "Új profilkép" gombot',
            image: images.profile1,
            help: null,
            mark: 'circle',
            markRadius: 31,
            markXPercent: 22,
            markYPercent: 40,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile2',
            content: 'Fénykép feltöltése',
            description: 'Koppints a "Fénykép feltöltése" gombra! Válassz ki egy fényképet a telefonodról vagy készíts egy újat a telefonod kamerájával.',
            image: images.profile2,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile3',
            content: 'Igazítsd a méretet:',
            description: 'Ha szükséges, igazítsd a fényképet a megfelelő méretűre, hogy ideálisan megjelenjen a profilodon. Koppints a Mentés Gombra',
            image: images.profile3,
        },
        {
            type: 'slide', // slide, link
            selector: '.profile4',
            content: 'Profilkép frissül',
            description: 'Ezután a profilképed azonnal frissül, és az új kép jelenik meg a profilodon. ',
            image: images.profile4,
        },
    ]
}
