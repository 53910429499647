import React from 'react';
import { Box, Chip, Select, MenuItem, IconButton, OutlinedInput } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import QuestionSelector from './QuestionSelector';

const LabelItem = ({
    labelId,
    localQuestions,
    setLocalQuestions,
    availableQuestions,
    newQuestionLabelId,
    setNewQuestionLabelId,
    newQuestionId,
    setNewQuestionId
}) => {

    const handleQuestionDelete = (labelId, questionId) => {
        const updatedQuestions = localQuestions[questionId].labels.filter(id => id !== labelId);
        setLocalQuestions({
            ...localQuestions,
            [questionId]: {
                ...localQuestions[questionId],
                labels: updatedQuestions
            }
        });
    };

    const handleQuestionAdd = () => {
        if (newQuestionLabelId && newQuestionId) {
            const updatedQuestions = localQuestions[newQuestionId]?.labels ? [...localQuestions[newQuestionId].labels, newQuestionLabelId] : [newQuestionLabelId];
            setLocalQuestions({
                ...localQuestions,
                [newQuestionId]: {
                    ...localQuestions[newQuestionId],
                    labels: updatedQuestions
                }
            });
            setNewQuestionLabelId(null);
            setNewQuestionId('');
        }
    };

    return (
        <>
            {Object.keys(localQuestions).filter(questionId => localQuestions[questionId]?.labels?.includes(labelId)).map(questionId => {
                const question = localQuestions[questionId];
                return (
                    <Chip
                        key={questionId}
                        label={question ? question.name : 'Unknown Question'}
                        onDelete={() => handleQuestionDelete(labelId, questionId)}
                        sx={{ m: 0.5 }}
                    />
                );
            })}
            <QuestionSelector
                labelId={labelId}
                localQuestions={localQuestions}
                newQuestionId={newQuestionId}
                setNewQuestionId={setNewQuestionId}
                newQuestionLabelId={newQuestionLabelId}
                setNewQuestionLabelId={setNewQuestionLabelId}
                availableQuestions={availableQuestions}
                handleQuestionAdd={handleQuestionAdd}
            />
        </>
    );
};

export default LabelItem;
