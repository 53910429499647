import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tada from 'react-reveal/Tada';
import { styled } from '@mui/system';
import Icons from '../MIIcons';
// import * as Icons from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ bgcolor, color }) => ({
    backgroundColor: bgcolor,
    color: color,
    width: '100%',
}));

const FlexContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
});

const Category = styled(Typography)({
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '8px',
});

const Message = styled(Typography)({
    fontSize: '1.5rem',
    marginBottom: '8px',
});

const StyledCard = styled(Box)(({ bgcolor, color }) => ({
    backgroundColor: bgcolor,
    color: color,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const ResultText = styled(Typography)({
    variant: 'body2',
    marginTop: '16px', // A kívánt távolság beállítása
});

const ExplanationText = styled(Typography)({
    variant: 'body2',
    marginTop: '16px', // A kívánt távolság beállítása
});

const ResultDialog = ({ categoryName, percent, open, handleClose, bgcolor, color, icon, category, message, result, explanation }) => {
    const IconComponent = Icons[icon];

    return (
        <StyledDialog open={open} onClose={handleClose} fullScreen>
            <StyledCard bgcolor={bgcolor} color={color} onClick={handleClose}>
                <DialogContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: bgcolor, color: color }}>
                    <FlexContainer>
                        <Category>{category}</Category>
                        <Box>
                            <IconComponent sx={{ fontSize: 100 }} />
                        </Box>
                        <Tada><Message>{message}</Message></Tada>
                        <ResultText>{`${categoryName}: ${result}`}
                            {percent && ` (${percent})%`}
                        </ResultText>
                        <ExplanationText>{`${explanation}`}</ExplanationText>
                    </FlexContainer>
                </DialogContent>
            </StyledCard>
        </StyledDialog>
    );
};

export default ResultDialog;
