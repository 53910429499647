import React from 'react';
import { Avatar, BottomNavigationAction, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { deepOrange } from '@mui/material/colors';
import { PlayArrow } from '@mui/icons-material';
import coverPhoto from "../../../assets/jpg/group2.jpg";
import GroupHeader from '../../../components/groups/GroupHeader';
import DashboardButton from '../../../commons/buttons/DashboardButton';
import UploadGroupImage from '../../../components/firebase/UploadGroupImage';

const Operations = ({
    user,
    group,
    groupId,
    instructor,
    isGroupAdmin,
    isSuperAdmin,
    isGroupManager,
}) => {
    const navigate = useNavigate();
    return (
        <>
            {/* <h3>Státusz</h3>

                        <div className=''>{`Csoporttagság: `}
                            <span className="listingType">
                                {isGroupAdmin ? 'Oktató' : isMember ? 'Csoportag' :
                                    isSubscribed ? 'Jelentkeztél' : ' Nem vagy tag'}
                            </span>
                        </div> */}
            <h3>Műveletek</h3>

            <div className="qrcodes">
                <Grid container spacing={2}>
                    <Grid className="profileButton" item xs={3}>
                        <GroupHeader
                            user={user}
                            group={group}
                            groupId={groupId}
                            instructor={instructor}
                            coverPhoto={coverPhoto}
                        />
                    </Grid>
                    {(isGroupAdmin || isSuperAdmin || isGroupManager) &&
                        <>
                            <Grid className="profileButton" item xs={3}>
                                <BottomNavigationAction
                                    showLabel
                                    onClick={() => { navigate(`/start/${groupId}`) }}
                                    label="Gyors tesztindítás"
                                    icon={
                                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
                                            <PlayArrow />
                                        </Avatar>
                                    } />
                            </Grid>
                            <Grid className="profileButton" item xs={3}>
                                <DashboardButton
                                    label={'Felhasználó felvétele'}
                                    icon={'CropFree'}
                                    onClick={() => (navigate("/subscribe"))}
                                    isAvatar={true}
                                    bgcolor={'#9B59B6'}
                                />
                            </Grid>
                            <Grid className="profileButton" item xs={3}>
                                <BottomNavigationAction
                                    showLabel
                                    // onClick={() => { setShow('profile') }}
                                    label="Borítókép feltöltés"
                                    icon={
                                        <Avatar sx={{ bgcolor: '#1ABC9C' }} variant="square">
                                            <UploadGroupImage groupId={groupId} group={group} />
                                        </Avatar>
                                    } />
                            </Grid>
                        </>
                    }
                </Grid>
            </div>
        </>
    );
};

export default Operations;