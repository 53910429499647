import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, List, ListItem, ListItemText, ListItemIcon, TextField } from '@mui/material';

const MetaMultiSelectDialog = ({ field, options, selected, multiple, setSelected }) => {
    const [open, setOpen] = useState(false);
    const [tempSelected, setTempSelected] = useState(selected);

    const handleToggle = (value) => {
        if (multiple) {
            const currentIndex = tempSelected.indexOf(value);
            const newSelected = [...tempSelected];

            if (currentIndex === -1) {
                newSelected.push(value);
            } else {
                newSelected.splice(currentIndex, 1);
            }
            setTempSelected(newSelected);
        } else {
            setTempSelected([value]);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTempSelected(selected); // Reset tempSelected to the original selected
    };

    const handleOk = () => {
        console.log('xxxxxx');
        setSelected(tempSelected);
        setOpen(false);
    };

    return (
        <>
            <TextField
                label={field.label}
                value={selected.join(', ')}
                onClick={handleClickOpen}
                InputProps={{
                    readOnly: true,
                }}
                fullWidth
                variant="standard"
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{field.label}</DialogTitle>
                <DialogContent>
                    <List>
                        {options.map((option) => (
                            <ListItem key={option} button onClick={() => handleToggle(option)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={tempSelected.indexOf(option) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Mégsem
                    </Button>
                    <Button
                        onClick={handleOk}
                        color="primary"
                        disabled={tempSelected.length === 0}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MetaMultiSelectDialog;
