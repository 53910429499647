import React from 'react';

const Info = ({ qtemplate }) => {
    return (
        <div>
            <h3>Tájékoztató</h3>
            <p>{qtemplate?.description}</p>
            <p>{qtemplate?.guide}</p>
            <small><em>*{qtemplate?.gdpr}</em></small>
        </div>
    );
};

export default Info;