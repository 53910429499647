import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import View from './View';
import { AppContext } from '../../services/appContext';
import Query3 from '../../hooks/query3';
import { convertArrayById, convertObjectById } from '../../commons/Utility';
import UpdateFilledQuestionnaires from '../../services/actions/UpdateFilledQuestionnaires';
import Document2 from '../../hooks/document2';
import InfoMessage from '../../commons/InfoMessage';

const Sampling = () => {
    const navigate = useNavigate();

    //PARAMS
    const params = useParams();
    const { id } = params;
    const [user] = useAuthState(auth);

    // FIREBASE DATA
    const { snapshots
        // ,success, setSuccess,
        // start, setStart,
        // submited, setSubmited,
    } = useContext(AppContext);
    const { subscribe } = UpdateFilledQuestionnaires();
    const [profile, setProfile] = useState(null);
    const [myGroups, setMyGroups] = useState([]);
    const [group, setGroup] = useState();
    const [questionnaire, setQuestionnaire] = useState(null);
    const [admins, setAdmins] = useState(null);
    const [members, setMembers] = useState(null);
    const [filledQuestionnaire, setFilledQuestionnaire] = useState(null);

    //STATE
    const [success, setSuccess] = useState(false);
    const [start, setStart] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [error, setError] = useState(false);
    let started = [];

    // GET FIREBASE DATA
    const myAdmins = Query3({
        setState: setAdmins,
        key: 'Admins' + id,
        table: 'users',
    });

    const myMembers = Query3({
        setState: setMembers,
        key: 'Members' + id,
        table: 'users',
    });

    const getAdmins = async ({ groupId }) => {
        await myAdmins.subscribe({
            filter: {
                field: "admins",
                operation: 'array-contains',
                data: groupId
            }
        }).then((snap) => {
            // console.log('snapsnap', snap);
        })
            .catch((err) => {
                console.error('error', err);
            });;
    }

    const getMembers = async ({ groupId }) => {
        await myMembers.subscribe({
            filter: {
                field: "groups",
                operation: 'array-contains',
                data: groupId
            }
        }).then((snap) => {
            console.log('snapsnap', snap);
        })
            .catch((err) => {
                console.error('error', err);
            });;
    }

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    const getData3 = (model, id) => {
        if (model && id) {
            const a = convertObjectById(model);
            return a[id];
        }
    }

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['profile']]);

    const myQuestionnaire = Document2({
        setState: (model) => { setQuestionnaire(getData(model)) },
        key: 'questionnaire-' + id,
        table: 'questionnaires',
        refresh: true
    });

    const getQuestionnaire = async (id2) => {
        const a = await myQuestionnaire.subscribe({ id: id2 });
        if (a) {
            // console.log('SIKERES  LEKÉRDEZÉS', a)
        }
        else {
            console.log('HIBA')
            setError(true);
        }
    }

    // USEEFFECTS
    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    useEffect(() => {
        const snapshot = snapshots['filledquestionnaires'];
        const convertedSnapshot = convertArrayById(snapshot, 'id');

        if (convertedSnapshot && typeof convertedSnapshot === 'object' && Object.keys(convertedSnapshot).length > -1 && id) {
            const filled = convertedSnapshot[id];
            // const e = evaluations[id];
            if (filled) {
                setFilledQuestionnaire(filled);
                getQuestionnaire(filled?.data?.questionnaire);
                setError(false);
            }
            else {
                console.log('HIBA');
                setError(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (questionnaire && questionnaire?.group) {
            getAdmins({ groupId: questionnaire.group });
            getMembers({ groupId: questionnaire.group });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaire]);

    useEffect(() => {
        setGroup(getData3(myGroups, questionnaire?.group));
    }, [questionnaire, myGroups]);

    // CHECK DATA
    const check = () => {
        if (filledQuestionnaire && questionnaire && group && profile &&
            questionnaire.group === group.id &&
            filledQuestionnaire.id === id &&
            profile.id === user?.uid)
            return false
        else
            return true
    }

    // SUBMIT DATA
    const submit = async ({ values, emotional = 0 }) => {
        setStart(true);
        const persons = Object.keys(values);
        if (!started[questionnaire]) {
            started[questionnaire] = true;
            const a = await subscribe(
                {
                    id: filledQuestionnaire.id,
                    questionnaire: questionnaire.id,
                    template: questionnaire.qtemplate.id,
                    values: values,
                    persons: persons,
                    group: questionnaire.group,
                    type: questionnaire.qtemplate.evaluate,
                    admin: group.data['Oktató'],
                    emotional: emotional
                }
            ).then((snap) => {
                // console.log('snapsnap', snap);
                localStorage.removeItem('sampling-' + id);
                setSubmited(true);
                // navigateAfterSubmit();
            })
                .catch((err) => {
                    console.error('error', err);
                });;
            if (a) {
                setSuccess(true);
                localStorage.setItem('lastSampling', id);
                setStart(false);
            }
            else {
                setStart(false);
                setSuccess(false);
            }
        }
    }

    const navigateAfterSubmit = () => {
        navigate('/questionnaires');
    }
    // console.log('check()', check());
    // console.log('submited', submited);
    return (<>
        {!submited && error && !start ?
            <InfoMessage
                color='error'
                icon='Error'
                label='Valami probléma történt'
                description={`Elképzelhető, hogy hálózati probléma lépett fel, 
                vagy időközben lejárt egy kitöltési határidő, 
                vagy egy másik eszközön kitöltötted a kérdőívet, de lehet, hogy mi hibáztunk... \n
                <br><h4>A következő lehetőségek állnak rendelkezésre:</h4>`}
                options={[
                    { name: 'Kezdőlap', link: '/' },
                    { name: 'Bejelentkezés', onClick: () => auth.signOut(), className: 'secondaryButton' }
                ]}
            />
            :
            <View
                id={id}
                profile={profile}
                group={group}
                admins={admins}
                members={members}
                questionnaire={questionnaire}
                submitCallback={submit}
                navigateAfterSubmit={navigateAfterSubmit}
                started={start}
                loading={check() || start}
                submited={submited}
                success={success}
            />
        }
    </>
    );
};

export default Sampling;