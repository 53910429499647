import React, { useEffect, useRef, useState } from 'react';
import CursorAnimation from './CursorAnimation'; // Import the CursorAnimation component
import Circle from './Circle';
import Rect from './Rect';
import { v4 as uuidv4 } from "uuid";

const ImageWithCircleOverlay = ({ step, imageHeight, showAnimation = true }) => {
    const imageRef = useRef(null);
    const canvasRef = useRef(null); // Add ref for canvas
    const [imageLoaded, setImageLoaded] = useState(false);
    const [parentID] = useState(uuidv4());
    const [imageID] = useState(uuidv4());
    const [screenWidth, setScreenWidth] = useState();
    const [imageWidth, setImageWidth] = useState();
    const [context, setContext] = useState();
    const [canvas, setCanvas] = useState();


    const [imageElement, setImageElement] = useState();
    const { image, mark, markRadius, markRectangleWidth,
        markRectangleHeight, markXPercent, markYPercent, alt,
        animations, animation } = step;
    const imageUrl = `https://kerdoiv.b612.hu/help/${image?.image ?? animation?.image}`;
    // const animationUrl = `https://kerdoiv.b612.hu/help/${animation?.image}`;

    const handleCanvasClick = (event) => {
        const canvas = event.currentTarget;
        const rect = canvas.getBoundingClientRect();
        const clickX = ((event.clientX - rect.left) / rect.width) * 100;
        const clickY = ((event.clientY - rect.top) / rect.height) * 100;
        console.log(`Kattintás koordinátái: ${clickX}%, ${clickY}%`);
    };

    // useEffect(() => {
    //     const image = imageRef.current;

    //     const handleImageLoad = () => {
    //         setImageLoaded(true);
    //     };

    //     if (image) {
    //         image.addEventListener('load', handleImageLoad);
    //         return () => {
    //             image.removeEventListener('load', handleImageLoad);
    //         };
    //     }
    // }, []);

    useEffect(() => {
        if (!imageLoaded) return;

        const drawCircle = () => {
            const imageElement = imageRef.current;
            setImageElement(imageElement);
            const canvas = document.createElement('canvas');
            setCanvas(canvas);
            canvasRef.current = canvas; // Set canvasRef to the created canvas
            const percent = imageHeight / imageElement.height;
            canvas.height = imageHeight || imageElement.height;
            const context = canvas.getContext('2d');
            setContext(context);
            const screenWidth = Math.min(window.screen.width, 700);
            // console.log('screenWidth', screenWidth)
            canvas.width = (imageElement.width / imageElement.height) * imageHeight;
            if (imageElement.width * percent > screenWidth) {
                canvas.width = imageElement.width * (screenWidth - 50) / imageElement.width;
                canvas.height = imageElement.height * (screenWidth - 50) / imageElement.width;
                context.drawImage(imageElement, 0, 0, (screenWidth - 50), imageElement.height * (screenWidth - 50) / imageElement.width);

            } else {
                context.drawImage(imageElement, 0, 0, imageElement.width * percent, imageElement.height * percent);
            }
            context.globalCompositeOperation = 'source-over'; // Default setting
            // width2 = canvas.width;
            setImageWidth(canvas.width)
            const element1 = document.getElementById(parentID);
            const rect1 = element1.getBoundingClientRect();
            setScreenWidth(rect1.width)

            // Replace image with canvas in the DOM
            imageElement?.parentElement?.replaceChild(canvas, imageElement);
            // Eseményfigyelő hozzáadása a canvas-hoz
            canvas?.addEventListener('click', handleCanvasClick);
        };

        drawCircle();
    }, [imageLoaded, imageUrl, imageHeight, mark, markRadius, markRectangleWidth, markRectangleHeight, markXPercent, markYPercent]);

    return (
        <div key={`imageParent${imageID}`} id={parentID} style={{ position: 'relative', width: '100%', height: 'auto' }}>
            {
                imageRef && imageUrl && imageID && step?.image &&
                <img
                    key={`image${imageID}`}
                    id={imageID}
                    ref={imageRef}
                    src={imageUrl}
                    alt={alt}
                    onLoad={() => setImageLoaded(true)}
                    style={{ width: '100%', height: 'auto', display: 'none' }}
                />
            }
             {
                 step?.animation &&
                <img
                    key={`animation${imageID}`}
                    src={imageUrl}
                    alt={alt}
                    style={{ width: '100%', height: 'auto'}}
                />
            }
            {imageElement && mark === 'rect' && (
                <Rect
                    key={`imageRect${imageID}`}
                    context={context}
                    canvas={canvas}
                    imageElement={imageElement}
                    imageHeight={imageHeight}
                    markXPercent={markXPercent}
                    markYPercent={markYPercent}
                    markRectangleWidth={markRectangleWidth}
                    markRectangleHeight={markRectangleHeight}
                />
            )}
            {imageElement && mark === 'circle' && (
                <Circle
                    key={`imageCircle${imageID}`}
                    context={context}
                    canvas={canvas}
                    imageElement={imageElement}
                    imageHeight={imageHeight}
                    markXPercent={markXPercent}
                    markYPercent={markYPercent}
                    markRadius={markRadius}
                />
            )}
            {showAnimation && imageLoaded && screenWidth &&
                imageWidth && animations &&
                animations.map((animation, index) => (
                    animation.name === 'cursor' &&
                    <CursorAnimation
                        key={`imageAnimation${imageID}-{index}`}
                        x0={(screenWidth - imageWidth) / 2}
                        x1={animation?.fromX ?? 50} // Adjust as needed
                        y1={animation?.fromY ?? 50} // Adjust as needed
                        x2={animation?.toX ?? (mark === 'circle' ? markXPercent : (markXPercent + markRectangleWidth) / 2)} // Adjust as needed
                        y2={animation?.toY ?? (mark === 'circle' ? markYPercent : (markYPercent + markRectangleHeight) / 2)} // Adjust as needed
                        canvasRef={canvasRef}
                    />
                ))}

            {/* {imageLoaded && screenWidth && imageWidth && (
                <CursorAnimation
                    x0={(screenWidth - imageWidth) / 2}
                    x1={50} // Adjust as needed
                    y1={50} // Adjust as needed
                    x2={mark === 'circle' ? markXPercent : (markXPercent + markRectangleWidth) / 2} // Adjust as needed
                    y2={mark === 'circle' ? markYPercent : (markYPercent + markRectangleHeight) / 2} // Adjust as needed
                    canvasRef={canvasRef}
                />
            )} */}
        </div>
    );
};

export default ImageWithCircleOverlay;
