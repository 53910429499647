import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const UpdateToken = () => {
    const { updateDocument } = useContext(AppContext);

    const subscribe = async ({ id, token, userId }) => {

        const newRecord = {
            id: id,
            token: token,
            userId: userId
        }

        const result = await updateDocument({
            id: id,
            table: 'fcmTokens',
            record: newRecord,
            fields: ['token', 'userId'],
        })
        return result;
    }

    return {
        subscribe
    }
};

export default UpdateToken;