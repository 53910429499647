import React from 'react';
import { Grid } from '@mui/material';
import AdminUsers from '../../../components/subscribed/AdminUsers';
import AddUserToGroupAdmins from '../../../components/admins/AddUserToGroupAdmins';
import ManagerUsers from '../../../components/subscribed/ManagerUsers';
import { Add, AddAPhoto } from '@mui/icons-material';
import CIconButton from '../../../components/buttons/CIconButton';
import { IconButton } from '@mui/material';
import AddUserToGroupManagers from '../../../components/admins/AddUserToGroupManagers';
import MemberUsers from '../../../components/subscribed/MemberUsers';

const AdminEditor = ({ groupId, group, isGroupAdmin, isSuperAdmin, isGroupManager, user }) => {
    const isGroupEditor = isGroupAdmin || isSuperAdmin || isGroupManager;
    return (
        <div>
            {/* <div className="qrcodes">
                <Grid container spacing={2}>
                    {(isGroupManager || isSuperAdmin) &&
                        <Grid className="profileButton" item xs={3}>
                            <AddUserToGroupAdmins
                                groupId={groupId}
                                group={group}
                            />
                        </Grid>
                    }
                </Grid>
            </div> */}
            <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Hallgatók
            </h3>
            <MemberUsers
                users={group?.Members}
                groupId={groupId}
                isAdmin={isGroupManager || isSuperAdmin}
                group={group}
            // navigateToUser={isGroupEditor}
            />
            <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Oktatók
                {(isGroupManager || isSuperAdmin) &&
                    <AddUserToGroupAdmins
                        groupId={groupId}
                        group={group}
                    />
                }
            </h3>
            <AdminUsers
                users={group?.admins}
                groupId={groupId}
                instructor={group ? group['Oktató'] : null}
                isAdmin={isGroupManager || isSuperAdmin}
                group={group}
                navigateToUser={isGroupEditor}
            />
            <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Ügyintézők
                {(isGroupManager || isSuperAdmin) &&
                    <AddUserToGroupManagers
                        groupId={groupId}
                        group={group}
                    />
                }
            </h3>

            <ManagerUsers
                users={group?.managers}
                groupId={groupId}
                instructor={group ? group['Oktató'] : null}
                isAdmin={isGroupManager || isSuperAdmin}
                group={group}
                navigateToUser={isGroupEditor}
            />
        </div>
    );
};

export default AdminEditor;