import React, { useContext } from 'react';
import { AppContext } from '../appContext';

const CreateErrorReport = () => {
    const { createDocument } = useContext(AppContext);

    const subscribe = async ({ subject, description, sendScreenshot }) => {
        const newRecord = {
            subject: subject,
            description: description,
            sendScreenshot: sendScreenshot
        }

        const result = await createDocument({ table: 'errorReports', record: newRecord })
        return result;
    }

    return {
        subscribe
    }
};

export default CreateErrorReport;