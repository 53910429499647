import React from 'react';
import ImageWithCircleOverlay from '../../images/ImageWithCircleOverlay';
import ImageWithCircleOverlay2 from '../../images/ImageWithCircleOverlay2';

const InfoCard = ({ step }) => {
    const { description, content, selector, image, mark, markRadius,
        markRectangleWidth, markRectangleHeight, markXPercent, markYPercent } = step;
    return (
        <div className={selector.substr(1, 100)}>
            <h3 className='helpTitle'>
                {content}
            </h3>
            
            <div className='helpDescription' dangerouslySetInnerHTML={{ __html: description }} />

            {step?.version === 2 &&
                <div className='centerContent2 helpImg'>
                    <ImageWithCircleOverlay2
                        step={step}
                        imageHeight={500}
                    />
                </div>
            }
            {step?.version !== 2 &&
                <div className='centerContent2 helpImg'>
                    <ImageWithCircleOverlay
                        step={step}
                        imageHeight={500}
                    />
                </div>
            }
        </div>
    );
};

export default InfoCard;