export const images = {
    signin: {
        name: 'Bejelentkezés',
        image: 'authentication/signin.png',
    },
    signup: {
        name: 'Regisztráció',
        image: 'authentication/signup.png',
    },
    forgotpassword: {
        name: 'Elfelejtett jelszó',
        image: 'authentication/forgotpassword.png',
    },
    confirm: {
        name: 'E-mail cím megerősítése',
        image: 'authentication/confirm.png',
    },
    reset: {
        name: 'Jelszó megváltoztatása',
        image: 'authentication/reset.png',
    },
    dashboard: {
        name: 'Kezdőoldal',
        image: 'dashboard/dashboard.png',
    },
    profile: {
        name: 'Profil',
        image: 'profile/profile.png',
    },
    qr: {
        name: 'Csoport QR kód',
        image: 'subscribe/qr.png',
    },
    scan: {
        name: 'Csoport QR kód beolvasása',
        image: 'subscribe/scan.png',
    },
    dashboard2: {
        name: 'Kezdőoldal',
        image: 'dashboard/dashboard2.png',
    },
    dashboard2b: {
        name: 'Kezdőoldal',
        image: 'dashboard/dashboard2b.png',
    },
    evaluations1: {
        name: 'Értékelések listája',
        image: 'evaluations/evaluations1.png',
    },
    evaluations2: {
        name: 'Értékelések listája',
        image: 'evaluations/evaluations2.png',
    },
    evaluations3: {
        name: 'Értékelés',
        image: 'evaluations/evaluations3.png',
    },
    manage1: {
        name: 'Kérdőívek',
        image: 'manage/manage1.png',
    },
    manage1: {
        name: 'Kérdőívek',
        image: 'manage/manage1.png',
    },
    start1: {
        name: 'Kérdőív készítés 1.',
        image: 'manage/start1.png',
    },
    start2: {
        name: 'Kérdőív készítés 2.',
        image: 'manage/start2.png',
    },
    start3: {
        name: 'Kérdőív készítés 3.',
        image: 'manage/start3.png',
    },
    start4: {
        name: 'Kérdőív készítés 4.',
        image: 'manage/start4.png',
    },
    start5: {
        name: 'Kérdőív készítés 5.',
        image: 'manage/start5.png',
    },
    start6: {
        name: 'Kérdőív készítés 6.',
        image: 'manage/start6.png',
    },
    start7: {
        name: 'Kérdőív készítés 7.',
        image: 'manage/start7.png',
    },
    start8: {
        name: 'Kérdőív készítés 8.',
        image: 'manage/start8.png',
    },
    start9: {
        name: 'Kérdőív készítés 9.',
        image: 'manage/start9.png',
    },
    groups1: {
        name: 'Csoportok',
        image: 'groups/mygroups1.png',
    },
    groups2: {
        name: 'Csoport - adatlap',
        image: 'groups/mygroups2.png',
    },
    groups3: {
        name: 'Csoport - felhasználók',
        image: 'groups/mygroups3.png',
    },
    groups4: {
        name: 'Csoport - adminisztráció',
        image: 'groups/mygroups4.png',
    },
    groups5: {
        name: 'Csoport - jelentkezők',
        image: 'groups/mygroups5.png',
    },
    profile1: {
        name: 'Profil',
        image: 'profile/profile1.png',
    },
    profile2: {
        name: 'Profilkép feltöltés',
        image: 'profile/profile2.png',
    },
    profile3: {
        name: 'Profilkép igazítás',
        image: 'profile/profile3.png',
    },
    profile4: {
        name: 'Profil - új profilkép',
        image: 'profile/profile4.png',
    },
    questionnaire1: {
        name: 'Kérdőív statisztika',
        image: 'questionnaires/questionnaire1.png',
    },
    sampling1: {
        name: 'Kitöltés 1.',
        image: 'sampling/sampling1.png',
    },
    sampling2: {
        name: 'Kitöltés 2.',
        image: 'sampling/sampling2.png',
    },
    sampling3: {
        name: 'Kitöltés 3.',
        image: 'sampling/sampling3.png',
    },
    sampling4: {
        name: 'Kitöltés 4.',
        image: 'sampling/sampling4.png',
    },
    sampling5: {
        name: 'Kitöltés 5.',
        image: 'sampling/sampling5.png',
    },
    sampling6: {
        name: 'Kitöltés 6.',
        image: 'sampling/sampling6.png',
    },
    sampling7: {
        name: 'Kitöltés 5.',
        image: 'sampling/sampling7.png',
    },
    sampling8: {
        name: 'Kitöltés 8.',
        image: 'sampling/sampling8.png',
    },
    sampling9: {
        name: 'Kitöltés 9.',
        image: 'sampling/sampling9.png',
    },
    sampling10: {
        name: 'Kitöltés 10.',
        image: 'sampling/sampling10.png',
    },
    sampling11: {
        name: 'Kitöltés 11.',
        image: 'sampling/sampling11.png',
    },
    sampling12: {
        name: 'Kitöltés 12.',
        image: 'sampling/sampling12.png',
    },
    sampling13: {
        name: 'Kitöltés 13.',
        image: 'sampling/sampling13.png',
    },
    sampling14: {
        name: 'Kitöltés 14.',
        image: 'sampling/sampling14.png',
    },
    sampling15: {
        name: 'Kitöltés 15.',
        image: 'sampling/sampling15.png',
    },
    subscribe1: {
        name: 'Feliratkozás 1.',
        image: 'subscribe/subscribe1.png',
    },
    subscribe2: {
        name: 'Feliratkozás 2.',
        image: 'subscribe/subscribe2.png',
    },
    subscribe3: {
        name: 'Feliratkozás 3.',
        image: 'subscribe/subscribe3.png',
    },
    subscribe4: {
        name: 'Feliratkozás 4.',
        image: 'subscribe/subscribe4.png',
    },
    subscribe5: {
        name: 'Feliratkozás 5.',
        image: 'subscribe/subscribe5.png',
    },
    subscribe6: {
        name: 'Feliratkozás 6.',
        image: 'subscribe/subscribe6.png',
    },
    install1: {
        name: 'Telepítés 1.',
        image: 'install/install1.png',
    },
    install2: {
        name: 'Telepítés 2.',
        image: 'install/install2.png',
    },
    dashboardNoGroup: { name: 'Kezdőoldal', image: 'dashboardNoGroup.png' },
    groupstats1: { name: 'Csoport statisztika', image: 'groupstats1.png' },
    groupstatsAnim: { name: 'Csoport statisztika', image: 'groupstatsAnim.gif' },
    groupstatsI1: { name: 'Csoport statisztika', image: 'groupstatsI1.png' },
    groupstatsI2: { name: 'Csoport statisztika', image: 'groupstatsI2.png' },
    groupstatsI3: { name: 'Csoport statisztika', image: 'groupstatsI3.png' },
    groupstatsI4: { name: 'Csoport statisztika', image: 'groupstatsI4.png' },
    groupstatsI5: { name: 'Csoport statisztika', image: 'groupstatsI5.png' },
    groupstatsI6: { name: 'Csoport statisztika', image: 'groupstatsI6.png' },
    groupstatsI7: { name: 'Csoport statisztika', image: 'groupstatsI7.png' },
    groupstatsI8: { name: 'Csoport statisztika', image: 'groupstatsI8.png' },
    groupstatsII1: { name: 'Csoport statisztika', image: 'groupstatsII1.png' },
    groupstatsIII5: { name: 'Csoport statisztika', image: 'groupstatsIII5.png' },
    groupstatsIV4: { name: 'Csoport statisztika', image: 'groupstatsIV4.png' },
    groupstatsLabelsEditor1: { name: 'Cimke szerkeszés', image: 'groupstatsLabelsEditor1.png' },
    groupstatsLabelsEditor2: { name: 'Cimke szerkeszés', image: 'groupstatsLabelsEditor2.png' },
    groupstatsLabelsEditor3: { name: 'Cimke szerkeszés', image: 'groupstatsLabelsEditor3.png' },
    groupstatsTemplateUpdate: { name: 'Sablon frissítés', image: 'groupstatsTemplateUpdate.png' },
    groupstatsV5: { name: 'Csoport statisztika', image: 'groupstatsV5.png' },
    inactiveDashboard2: { name: 'Inaktív csoportok', image: 'inactiveDashboard2.png' },
    inactiveDashboard: { name: 'Aktív csoportok', image: 'inactiveDashboard.png' },
    inactiveMyGroup: { name: 'Inaktív csoport', image: 'inactiveMyGroup.png' },
    loginError1: { name: 'Bejelentkezés hiba', image: 'loginError1.png' },
    mygroupsCreate: { name: '', image: 'mygroupsCreate.png' },
    mygroupsEdit: { name: 'Csoport készítése', image: 'mygroupsEdit.png' },
    mygroupsList: { name: 'Csoport lista', image: 'mygroupsList.png' },
    mygroupsUsersList: { name: 'Csoport felhasználói', image: 'mygroupsUsersList.png' },
    registrationError1: { name: 'Regisztráció hiba', image: 'registrationError1.png' },
    schedulesCreate2: { name: 'Ütemezés készítése - dátumok', image: 'schedulesCreate2.png' },
    schedulesCreate: { name: 'Ütemezés készítése', image: 'schedulesCreate.png' },
    schedulesDateEdit: { name: 'Ütemezés készítése - dátumok készítése - dátum szerkesztése', image: 'schedulesDateEdit.png' },
    schedulesEdit: { name: 'Ütemezés szerkesztése', image: 'schedulesEdit.png' },
    schedulesList: { name: 'Ütemezések listája', image: 'schedulesList.png' },
    userEdit1: { name: 'Felhasználó szerkesztése', image: 'userEdit1.png' },
    userEdit2: { name: 'Felhasználó adatainak módosítása', image: 'userEdit2.png' },
    stat1: { name: 'Önértékelés és értékelés', image: 'stat1.png' },
    stat2: { name: 'Értékelő perspektíva', image: 'stat2.png' },
    stat3: { name: 'Csoport perspektíva', image: 'stat3.png' },
    labels1: { name: 'Csoport perspektíva', image: 'labels1.png' },
    groupStatSetup1: { name: 'Setup 1', image: 'groupStatSetup1.png' },
    groupStatSetup2: { name: 'Setup 1', image: 'groupStatSetup2.png' },
    groupStatSetup3: { name: 'Setup 1', image: 'groupStatSetup3.png' },
    groupStatSetup4: { name: 'Setup 1', image: 'groupStatSetup4.png' },
    groupStatSetup5: { name: 'Setup 1', image: 'groupStatSetup5.png' },
    groupStatSetup6: { name: 'Setup 1', image: 'groupStatSetup6.png' },
    groupStatSetup7: { name: 'Setup 1', image: 'groupStatSetup7.png' },
    groupStatSetup8: { name: 'Setup 1', image: 'groupStatSetup8.png' },
    groupStatSetup9: { name: 'Setup 1', image: 'groupStatSetup9.png' },
    groupStatSetup10: { name: 'Setup 1', image: 'groupStatSetup10.png' },
    groupStatSetup11: { name: 'Setup 1', image: 'groupStatSetup11.png' },
    groupStatSetup12: { name: 'Setup 1', image: 'groupStatSetup12.png' },
    groupStatSetup13: { name: 'Setup 1', image: 'groupStatSetup13.png' },
    groupStatSetup14: { name: 'Setup 1', image: 'groupStatSetup14.png' },
    groupStatButtons: { name: 'Setup 1', image: 'groupStatButtons.png' },
    groupStatTabs: { name: 'Setup 1', image: 'groupStatTabs.png' },

    history0: { name: 'Kitöltési előzmények', image: 'history0.png' },
    history1: { name: 'Kitöltési előzmények', image: 'history1.png' },
    history2: { name: 'Kitöltési előzmények', image: 'history2.png' },
    history3: { name: 'Kitöltési előzmények', image: 'history3.png' },
    createUser1: { name: 'Felhasználó létrehozása', image: 'createUser1.png' },
    createUser2: { name: 'Felhasználó létrehozása', image: 'createUser2.png' },
    admin1: { name: 'Oktató hozzáadása csoporthoz', image: 'admin1.png' },
    admin2: { name: 'Oktató hozzáadása csoporthoz', image: 'admin2.png' },
    admin3: { name: 'Oktató hozzáadása csoporthoz', image: 'admin3.png' },
    admin4: { name: 'Oktató hozzáadása csoporthoz', image: 'admin4.png' },
    mobile1: { name: 'QR kód kamera beállítás', image: 'mobile1.png' },
    mobile2: { name: 'QR kód kamera beállítás', image: 'mobile2.png' },
    quickStart: { name: 'Gyors tesztindítás', image: 'quickStart.png' },
    subscribeGroup1: { name: 'Feliratkozás csoportba', image: 'subscribeGroup1.png' },
    groupQR: { name: 'Csoport tab QR', image: 'groupQR.png' },
    groupMembers: { name: 'Csoport tab Members', image: 'groupMembers.png' },
    groupQuick: { name: 'Csoport tab Quick', image: 'groupQuick.png' },
    groupStat: { name: 'Csoport tab Stat', image: 'groupStat.png' },
    subscribeToGroup4: { name: 'Csoport tab Stat', image: 'subscribeToGroup4.gif' },
    subscribe2b: { name: 'Feliratkozás 2.', image: 'subscribe2b.png' },
    subscribe4b: { name: 'Feliratkozás 2.', image: 'subscribe4b.png' },
    subscribe5b: { name: 'Feliratkozás 2.', image: 'subscribe5b.png' },
    group: { name: 'Feliratkozás 2.', image: 'group.png' },
    userCRUD: { name: 'Feliratkozás 2.', image: 'userCRUD.png' },
    roles2: { name: 'Feliratkozás 2.', image: 'roles2.png' },
    qr2: { name: 'QR kód beolvasása', image: 'qr2.png' },
}