import React from 'react';
import { Button } from '@mui/material';
import { convertToNum } from './Utility';

const CButtonSelect = ({
    field,
    handleChange,
    value,
    options,
}) => {
    const setValue = (v) => {
        handleChange(field, v)
    }

    return (
        <>
            {options && options.map((option, index) => (
                <Button
                    sx={{

                        padding: '0.85rem 0rem1',
                        // color: value === option.value ? '#00000' : '#00cc66',
                        // backgroundColor: value === option.value ? '#00cc66' : '#00000',
                        fontweight: 600,
                        fontSize: '1.0rem',
                        width: '90%',
                        margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '15px',
                        justifyContent: 'center',
                        borderRadius: 28
                    }}
                    // className="optionsButton"
                    value={convertToNum(option.value)}
                    onClick={() => { setValue(option.value) }}
                    variant={value === option.value ? "contained" : "outlined"}
                    color={value === option.value ? "primary" : "primary"}
                    key={`div${index}${option.label}`}
                    size={"xlarge"}
                    label={option.label}
                >
                    {option.label}
                </Button>
            ))}
        </>
    )
}
export default CButtonSelect
