import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import DashboardButton from '../../commons/buttons/DashboardButton';
import { useNavigate } from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddApp = ({ storedApps, createApp, open, handleClose }) => {
    const [url, setUrl] = useState('');
    const [error, setError] = useState();
    const navigate = useNavigate();

    const handleOk = async () => {
        const urlRegex = /^https:\/\/kerdoiv\.b612\.hu\/apps\/.+/;
        let appUrl = `https://kerdoiv.b612.hu/apps/${url}.json`;

        if (urlRegex.test(url)) {
            appUrl = url;
        }

        try {
            // Ellenőrizzük az URL-t

            if (!urlRegex.test(appUrl)) {
                setError('Érvénytelen URL formátum');
                return;
            }

            // Kérjük le a JSON-t az axios segítségével
            const response = await axios.get(appUrl, {
                withCredentials: false,
            });
            const data = response.data;
            // Ellenőrizzük a visszakapott értéket
            if (typeof data === 'object' && data !== null) {
                // Ellenőrizzük, hogy az ID még nem létezik-e
                if (storedApps[data.id]) {
                    setError('Az alkalmazás már létezik');
                    return;
                }
                // Ellenőrizzük az ID-t és a config mezőt
                if (typeof data.id === 'string' && data.config && typeof data.config === 'object') {
                    const requiredFields = [
                        'apiKey',
                        // 'authDomain',
                        // 'databaseURL',
                        // 'projectId',
                        // 'storageBucket',
                        // 'messagingSenderId',
                        // 'appId'
                        // 'measurementId',
                    ];
                    // Ellenőrizzük a config mezőt
                    const configIsValid = requiredFields.every(field => typeof data.config[field] === 'string');
                    if (!configIsValid) {
                        setError('Hiányzó vagy érvénytelen config mezők');
                        return;
                    }
                    // Ha minden rendben, hozzáadjuk az alkalmazást
                    storedApps[data.id] = data;
                    createApp(data);
                    setUrl('');
                    setError(null);
                    handleClose();
                } else {
                    setError('Érvénytelen ID vagy config mező');
                }
            } else {
                setError('Érvénytelen JSON formátum');
            }
        } catch (error) {
            setError('Nem sikerült lekérni az alkalmazást');
        }
    };

    const handleCancel = () => {
        setUrl('');
        setError(null);
        handleClose();
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError('');
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Új alkalmazás hozzáadása</DialogTitle>

            <DialogContent>

                <TextField
                    label="Alkalmazás azonosító/URL"
                    fullWidth
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <div className='centerContent2'>
                    <br />
                    <DashboardButton
                        label={'Hozzáadás QR kóddal'}
                        icon={'CropFree'}
                        onClick={() => (navigate("/subscribe"))}
                    // isAvatar={true}
                    // bgcolor={'#9B59B6'}
                    />
                </div>

                <DialogActions>
                    <Button variant="oulined" onClick={handleCancel} style={{ marginLeft: '1em', marginTop: '1em' }}>
                        Mégsem
                    </Button>

                    <Button variant="contained" onClick={handleOk} style={{ marginTop: '1em' }}>
                        OK
                    </Button>
                </DialogActions>
            </DialogContent>
            {error &&
                <Alert onClose={handleErrorClose} severity="error">
                    {error}
                </Alert>
            }
        </Dialog>
    );
};

export default AddApp;
