import React, { useState } from 'react';
import { Button, TablePagination, Grid } from '@mui/material';
import LogTable from './components/LogTable';
import { CSVLink, CSVDownload } from "react-csv";
import { Download } from '@mui/icons-material';
import DashboardButton from '../../commons/buttons/DashboardButton';
import EvaluationsMonthChart from './components/EvaluationsMonthChart';

const View = ({ getData, data = [] }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="profileList">
            <header className="profileHeader">
                <p className="pageHeader"> Előzmények</p>
            </header>
            <main>
                <Grid container spacing={0}>
                    <DashboardButton
                        label={'Frissítés'}
                        icon={'Refresh'}
                        onClick={() => getData()}
                    />
                    <CSVLink separator={";"} data={data} filename={"prvet_kitoltesek.csv"}>
                        <DashboardButton
                            label={'CSV letöltés'}
                            icon={'Download'}
                        />
                    </CSVLink>
                </Grid>

                {data && data.length > 0 && (
                    <div style={{ overflowX: 'auto' }}>
                        <EvaluationsMonthChart data={data} />
                        <LogTable logData={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                )}
            </main>
        </div >
    );
};

export default View;
