import React from 'react';
import { Dialog } from '@mui/material';
import { Close, Help } from '@mui/icons-material';
import { pink } from '@mui/material/colors';
import CIconButton from '../../buttons/CIconButton';
import NextButton from '../../buttons/NextButton';
import { useNavigate } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';

const ReStart = ({
    load,
    setLoad,
    setActualCategory,
    // setCount,
    pages,
    setup,
    setEmotional
}) => {
    const navigate = useNavigate();
    return (
        <Dialog fullScreen open={load}>
            <CIconButton
                icon={<Close />}
                color={'primary'}
                confirm={false}
                onClick={() => { navigate(`/questionnaires`) }}
                style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    zIndex: 9999
                }}
            />
            <div className='centerContent' >
                <h1>Folytatás?</h1>
                <p>A kérdőív kitöltésének folytatása?</p>
                <Help sx={{ fontSize: 170, color: pink[500] }} />
                <div>
                    <NextButton
                        name={'Folytatás'}
                        variant={'contained'}
                        onClick={() => {
                            setup();
                            setLoad(false)
                        }}
                    />
                </div>
                <div>
                    <NextButton
                        name={'Újrakezdés'}
                        variant={'contained'}
                        className={"secondaryButton2"}
                        onClick={() => {
                            const page = pages[0];
                            const categoryId = page?.category_id;
                            setActualCategory(categoryId);
                            // setCount(0);
                            setLoad(false)
                            setEmotional(false)
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ReStart;