import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import ChartComponent from './components/ChartComponent';
import { Stack } from '@mui/material';
import FiltersBox2 from '../../components/FiltersBox2';
import MetaMultiSelectDialog from './components/MetaMultiSelectDialog';
import SeriesOrderSelectDialog from './components/SeriesOrderSelectDialog';
import { chartTypeFilters2 } from '../../../anonimousData/Constansts';

// Adatok rendezése egy adott érték alapján
const orderData2 = (values, name, selectedCategories) => {
    const target = values.find(v => v.name === name);

    if (!target) return values; // Az eredeti értékeket adja vissza, ha a név nem található

    // Az adatok szám értékké alakítása a megfelelő rendezés érdekében
    const targetData = target.data.map(value => parseFloat(value));

    // A rendezett indexek megszerzése
    const sortedIndices = targetData
        .map((value, index) => ({ value, index }))
        .sort((a, b) => a.value - b.value)
        .map(({ index }) => index);

    // Minden adat tömb rendezése az indexek alapján
    const orderedValues = values.map(obj => {
        const orderedData = sortedIndices.map(index => obj.data[index]);
        return { ...obj, data: orderedData };
    });

    const selectedCategories2 = sortedIndices.map(index => selectedCategories[index]);
    return {
        sortedData3: orderedValues,
        selectedCategories2: selectedCategories2
    };
};

const CategoryApexChartSeries2 = ({
    data,
    categoryField,
    valuesField,
    seriesField,
    decimal = 0,
    type = chartTypeFilters2.bar,
    filterFields,
    filterFieldValues,
    showFilterPanel = true
}) => {
    const [selectedOrder, setSelectedOrder] = useState(0);

    useEffect(() => {}, [data, valuesField, seriesField]);

    let sortedData;
    if (categoryField?.isDate) {
        // Dátum szerint rendezés
        sortedData = [...data].sort((a, b) => a[categoryField.field]?.localeCompare(b[categoryField.field]));
    } else {
        // Szám szerint rendezés
        sortedData = [...data].sort((a, b) => a[categoryField.field] - (b[categoryField.field]));
    }

    // Kategória és sorozat opciók meghatározása
    const categoryOptions = [...new Set(sortedData?.map(item => item[categoryField?.field]))];
    const seriesOptions = [...new Set(sortedData?.map(item => item[seriesField?.field]))];

    // Feliratok összerakása a szűrők alapján
    const subtitle = filterFieldValues?.map((x, index) =>
        typeof x.value === 'object' ?
            `${filterFields[index].label}: ${x.value.map((y) => filterFields[index].meta[y]?.name).join(', ')}`
            : `${filterFields[index].label}: ${filterFields[index].meta[x.value]?.name}`
    ).join(' - ');

    // Alapértelmezett kiválasztások
    const [selectedCategories, setSelectedCategories] = useState(categoryOptions);
    const [selectedSeries, setSelectedSeries] = useState(seriesOptions);

    // Szűrt adatok előkészítése a kiválasztások alapján
    const filteredData = sortedData.filter(item =>
        selectedCategories.includes(item[categoryField?.field]) && selectedSeries.includes(item[seriesField?.field])
    );

    // Sorozat adatok készítése a kiválasztott kategóriák alapján
    const seriesData = selectedSeries.map(seriesName => {
        return {
            name: seriesName,
            data: selectedCategories.map(cat => {
                const item = filteredData.find(d => d[categoryField?.field] === cat && d[seriesField?.field] === seriesName);
                return item ? item[valuesField?.field].toFixed(decimal) : 0;
            })
        };
    });

    // Adatok rendezése, ha szükséges
    const { sortedData3, selectedCategories2 } = categoryField?.isDate
        ? { sortedData3: seriesData, selectedCategories2: selectedCategories }
        : orderData2(seriesData, seriesOptions[selectedOrder], selectedCategories);

    // Grafikon opciók beállítása
    const horizontal = type?.horizontal && !categoryField?.isDate;
    const options = {
        chart: {
            type: type?.field,
            stacked: type?.stacked,
        },
        xaxis: {
            categories: selectedCategories2,
            title: {
                text: horizontal ? valuesField?.label : categoryField?.label
            }
        },
        yaxis: {
            title: {
                text: !horizontal ? valuesField?.label : ''
            }
        },
        title: {
            text: categoryField?.isDate
                ? `${seriesField?.label === 'series' ? valuesField?.label + ' - ' : seriesField?.label + ' szerinti időbeli'} (${categoryField?.label}) trend `
                : `${categoryField?.label} ${seriesField?.label && seriesField?.field !== 'series' ? ' és ' + seriesField?.label?.toLowerCase() : ''}`,
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            }
        },
        subtitle: {
            text: subtitle?.length > 90 ? subtitle?.substr(0, 90) + '...' : subtitle,
            align: 'center',
        },
        plotOptions: {
            bar: {
                horizontal: horizontal,
                dataLabels: {
                    enabled: Array.isArray(data) && data?.length < 20,
                    position: 'center'
                }
            },
            boxPlot: {
                colors: {
                    upward: '#3C90EB',
                    downward: '#DF7D46'
                }
            }
        },
        dataLabels: {
            enabled: Array.isArray(data) && data?.length < 30,
            formatter: function (val) {
                // Ha az érték 0, ne jelenjen meg
                return val === 0 ? '' : val.toFixed(decimal);
            },
            style: {
                colors: ['#000'] // Betűszín beállítása feketére
            }
        }
    };

    return (
        <Stack spacing={2}>
            <FiltersBox2 show={showFilterPanel} label='Szűrők'>
                <MetaMultiSelectDialog
                    key={uuidv4()}
                    field={categoryField}
                    options={categoryOptions}
                    selected={selectedCategories}
                    setSelected={setSelectedCategories}
                    multiple={true}
                />
                <MetaMultiSelectDialog
                    key={uuidv4()}
                    field={seriesField}
                    options={seriesOptions}
                    selected={selectedSeries}
                    setSelected={setSelectedSeries}
                    multiple={true}
                />
                <SeriesOrderSelectDialog
                    field={{ label: 'Rendezés' }}
                    selected={selectedOrder}
                    setSelected={setSelectedOrder}
                    options={seriesOptions}
                    selectedValue={selectedSeries}
                />
            </FiltersBox2>
            <ChartComponent
                key={uuidv4()}
                chartData={{ options: options, series: sortedData3 }}
                type={type?.field}
            />
        </Stack>
    );
};

export default CategoryApexChartSeries2;
