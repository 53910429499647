import { Avatar, BottomNavigationAction, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import ProfileAvatar from '../../pages/profile/components/ProfileAvatar';
import { AddAPhoto, InstallMobile } from '@mui/icons-material';
import { auth } from '../../firebase.config';
import DashboardButton from '../../commons/buttons/DashboardButton';
import { useNavigate } from 'react-router-dom';
import Install from '../../pages/profile/components/Install';
import { AppContext } from '../../services/appContext';
import { deepOrange, green } from '@mui/material/colors';
import ErrorReport from '../../commons/admins/ErrorReport';

const Personal = () => {
    const [show, setShow] = useState();
    const navigate = useNavigate();
    const { isSuperAdmin, isAdmin, isManager } = useContext(AppContext);
    return (
        <div className="qrcodes">
            <Grid container spacing={0}>
                {(isSuperAdmin || isAdmin || isManager) ?
                    <Grid item xs={3}>
                        <ErrorReport screenshot={false} />
                    </Grid>
                    :
                    <Grid item xs={3}>
                        <BottomNavigationAction
                            showLabel
                            // onClick={() => setShow('install')}
                            label="Telepítés"
                            icon={
                                <Avatar sx={{ bgcolor: '#1ABC9C' }} variant="rounded">
                                    <InstallMobile />
                                </Avatar>
                            } />
                    </Grid>
                }
                <Grid className="profileButton" item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { setShow('profile') }} label="Új profilkép" icon={
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
                            <AddAPhoto />
                        </Avatar>

                    } />
                </Grid>
                <Grid item xs={3}>
                    <DashboardButton
                        label={'QR kódom'}
                        icon={'QrCode'}
                        onClick={() => (navigate("/myprofile"))}
                        isAvatar={true}
                        bgcolor={'#F39C12'}
                    />
                </Grid>
                <Grid item xs={3}>
                    {isAdmin ?
                        <DashboardButton
                            label={'Felhasználó felvétele'}
                            icon={'CropFree'}
                            onClick={() => (navigate("/subscribe"))}
                            isAvatar={true}
                            bgcolor={'#9B59B6'}
                        />
                        :
                        <DashboardButton
                            label={'Jelentkezés csoportba'}
                            icon={'GroupAdd'}
                            onClick={() => (navigate("/subscribe"))}
                            isAvatar={true}
                            bgcolor={'#9B59B6'}
                        />
                    }
                </Grid>
            </Grid>
            {show === 'profile' && <ProfileAvatar auth={auth} exit={() => (setShow(false))} />}
            {show === 'install' && <Install auth={auth} exit={() => (setShow())} />}
        </div>
    );
};

export default Personal;