import React, { useEffect } from 'react';
import ColourFull from '../../../commons/loaders/ColourFull';
import Circles from '../../../commons/loaders/Circles';
import { useLocalStorageSize } from '../../../commons/localStorage/useLocalStorageSize';

const Debug = ({
    counter, setCounter,
    debug, setDebug,
    deviceId, isTokenFound,
    version
}) => {
    const localStorageSize = useLocalStorageSize();

    useEffect(() => {
        if (counter > 5) {
            if (debug) {
                setDebug(0);
                localStorage.setItem('debug', 0);
            }
            else {
                setDebug(1);
                localStorage.setItem('debug', 1);
            }
            setCounter(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);
    return (
        <div className='license'>
            <div>
                <button onClick={() => { setCounter(counter + 1) }}>
                    {version}
                </button>
            </div>
            {debug == 1 &&
                <>
                    <ColourFull />
                    <div><b>Token:</b> {isTokenFound ? isTokenFound?.substr(1, 20) : ''}</div>
                    <div><b>Device:</b> {deviceId}</div>
                    <div><b>Local Storage Size:</b> {localStorageSize}</div>
                    <Circles />
                </>
            }
        </div>
    );
};

export default Debug;