import React, { useEffect, useState, useContext } from 'react';
import View from './View';
import { auth } from '../../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AppContext } from '../../services/appContext';

const Questionnaires = () => {
    const { queries, snapshots, isLoading, loadings, setLoading } = useContext(AppContext);
    const [user, loading, error] = useAuthState(auth);
    const [filledQuestionnaires, setFilledQuestionnaires] = useState(null);
    const [groupList, setGroupList] = useState(null);
    const [profile, setProfile] = useState(null);
    const [myGroups, setMyGroups] = useState([]);
    const [lastSampling, setLastSampling] = useState(localStorage.getItem('lastSampling'));

    const getData = (model) => {
        if (model && model[0] && model[0].data)
            return { ...model[0].data, id: model[0].id }
        else
            return null
    }

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            setMyGroups(snapshots['allgroups']);
        }
    }, [snapshots['allgroups']]);

    useEffect(() => {
        if (profile) {
            const groups1= profile?.groups ?? [];
            const admins = profile.isAdmin && profile.admins ? profile.admins : [];
            const all = groups1?.concat(admins);
            setGroupList(all)
        }
    }, [profile]);

    useEffect(() => {
        if (snapshots && snapshots['profile']) {
            setProfile(getData(snapshots['profile']));
        }
    }, [snapshots['profile']]);

    useEffect(() => {
        setFilledQuestionnaires(snapshots['filledquestionnaires']);
    }, [snapshots['filledquestionnaires']]);

    return (
        <View
            profile={profile}
            questionnaires={filledQuestionnaires}
            groups={myGroups}
            selectItem={'sampling'}
            lastSampling={lastSampling}
        />
    );
};

export default Questionnaires;