import React from 'react';
import { Box } from '@mui/material';
import StorageAvatar from '../../firebase/StorageAvatar';

const NextGroup = ({ group, onClick }) => {
    const name = group?.data?.name;
    const icon = group?.data?.Profilkép;

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: `calc(100vh - 200px)`,
                bgcolor: 'background.paper',
                position: 'relative',
                padding: 0,
            }}>
            <div className='centerContent' onClick={() => onClick()}>
                <h3>Következő értékelt</h3>
                <h1>{name}</h1>
                <div className='avatar-container'>
                    <StorageAvatar
                        value={icon}
                        bucket={'group_images'}
                        size='xxxlarge'
                    />
                </div>
                <p className='infoContent'>Tovább az értékeléshez</p>
            </div>
        </Box>
    );
};

export default NextGroup;