import { images } from "./images"

export const labels = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Címkék',
    image: images.labels1,
    description: `
    A címkék olyan kategóriák, amelyek lehetővé teszik, hogy kérdéseket csoportosítsunk és ezekre a csoportokra vonatkozó értékeléseket együtt vizsgáljuk. 
    `,
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Példa',
            description: `
Például, ha van egy "Figyelt-e arra, hogy igényes legyen a munkája?" és egy "Figyeltél-e arra, hogy igényes legyen a munkád?" kérdésünk, ezeket összekapcsolhatjuk az "Igényes munka" címkével, és így együtt értékelhetjük ezeket a kérdéseket.            `,
            image: images.labels1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Normalizálás',
            description: `
A címkék dinamikusan változtathatóak, ami lehetővé teszi, hogy rugalmasan kezeljük és alkalmazzuk őket a kérdések és értékelések csoportosításában.

A címkék során érdemes normalizált értékeket használni, mivel a kérdések eltérő skálákat használhatnak. Ennek érdekében az értékeléseket minden skálát 0-100 közötti értékre konvertáljuk, így összevethetővé válnak az eltérő skálájú értékelésekre leadott válaszok. Például az 1-3 vagy 1-10 skálákat egységesen 0-100 közötti értékekkel lehet értelmezni és összehasonlítani.
            `,
            // image: images.labels1,
            help: null,
        },
        {
            type: 'title', // slide, link
            selector: '.stat2',
            content: 'Címkék szerkesztése',
            description: `
A címkék szerkesztése lehetővé teszi, hogy testreszabjuk és finomhangoljuk a kérdések és az ezekre adott értékelések csoportosítását. Ez az eszköz rugalmasan lehetőséget biztosít az adatok elemzéséhez.
A címkék szabadon módosíthatók az alkalmazásban, ami azt jelenti, hogy az oktatók és csoport adminisztrátorok könnyen hozzáigazíthatják a címkéket a specifikus igényeikhez és céljaikhoz.
A címkék a címkék szerkesztése  gomb alatt érhetőek el, Ez a gomb lehetővé teszi, hogy hozzáférjünk a címkék kezeléséhez szükséges eszközökhöz.
            `,
            // image: images.groupstatsLabelsEditor1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.mygroupsEdit',
            content: 'Navigálás a címkék szerkesztéséhez',
            description: 'A címkék szerkesztése a csoport oldalán, a statsztika fülön található "Címkék szerkesztése" gombbal érhető el.',
            image: images.groupstats1,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 33,
            markYPercent: 41,
            markRectangleWidth: 49,
            markRectangleHeight: 49,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.stat3',
            content: 'Nézetek',
            description: `
            A címkék kétféle nézetben tekinthetők meg: címke nézet és kérdések nézet.
címke nézet: ebben a nézetben az egyes címkéket és azokhoz kapcsolódó kérdéseket láthatjuk
            `,
            image: images.groupstatsLabelsEditor1,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 12,
            markYPercent: 29,
            markRectangleWidth: 44,
            markRectangleHeight: 33,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.stat3',
            content: 'Kérdések nézet',
            description: `
kérdések nézet: ebben a nézetben az összes kérdés listáját tekinthetjük meg, és láthatjuk, hogy melyik címkéhez tartoznak

            `,
            image: images.groupstatsLabelsEditor2,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 12,
            markYPercent: 29,
            markRectangleWidth: 39,
            markRectangleHeight: 33,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
        {
            type: 'slide', // slide, link
            selector: '.stat3',
            content: 'Kérdések kezelése',
            description: `
Címke nézetben a címkékhez új kérdések adhatók hozzá és törölhetők. Ez lehetővé teszi, hogy a címkéket pontosan azokhoz a kérdésekhez rendeljük hozzá, amelyek relevánsak az adott kategória szempontjából. Kérdések nézetben a kérdésekhez adhatóak új címkék 
Új címke is létrehozható az az új címke gomb  segítségével , ami azt jelenti, hogy teljesen új kategóriákat hozhatunk létre az értékelések csoportosítására.
            `,
            image: images.groupstatsLabelsEditor3,
            help: null,
            mark: 'rect',
            markRadius: 30,
            markXPercent: 14,
            markYPercent: 15,
            markRectangleWidth: 56,
            markRectangleHeight: 95,
            animations: [
                { name: 'cursor', action: 'move' }
            ]
        },
    ]
}
