import React, { useContext, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';

const CreateQuestionnaireAction = () => {
    const { createDocument } = useContext(AppContext);

    const subscribe = async ({ group, from, to, scope, template, qtemplate, evaluate }) => {
        const newRecord = {
            group: group,
            from: from,
            to: to,
            scope: scope,
            template: template,
            qtemplate: qtemplate,
            evaluate: evaluate
        }
        const result = await createDocument({ table: 'questionnaires', record: newRecord })
        // console.log('rrrrrrrr', result.id);
        return result;
    }

    return {
        subscribe
    }
};

export default CreateQuestionnaireAction;