import React from 'react';
import SubscribedUser from './SubscribedUser';
import DeleteAdminAction from '../../services/actions/DeleteAdminAction';

const AdminUsers = ({ users, groupId = null, isAdmin = false, group, navigateToUser = false }) => {
    const { deleteAdminAction } = DeleteAdminAction();
    return (
        <div>
            {users && Object.keys(users).map((g, index) => {
                return (
                    <SubscribedUser
                        level='admin'
                        group={group}
                        key={`admin-${users[g]}`}
                        user={users[g]}
                        groupId={groupId}
                        deleteAction={isAdmin ? deleteAdminAction : undefined}
                        navigateToUser={navigateToUser}
                    />)
            })}
        </div>
    );
};

export default AdminUsers;