import React from 'react';
import { valuesFields } from '../anonimousData/Constansts'; // Az import útvonalat szükség szerint igazítsd
import LabelsSeriesFilterChart1 from './chart/LabelsSeriesFilterChart1';
import generateChartDescriptionJSON from '../anonimousData/generateChartDescriptionJSON';

const BChart2 = ({ label, data, options, allFields, meta }) => {

    const {
        defaultCategoryField,
        defaultSeriesField,
        defaultValuesField,
        defaultChartType,
        filterFields,
        // title,
        // description,
        help
    } = options;

    const filters = filterFields?.map(element => {
        let field = { ...allFields[element.field], multiselect: element.multiselect };
        return field;
    });

    const { date: deleted, ...rest } = allFields;
    const chartDescriptionJSON = generateChartDescriptionJSON(label, options, allFields);

    const seriesFields = {
        ...rest,
        nincs: { label: 'nincs' },
    };

    const chartValuesFields = [
        { ...valuesFields['evaluated_count'], groupByFields: [allFields['evaluated'], valuesFields['filledQuestionnaire']] },
        { ...valuesFields['filledQuestionnaire_count'], groupByFields: [valuesFields['filledQuestionnaire']] },
        { ...valuesFields['evaluated_value'], stat: ['count', 'min', 'max', 'average', 'median', 'variance'], defaultValue: 'average' },
        { ...valuesFields['evaluated_value_normalized'], stat: ['count', 'min', 'max', 'average', 'median', 'variance'], defaultValue: 'average' },
        valuesFields['emotional']
    ];

    return (
        <> {data &&
            <LabelsSeriesFilterChart1
                data={data}
                categoryFields={allFields}
                seriesFields={seriesFields}
                filterFields={filters}
                valuesFields={chartValuesFields}
                defaultCategoryField={allFields[defaultCategoryField]}
                defaultSeriesField={defaultSeriesField ? allFields[defaultSeriesField] : null}
                defaultValuesField={Object.keys(valuesFields).indexOf(defaultValuesField)}
                meta={meta}
                help={help}
                helpData={chartDescriptionJSON}
                defaultChartType={defaultChartType || 'bar2'}
                showCategoryPanel={false}
                showSeriesPanel={false}
                showValuesPanel={false}
                showFilterPanel={false}
            />
        }
        </>
    );
};

export default BChart2;
