import Multiselect from 'multiselect-react-dropdown';
import React from 'react';

const FSPFieldModelMultiSelect = ({
    id,
    label,
    placeholder,
    model,
    valuesKey,
    valuesLabel,
    selected,
    onRemove,
    onSelect,
    addId
}) => {
    // console.log('model',model )
    const options = (typeof model === 'object') ?
        Object.keys(model).map
            ((option, index) => (
                { "id": model[option][valuesKey], "name": model[option].data[valuesLabel] }
            )) : [];
            // console.log('options',options )
    var optionsFiltered = options.filter((el) => typeof el.id == 'string' && typeof el.name == 'string')
    if (addId) {
        optionsFiltered = [...optionsFiltered, { "id": "id", "name": "id" }]
    }
    const selectedValues =
        optionsFiltered &&
            selected
            ? optionsFiltered.filter(function (item, index) {
                return (selected.indexOf(item[valuesKey]) > -1);
            })
            : [];
    return (
        <div>
            <div>
                {label}
            </div>
            <Multiselect
                id={"persid" + id}
                name={id}
                placeholder={placeholder}
                style={{ zIndex: '999999' }}
                options={optionsFiltered ?? []}
                selectedValues={selectedValues ?? []}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue={valuesLabel}
            />
        </div>
    );
};

export default FSPFieldModelMultiSelect;