const QuestionsUtitity = () => {
    const generateQuestions = ({ form, meta }) => {
        const sections = form.section;
        // console.log('form', form);
        // console.log('sections', sections);

        const questions = [];
        sections.forEach((section, index) => {
            const fields = section.fields ? section.fields : section.questions;
            // console.log('section', section);
            // console.log('fields', fields);
            fields.forEach(field => {
                // console.log('field', field);
                // TODO: generálás személyekre...
                questions.push({
                    ...field,
                    section: section.name,
                    questionType: 1,
                    data: []
                }
                )
            })
        })
        // console.log('questions', questions);
        return questions;
    }

    const generateStepper = ({ slideItems, startPage, submitPage }) => {
        const s = [startPage];
        const steps = [];
        slideItems.forEach(field => {
            // console.log('field', field);
            // TODO: generálás személyekre...
            if (!steps[field.section]) {
                steps[field.section] = field.section
                s.push(field.section)
            }
        })
        s.push(submitPage)
        // console.log('steps', s);
        return s;
    }

    const findProjects = (arrayOfArrays) => {
        if (!Array.isArray(arrayOfArrays) || arrayOfArrays.length === 0) {
            return [];
        }
        const alapTomb = arrayOfArrays[0];
        const kozosStringek = alapTomb.filter((elem) => {
            return arrayOfArrays.every((tomb) => tomb.includes(elem));
        });

        return kozosStringek;
    }

    const filterQtemplatesByProjects = (projects, qtemplates) => {
        if (!Array.isArray(projects) || !Array.isArray(qtemplates)) {
            // Ellenőrizzük, hogy mindkét bemeneti érték tömb-e
            return [];
        }

        // Szűrjük az 'qtemplates' tömböt azokra az objektumokra, amelyeknél a 'data.projects' tömb tartalmazza az egyik 'projects' elemet
        const filteredQtemplates = qtemplates.filter((qtemplate) => {
            return qtemplate.data.projects.some((project) => projects.includes(project));
        });

        return filteredQtemplates;
    }

    return {
        generateQuestions, generateStepper, findProjects, filterQtemplatesByProjects
    };
};

export default QuestionsUtitity;