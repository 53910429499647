import React, { useContext, useEffect, useState } from "react";
import { useAuthStatus } from "../../hooks/useAuthStatus";
import Spinner from "../../components/Spinner";
import { AppContext } from '../../services/appContext';
import CheckingStatus from "../../commons/CheckingStatus";
import EvaluationAlert from "../../components/alerts/EvaluationAlerts";
import InstallPwa from "../../commons/InstallPwa";
import UploadAvatarWarning from "../../commons/UploadAvatarWarning";
import GroupQrCodes from "../../components/groups/GroupQrCodes";
import HelpButton from "../../commons/help/HelpButton";
import HelpIcons from "../../commons/help/HelpIcons";
import { apps } from "../../data/apps";
import Personal from "../../components/dashboard/Personal";
import CreateFCMessage from "../../services/actions/CreateFCMessage";
import ErrorReport from "../../commons/admins/ErrorReport";
// import DashboardItem from "../../components/dashboard/DashboardItem";
// import AllSubscribedUser from "../../components/subscribed/AllSubscribedUser";
// import DashboardTour from "../../commons/tours/dashboard/DashboardTour";
// import Help from "../../commons/help/Help";
// import { helps } from "../../commons/help/data/helps";
// import HelpTour from "../../commons/help/HelpTour";
// import ResultCard from "../../commons/cards/ResultCard";
// import { PhotoAlbum } from "@mui/icons-material";
// import ResultCards from "../../components/cards/ResultCards";
import GestureHandler from "../../components/touch/GestureHandler";
import BFab from "../../commons/fab/BFab";

function Dashboard() {
  const { checkingStatus } = useAuthStatus();
  const { selectedApp, isAdmin, isManager } = useContext(AppContext);
  const [profile] = useState(null);
  const [server, setServer] = useState('');
  const { subscribe } = CreateFCMessage();

  useEffect(() => {
    const href = window.location.href;
    if (href.indexOf('localhost:') > -1) {
      setServer('- Dev')
    }
    if (href.indexOf('demo.') > -1) {
      setServer('- Demó')
    }
  }, []);

  if (checkingStatus) {
    return <Spinner />;
  }
  const handlePushNotification = () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification('Teszt üzenet küdése', {
          body: 'Új kitöltés',
        });
      });
    } else {
      console.error('A service worker or push notifications are not supported in this browser.');
    }
  };

  const getUrl = () => {
    console.log(window.location.origin);
    console.log(window.location.origin === 'http://localhost:3000');
    const url = window.location.origin === 'http://localhost:3000' ? 'https://demo.b612.hu' : window.location.origin;
    return url;
  };

  return <>

    <CheckingStatus />
    {/* <BFab
      show={true}
      actions={
        [
          {
            text: "Email",
            icon: 'Email',
            fontSize: '30',
            color: '#ffffff',
            backgroundColor: '#3498db',
            onClick: () => alert('Here is your notification.'),
            link: '/dashboard',
            confirm: false,
            show: true
          },
          {
            text: "Help",
            icon: 'Help',
            fontSize: '30',
            color: '#ffffff',
            backgroundColor: '#3498db',
            // onClick: () => alert('Here is your notification.'),
            link: '/profile',
            confirm: false,
            show: true
          }
        ]}
    /> */}
    {/* <GestureHandler
      left={false}
      right={'offers'}
    > */}
    < div className="profileList" >
      <header>
        <p className="pageHeader">{apps[selectedApp]?.label} {server} </p>
      </header>
      <main>
        <div className="listingsList">
          <UploadAvatarWarning profile={profile} />
          <InstallPwa />
          <EvaluationAlert />
          {/* <ErrorReport screenshot={true} /> */}
          {/* <button onClick={() => handlePushNotification()}>
            Indítsd el a push értesítést!
          </button>
          <button onClick={() => { subscribe({ name: 'Teszt', icon: 'https://kerdoiv.b612.hu/apps/demo.png', url: getUrl() }) }}>
            Új üzenet
          </button> */}
          {/* <AllSubscribedUser /> */}
          <Personal />
          <GroupQrCodes isAdmin={isAdmin || isManager} />
          {/* <InfoIcons /> */}
          <HelpIcons steps='documentation' />
        </div>
        {/* <DashboardTour /> */}
        {/* <HelpTour steps={helps.version01.steps} /> */}
        {/* <HelpButton steps='documentation' /> */}
        <ul className="listingsList">
          <p></p>
          <p></p>
        </ul>
        <br /><br />  <br /><br />
      </main >
    </div >
    {/* </GestureHandler> */}
  </>;
}

export default Dashboard;
