export const firebaseConfigDemo = {
    apiKey: "AIzaSyCoPMMjPpA9XY5pEfQ-YfgFxgny9nsqgts",
    authDomain: "edunetprvet-eea49.firebaseapp.com",
    databaseURL: "https://edunetprvet-eea49-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "edunetprvet-eea49",
    storageBucket: "edunetprvet-eea49.appspot.com",
    messagingSenderId: "671993670064",
    appId: "1:671993670064:web:872860d05dceae83920e75",
    measurementId: "G-KEW941ZBD4"
};

// KESZ CSOPORT (kesz-9e5e4)
export const firebaseConfigKesz = {
    apiKey: "AIzaSyA57IC7QYNrQAFJyFBefLzSzSo9ToqZl94",
    authDomain: "kesz-9e5e4.firebaseapp.com",
    projectId: "kesz-9e5e4",
    storageBucket: "kesz-9e5e4.appspot.com",
    messagingSenderId: "149409422510",
    appId: "1:149409422510:web:87185f5c4442db0082213f",
    measurementId: "G-KSVL7X6Q6H"
};

// új edunet2 (edunet-619f3)
export const firebaseConfig = {
    apiKey: "AIzaSyBtYB0FQTasQBxhiQuZSmBpz04_dCTSJ-4",
    authDomain: "edunet-619f3.firebaseapp.com",
    databaseURL: "https://edunet-619f3-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "edunet-619f3",
    storageBucket: "edunet-619f3.appspot.com",
    messagingSenderId: "583781021336",
    appId: "1:583781021336:web:9989e36b7e7e20f54202ed",
    measurementId: "G-BQ3P8822TL"
};

export const apps = {
    "demo": {
        "defaultApp": false,
        "vapidKey": "BFBn84qAP35iy208a2lXopjwhduQz5YXGUPYAs5DzhZS00arOaiLogrAOT4cP2ZpER2x1SsmRU3FqXL6elA3tjM", 
        "sdkVersion": "0.1",
        "version": "0.1",
        "updatedAt": {
            "seconds": 1684247044,
            "nanoseconds": 443000000
        },
        "config": firebaseConfigDemo,
        "name": "Teszt",
        "id": "demo",
        "icon": "Psychology",
        "logo": "https://kerdoiv.b612.hu/apps/demo.png",
        "label": "Demó"
    },
    "kesz": {
        "sdkVersion": "0.1",
        "defaultApp": false,
        "vapidKey": "BMwmTbhnhD3JtN7AcjeSsO92k95h8IZkld9yV5G6niAG7rQNOXQKqwjoWXeRLOo7RaldtXtuJlEUWt9jX-sc6B0", 
        "version": "0.1",
        "updatedAt": {
            "seconds": 1684247044,
            "nanoseconds": 443000000
        },
        "config": firebaseConfigKesz,
        "name": "Kész",
        "id": "kesz",
        "icon": "Token",
        "logo": "https://kerdoiv.b612.hu/apps/kesz.png",
        "label": "KÉSZ CSOPORT"
    },
    "firstApp": {
        "defaultApp": true,
        "vapidKey": "BNMzGTwtwyzDvLYKRf802p1VIsaVBL1uxUNzOaT9wrulcA4fUcTFv67dHc4aM-Qvz-nn3J1MpIoYHNOD4W_Zcwo", 
        "label": "PR-VET EDUNET",
        "icon": "Diversity3",
        "logo": "https://kerdoiv.b612.hu/apps/logo.png",
        "version": "0.1",
        "name": "PR-VET",
        "config": firebaseConfig,
        "sdkVersion": "0.1",
        "updatedAt": {
            "seconds": 1684247196,
            "nanoseconds": 238000000
        },
        "id": "firstApp"
    }
};