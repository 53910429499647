import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase.config";
import { AppContext } from "../../services/appContext";
import Modern from "./themes/Modern";
import Standard from "./themes/Standard";

function Navbar() {
  const { snapshots, newQuestionnaires, theme } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, loading] = useAuthState(auth);
  const [value, setValue] = React.useState(location.pathname);
  const [profile, setProfile] = useState(null);
  const enabledPath = [
    "/sign-in",
    "/sign-out",
    "/forgot-password",
    "/apps",
    "/app/",
  ];

  useEffect(() => {
    if (loading) return;
    if (
      !user &&
      enabledPath.indexOf(value) < 0 &&
      value.indexOf("/app/") !== 0 &&
      value.indexOf("/apps/") !== 0
    )
      console.log('location', location);
    return navigate("/sign-in",
      {
        state: {
          from: location
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  const handleSubmit = (a) => {
    setValue(a);
    navigate(a);
  };

  useEffect(() => {
    if (snapshots && snapshots["profile"]) {
      setProfile(snapshots["profile"]);
    }
  }, [snapshots["profile"]]);

  return (
    <>
      {theme === 'modern' &&
        <Modern
          user={user}
          profile={profile}
          handleSubmit={handleSubmit}
          location={location}
          newQuestionnaires={newQuestionnaires}
        />
      }
      {theme === 'standard' &&
        <Standard
          user={user}
          profile={profile}
          handleSubmit={handleSubmit}
          location={location}
          newQuestionnaires={newQuestionnaires}
        />
      }
    </>
  );
}

export default Navbar;
