import { images } from "./images"

export const groupStatTypes = {
    isAdmin: false,
    icon: 'PhonelinkLock',
    type: 'help',
    content: 'Értékelés típusok',
    description: `
    Az értékelések több jellemzője is fontos, de az egyik legfontosabb az értékelt és értékelő közötti kapcsolat. Ezek alapján több értékelési típus csoportosítás létezik, melyek segítségével átfogó képet kaphatunk a diákok teljesítményéről és fejlődéséről, valamint azonosíthatjuk azokat a területeket, ahol további támogatásra van szükség. Az első és legfontosabb típus az "Önértékelés és értékelés".
    `,
    steps: [
        {
            type: 'slide', // slide, link
            selector: '.stat1',
            content: 'Önértékelés és értékelés',
            description: `
            Az applikációban használt kérdőívek két fő értékelési típust alkalmaznak: önértékelés és értékelés. Az önértékelés során a diákok saját magukat értékelik. Ez lehetőséget biztosít számukra, hogy reflektáljanak saját teljesítményükre és fejlődésükre. Az értékelés esetében a tanárok értékelik a diákokat, ami külső visszajelzést nyújt a diákok számára. Az alkalmazásban jelenleg négy kérdőívet használunk: kettőben a diákok önmagukat értékelik, a másik kettőben pedig a tanárok értékelik a diákokat.
            Az önértékelések és a tanári értékelések összehasonlítása értékes információkat nyújt mind a diákok, mind az oktatók számára. Például, egy diák önértékelését összevethetjük a rá érkezett tanári értékelésekkel, így azonnal láthatóvá válik, hogy a diák saját megítélése mennyire egyezik a tanárok értékelésével. Ez segíthet azonosítani azokat a területeket, ahol a diák reálisan értékeli magát, illetve ahol esetleg tévesen ítéli meg saját teljesítményét. 
            `,
            image: images.stat1,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat2',
            content: 'Értékelő perspektíva',
            description: `
            Az oktatók által adott értékeléseket tovább finomíthatjuk az értékelő perspektívájának figyelembevételével, azaz különválaszthatjuk a saját értékeléseket a többi oktató értékeléseitől. Így három csoportot különíthetünk el: önértékelés, saját értékelések (én értékeléseim) és mások értékelései (a többi oktató értékelése).
            Ez a megközelítés segíthet összehasonlítani az oktatók értékeléseit és az esetleges eltéréseket. Az ilyen típusú összehasonlítások pedig lehetővé teszik az oktatók számára, hogy megértsék és elemezzék mi okozza ezeket az eltéréseket az értékelésekben.
            `,
            image: images.stat2,
            help: null,
        },
        {
            type: 'slide', // slide, link
            selector: '.stat3',
            content: 'Csoport perspektíva',
            description: `
            Az értékeléseket tovább finomíthatjuk a csoport perspektívájának figyelembevételével. Ezen megközelítés során lehetőségünk van a korábban tárgyalt ("én értékelésem" és "mások értékelései") értékelések mellett megtekinteni, hogy hogyan értékeltem más diákokat, hogy más oktatók hogyan értékelnek más diákokat. Emelett a diák önértékelését összevethetjük a többi diák önértékelésével. (Megjegyzés: jelenleg más, lehetséges értékelési típusok (pl. az "önértékelésem" és az "engem értékelnek") nem szerepelnek a statisztikákban, mivel nincs olyan kérdőív, amely az oktatóra vonatkozna.)
            Így egy diák értékelése során nemcsak azt vizsgálhatjuk meg, hogy én és a többi oktató hogyan értékelte a diák teljesítményét, hanem azt is, hogy általában hogyan értékeltük a csoportban. Ez a megközelítés kontextust ad az értékeléseknek, és segít mélyebb betekintést nyerni abba, hogy hogyan látják és értékelik egymást a csoport tagjai. Ezáltal hozzájárulhatunk a csoportdinamika és a tanulási folyamatok jobb megértéséhez és támogatásához.
            `,
            image: images.stat3,
            help: null,
        },
    ]
}
