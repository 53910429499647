import React from 'react';
import { FormControl, InputLabel, ToggleButton, ToggleButtonGroup, BottomNavigationAction, Typography } from '@mui/material';
import CIcon2 from '../../../commons/CIcon2';

const MyToggleButtonGroup = ({ selectedFilter, handleFilterChange, filters, title = 'Napi/heti/havi bontás' }) => {
    const handleAlignment = (event, newModule) => {
        event.preventDefault();
        // console.log(newModule);
        if (newModule)
            handleFilterChange(filters[newModule]);
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '5px' }}>
            <ToggleButtonGroup
                // className='a'
                value={selectedFilter?.value}
                exclusive
                showLabel
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                {Object.keys(filters).map((key) => (
                    <ToggleButton key={key} value={key}>
                        {/* {filterks[key].label} */}
                        <CIcon2
                            fontSize={'normal'} // Javítsd ki a kívánt értékre, pl. 'large'
                            color={'rgba(0, 0, 0, 0.54)'}
                            icon={filters[key].icon}
                            rotated={filters[key].rotated}
                        />
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Typography variant="span">
                {title}
            </Typography>
        </div>
    );
};

export default MyToggleButtonGroup;
