import React from 'react';
import QuestionnaireItemList from '../../components/questionnaire/QuestionnaireListItem';
import { convertArrayById3 } from '../../commons/Utility';
import ScrollToBottomIcon from '../../components/buttons/RBButton';
import { Add } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import DoneMessage from '../../commons/DoneMessage';

const View = ({ questionnaires, groups, selectItem, profile }) => {
    const navigate = useNavigate();
    // console.log('questionnaires', questionnaires);
    // console.log('groups', groups);

    const g = convertArrayById3(groups, 'id');
    return (
        <div className="profileList">
            <header className="profileHeader">
                <p className="pageHeader">Kérdőívek kezelése</p>
            </header>

            <main>
                {typeof questionnaires == 'object' && Array.isArray(questionnaires) && questionnaires.length < 1 &&
                    <DoneMessage icon='Quiz' label='Jelenleg nincs kitöltendő kérdőív' />
                }
                <div className="listingsList">
                    {questionnaires && groups &&
                        questionnaires.map((element, index) => {
                            return (
                                <QuestionnaireItemList
                                    key={index}
                                    profile={profile}
                                    questionnaire={element}
                                    group={g[element.data.group]}
                                    selectItem={selectItem}
                                />
                            )
                        }
                        )
                    }
                </div>
            </main>
            <ScrollToBottomIcon
                name={'Kérdőív készítés'}
                icon={<Add />}
                onClick={() => { navigate('/start'); }}
            />
        </div>
    );
};

export default View;